import React, { Component } from 'react'
import moment from 'moment';

export default class UserData extends Component {
  render() {
    let { user } = this.props
    return (
      <div className="user-profile-container">
        <span className="fl-right user-profile-status">
            {user && user.active ? 'Активный' : 'Заблокирован'}
        </span>
        <b>Данные пользователя</b>
        <div className="hr"></div>

        <div className="w-50">
            Организационная структура
            <br/><br/>
            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-project-diagram"></i>
                    Компания
                </div>
                <div>
                    {user && user.company || '-'}
                </div>
                <div className="clear"></div>
            </div>

            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-project-diagram"></i>
                    Объект
                </div>
                <div>
                    {user && user.filial || '-'}
                </div>
                <div className="clear"></div>
            </div>

            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-project-diagram"></i>
                    Отдел
                </div>
                <div>
                    {user && user.department || '-'}
                </div>
                <div className="clear"></div>
            </div>

            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-user-tie"></i>
                    Должность
                </div>
                <div>
                    {'-'}
                </div>
                <div className="clear"></div>
            </div>

            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-calendar-alt"></i>
                    Прием на работу
                </div>
                <div>
                    {'-'}
                </div>
                <div className="clear"></div>
            </div>
        </div>
        <br/>
        <div className="w-50">
            Личная информация
            <br/><br/>
            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-envelope"></i>
                    E-mail
                </div>
                <div>
                    {user && user.email || '-'}
                </div>
                <div className="clear"></div>
            </div>

            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-phone-square"></i>
                    Телефон
                </div>
                <div>
                    {user && user.phone || '-'}
                </div>
                <div className="clear"></div>
            </div>

            <div className="user-profile-l-r mb-15">
                <div>
                    <i className="far fa-calendar-alt"></i>
                    Дата рождения
                </div>
                <div>
                    {user && user.birth_date && moment(user.birth_date).format('DD-MM-YYYY') || '-'}
                </div>
                <div className="clear"></div>
            </div>
        </div>

        {/* <br/><br/>
        <b>Задачи</b>
        <div className="hr"></div> */}
      </div>
    )
  }
}
