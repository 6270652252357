import get from 'lodash/get'
import React, { Component } from 'react'
import ViewCourses from './view';
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';
import BlockEducation from './../../contents/block-education';
import BlockTest from './../../contents/block-test';
import history from '../../history';

export default class Lecture extends Component {
    state = {
        materials: null,
        course: null,
        loaderMaterial: false,
        loaderLast: false,
        limit: 5,
        offset: 0
    }
    componentWillMount(){
      let { limit, offset} = this.state
      header.pageName('Лекции')

      this.setState({loaderMaterial: true}, () => {
        webApi.getLecture(this.props.match.params.id, limit, offset)
        .then(data => {
          if(data.status === 200){
            this.setState({
              materials: data.response,
              loaderMaterial: false,
              //offset: this.state.offset + limit,
            })
          }else {this.setState({loaderMaterial: false})}
        })
        .catch(error => error)

        webApi.getCourse(this.props.match.params.id).then(data => {
          if(data.status === 200) (data.response.test && data.response.test.published) && this.setState({course: data.response.test}, () => console.log())
        }).catch(err => console.log(err))
      })
    }

    handlePush = (e, index) =>{
      if (index.after_test_id && !index.after_test) {
        header.getSnackbar(`Чтобы перейти к обучению материала, пройдите тест "${get(index, 'after_test_title.title')}"`)
      } else {
        header.addBreadcrumps({title:e.currentTarget.dataset.title, route: '/lectures/'+e.currentTarget.dataset.route})
        history.push('/lesson/'+e.currentTarget.dataset.route)
      }
    }

    renderMaterial = () => {
        let { materials } = this.state
        if(materials){
          //.filter(e => e.active === true)
          return materials.sort(({ order: ordA }, { order: ordB }) => ordA - ordB).map((index) => {
            return(<div className="cur-p" key={ index.id }>
                    <div data-title={index.title} data-route={index.id} onClick={e => this.handlePush(e, index)}><BlockEducation body = {index}/></div>
                    {index.test && index.test.published && <div><BlockTest body = {index} test_id={index.test.id} title={index.test.title} time_to_pass={index.test.time_to_pass} questions_amount={index.test.questions_amount} min_score_points={index.test.min_score_points} /></div>}
                  </div>)
          })
        }
        else {
          return (<div><br/>На данный момент материалов для обучения нет</div>)
        }
    }

    loadMaterial = () =>{
      let { limit, offset, materials } = this.state

      this.setState({loaderMaterial: true}, () => {
        webApi.getLecture(this.props.match.params.id, limit, offset + limit)
        .then(data => {
          if(data.status === 200){
            this.setState({
              materials: [...materials, ...data.response],
              offset: this.state.offset + limit,
              loaderMaterial: false
            })
          }else this.setState({loaderLast: true, loaderMaterial: false})
        })
      })
    }

    render() {
        return (
            <ViewCourses
              tests = {this.state.course}
              blockEducation = { this.renderMaterial() }
              loadMaterial = {this.loadMaterial}
              loaderLast = {this.state.loaderLast}
              loaderMaterial = {this.state.loaderMaterial}
              materials = {this.state.materials}
            />
        )
    }
}
