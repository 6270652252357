import React, { Component } from 'react'
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import {stateFromHTML} from 'draft-js-import-html'
// import { EditorState, convertToRaw, ContentState, AtomicBlockUtils } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import history from '../../history';
import webApi from '../../../../../utilities/web-api';
//import audio from '../../../../../../audio/50cent.mp3'
//import AudioPlayer from './../../../../../utilities/audio-player/index';
import { header } from './../../../../../utilities/page';
import getDiskSpace from '../../../../../utilities/disk-space';
import Select from 'react-select';
import CkEditor from '../../ckEditor/ckedit'

// class CustomOption extends Component {
//     addImage = (src, height, width, alt) => {
//         const { editorState, onChange, config } = this.props;
//         const entityData = { src, height, width };
//         if (config && config.alt.present) {
//           entityData.alt = alt;
//         }
//         const entityKey = editorState
//           .getCurrentContent()
//           .createEntity('IMAGE', 'MUTABLE', entityData)
//           .getLastCreatedEntityKey();
//         const newEditorState = AtomicBlockUtils.insertAtomicBlock(
//           editorState,
//           entityKey,
//           ' ',
//         );
//         onChange(newEditorState);
//     };



//     render() {
//       return (
//         <div className="rdw-emoji-wrapper" onClick={() => {
//             getDiskSpace(result => {
//                 this.addImage(result)
//             }, {
//                 type: false,
//                 fullUrl: false
//             })
//         }}><i className="fal fa-image custom-wysiwyg"></i></div>
//       );
//     }
//   }

export default class ViewCreateLecture extends Component {
    state = {
        editorState: null,
        lecture: {},
        input:{},
        trigger: [],
        type: null
    }

    componentWillMount(){
        header.pageName('Редактирование лекции')
        webApi.getLectureId(this.props.match.params.id)
        .then(async data => {
            if(data.status === 200){
                await header.addBreadcrumps({title:data.response.title, route:`/edit-lecture/${data.response.id}`})
                let obj = await data.response 
                await data.response.after_test_id && webApi.getTest(data.response.after_test_id).then(data => obj.after_test_title = data.response.title)
                await this.setState({lecture: obj, type: data.response.type}, () => {
                    console.log(this.state.lecture)
                    webApi.getCourse(this.state.lecture.course_id)
                    .then(data => {
                        this.setState({lecture:{
                            ...this.state.lecture,
                            course_title: data.response.title
                        }, input:{
                            id: this.props.match.params.id,
                            additional_materials: this.state.lecture.additional_materials
                        }}, () => {
                            let memoryTrigger = []
                            this.state.input.additional_materials.map(el => {// {id: `#${el.title}`, name: `${el.title}`, link:el.link}]
                                return memoryTrigger=[...memoryTrigger, {id: `#${el.title}`, href: `#${el.url}&${el.type}` , name: `${el.title}`, link:`#${el.url}&${el.type}`}]
                            })
                            this.setState({
                                trigger:memoryTrigger,
                                editorState: this.state.lecture.html_content
                            }, () => console.log(this.state.trigger))
                        })                           
                    }
                    )
                    .catch(error => console.log(error))
                })
            }
        })
        .catch(error => console.log(error))
    }


    updateLecture = async () => {
        //draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        await this.setState({input:{
            ...this.state.input,
            html_content: this.state.editorState,
            after_test_id: this.state.after_test_id ? this.state.after_test_id.id : null
        }})

        if(this.state.input.estimation) await this.setState({input:{...this.state.input, estimation: this.state.input.estimation * 60}})

        let obj = await this.state.input
        await !obj.after_test_id && delete obj.after_test_id
        await this.state.after_test_id === null && (obj.after_test_id = null)
        
        await this.state.input.additional_materials && this.state.input.additional_materials.map((el, i) => {
            !el.title && obj.additional_materials.splice(i, 1)
            !el.order && delete el.order
            !el.description && delete el.description
            return true
        })

        if(this.state.lecture.type !== 'mixed'){
            obj.type = this.state.lecture.type
            obj.video_url = this.state.video_url
        }

        // if(!obj.description) return header.getSnackbar('Ошибка: "Краткое описание лекции" не заполнено')

        // if(!obj.estimation) return header.getSnackbar('Ошибка: "Продолжительность лекции" не заполнено')
        if(!obj.html_content) return header.getSnackbar('Ошибка: Материал лекции не может быть пустым')
        if(obj.estimation && obj.estimation > 8100000) return header.getSnackbar('Рекомендуемое время не может приувеличивать 135000 мин. (3 месяца)')

        await webApi.updateLecture(obj)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar('Лекция успешно изменена')
                history.goBack()
            }
        })
        .catch(err => console.log(err))
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
    };

    handleInput = e => {
        if(e.target.name === 'estimation'){
            this.setState({input:{
                ...this.state.input,
                estimation: e.target.value.replace(/\D/, '')
                }
            })
        }else{
            this.setState({input:{
                ...this.state.input,
                [e.target.name]: e.target.value
                }
            })
        }
    }

    uploadImg = e => {
        this.setState({input:{
            ...this.state.input,
            images: e
        }})
    }

    inputMemory = (e, index) => {
        let obj = this.state.input.additional_materials
        obj[index].title = e.target.value
        delete obj[index].order
        delete obj[index].description

        let objTrigger = this.state.trigger
        objTrigger[index].id = `#${e.target.value}`
        objTrigger[index].name = `${e.target.value}`

        this.setState({
            input:{
                ...this.state.input,
                additional_materials: obj
            },trigger: objTrigger
        })
    }

    addMemory = () => {
        !this.state.input.additional_materials && this.setState({
            input: {
                ...this.state.input,
                additional_materials: []
            }
        })
            getDiskSpace(result => {
                result.title = ''
                this.setState({
                    input: {
                        ...this.state.input,
                        additional_materials: [
                            ...this.state.input.additional_materials,
                            result
                        ]
                    }
                }, () => {
                    let objTrigger = {}
                    objTrigger.id = ''
                    objTrigger.name = ''
                    objTrigger.link = `${window.location.origin}/lesson/${this.props.match.params.id}#${result.url}&${result.type}`

                    this.setState({
                        trigger: [
                            ...this.state.trigger,
                            objTrigger
                        ]
                    })
                })
            })
    }

    delMemory = (index, id) =>{
        let obj = this.state.input.additional_materials
        let objTrigger = this.state.trigger
        obj.splice(index, 1)
        objTrigger.splice(index, 1)

        this.setState({additional_materials: obj, trigger: objTrigger}, () => {
            id ? webApi.deleteAdditionMaterial(id).then(data => data.status === 200 && header.getSnackbar("Дополнительный материал удален"))
               : header.getSnackbar("Дополнительный материал удален")
        })
    }

    renderVideoType = () =>{
        return (
            <div>
                {this.state.lecture.video_url &&
                    <video src={this.state.lecture.video_url} width="500px" controls></video>
                }
                <br/><br/>
                <div onClick={() => getDiskSpace(result => this.setState({video_url: result.url}, () => console.log(this.state)))} className="btnSaveProfile fl-left">Выбрать файл</div>
            </div>
        )
    }

    handleSelect = e =>{
        this.setState({
            type: e.value
        })
    }

    handleActive = () =>{
        let obj = this.state.lecture
        webApi.lectureActive(obj.id).then(data => {
            if(data.status === 200){
                header.getSnackbar('Лекция успешно опубликована')
                history.goBack()
            }
        })
    }

    handleInActive = () =>{
        let obj = this.state.lecture
        webApi.lectureInActive(obj.id).then(data => {
            if(data.status === 200){
                header.getSnackbar('Лекция успешно убрана с публикации')
                history.goBack()
            }
        })
    }

    // componentDidMount(){
    //     setTimeout(()=> {
    //         let hrefElem = document.querySelectorAll('.rdw-mention-link')
    //         if(hrefElem){
    //             //.attributes.href.nodeValue
    //             hrefElem.forEach(e => {
    //                 e.setAttribute("href", `${window.location.origin}/lesson/${this.props.match.params.id}#${e.attributes.href.nodeValue.split('#')[1]}`)
    //             })
    //         }
    //     }, 1000)
    // }

    handlerHtml = html =>{
        this.setState({editorState: html})
    }

    availabilityLesson = val => {
        if(val.length){
            webApi.getTestLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    console.log(res)
                    this.setState({after_test_input: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({after_test_input: []})
                }              
            })
        }
    }
    /*input:{
            ...this.state.input,
            [e.target.name]: e.target.value
        }

    */

    changeSelectAvailability = e =>{
        this.setState({
            after_test_id: e
        })
    }

    removeAfter = () => {
        this.setState({after_test_id: null}, () => {
            let inp = document.querySelector('.fix-selector-width input')
            inp.value = ''
        })
    }
    

    render() {
        //this.props.match && this.props.match.params && console.log(`${window.location.origin}/lesson/${this.props.match.params.id}`)
        let{ lecture, input, type } = this.state
        let options = [
            {value: 'mixed', label: 'Смешанный'},
            {value: 'video', label: 'Видео-лекция'}
        ]

        //rdw-image-wrapper
        if(document.querySelector('.rdw-image-wrapper')) document.querySelector('.rdw-image-wrapper').style.display = 'none'
        return lecture && (
        <div className="user-right-content">
            <div className="block-content">
            <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                <div className="w-50 fl-left">
                    Наименование курса <br/>
                    <input type="text" className="inputTraining" defaultValue={lecture.course_title} disabled/> <br/><br/>
                    Наименование лекции <br/>
                    <input type="text" defaultValue={lecture.title} onChange={this.handleInput} name="title" className="inputTraining" placeholder="Введите название лекции"/> <br/><br/>
                    Краткое описание лекции <br/>
                    <textarea className="inputTrainingHeight" defaultValue={lecture.description} onChange={this.handleInput} name="description" placeholder="Опишите лекцию"><span>{lecture.description}</span></textarea> <br/><br/>
                    Продолжительность лекции (мин)
                    <input type="text" onChange={this.handleInput} defaultValue={lecture.estimation && lecture.estimation/60} value={input.estimation ? input.estimation || '' : lecture.estimation && lecture.estimation/60} name="estimation" className="inputTraining" placeholder="Введите продолжительность лекции"/> <br/><br/>
                    Тип лекции<br/>
                    {type && <Select onChange={this.handleSelect} placeholder="Выберите тип лекции" defaultValue={type === 'video' ? options[1] : options[0]} options={options} />}<br/>
                    Доступность материала<br/>
                    <i className="fas fa-times fix-del-after-icon" onClick={this.removeAfter}></i>
                    {lecture.after_test_title && <Select
                        className="fix-selector-width"
                        getOptionValue = {(option) => (option['id'])}
                        getOptionLabel = {(option) => (option['title'])}
                        value = {this.state.after_test_id}
                        defaultInputValue = {lecture.after_test_title}
                        options = {this.state.after_test_input}
                        onInputChange = {this.availabilityLesson}
                        onChange = {this.changeSelectAvailability}
                        placeholder = "Выберите тест после которого лекция будет доступна"
                    />}
                    {!lecture.after_test_title && <Select
                        className="fix-selector-width"
                        getOptionValue = {(option) => (option['id'])}
                        getOptionLabel = {(option) => (option['title'])}
                        value = {this.state.after_test_id}
                        options = {this.state.after_test_input}
                        onInputChange = {this.availabilityLesson}
                        onChange = {this.changeSelectAvailability}
                        placeholder = "Выберите тест после которого лекция будет доступна"
                    />}
                    <br/>
                </div>
                <div className="w-50 fl-left center">
                Обложка (jpg/png) <br/><br/>
                        {input.images && <div><img className="no-img-training img_cover_material" src={input.images.normal} alt=""/></div>}
                        {lecture.images && !input.images && <div><img className="no-img-training img_cover_material" src={lecture.images.normal} alt=""/></div>}
                        {!lecture.images && !input.images && <div className="no-img-training">320x240<br/></div>}
                        <br/>
                        <input type="file" className="hide" onChange={this.uploadImg} id="_program-cover"/>
                        <button className="btnSaveProfile" onClick={() => getDiskSpace(result => this.uploadImg(result),{type:false, fullUrl: true})}>Выбрать файл</button>
                </div>
                <div className="clear"></div>
                {/* <br/>
                <AudioPlayer file = { audio }/> */}
                <br/>
                <br/>
                <b>Дополнительные материалы к лекции</b>

                <div className="addition-material-block">
                    <div className="addition-material-header"><i onClick={this.addMemory} className="fal fa-file-plus"></i></div>
                    <div className="addition-material-edit">

                        {this.state.input.additional_materials && this.state.input.additional_materials.map((el, i) =>{
                            console.log(el.title)
                            return (
                                <div key={i + "-key"} className="addition-material-add" data-id={i}>
                                    {!el.title && <div className="test-alarm">Для публикации дополнительного материала введите его название</div>}
                                    <div className="addition-material-icon">
                                        <i className={`fal fa-file-${el.type}`}></i>
                                        {el.title && <i className="fal fa-check"></i>}
                                    </div>
                                    <div className="addition-material-file">

                                    <input value={el.title} onChange={e => this.inputMemory(e, i)} type="text" placeholder="Название материала"/><br/><br/>
                                    {/*this.typeFile(el.type, el.url)*/}
                                    <b>{el.url && el.url.split('/')[el.url.split('/').length - 1]}</b><br/><br/>
                                    <i className="font-red" onClick={() => this.delMemory(i, el.id)}>Удалить</i>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                { type && type === 'mixed' ? <div>
                <b>Материал лекции</b> <br/><br/>
                {/* <Editor
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbarCustomButtons={[<CustomOption />]}
                    mention={{
                        separator: ' ',
                        trigger: '#',
                        suggestions: this.state.trigger,
                    }}
                /> */}
                <CkEditor html ={ this.state.editorState } handler = { this.handlerHtml } trigger={this.state.trigger} />
                <br/>
                <div className="help-wysiwyg">
                      # - привязка ссылки к материалу
                </div></div> : this.renderVideoType()}

                <button onClick = { this.updateLecture } className="btnSaveProfile fl-right">Сохранить</button>
                {lecture.active ? <button onClick = { this.handleInActive } className="btnSaveProfile fl-left mg-l">Деактивировать</button>:
                <button onClick = { this.handleActive } className="btnSaveProfile fl-left mg-l">Активировать</button>}
                <br/>
                <br/>
            </div>
        </div>
        )
    }
}
