import React, { Component } from 'react'
import SignIn from './sign-in/index'
import Update from './update/index'
import SignUp from './sign-up/index'
import ViewAuth  from './view'
import './style.css'

export default class Auth extends Component {
    state = {
        component: 'SIGN_IN'
    }

    handleForm = type =>{
        if(type === 'SIGN_IN' || type === 'SIGN_UP' || type === 'UPDATE') this.setState({component: type})
        else console.error('form type error')
    }

    renderComponent = () =>{
        switch (this.state.component) {
            case 'SIGN_IN':
                return <SignIn handleForm = { this.handleForm }/>
            case 'SIGN_UP':
                return <SignUp handleForm = { this.handleForm }/>
            case 'UPDATE':
                return <Update />
            default:
                return <SignIn />
        }
    }

    toggleString = () =>{
        switch (this.state.component) {
            case 'SIGN_IN':
                return 'Регистрация'
            case 'SIGN_UP':
                return 'Назад'
            case 'UPDATE':
                return 'Назад'
            default:
                return <SignIn />
        }
    }

    render() {
        return (
            <ViewAuth component = { this.renderComponent() } toggleString = { this.toggleString() } handleForm = { this.handleForm } state = { this.state } />
        )
    }
}
