import React, { Component } from 'react'
import TogglePanel from '../../../../../elements/TogglePanel/view'
import webApi from '../../../../../../utilities/web-api';


export default class ViewAwardTab extends Component {

    state ={
        select: null,
        data: null
    }

    componentWillMount(){
        webApi.getHelper().then(data => data.status === 200 && this.setState({data: data.response}))
    }

    handleSelect = e => {
        console.log(e)
    }
    
    render() {
        let {data} = this.state
        //let {award} = this.props
        return (
            <div>
                <div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>
                    Добавить <i className="fas fa-plus"></i>
                </div>
                <div className="clear"><br/></div>
                {
                    data ? data.map((e, i) => (<div key = {i} className="relative"><span className="fixEditBtn" onClick={() => this.props.handleAward(this.state.data.filter(el => el.id === e.id))}><i className="far fa-pencil"></i></span><TogglePanel title = {e.question} body = {e.answer} /><br/></div>)) : <div>Ни одного раздела помощи не создано</div>
                }
                
                
        </div>
        )
    }
}
