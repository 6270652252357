import React, { Component } from 'react'
import Ts from '../_t'

export default class EditImg extends Component {
    state = {
        img: null
    }

    // componentWillMount(){
    //     let file = this.props.file
    //     let reader = new FileReader()

    //     reader.onload = r => {
    //         this.setState({img: r.target.result})
    //     }
    //     reader.readAsDataURL(file);
    //     //console.log(file)
    // }

    render() {
        //let { img } = this.state
        return (
            this.props.file && <Ts togglePreloader = {this.props.togglePreloader} upload = {this.props.upload} getFilesRefresh = {this.props.getFilesRefresh} src={this.props.file}/>
        )
    }
}
