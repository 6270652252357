import get from 'lodash/get'
import React from 'react'
import PropTypes from 'prop-types'
import without from 'lodash/without'

const MessagesHeader = ({ images, dialog, currentUserId, clients }) => {
  const isCurrentUserOnline = () => {
    if (!dialog || !dialog.users) return false
    if (dialog.users.length === 2 && dialog.users.every(id => id === dialog.users[0])) return true
    const users = without(dialog.users, currentUserId)
    return clients.some(el => users.includes(el))
  }

  const onlineBlock = () => {
    const isOnline = isCurrentUserOnline()
    return (
      <div className={`chat-dialogues-online-block ${isOnline ? '': 'offline'}`}>
        <span className="chat-dialogues-online-icon"/>
        <span className="chat-dialogues-online-status">{isOnline ? 'Онлайн' : 'Не в сети'}</span>
      </div>
    )
  }

  const getAvatar = () => images && images.normal
    ? <img className="chat-messages-avatar" src={images.normal} alt="user-avatar"/>
    : <div className="ava-chat default-header-avatar"/>

  return (
    <div className="chat-messages-header">
      {getAvatar()}
      <div className="chat-messages-wrapper">
        <h4 className="chat-messages-header-username">{get(dialog, 'title', '')}</h4>
        {onlineBlock()}
      </div>
    </div>
  )
}

MessagesHeader.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  images: PropTypes.object,
  dialog: PropTypes.object,
  clients: PropTypes.array.isRequired,
}

export default MessagesHeader
