import React, { Component } from 'react'
import LeftPanel from './left-panel/index'
import RightPanel from './right-panel/index'
import './style.css'
import { connect } from 'react-redux';
import '../../../fontawesome/css/all.min.css'

class Client extends Component {

    render() {
        return (
            <div className="d-flex h100">
                <RightPanel/>
                <LeftPanel role = {this.props.state}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      state: state.user
    }
}

export default connect(mapStateToProps)(Client)
