import React from 'react'
//import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    width: '100%',
    height: '120px'
  }
})

function SelectDate({ classes, updateFromDate, updateDueDate, from_date, due_date }) {
  const emitFromDate = e => updateFromDate(moment(e.target.value).toISOString())
  const emitDueDate = e => updateDueDate(moment(e.target.value).toISOString())

  return (
    <div className=' block-content'>
      <h3>Период прохождения</h3> <span className="data-fix-first"></span>
      <div className="row-report fl-left">
        <input
          defaultValue={moment(from_date).format('YYYY-MM-DD')}
          type="date"
          name="from_date"
          onChange={emitFromDate}
        />
      </div>
      <div className="row-report fl-right">
        <input
          defaultValue={moment(due_date).format('YYYY-MM-DD')}
          type="date"
          name="due_date"
          onChange={emitDueDate}
        />
      </div>
      <div className="clear"></div>
    </div>
  )
}

// SelectDate.propTypes = {
//   classes: PropTypes.object.isRequired,
//   updateFromDate: PropTypes.func.isRequired,
//   updateDueDate: PropTypes.func.isRequired,
//   from_date: PropTypes.string.isRequired,
//   due_date: PropTypes.string.isRequired,
// }

export default withStyles(styles)(SelectDate)
