import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import PlusIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';

const styles = theme => ({
  root: {
    width: '100%',
  },
  h1:{
      'fontWeight': '18px'
  },
  icon: {
    color: '#a9a9a9',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    // fontWeight: 'bold',
  },
});

class TogglePanel extends Component{
  state = {
    expansionPanelOpen: false
  }
  render() {
    const { classes, title, body, defaultExpanded } = this.props;
    const {expansionPanelOpen} = this.state
    return (
      <div className={classes.root}>
        <ExpansionPanel className="togglePanel" defaultExpanded = {defaultExpanded && defaultExpanded}>
          <ExpansionPanelSummary onClick={() => this.setState({expansionPanelOpen: !this.state.expansionPanelOpen})} expandIcon={expansionPanelOpen ?<MinusIcon className={classes.icon}/> : <PlusIcon className={classes.icon}/>}>
            <div className={classes.heading}>{title}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              {body}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}
export default withStyles(styles)(TogglePanel);