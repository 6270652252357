import { actionCreator } from '../action-created'
import {
  SIGNIN,
  TOKEN,
  SET_USER,
  CLEAR_TOKEN,
  SET_UUID,
  GET_UUID,
  SET_TOKEN,
  REFRESH_TOKEN, SET_APPEAL_PERMISSION,
} from './key'

export const signIn = (payload) => actionCreator(SIGNIN, payload)
export const getToken = (payload) => actionCreator(TOKEN, payload)
export const setToken = (payload) => actionCreator(SET_TOKEN, payload)
export const setUser = (payload) => actionCreator(SET_USER, payload)
export const clearToken = () => actionCreator(CLEAR_TOKEN)
export const setUuid = (payload) => actionCreator(SET_UUID, payload)
export const getUuid = (payload) => actionCreator(GET_UUID, payload)
export const getRefreshToken = (payload) => actionCreator(REFRESH_TOKEN, payload)
export const setPermissions = (payload) => actionCreator(SET_APPEAL_PERMISSION, payload)
