import React, { Component } from 'react'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';
import Select from 'react-select';

export default class CreateDepartament extends Component {
    state = {
        company_id: null,
        company: null,
        rTitle: null,
        rCompany: null
    }

    componentWillMount(){
        webApi.getCompany().then(data => data.status === 200 && this.setState({company: data.response}, () => console.log(this.state)))
        webApi.getDepartment(this.props.id_object).then(data => data.status === 200 && this.setState({rTitle: data.response[0].title }, () => {
            webApi.getCompanyId(data.response[0].company_id).then(data => {
                this.setState({rCompany: [{
                    id: data.response[0].id,
                    title: data.response[0].title
                }], title: data.response[0].title})
            })
        }))
    }

    handleCreate = () => {
        let {title, company_id} = this.state

        let obj = {id: this.props.id_object,title, company_id}
        console.log(obj.title)
        if(!obj.title) return header.getSnackbar("Название отдела не заполнено")
        if(!obj.company_id) obj.company_id = this.state.rCompany[0].id
        //if(!obj.company_id) return header.getSnackbar("Название компании не заполнено")
        

        webApi.updateDepartment(obj)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Отдел успешно обновлен")
                this.props.handlePage('view')
            }
        })
    }

    handleInput = e => {
        this.setState({title:e.target.value})
    }

    onChangeSelect = e => {
        this.setState({company_id: e.id})
    }

    render() {
        let {rTitle} = this.state
        return (  
            <div>
                <span className="href" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>     
                <div className="create-form">
                    <span><b>Название отдела</b></span> <br/>
                    <input name = "title" defaultValue={rTitle && rTitle} onChange={this.handleInput} type="text" placeholder="Введите название отдела"/> <br/><br/>
                    <span><b>Название компании</b></span> <br/>
                    {/* <input type="text" defaultValue={this.props.company && this.props.company} disabled/> <br/><br/> */}
                    {this.state.rCompany && <Select 
                        onChange={this.onChangeSelect}
                        defaultValue={this.state.rCompany}
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        options = {this.state.company || [{id: 0, title: "Нет ни одной компании"}]}
                        placeholder="Выберите компанию"
                    />}<br/><br/>
                    <div className="btnSaveProfile fl-left" onClick={this.handleCreate}>Обновить</div>
                </div>
            </div>
        )
    }
}

