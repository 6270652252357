import React, { Component } from 'react'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import Video from './video'
import Audio from './audio'
import Mixed from './mixed'
import AdditionalMaterials from './additional'
import { header } from './../../../../../../utilities/page';
import history from '../../../history';

function getComponent(type) {
    switch (type) {
        case 'video':
            return Video
        case 'audio':
            return Audio
        case 'mixed':
            return Mixed
        default:
            return <h4>Компонент не найден</h4>
    }
}

export default class ViewLesson extends Component {
    state = {
        audioPaly: false
    }

    render() {
        if (!this.props.blockEducation) return <div/>
        const {
            title,
            html_content,
            audio_url,
            video_url,
            description,
            additional_materials,
            type,
            test
        } = this.props.blockEducation

        const Content = getComponent(type)

        let index = 0
        // Sort and group material by type
        const Materials = groupBy(additional_materials.sort((prev, next) => prev.order - next.order), ({ type }) => type)
        //let videoCont = {video_url, description}
        return (
            <div className="user-right-content">
                <div className="lesson-page-content-container">
                    <div className="lesson-page-content"> <br/>
                    <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                    <h3>{ title || 'Название лекции не указано' }</h3>
                        <Content
                          innerHtml={html_content}
                          audioUrl={audio_url}
                          videoUrl={video_url}
                          description = {description}
                          test={test}
                        />
                        {test && test.published && 
                            <div>
                                <div className="hr"></div>                               
                                <span>Вам доступен тест по этой лекции</span><br/><br/>
                                <div className="btnSaveProfile fl-left" onClick={()=> history.push(`/test/${test.id}`)} >Пройти тест</div>
                                <br/><br/><br/><br/>
                            </div>
                        }
                        {/* <p className="lesson-description">{description}</p> */}
                    </div>
                    <div className="content-additional-materials">
                        <div className="content-additional-materials-header">
                            <div className="content-additional-materials-header-cont"><h2>Дополнительные материалы</h2><span className="content-additional-materials-count">{additional_materials.length}</span></div>
                        </div>

                        <div className='additional-material-fixed'>

                            {map(Materials, (materials, type) => <AdditionalMaterials type={type} materials={materials} key={++index} />)}

                            {additional_materials.length === 0 && (
                            <h4>Дополнительные материалы не указаны.</h4>
                            )}
                        </div>
                    </div>
                    {/* <div className="play-type-controle"
                        onClick={
                            () => {
                                this.setState({audioPaly: !this.state.audioPaly})
                                let audio = document.getElementsByTagName('audio')[0]
                                if(audio.paused) audio.play()
                                else audio.pause()
                            }
                        }
                    >
                        {!this.state.audioPaly ? <i className="fas fa-play"></i> : <i className="fas fa-pause"></i>}
                    </div> */}
                </div>
            </div>
        )
    }
}
