import React, { Component } from 'react'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { header } from './../../../../utilities/page'
import webApi from '../../../../utilities/web-api'
import history from '../history';

export default class TasksEdit extends Component {
  state = {
    from_date: null, //moment().toISOString(),
    due_date: null,//moment().add(1, 'month').toISOString(),
    task: null,
    options: [],
    type: null,
    title: null
  }

  componentWillMount() {
    header.pageName('Редактирование задачи')
    header.newBreadcrumps([{ title: 'Задачи', route: '/tasks' }])
    let taskId = this.props.match.params.id
    webApi.getTaskId(taskId)
    .then(data => {
      if(data.status === 200){
        this.setState({
          task:data.response,
          from_date: data.response.from_date,
          due_date: data.response.due_date
        }, () => {
          console.log(this.state)
          let {task} = this.state

          if(task.program_id){
            webApi.getProgram(task.program_id)
            .then(d => this.setState({options:[
                                      ...this.state.options,
                                      {type: 'Программа', title: d.response[0].title}
                                    ]}))
          }
          if(task.course_id){
            webApi.getCourseId(task.course_id)
            .then(d => this.setState({options:[
                                      {type: 'Курс', title: d.response.title}
                                    ]}))
          }
        })
      }else header.getSnackbar("Задачи не найдено")
    })
  }

  updateTask = () =>{
    let { from_date, due_date, task } = this.state
    if(moment(this.state.from_date) > moment(this.state.due_date)) header.getSnackbar("Неверно задан период")
    else{
      webApi.updateTask(task.id, {
        from_date,
        due_date
      })
      .then(data => data.status === 200 && header.getSnackbar("Данные успешно обновлены"))
    }
  }

  handlerDate = e => {
    this.setState({
      [e.target.name]: moment(e.target.value).toISOString()
    })
  }

  render() {
    let { task, options } = this.state

    return (
      <div className="block-content">
        <div className="user-right-content w-50 task-page">
          {task &&
            <div className="pd-tb-25">
              Изменить дату для задачи<br/>
              <div className="hr"></div>
              {options.length > 0 && 
                options.map((e,i) =>{
                return(
                <div key={i}>
                  {e.type}
                  <input type="text" defaultValue={e.title} disabled = {true}/> <br/><br/>
                </div>)})
              }
              С <br/>
              <input name="from_date" type="date" onChange={this.handlerDate} defaultValue={moment(task.from_date).format('YYYY-MM-DD')}/><br/><br/>
              По <br/>
              <input type="date" name="due_date" onChange={this.handlerDate} defaultValue={moment(task.due_date).format('YYYY-MM-DD')}/> <br/><br/>
              <div className="btnSaveProfile fl-left" onClick={this.updateTask}>Сохранить</div>
            </div>
          }
        </div>
      </div>
    )
  }
}