import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import webApi from '../../../../../../utilities/web-api';
import Moment from 'react-moment';

export default class ViewObjectTab extends Component {
    state={
        company: [],
        rating: null
    }

    componentWillMount(){
        webApi.getRatings()
        .then(data => {
          if(data.status === 200){
            let rating = []
            data.response.result.map((r,i) =>{
              return rating = [
                ...rating,
                {
                  id: !r.factor ? '-' : ++i,
                  user: `${r.surname} ${r.name}`,
                  factor: r.factor
                }
              ]
            })

            rating && this.setState({rating})
          }
        })
    }

    render() {
          const columns = [{
            Header: "Место",
            accessor: 'id', // String-based value accessors!
            minWidth: 100,
            Cell: props => <span className='number'>{props.value}</span>
          }, {
            Header: 'Пользователь',
            accessor: 'user',
            Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
            minWidth: 100
          }, {
            Header: 'Очки',
            accessor: 'factor',
            Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
            minWidth: 100
          }]

          //console.log(columns)
          //console.log(this.props.handleCompany)
          return (
            <div>
            {this.state.rating ? <ReactTable
            data={this.state.rating}
            resizable={false}
            pageSize = {this.state.rating.length}
            columns={columns}
            />: <div>Ни одного пользователя не существует</div>}</div>)
    }
}

