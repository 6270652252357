import React  from 'react'
import Audio  from './audio'
import Video  from './video'
import Image  from './image'
//import { header } from './../../../../../../utilities/page';
import PdfViewer from './../../../pdfDesc';
import { header } from '../../../../../../utilities/page';

function getIconByType(type) {
  switch (type) {
    case 'audio':
      return 'fa-volume-up'
    case 'video':
      return 'fa-video'
    case 'file':
      return 'fa-file'
    case 'pdf':
      return 'fa-file-pdf'
    case 'image':
      return 'far fa-image'
    case 'text':
      return 'far fa-font'
    default:
      return 'fa-question-circle'
  }
}

function getNameByType(type) {
  switch (type) {
    case 'audio':
      return "Звукозапись"
    case 'video':
      return 'Видео контент'
    case 'file':
      return 'Файл'
    case 'pdf':
      return 'PDF файл'
    case 'image':
      return 'Изображение'
    case 'text':
      return 'Текстовая информация'
    default:
      return 'Неизвестный формат'
  }
}

export default ({ type, materials }) => {
  function getComponentByType({ title, url, description }) {
    switch (type) {
      case 'file':
      case 'pdf':
        return <div className="href" onClick={() =>header.getModalPage({title: '', content: <PdfViewer file={url} />})}>Открыть {title}</div>
      case 'text':
        return <span className='text-additional'>{description}</span>
      case 'audio':
        return <Audio audioUrl={url}/>
      case 'video':
        return <Video videoUrl={url}/>
      case 'image':
        return <Image imageUrl={url}/>
      default:
        return <p>Неизвестный формат</p>
    }
  }

  return (
    <div className='additional-material-wrapper'>
      <div className="additional-material-header">
        <i className={`fal additional-material-icon ${getIconByType(type)}`} />
        <h4>{getNameByType(type)}</h4>
      </div>
      
        <div className='additional-materials-container'>
          {materials.map((params, i) => (
              <span key={i}>{getComponentByType(params)}</span>
          ))}
        </div>

    </div>
  )
}
