import React, { Component } from 'react'
import webApi from '../../../../utilities/web-api'
import moment from 'moment'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { header } from './../../../../utilities/page';
import history from '../history';
import Tooltip from '@material-ui/core/Tooltip';


export default class TableTask extends Component {
    state = {
        tableRow: null,
        tableValue: [],
        tableOrigin: null,
        limit: 10,
        offset: 0,
        loaderMaterial: false,
        loaderLast: false,
    }



    tasks = (user = '', limitD, offsetD) => {
      let { limit, offset, tableValue } = this.state


      webApi.getAllTasks(user, limitD ? limitD : limit, offsetD ? 0 : offset)
        .then(data => {
            if(data.status === 200){
              this.setState({
                loaderMaterial: false,
                offset: this.state.offset + limit,
                loaderMaterial: false
              },() =>{
                let result = data.response
                let newObject = tableValue

                result.map(el =>{
                    let elementObj

                    for(let obj in el){
                        elementObj = {...elementObj, [obj]:el[obj] || '-'}

                        if(el.completed) elementObj.completed = el.completed ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
                        if(el.user) elementObj.user = `${el.user.surname} ${el.user.name}`
                        if(el.from_date) elementObj.from_date = moment(el.from_date).format('DD.MM.YYYY/HH:mm')
                        if(el.due_date) elementObj.due_date = moment(el.due_date).format('DD.MM.YYYY/HH:mm')
                        if(el.program) elementObj.program = el.program.title
                        if(el.course) elementObj.course = el.course.title
                        if(el.lecture) elementObj.lecture = el.lecture.title
                        if(el.created_at) elementObj.created_at = moment(el.created_at).format('DD.MM.YYYY/HH:mm')
                    }

                    newObject = [...newObject, elementObj]

                })

                this.setState({tableValue: newObject, tableLength: newObject.length})
            })
            }else this.setState({loaderLast: true, loaderMaterial: false})
        })
    }

    searchUserData = (user = '') => {
      let { limit, offset, tableValue } = this.state


      webApi.getAllTasks(user, 50, 0)
        .then(data => {
            if(data.status === 200){
                let result = data.response
                let newObject = []

                result.map(el =>{
                    let elementObj

                    for(let obj in el){
                        elementObj = {...elementObj, [obj]:el[obj] || '-'}

                        if(el.completed) elementObj.completed = el.completed ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
                        if(el.user) elementObj.user = `${el.user.surname} ${el.user.name}`
                        if(el.from_date) elementObj.from_date = moment(el.from_date).format('DD.MM.YYYY/HH:mm')
                        if(el.due_date) elementObj.due_date = moment(el.due_date).format('DD.MM.YYYY/HH:mm')
                        if(el.program) elementObj.program = el.program.title
                        if(el.course) elementObj.course = el.course.title
                        if(el.lecture) elementObj.lecture = el.lecture.title
                        if(el.created_at) elementObj.created_at = moment(el.created_at).format('DD.MM.YYYY/HH:mm')
                    }

                    

                    newObject = [...newObject, elementObj]
                    console.log(newObject)

                })
                this.setState({tableValue: newObject, tableLength: newObject.length}, () => console.log(this.state.tableValue))
            }else this.setState({loaderLast: true, loaderMaterial: false})
        })
    }

    

    loadMaterial = () =>{
      let { limit, offset, tableValue } = this.state
  
      this.setState({loaderMaterial: true}, () => {
        webApi.getAllTasks(null, limit,offset)
        .then(data => {
            if(data.status === 200) {
              this.setState({
                loaderMaterial: false,
                offset: this.state.offset + limit,
                loaderMaterial: false
              },() =>{
                let result = data.response
                let newObject = tableValue

                result.map(el =>{
                    let elementObj

                    for(let obj in el){
                        elementObj = {...elementObj, [obj]:el[obj] || '-'}

                        if(el.completed) elementObj.completed = el.completed ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
                        if(el.user) elementObj.user = `${el.user.surname} ${el.user.name}`
                        if(el.from_date) elementObj.from_date = moment(el.from_date).format('DD.MM.YYYY/HH:mm')
                        if(el.due_date) elementObj.due_date = moment(el.due_date).format('DD.MM.YYYY/HH:mm')
                        if(el.program) elementObj.program = el.program.title
                        if(el.course) elementObj.course = el.course.title
                        if(el.lecture) elementObj.lecture = el.lecture.title
                        if(el.created_at) elementObj.created_at = moment(el.created_at).format('DD.MM.YYYY/HH:mm')
                    }

                    newObject = [...newObject, elementObj]

                })

                this.setState({tableValue: newObject, tableLength: newObject.length})
            })
          }else this.setState({loaderLast: true, loaderMaterial: false})
        })
        .catch(error => console.log(error))
      })
    }

    componentWillMount(){
        header.pageName('Задачи')

        this.setState({loaderMaterial: true}, () => this.tasks())
    }

    searchUser = e =>{
      this.searchUserData(e.target.value)
    }

    deleteTask = id =>{
      webApi.deleteTask(id)
      .then(data => {
        if(data.status === 200){
          header.getSnackbar('Задача успешно удалена')

          let {tableValue} = this.state
          let idDelete = tableValue.map(e => e.id).indexOf(id)
          tableValue.splice(idDelete, 1)
          this.setState({tableValue})
        }else{
          header.getSnackbar('Произошла ошибка')
        }
      })
    }

    renderTable = (data, lnh, col) =>{
      return (
        <ReactTable
          data={data}
          resizable={false}
          pageSize = {lnh}
          columns={col}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: e => {
                e.target.localName === 'span' && history.push(`tasks/${rowInfo.original.id}`)
              }
            }
          }}
        />
      )
    }

    render() {
        const columns = [{
            Header: "ID",
            accessor: 'id',
            minWidth: 100,
            Cell: props => <span className='number'>{props.value} <Tooltip title="Удалить задачу" aria-label="Удалить задачу"><i onClick={() => this.deleteTask(props.value)} className="far fa-trash-alt"></i></Tooltip></span>
          }, {
            Header: 'Программа',
            accessor: 'program',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }, {
            Header: 'Курс',
            accessor: 'course',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }, {
            Header: 'Лекция',
            accessor: 'lecture',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }, {
            Header: 'Пользователь',
            accessor: 'user',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }, {
            Header: 'C',
            accessor: 'from_date',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }, {
            Header: 'По',
            accessor: 'due_date',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }, {
            Header: 'Выполнение',
            accessor: 'completed',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }, {
            Header: 'Дата',
            accessor: 'created_at',
            Cell: props => <span className='number'><Tooltip className='number' title={props.value}><div>{props.value}</div></Tooltip></span>,
            minWidth: 100
          }]
          let length = this.state.tableLength
        return (
            <div className="user-right-content">
                <div className="block-content">
                    <span className="href" onClick={() => header.historyBack()}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>

                    <div onClick={()=>history.push('/tasks/create')} className="btnSaveProfile fl-right">Создать</div><br/>

                    <div className="fl-left w-50 relative">
                      {this.state.tableValue && `Всего: ${this.state.tableValue.length}`}
                      <br/><br/>
                      <input className="search-user-input" onChange={this.searchUser} type="text" placeholder="Поиск по пользователю"/>
                    </div>
                    <div className="clear"></div><br/>

                    {this.state.tableValue && this.state.tableValue.length > 0 ? this.renderTable(this.state.tableValue, this.state.tableValue.length, columns): <span>Ни одной задачи не создано</span>}
                  </div>

                  <button className="loadMaterialBtn" onClick={!this.state.loaderLast ? this.loadMaterial : null}>
                    {this.state.loaderLast ? 'материалов больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
                  </button>
            </div>
        )
    }
}