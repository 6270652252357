import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import { header } from '../../../../utilities/page';
import PdfViewer from './../pdf';
import EditImg from './editImg';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//import { header } from './../../../../utilities/page';


export default class ViewDisk extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
      }

    componentDidMount(){
        let menu = document.querySelectorAll('.disk-space-menu')
        let uploadBtn = document.querySelector('.disk-space-upload')

        menu.forEach(element => {
            element.addEventListener("click", (e) => {
                let fileAll = document.querySelectorAll('.disk-space-file-check-active')
                if(fileAll && fileAll[0]) fileAll[0].classList.remove('disk-space-file-check-active')
                let active = document.querySelectorAll('.disk-space-menu-active')[0]
                active.classList.remove('disk-space-menu-active')
                e.target.classList.add('disk-space-menu-active')

                this.props.handleSort(e.target.dataset.type)
            })

        });

        uploadBtn.addEventListener("click", () => {
            this.props.handleCheckFile(null)
            document.getElementById('disk-space-upload').click()
            menu[0].click()
        })
    }

    imgValid = imgUrl =>{
        let objImg = new Image()
        objImg.src = this.replaceUrl(imgUrl)

        if(!objImg.complete) return <div className="disk-space-file-cover"><i className="fal fa-file-image"></i></div>
        else return <img src={imgUrl} alt="cover"/>
    }

    fileActive = (e, el) => {
        let fileAll = document.querySelectorAll('.disk-space-file-check-active')
        if(fileAll && fileAll[0]) fileAll[0].classList.remove('disk-space-file-check-active')

        if(e.target.classList.contains('disk-space-file-block')) e.target.classList.add('disk-space-file-check-active')
        else e.target.offsetParent.classList.add('disk-space-file-check-active')

        this.props.handleCheckFile(el)
    }

    fileFooterFull = () =>{
        let footer = document.querySelector('.disk-space-footer-check')
        footer && footer.classList.add('disk-space-footer-check-full')
        this.setState({pageNumber: 1})
    }

    removeFooterFull = e => {
        let footer = e.target.offsetParent
        footer.classList.contains('disk-space-footer-check-full') && footer.classList.remove('disk-space-footer-check-full')
    }

    fileInactive = () => {
        let fileAll = document.querySelectorAll('.disk-space-file-check-active')
        if(fileAll && fileAll[0]) fileAll[0].classList.remove('disk-space-file-check-active')

        this.props.handleCheckFile(null)
    }

    replaceUrl = url => {
        let str = url
        let arrStr = str.split('/')
        let indexReplace = arrStr.indexOf('test2dls.aisdrive.by')

        arrStr[indexReplace] = '10.0.1.17'

        return arrStr.join('/')
    }

    editFile = e => {
        e.target.files[0].type.split('/')[0] === 'image' ?
        header.getDialog({
            className: "reviewImgModal",
            title: e.name,
            content: <EditImg togglePreloader={this.props.togglePreloader} getFilesRefresh = {this.props.getFilesRefresh} upload = {this.props.uploadFiles}  file={e.target.files[0]} />
        }) : this.props.uploadFiles(e.target.files[0])
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    pdfNext = () => this.setState({pageNumber: this.state.pageNumber + 1})
    pdfPrev = () => this.setState({pageNumber: this.state.pageNumber - 1})

    render() {
        let { sort, fileSearch, checkFile, preload, selectFile } = this.props
        let { pageNumber, numPages} = this.state
        return (
            <div className="disk-space-main">
                <div className="disk-space-menu-main">
                    <div data-type="all" className="disk-space-menu disk-space-menu-active">
                        Все
                    </div>
                    <div data-type="image" className="disk-space-menu">
                        Изображение
                    </div>
                    <div data-type="audio" className="disk-space-menu">
                        Аудио
                    </div>
                    <div data-type="video" className="disk-space-menu">
                        Видео
                    </div>
                    <div data-type="pdf" className="disk-space-menu">
                        Pdf
                    </div>
                    <div className="disk-space-menu disk-space-upload href fl-right">
                        <input type="file" id="disk-space-upload" className="hide" onChange={e => this.editFile(e)/*uploadFiles(e.target.files)*/}/>
                        Загрузить файл <i className="far fa-layer-plus"/>
                    </div>
                    <div className="disk-space-menu-search fl-right">
                        <i className="far fa-search"></i><input onChange={fileSearch} className="_filesSearch" type="text" placeholder="Поиск..."/>
                    </div>
                </div>

                {!preload ? <div className="disk-space-view">
                    {sort && sort.map((el,i) =>{
                        let nameFile = el.original ? el.original.split('/')[el.original.split('/').length - 1] : el.url && el.url.split('/')[el.url.split('/').length - 1]

                        return(
                            <Tooltip key={i} title={nameFile || ''}>
                                <div onDoubleClick={this.fileFooterFull} onClick={ e => this.fileActive(e, el) } className="disk-space-file-block">
                                    {el.type === 'audio' && <div className="disk-space-file-cover"><i className="fal fa-file-audio"></i></div>}
                                    {el.type === 'pdf' && <div className="disk-space-file-cover"><i className="fal fa-file-pdf"></i></div>}
                                    {el.type === 'video' && <div className="disk-space-file-cover"><i className="fal fa-file-video"></i></div>}
                                    {el.type === 'image' && el.original && <img src={el.original} alt="загрузка..."/>}
                                    <b className="disk-space-name-file">{nameFile}</b>
                                </div>
                            </Tooltip>
                        )
                    })}

                    {!sort.length && <div className="disk-space-no-file">Файлов по данному типу не существует</div>}

                    {checkFile &&   <div className="disk-space-footer-check">
                                        <div className="disk-space-footer-check-btn">
                                            <button onClick={selectFile}>Выбрать файл</button> <button onClick={this.fileFooterFull}>Предпросмотр</button>
                                        </div>
                                        <div onClick={this.fileInactive} className="disk-space-footer-check-close">
                                            <Tooltip title="Закрыть"><i className="fal fa-times"></i></Tooltip>
                                        </div>
                                        <div className="disk-space-footer-check-close">
                                            <Tooltip title={
                                                <React.Fragment>
                                                    <div>Имя файла: <b>{checkFile.original ? checkFile.original.split('/')[checkFile.original.split('/').length - 1] : checkFile.url && checkFile.url.split('/')[checkFile.url.split('/').length - 1]}</b></div>
                                                    <div>Тип файла: <b>
                                                    {checkFile.type === 'image' && 'Изображение' }
                                                    {checkFile.type === 'audio' && 'Аудио' }
                                                    {checkFile.type === 'video' && 'Видео' }
                                                    {checkFile.type === 'pdf' && 'PDF' }
                                                    </b></div>
                                                </React.Fragment>
                                            }><i className="fal fa-info"></i></Tooltip>
                                        </div><div className="clear"></div>
                                        <div className="disk-space-footer-preview">
                                            {checkFile.type === 'image' && <div className="disk-space-footer-preview-file">
                                                <img src={checkFile.original} alt="img"/>
                                            </div>}

                                            {checkFile.type === 'video' &&
                                            <div className="disk-space-footer-preview-file">
                                               <video width="400px" src={checkFile.url} controls></video>
                                            </div>}

                                            {checkFile.type === 'audio' &&
                                            <div className="disk-space-footer-preview-file">
                                               <audio src={checkFile.url} controls></audio>
                                            </div>}

                                            {checkFile.type === 'pdf' && <div className="disk-space-footer-preview-file">
                                            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent) && <PdfViewer file={checkFile.url} />}
                                            <button disabled={pageNumber === 1} onClick={this.pdfPrev}>Назад</button><button disabled={pageNumber === numPages} onClick={this.pdfNext}>Далее</button>
                                            <p>Страница {pageNumber} из {numPages}</p>

                                            <Document
                                                file={checkFile.url}
                                                onLoadSuccess={this.onDocumentLoadSuccess}
                                                >
                                                <Page pageNumber={pageNumber} />
                                                </Document>

                                            </div>}
                                            <Tooltip title="Свернуть"><i onClick={this.removeFooterFull} className="fas fa-chevron-double-down"></i></Tooltip>
                                        </div>
                                    </div>}
                </div>:
                <div className="disk-space-view">
                    <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div> }
            </div>
        )
    }
}
