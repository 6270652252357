import isNil from 'lodash/isNil'

export const actionCreator = (actionType, actionPayload) => {
    const action = {type: actionType}
    if(actionPayload){
        return {...action, payload: actionPayload}
    }
    return action
}

export const chatActionCreator = (actionType, actionPayload) => {
    const action = {type: actionType}
    if(!isNil(actionPayload)) {
        return {...action, payload: actionPayload}
    }
    return action
}
