import React, { Component } from 'react'
import Profile from './profile/'
import Chat from './chat'
import Header from './header/'
import Materials from './create-education/'
import Material from './view-education/programs'
import CreateProgram from './create-education/program/'
import CreateLecture from './create-education/lecture'
import CreateAward from './create-award'
import Report from './report'
import Tasks from './tasks/table'
import TasksCreate from './tasks'
import TasksEdit from './tasks/edit'
import Test from './test'
import CreateCategory from './admin/settings/index'
import CreateCourse from './create-education/course'
import Admin from './admin'
//import Ckedit from './ckEditor/ckedit'
import history from './history'
import { Switch, Route,Router, Redirect } from 'react-router-dom'
import CreateTest from './create-test/';
import Course from './view-education/courses/index';
import Lecture from './view-education/lectures/index';
import Lesson from './view-education/lesson/index';
import EditCourse from './create-education/course/edit'
import EditLecture from './create-education/lecture/edit'
import HistoryTest from './test/history'
import Scheduler from './scheduler'
import Availability from './availability/table'
import AvailabilityCreate from './availability/index'
import _t from './_t'
import Helper from './helper'
//import { header } from './../../../utilities/page';
import PdfViewer from './pdf';
import ProfileUser from './user-profile/index';
import Issues from "./issues/view";
import IssuePage from "./issues/issue-page/view";

export default class RightPanel extends Component {
  state = {
    routeHistory: '/',
    redirect: false
  }

  componentWillMount(){
    let routeHistory
    localStorage.getItem('_h') !== null ? routeHistory = JSON.parse(localStorage.getItem('_h')).route : routeHistory = '/'
    this.setState({routeHistory})
  }

  render() {
    return (
      <div className="right-content">
        <Header />
        {this.state.redirect && <Redirect to={this.state.routeHistory} />}
        <Router history = { history }>
          <Switch>
            <Route path="/profile/:id" exact component={ ProfileUser } />
            <Route path="/" exact component={ Profile } />
            <Route path="/pg:helper" exact component={ Helper } />
            <Route path="/_t" exact component={ _t } />
            {/* <Route path="/_ckedit" exact component={ Ckedit } /> */}
            <Route path="/pdf" exact component={ PdfViewer } />
            <Route path="/chat" exact component={ Chat } />
            <Route path="/education" exact component={ Profile } />
            <Route path="/availability" exact component={ Availability } />
            <Route path="/availability/create" exact component={ AvailabilityCreate } />
            <Route path="/scheduler" exact component={ Scheduler }/>
            <Route path="/orders" exact component={ Profile } />
            <Route path="/history/:id" exact component={ HistoryTest } />
            <Route path="/orders/1" exact component={ Profile } />
            <Route path="/test/:id" exact component={ Test } />
            <Route path="/test/result" exact component={ Profile } />
            <Route path="/training" exact component={ Profile } />
            <Route path="/report" exact component={ Report } />
            <Route path="/tasks" exact component={ Tasks } />
            <Route path="/tasks/create" exact component={ TasksCreate } />
            <Route path="/tasks/:id" exact component={ TasksEdit } />
            <Route path="/material" exact component={ Material } />
            <Route path="/materials" exact component={ Materials } />
            <Route path="/materials/:id" exact component={ Materials } />
            <Route path="/course/:id" name="courseTest" exact component={ Materials } />
            <Route path="/lesson/:id" exact component={ Lesson } />
            <Route path="/courses/:id" exact component={ Course } />
            <Route path="/lectures/:id" exact component={ Lecture } />
            <Route path="/lecture/:id" exact component={ Materials } />
            <Route path="/create-training" exact component={ Profile } />
            <Route path="/create-award" exact component={ CreateAward } />
            <Route path="/create-materials" exact component={ CreateProgram } />
            <Route path="/create-test/:id" exact component={ CreateTest } />
            <Route path="/create-test/:material_type/:material_id/:test_id" exact component={ CreateTest } />
            <Route path="/create-course" exact component={ CreateCourse } />
            <Route path="/create-lecture" exact component={ CreateLecture } />
            <Route path="/create-category" exact component={ CreateCategory } />
            <Route path="/create-course/:id" exact component={ CreateCourse } />
            <Route path="/edit-course/:id" exact component={ EditCourse } />
            <Route path="/edit-lecture/:id" exact component={ EditLecture } />
            <Route path="/create-lecture/:id" exact component={ CreateLecture } />
            <Route path="/create-materials/:id" exact component={ CreateProgram  } />
            <Route path="/settings" exact component={ Admin } />
            <Route path="/stat" exact component={ Profile } />
            <Route path="/issues" exact component={ Issues } />
            <Route path="/issues/:id" exact component={ IssuePage } />
            <Route path="*" component={ Profile } />
          </Switch>
        </Router>
      </div>
    )
  }
}
