import React, { Component } from 'react'
//import { header } from '../../../../../utilities/page';
import Select from 'react-select';
import Selection from './create-selection'
import Free from './create-free'
import webApi from '../../../../../utilities/web-api';
import FetchQuestion from './fetchQuestion'
import { header } from '../../../../../utilities/page';

export default class TestAssigne extends Component {
    state={
        test_id: null,
        openType: false,
        selectDisable: false,
        fetchQuestions: null
    }

    componentDidMount(){
        this.props.options && this.props.options.test_id !== "new" && this.setState({testId: this.props.options.test_id})
        this.props.options && this.props.options.test_id !== "new" && webApi.getTestQuestions(this.props.options.test_id)
        .then(data => {
            data.response && this.setState({fetchQuestions: data.response}, () => {
                this.state.fetchQuestions.length > 0 && this.state.fetchQuestions.map((e, i) =>{
                    e.lecture_id && webApi.getLectureId(e.lecture_id)
                        .then(async data => {
                            if(data.status === 200){
                                let obj = this.state.fetchQuestions
                                obj[i].lecture_title = data.response.title
                                this.setState({
                                    fetchQuestions: obj
                                })
                            }
                        })
                })
            })
        })
    }

    refreshQuestions = () => {
        this.props.options && webApi.getTestQuestions(this.props.options.test_id)
        .then(data => {
            if(data.status === 200) data.response && this.setState({fetchQuestions: data.response}, () => {
                this.state.fetchQuestions.map((e, i) => {
                    e.lecture_id && webApi.getLectureId(e.lecture_id)
                        .then(async data => {
                            if(data.status === 200){
                                let obj = this.state.fetchQuestions
                                obj[i].lecture_title = data.response.title
                                this.setState({
                                    fetchQuestions: obj
                                })
                            }
                        })
    })
            })
        })
    }

    deleteQuestion = id => {
        if(this.props.test.published && this.state.fetchQuestions.length <= this.props.test.questions_amount){
            return header.getSnackbar("Вопросов в тесте не может быть меньше минимально допустимого")
        }else{
            webApi.deleteQuestion(id)
            .then(data => {
                if(data.status === 200){
                    header.getSnackbar("Вопрос успешно удалён")

                    let indexQuestion =  this.state.fetchQuestions.map((el) => el.id).indexOf(id)
                    let newFetchQuestion = this.state.fetchQuestions
                    newFetchQuestion.splice(indexQuestion, 1)

                    this.setState({fetchQuestions: newFetchQuestion})
                }else{
                    header.getSnackbar("Произошла ошибка")
                }
            })
        }
    }

    openType = () => {
        this.setState({openType: !this.state.openType})
    }

    selectTypeClick = e => {
        this.setState({
            type: {title:e.currentTarget.dataset.title, value:e.currentTarget.dataset.value}
        })
    }

    handleSelect = e =>{
        this.setState({type:e})
    }

    handleDisabledSelect = (bool) => {
        this.setState({selectDisable: bool})
    }

    rerender = () => {
        //window.location.reload();
        this.setState({
            test_id: null,
            openType: false,
            selectDisable: false,
            type: null
            //fetchQuestions: null
        })
    }

    render() {
        const typeTest = [
            { value: 'selection', label: 'Выбор из вариантов ответа' },
            { value: 'free', label: 'Текстовый ответ' }
        ]
        let points = 0

        this.state.fetchQuestions && this.state.fetchQuestions.length && this.state.fetchQuestions.forEach(el => {
            points += el.points
        })

        return this.props.options.test_id !== "new" ? (
            <div>
        {this.props.test && <div className="row-stat-test-create fl-left">
            <div className="row-stat-test-create-left">Вопросы: {`${(this.state.fetchQuestions && !this.state.fetchQuestions.error) ? this.state.fetchQuestions.length : '0'}/${this.props.test && this.props.test.questions_amount}`}</div>
            {(this.props.test) && this.props.test.assessment_type !== 'correct_answers' && <div className="row-stat-test-create-right">Баллы: {`${points}/${this.props.test.max_score_points && this.props.test.max_score_points}`}</div>}
        </div>} <br/>
                <div className="clear"></div>
                <div className="blockTableNewTest">
                    Тип вопроса <br/>
                    <Select placeholder="Выберите тип вопроса" noOptionsMessage = {() => '...'} onChange={this.handleSelect} name="select" options={typeTest} isDisabled={this.state.selectDisable}/> <br/>
                    {!this.state.type && ''}
                    {(this.state.type && this.state.type.value) === 'selection' && <Selection test_difficulty={this.props.test && this.props.test.difficulty} scoreType = {this.props.test && this.props.test.assessment_type} refreshQuestions={this.refreshQuestions} options = {this.props.options} rerender = {this.rerender} type={this.state.type.value} handleDisabledSelect={this.handleDisabledSelect}/>}
                    {(this.state.type && this.state.type.value) === 'free' && <Free test_difficulty={this.props.test && this.props.test.difficulty} scoreType = {this.props.test && this.props.test.assessment_type} refreshQuestions={this.refreshQuestions} options={this.props.options} rerender = {this.rerender} handleDisabledSelect={this.handleDisabledSelect} type={this.state.type.value}/>}
                </div>
                <FetchQuestion test_difficulty={this.props.test && this.props.test.difficulty} scoreType = {this.props.test && this.props.test.assessment_type} refreshQuestions = {this.refreshQuestions} deleteQuestion={this.deleteQuestion} fetchQuestions={this.state.fetchQuestions}/>
            </div>
        ) : "Для добавления вопросов, создайте тест"
    }
}
