import get from 'lodash/get'
import React, { Component } from 'react'
import TestStat from './test-stat'
import webApi from '../../../../utilities/web-api';
import Fail from './finish-fail'
//import Success from './finish-success'
import { header } from './../../../../utilities/page';
import history from '../history';

export default class ViewTest extends Component {
  state = {
    id_active: 2,
    questions: null,
    actualQuestion: null,
    answers: null,
    q_num: null,
    handleAnswer: null,
    answer_id: [],
    questionAnswer: null,
    ids: 1,
    finish_test: null,
    achievement: null,
    next_attempt_time: null,
    answer_result_save: null
}

async componentWillMount(){
    let { questions } = this.props
    if(questions.questions.length > 0){
      await webApi.getHistoryTest(this.props.id_test).then(data => data.status === 200 && this.setState({answer_result_save: data.response.success ? {} : data.response.results}, () =>{
        if(Object.keys(this.state.answer_result_save).length && !data.response.success) {
          const id_active = get(document.querySelector(`[data-num="${localStorage.getItem("_qNum")}"]`), 'dataset.id')
          if (id_active) {
            this.setState({id_active})
            for( let obj in this.state.answer_result_save){
              document.querySelector(`[data-id="${obj}"]`) && document.querySelector(`[data-id="${obj}"]`).classList.add('coub-send-award')
            }
          }
        }
      }))
      await !Object.keys(this.state.answer_result_save).length && this.setState({id_active: questions.questions[0].id})
      await this.setState({questions: questions.questions},
      () => {
        this.setAnswerObject()
        !document.querySelectorAll('.coub-start').length && document.getElementsByClassName('min-block-right-coub')[0].classList.add('coub-start')
      })
    }
}

componentDidMount(){
  if(localStorage.getItem('_qNum')){
    if(document.querySelector(`[data-num="${localStorage.getItem('_qNum')}"]`)){
      document.querySelector(`[data-num="${localStorage.getItem('_qNum')}"]`).classList.add('coub-start')
      document.querySelector(`[data-num="${localStorage.getItem('_qNum')}"]`).click()
    }
  }
}

sendAnswer = () => {
  if(this.state.questionAnswer.type === "free") {
    this.setState({questionAnswer:{...this.state.questionAnswer, answer:this.state.handleAnswer[this.state.id_active]}}, () => {
      webApi.sendAnswer(this.state.questionAnswer)
      .then(data => data.status === 200 && this.setState({answer_result_save: data.results}))
      .catch(error => console.log(error))
    })
  }
  else if(this.state.questionAnswer.type === "selection")
  {
    this.setState({questionAnswer:{...this.state.questionAnswer, answer_id:this.state.answer_id}}, () => {
      webApi.sendAnswer(this.state.questionAnswer)
      .then(data => data.status === 200 && this.setState({answer_result_save: data.results}))
      .catch(console.error)
    })
  }
}

handleAnswer = e => {
  this.setState({handleAnswer: {
    ...this.state.handleAnswer,
    [e.target.dataset.idFreetype]:e.target.value

  },answer_result_save:{
    ...this.state.answer_result_save,
    [e.target.dataset.idFreetype]: e.target.value

  }
})
}

goQuestion = e =>{
    this.setState({
      id_active: e.target.dataset.id,
      q_num: e.target.innerText,
      ids: e.target.dataset.num
    }, () => {
      this.setAnswerObject()
      localStorage.setItem('_qNum', this.state.ids)
    })
    document.querySelectorAll('.coub-start')[0] && document.querySelectorAll('.coub-start')[0].classList.remove('coub-start')
    e.target.classList.add('coub-start')
}

renderBtnsTest = () => {
    let { questions } = this.props

    return questions.questions.map((el,i) =>{
        return(
            <div key={i} className="min-block-right-coub cur-p" onClick={this.goQuestion} data-num={i+1} data-id={el.id}>{i+1}</div>
        )
    })
}


nextQuestion = async () => {
  let btnIds = await this.state.ids
  await btnIds++
  localStorage.setItem('_qNum', btnIds)
  await this.setState({ids: btnIds})
  let btn = await document.querySelector(`[data-num="${btnIds}"]`)
  await btn.click()
  await this.setAnswerObject()
}

sendQuestion = async (e) => {
    let btnIds = await this.state.ids
    if(this.state.ids < document.querySelectorAll('.min-block-right-coub').length) {
      await document.querySelector(`[data-num="${btnIds}"]`).classList.add('coub-send-award')
      await btnIds++
      await this.setState({ids: btnIds})
      localStorage.setItem('_qNum', btnIds)
      let btn = await document.querySelector(`[data-num="${btnIds}"]`)
      await this.sendAnswer()
      await btn.click()
      await this.setState({answer_id: []})
      await this.setAnswerObject()
    }
    else{
      await document.querySelector(`[data-num="${btnIds}"]`).classList.add('coub-send-award')
      await this.sendAnswer()
      await this.setState({answer_id: []})
      await this.setAnswerObject()
      await document.querySelectorAll('.min-block-right-coub:not(.coub-send-award)') && document.querySelectorAll('.min-block-right-coub:not(.coub-send-award)')[0] && document.querySelectorAll('.min-block-right-coub:not(.coub-send-award)')[0].click()
    }
}

prevQuestion = async() => {
  let btnIds = await this.state.ids
  await btnIds--
  localStorage.setItem('_qNum', btnIds)
  await this.setState({ids: btnIds})
  let btn = await document.querySelector(`[data-num="${btnIds}"]`)
  await btn.click()
  await this.setAnswerObject()
}

setAnswerObject = async () =>{
  let answ_id
  if(this.state.questions){
    let actual = await this.state.questions && this.state.questions.filter((el) =>{
      return el.id === this.state.id_active
    })

    if (!actual[0]) return

    await actual[0].type === "free" ? actual[0].answers.map(el => answ_id = [el.id]) : /*answ_id = this.state.answer_id*/ answ_id = this.state.answer_id

    await this.setState({questionAnswer:{
      test_id: this.props.id_test,
      question_id: actual[0].id,
      answer_id:answ_id,
      type: actual[0].type,
      answer: null
    }}, () => {
      actual[0].type === "selection" && delete this.state.questionAnswer.answer
      return console.log(this.state.questionAnswer)
      })
  }
}

finishTest = () =>{
  webApi.finishTest(this.props.id_test)
  .then(data => {
    if(data.status === 200){
      localStorage.removeItem('_qNum')

      data.achievement && webApi.getAward(data.achievement.award_id).then(data => {
        data.status === 200 && this.setState({achievement: data.response[0]})
      })
      data.next_attempt_time && this.setState({next_attempt_time: data.next_attempt_time})
      return history.push(`/history/${data.history_id}`) //webApi.getHistory(data.history_id).then(res => this.setState({finish_test:res})).catch(err => console.log(err))
    }
  })
  .catch(error => console.log(error))
}

handleSelection = id => {
  //this.state.answer_result_save && console.log(this.state.answer_result_save[id.target.dataset.idAnswer], 111111111111111, id.target.dataset.idAnswer)
  let ids
  if(this.state.answer_result_save && this.state.answer_result_save[id.target.dataset.idAnswer]) ids = this.state.answer_result_save[id.target.dataset.idAnswer]
  else ids = this.state.answer_id

  if(id.target.checked){
    id.target.checked = true
    ids.push(id.target.dataset.id)
  }
  else {
    id.target.checked = false
    ids.splice(ids.indexOf(id.target.dataset.id), 1)
  }
  this.setState({answer_id:ids})
}

renderAnswerFree = (id) =>{
  let str
  if(this.state.answer_result_save && this.state.answer_result_save[id] && id === this.state.id_active){
    str = this.state.answer_result_save[id]
    if(document.getElementById('free-type-answer')) document.getElementById('free-type-answer').value = document.getElementById('free-type-answer').defaultValue
    return str[Object.keys(str)]
  }else{
    if(document.getElementById('free-type-answer')) document.getElementById('free-type-answer').value = ''
  }

  //(this.state.handleAnswer && this.state.handleAnswer[this.state.id_active]) && this.state.handleAnswer[this.state.id_active]
}

isBtnDisabled = (freeVar) => {
  let type
  if(this.state.questionAnswer) type = this.state.questionAnswer.type

  if(type === 'selection'){
    let answer_id = this.state.answer_result_save[this.state.id_active]
    console.log(answer_id)
    if((answer_id && answer_id.length) || (this.state.answer_id && this.state.answer_id.length)) return false
    else return true
  }else{
    let handleAnswer
    let resultSave
    if(this.state.handleAnswer && this.state.handleAnswer[this.state.id_active]){
      handleAnswer = this.state.handleAnswer[this.state.id_active]
    }
    if(!handleAnswer) handleAnswer = 0

    if(this.state.answer_result_save[this.state.id_active]){
      resultSave = this.state.answer_result_save[this.state.id_active]
    }
    console.log(handleAnswer, resultSave)
    if((resultSave) || (this.state.handleAnswer && this.state.handleAnswer && this.state.handleAnswer[this.state.id_active])) return false
    else return true
  }
}

renderQuestions = () =>{
    let active_tab = null

    if(localStorage.getItem("_qNum")){
        if(document.querySelector(`[data-num="${localStorage.getItem("_qNum")}"]`)){
          active_tab = document.querySelector(`[data-num="${localStorage.getItem("_qNum")}"]`).dataset.id
        }
    } else active_tab = this.state.id_active
    let actual = this.state.questions.filter((el) =>{
        return el.id === this.state.id_active
    })

    let answers
    if(actual[0] && actual[0].type === "selection"){
        answers = actual[0].answers
        .reduce((acc, answer) => !acc.find(v=>v.id === answer.id) ? acc.concat(answer): acc,[])
        .map((el,i) => {
            return (<div key={i + el.id}>
              <input
              onClick={this.handleSelection}
              className="fl-left input-checkbox"
              defaultChecked={(this.state.answer_result_save && actual[0].id === this.state.id_active && this.state.answer_result_save[actual[0].id] && this.state.answer_result_save[actual[0].id].indexOf(el.id) >= 0) ? true : false}
              type="checkbox" data-id-answer={actual[0].id} data-id={el.id}
              /> {el.body} <br/><br/>
            </div>)
        })
    }
    else {
      let str = null
      if(this.state.answer_result_save && actual[0] && actual[0].id === this.state.id_active){
        if(this.state.answer_result_save[actual[0].id]) str = this.state.answer_result_save[this.state.id_active]
        answers = <div><textarea cols="30" rows="10" data-id-freetype={this.state.id_active && this.state.id_active} id="free-type-answer"
                  onChange = {this.handleAnswer}
                  defaultValue={
                    this.renderAnswerFree(actual[0].id)
                    //(this.state.handleAnswer && this.state.handleAnswer[this.state.id_active]) && this.state.handleAnswer[this.state.id_active]
                    //str && str[Object.keys(str)]
                    //(str && str[Object.keys(str)] && !this.handleAnswer) && str
                  }
                  value = {(this.state.handleAnswer && this.state.handleAnswer[this.state.id_active]) && this.state.handleAnswer[this.state.id_active]}
                  placeholder="Напишите свой ответ">
                  </textarea><br/><br/><br/><br/></div>
      }else {
        answers = <div><textarea cols="30" rows="10" id="free-type-answer" onChange = {this.handleAnswer} defaultValue={''} placeholder="Напишите свой ответ"></textarea><br/><br/><br/><br/></div>
      }
    }
    //questionAnswer()

    //!

    return this.state.id_active && (
      <div className="min-block-left">
        <TestStat finishTest={this.finishTest} id_test={this.props.id_test} questions={this.props.questions} time_to_pass = {this.props.time_to_pass} q_length = {this.state.questions.length} q_num = {this.state.q_num || 1}/>
          <br/>
          <br/>
          <b>{actual[0] ? actual[0].text: ''}</b>
          <div className="hr"></div>
          <div>{answers}</div><br/>

          {this.state.q_num > 1 && <button className="btn-def" onClick={this.prevQuestion}><i className="fas fa-arrow-left"></i> Назад</button>}
          {document.querySelectorAll('.coub-send-award').length !== this.state.questions.length ? <button
                                                                                                  className="btn-next"
                                                                                                  onClick={this.sendQuestion}
                                                                                                  disabled={this.isBtnDisabled()}>Ответить и продолжить</button> : <button className="btn-next" onClick={this.sendQuestion} disabled={this.isBtnDisabled()}>Поменять ответ</button>}
          {this.state.q_num <= this.state.questions.length - 1 && <button className="btn-def" onClick={this.nextQuestion}>Вперед <i className="fas fa-arrow-right"></i></button>}

          {document.querySelectorAll('.coub-send-award').length === this.state.questions.length && <button className="fl-right" onClick={this.finishTest}>Закончить тест</button>}
        </div>
    )
}

  renderTest = () => {
    let { /*id_test,*/questions } = this.props
    return (<div className="user-right-content">
            {this.state.questions && this.renderQuestions()}

            <div className="min-block-right">
            <div className="min-block-right-title">Вопросы {this.state.questions && this.state.questions.length}</div>
            {questions && this.renderBtnsTest()}
            <br/><br/>
            <div className="min-block-right-inf">
              <i className="fal fa-info-circle"></i> В процессе выполнения тестового задания, Вы можете пропускать вопросы с возможностью вернуться к ним потом. Но, следите за временем и статусом отвеченных и пропущенных вопросов, чтобы успеть ответить на все вопросы за отведённое время. <br/><br/> Желаем удачи!
            </div>
            </div>
            <div className="clear"></div>
          </div>)
  }

  renderFinishStep = id => {
    return (
      <Fail nextTime = {this.state.next_attempt_time} achievement={this.state.achievement} data={id.response}/>
    )
  }

  render() {
    return (
      this.state.finish_test === null ? this.renderTest() : this.renderFinishStep(this.state.finish_test)
    )
  }
}
