import { combineReducers } from 'redux'
import component from './preloader/component'
import client from './client/client'
import pageName from './header/page-name'
import modals from './elements/modals'
import breadcrumbs from './breadcrumbs/index'

export default combineReducers({
    preloader: component,
    user: client,
    page: pageName,
    modals,
    brdc: breadcrumbs
})