import React, { Component } from 'react'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';

export default class CreateDepartament extends Component {
    state = {
        company: null,
        title: null
    }

    componentWillMount(){
        webApi.getCompanyId(this.props.id_company).then(data => {
            if(data.status === 200){
                this.setState({title: data.response[0].title})
            }
        })
    }
    handleCreate = () => {
        let {title, company} = this.state
        let obj = {
            id: this.props.id_company,
            title
        }

        console.log(this.state)

        if(!title) return header.getSnackbar("Название компании не заполнено")

        webApi.updateCompanyId(obj)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Компания обновлена")
                this.props.handlePage('view')
            }
        })
    }

    handleInput = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    render() {
        let {title} = this.state

        return (  
            <div>
                <span className="href" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>     
                <div className="create-form">
                    <span><b>Название компании</b></span> <br/>
                    <input name = "title" defaultValue={title && title} onChange={this.handleInput} type="text" placeholder="Введите название компании"/> <br/><br/>
                    <div className="btnSaveProfile fl-left" onClick={this.handleCreate}>Обновить</div>
                </div>
            </div>
        )
    }
}