import React, {useState} from 'react';
import './style.css'
import Button from "@material-ui/core/Button";
import Icon from '../../../../../../img/check-circle.svg'
import store from "../../../../../store";
import {changeSuccessModal} from "../../../../../store/actions/elements/action";

const SuccessIssueModal = ({id}) => {

  const [active, setActive] = useState()

  store.subscribe(() => {
    setActive(store.getState().modals.successModal)
  })

  const closeHandler =() => {
    store.dispatch(changeSuccessModal(false))
  }

  return (
    <div onClick={closeHandler} className={`modal-wrapper-success  ${active && 'active-modal-success'}`}>
      <div className="modal-body-success ">
        <img src={Icon} alt={'icon'} />
        <div className="modal-text-success">Ваше обращение успешно отправлено!</div>
        <div className="modal-text-number">Номер обращения {id}</div>
        <Button onClick={closeHandler} className={'modal-button-success '}>Закрыть</Button>
      </div>
    </div>
  );
};

export default SuccessIssueModal;
