import React, { Component } from 'react'
import ViewProfileUser from './view'
import { header } from './../../../../utilities/page';
import webApi from '../../../../utilities/web-api';

export default class ProfileUser extends Component {
    state = {
        user: null
    }

    componentWillMount(){
        const id = this.props.match.params.id

        header.pageName("Профиль пользователя")

        webApi.getProfileId(id)
        .then(data => {
            if(data.status === 200){
                this.setState({user: data.response}, () => {
                    let {user} = this.state

                    webApi.getAchievement(id)
                    .then(a => a.status === 200 && this.setState({user:{...this.state.user, achievements: a.response}}))

                    webApi.getRatings(id)
                    .then(r => r.status === 200 && this.setState({user:{...this.state.user, ratings: r.response.result}}))

                    webApi.getTimeLineId(id)
                    .then(t => t.status === 200 && this.setState({user:{...this.state.user, timeline: t.response}}, () =>{
                        if(this.state.user.timeline && this.state.user.timeline.length){
                            this.state.user.timeline.map((t,i) => {
                                if(t.program && t.program.test_id){
                                    let id_test = t.program.test_id
                                    webApi.getHistoryTest(id_test)
                                    .then(data => {
                                        if(data.status === 200){
                                            let tm = this.state.user.timeline
                                            tm[i].program_history = data.response
                                            this.setState({
                                                user:{
                                                    ...this.state.user,
                                                    timeline: tm
                                                }
                                            })
                                    }})
                                }
                                if(t.course && t.course.test_id){
                                    let id_test = t.course.test_id
                                    webApi.getHistoryTest(id_test)
                                    .then(data => {
                                        if(data.status === 200){
                                            let tm = this.state.user.timeline
                                            tm[i].course_history = data.response
                                            this.setState({
                                                user:{
                                                    ...this.state.user,
                                                    timeline: tm
                                                }
                                            })
                                    }})
                                }
                                if(t.lecture && t.lecture.test_id){
                                    let id_test = t.lecture.test_id
                                    webApi.getHistoryTest(id_test)
                                    .then(data => {
                                        if(data.status === 200){
                                            let tm = this.state.user.timeline
                                            tm[i].lecture_history = data.response
                                            this.setState({
                                                user:{
                                                    ...this.state.user,
                                                    timeline: tm
                                                }
                                            })
                                    }})
                                }
                            })
                        }
                    }))

                    if(user.company_id){
                        webApi.getCompanyId(user.company_id)
                        .then(c => c.status === 200 && this.setState({user:{...this.state.user, company: c.response[0].title}}))
                    }
                    if(user.department_id){
                        webApi.getDepartment(user.department_id)
                        .then(d => d.status === 200 && this.setState({user:{...this.state.user, department: d.response[0].title}}))
                    }
                    if(user.filial_id){
                        webApi.getFilial(user.filial_id)
                        .then(f => f.status === 200 && this.setState({user:{...this.state.user, filial: f.response[0].title}}))
                    }

                })
            }
        })
    }
    render() {
        return (
            <div className="block-content">
               {this.state.user && <ViewProfileUser user = {this.state.user}/>}
            </div>
        )
    }
}
