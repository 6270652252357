import React from 'react'
import { header } from './../../../../../../utilities/page';
import FileViewer from './../../../fileReader';


export default ({ imageUrl }) => {
  return (
    // <div className='lesson-material-type image'>
    //   <img src={imageUrl} alt="additional material image"/>
    // </div>
    //header.getModalPage({title: '', content: <FileViewer type="desc" file={<img src={imageUrl} alt="_img" className="fix-image-web"/>} />})
    <img onClick={()=> header.getModalPage({title: '', content: <FileViewer type="desc" file={<img src={imageUrl} alt="_img" className="fix-image-web"/>} />})} src={imageUrl} alt="img"/>
  )
}
