import React, { Component } from 'react'
import ViewDisk from './view'
import webApi from '../../../../utilities/web-api';
import { header } from '../../../../utilities/page';

export default class Disk extends Component {
    state = {
        files: [],
        type: 'all',
        sort: [],
        checkFile: null,
        preload: true,
        select: null
    }

    getFiles = () => {
        !this.state.preload && this.setState({preload: true})

        webApi.getFiles()
        .then(data => {
                this.setState({preload: false})
                data.status === 200 && this.setState({files: data.response}, () =>{
                this.handleSort()
            })
        })
    }

    togglePreloader = e => {
        this.setState({preload: e})
    }

    selectFile = () =>{
        this.setState({select: this.state.checkFile}, () => {
            this.props.propFile && (this.props.propFile.bar = this.state.select)
            header.closeModalPage()
        })
    } 

    uploadFiles = file => {
        this.setState({preload: true})
        header.uploader(file, () => this.setState({preload: false}, () => this.getFiles()))
    }

    componentWillMount(){
        this.getFiles()
    }

    handleCheckFile = e => this.setState({checkFile: e})

    handleSort = (typeFile = 'all') => {

        this.setState({type:typeFile})

        let {files, type} = this.state

        let sort = files.filter(el => {
            let typeSort = type
            if(typeSort === 'all') return el.type
            else return el.type === typeSort
        })

        this.setState({sort})
    }

    fileSearch = e => {
        const value = e.target.value.toLowerCase();

        let fileAll = document.querySelectorAll('.disk-space-file-check-active')
        if(fileAll && fileAll[0]) fileAll[0].classList.remove('disk-space-file-check-active')
      
        const filter = this.state.files.filter(file => {
          return file.original ? file.original.toLowerCase().includes(value) : file.url && file.url.toLowerCase().includes(value)
        });
      
        this.setState({sort: filter, checkFile: null})
      };

    render() {
        return (
          <ViewDisk
            togglePreloader = {this.togglePreloader}
            getFilesRefresh = {this.getFiles}
            checkFile = { this.state.checkFile }
            handleCheckFile = { this.handleCheckFile }
            fileSearch = { this.fileSearch }
            sort = { this.state.sort }
            handleSort = { this.handleSort }
            uploadFiles = { this.uploadFiles }
            preload = { this.state.preload }
            selectFile = { this.selectFile }
          />
        )
    }
}
