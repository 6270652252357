import React, { Component } from 'react'
import ViewAvailability from './view'
import { header } from './../../../../utilities/page';
import webApi from '../../../../utilities/web-api';

export default class Availability extends Component {
    state = {
        selectMaterial: "program",
        selectCompany: "company",
        inputMaterial: '',
        inputCompany: '',
        resultMaterial: [],
        resultCompany: [],
        selectInputMaterial: [],
        selectInputCompany: [],
    }

    componentWillMount(){
        header.pageName('Создание доступности материала')
    }

    onChangeMaterial = (_, type) => {
        if(type){
            this.setState({ 
                selectMaterial: type,
                selectInputMaterial: [],
                resultMaterial: [],
             })
        }
    }

    onChangeCompany = (_, type) => {
        if(type){
            this.setState({ 
                selectCompany: type,
                selectInputCompany: [],
                resultCompany: [],
             })
        }
    }

    handleMaterial = (val) => {
        if(val.length){
            if(this.state.selectMaterial === 'program'){
                webApi.getProgramLike(val)
                .then(e => {
                    if(e.status === 200){
                    let res = e.response
                        this.setState({resultMaterial: res})
                    }else {
                        header.getSnackbar('Ничего не найдено')
                        this.setState({resultMaterial: []})
                    }          
                })
            }else if(this.state.selectMaterial === 'course'){
                webApi.getCourseLike(val)
                .then(e => {
                    if(e.status === 200){
                    let res = e.response
                        this.setState({resultMaterial: res})
                    }else{
                        header.getSnackbar('Ничего не найдено')
                        this.setState({resultMaterial: []})
                    }              
                })
            }else if(this.state.selectMaterial === 'lecture'){
                webApi.getLectureLike(val)
                .then(e => {
                    if(e.status === 200){
                    let res = e.response
                        this.setState({resultMaterial: res})
                    }else{
                        header.getSnackbar('Ничего не найдено')
                        this.setState({resultMaterial: []})
                    }              
                })
            }
        }
    }

    changeSelectCompany = e =>{
        this.setState({selectInputCompany: e})
    }

    changeSelectMaterial = e =>{
        this.setState({selectInputMaterial: e})
    }

    handleCompany = (val) => {
        if(val.length){
            if(this.state.selectCompany === 'company'){
                webApi.getCompanyLike(val)
                .then(e => {
                    if(e.status === 200){
                    let res = e.response
                        this.setState({resultCompany: res})
                    }else {
                        header.getSnackbar('Ничего не найдено')
                        this.setState({resultCompany: []})
                    }           
                })
            }else if(this.state.selectCompany === 'object'){
                webApi.getDepartmentLike(val)
                .then(e => {
                    if(e.status === 200){
                    let res = e.response
                        this.setState({resultCompany: res})
                    }else {
                        header.getSnackbar('Ничего не найдено')
                        this.setState({resultCompany: []})
                    }          
                })
            }else if(this.state.selectCompany === 'department'){
                webApi.getFilialLike(val)
                .then(e => {
                    if(e.status === 200){
                    let res = e.response
                        this.setState({resultCompany: res})
                    }else {
                        header.getSnackbar('Ничего не найдено')
                        this.setState({resultCompany: []})
                    }           
                })
            }
        }
    }

    sendAvailability = () => {
        let {selectCompany, selectMaterial, selectInputMaterial, selectInputCompany} = this.state
        let obj = {}

        let idCompany = selectInputCompany.id
        let idMaterial = selectInputMaterial.id

        if(selectCompany === 'company') obj.company_id = idCompany
        else if(selectCompany === 'object') obj.department_id = idCompany
        else if(selectCompany === 'department') obj.filial_id = idCompany

        if(selectMaterial === 'program') obj.program_id = idMaterial
        else if(selectMaterial === 'course') obj.course_id = idMaterial
        else if(selectMaterial === 'lecture') obj.lecture_id = idMaterial

        webApi.setAvailability(obj)
        .then(data => {
            if(data.status === 200){
                this.setState({ 
                    selectInputCompany: [],
                    resultCompany: [],
                    selectInputMaterial: [],
                    resultMaterial: [],
                 })
                 return header.getSnackbar("Доступ успешно добавлен")
            } else return header.getSnackbar("Произошла ошибка")
        })

    }

    render() {
        return (
            <ViewAvailability
                resultMaterial ={ this.state.resultMaterial }
                resultCompany = { this.state.resultCompany }
                selectInputCompany = { this.state.selectInputCompany }
                selectInputMaterial = { this.state.selectInputMaterial }
                inputCompany = { this.state.inputCompany }
                changeSelectCompany = { this.changeSelectCompany }
                changeSelectMaterial = { this.changeSelectMaterial }
                inputMaterial = { this.state.inputMaterial }
                handleMaterial = { this.handleMaterial }
                handleCompany = { this.handleCompany }
                onChangeCompany = { this.onChangeCompany }
                onChangeMaterial = { this.onChangeMaterial }
                sendAvailability = { this.sendAvailability }
                state = { this.state }
            />
        )
    }
}
