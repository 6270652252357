import React, { Component } from 'react'
import Menu from './menu'
import RightContent from './right-content'
import { header } from './../../../../utilities/page';

export default class Admin extends Component {
  state={
    tap: "object"
  }

  componentWillMount(){
    header.pageName('Настройки')
  }
  handleTab = e =>{
    this.setState({tab: e.target.dataset.tab}, ()=> console.log(this.state.tab))
  }

  render() {
    let { tab } = this.state 
    return (
      <div className="user-right-content no-padding d-flex admin-setting">
        <Menu handleTab = { this.handleTab }/>
        <RightContent handleTab = {this.handleTab} tab = { tab }/>
      </div>
    )
  }
}
