import React, { Component } from 'react'
import history from '../../../history';
import Tooltip from '@material-ui/core/Tooltip';
import { header } from './../../../../../../utilities/page';
import webApi from '../../../../../../utilities/web-api';
import Tabs from '../../../../../elements/tabs/view'

export default class ViewProgramTable extends Component {
  state = {
    programs: null,
    oldSort: null,
    isActive: true,
    loader: true,
    editorState: false,
    orders: null,
    loaderMaterial: false,
    loaderLast: false,
    limit: 5,
    offset: 0
  }

  componentWillMount(){
    let { limit, offset} = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms(limit, offset)
        .then(data => {
          if(data.status === 200){
            this.setState({
              loader:false,
              programs: data.response,
              oldSort: data.response,
              loaderMaterial: false,
              //offset: this.state.offset + limit
            }, () => {
              let {programs} = this.state
              this.setState({orders:
                programs.sort(({ order: ordA },
                { order: ordB }) => ordA - ordB).map(e => {
                return {id: e.id, order: e.order}
                })
              })
            })
          }else {this.setState({loaderMaterial: false})}
        })
        .catch(error => console.log(error))
    })
  }

  loadMaterial = () =>{
    let { limit, offset, programs } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms(limit, offset + limit)
      .then(data => {
        if(data.status === 200){
          this.setState({
            programs: [...programs, ...data.response],
            offset: this.state.offset + limit,
            loaderMaterial: false
          })
        }else this.setState({loaderLast: true, loaderMaterial: false})
      })
    })
  }

  handleTable = (e) => {
    let {editorState} = this.state

    if(!editorState){
      if(e.target.className === 'far fa-edit'){
        history.push('/create-materials/' + e.target.dataset.id)
      }
      else if(e.target.className === 'fas fa-vial'){
        history.push(`/create-test/program/${e.target.dataset.materialId}/${e.target.dataset.testId}`)
      }else if(e.target.className === 'far fa-arrow-down' || e.target.className === 'far fa-arrow-up'){
        return true
      }
      else {
        history.push('/course/' + e.target.parentNode.dataset.id)
        header.addBreadcrumps({title:e.target.parentNode.dataset.title, route:'/course/' + e.target.parentNode.dataset.id})
        this.props.handleTableView('course')
      }
    }
  }

  async swapIndex(current, next) {
    // const { status, response } = await webApi.updateProgramOrder([
    //   { id: current.id, order: next.order },
    //   { id: next.id, order: current.order }
    // ])
    // if (status === 200) {
    //   this.setState({
    //     programs: this.state.programs.map(program => {
    //       const found = response.find(({ id }) => id === program.id)
    //       if (found) program.order = found.order
    //       return program
    //     })
    //   })
    // }
    let programs = this.state.programs.filter(e => e !== current && e !== next)
    programs = [...programs, {...current, order: next.order}, {...next, order: current.order}].sort(({order: a}, {order: b}) => a - b)
    this.setState({programs})

    let orders = this.state.orders.filter(e => e.id !== current.id && e.id !== next.id)
    orders = [...orders, { id: current.id, order: next.order }, { id: next.id, order: current.order }].sort(({order: a}, {order: b}) => a - b)
    this.setState({orders})
  }

  saveOrder = () => {
    webApi.updateProgramOrder(this.state.orders).then(data => data.status === 200 && header.getSnackbar('Порядок успешно сохранен'))
    this.setState({editorState: false})
  }

  renderTable = (data, active) => {
    const programs = this.state.loader ? null : data
      .filter(e => e.active === active)
      .sort(({ order: ordA }, { order: ordB }) => ordA - ordB)

    return(
      <div className="table fix-table-programms">
        <div className="table-header">
          <div className="table-column">
            {!this.state.editorState ? <Tooltip onClick={() => this.setState({editorState: true})} title="Отсортировать материал" aria-label="Отсортировать материал">
              <i className="fas fa-sort sort-icon"></i>
            </Tooltip> :
            <Tooltip onClick={() => this.saveOrder()} title="Сохранить" aria-label="Сохранить">
              <i className="fas fa-save sort-icon"></i>
            </Tooltip>
            }
            ID</div>
          <div className="table-column">Наименование материала</div>
          <div className="table-column">Количество курсов</div>
          <div className="table-column">Количество участников</div>
          <div className="table-column">Завершило успешно</div>
          <div className="table-column">Тест</div>
          <div className="table-column">Состояние</div>
        </div>
        {this.state.loader ? 'Подождите...' : programs.map((program, i) => (
          <div data-column={i+1} key={i}>
            <div onClick={this.handleTable} data-id={program.id} data-title={program.title} className="table-line">
              <div className="table-column">{program.id}
                {this.state.editorState && program && programs[i-1] &&
                  <span onClick={() => this.swapIndex(program, programs[i-1])} className="orderUp">
                    <Tooltip title="Поднять позицию" aria-label="Поднять позицию">
                      <i data-id={program.id} className="far fa-chevron-square-up"/>
                    </Tooltip>
                  </span>
                }
                {this.state.editorState && program && programs[i+1] &&
                  <span onClick={() => this.swapIndex(program, programs[i+1])} className="orderDown">
                    <Tooltip title="Опустить позицию" aria-label="Опустить позицию">
                      <i data-id={program.id} className="far fa-chevron-square-down"/>
                    </Tooltip>
                  </span>
                }
              </div>
              <div className="table-column">{program.title}</div>
              <div className="table-column">-</div>
              <div className="table-column">-</div>
              <div className="table-column">-</div>
              <div className="table-column">{program.test ? <i className="far fa-check-circle have-test"></i> : <i className="far fa-times-circle dont-test"></i>}</div>
              <div className="table-column">{active ? 'Активный' : 'Неактивный'}
              {!this.state.editorState &&
                <div data-id={program.id} data-title={program.title}>
                  <span className="editMaterialLine"><Tooltip title="Редактировать" aria-label="Редактировать"><i data-id={program.id} className="far fa-edit"></i></Tooltip></span>
                  <span className="editMaterialLine editMaterialTest"><Tooltip title="Тест" aria-label="Тест"><i data-material-id={program.id} data-test-id={program.test ? program.test.id : 'new'}  className="fas fa-vial"></i></Tooltip></span>
                </div>
              }
              </div>
            </div>
          </div>)
        )}
      </div>
    )
  }

  handleActive = () => {
    this.setState({isActive: true})
  }

  handleInActive = () => {
    this.setState({isActive: false})
  }

  render() {
    let { programs } = this.state

    let tabs = []
    if(programs) {
      tabs = [
      {
          title: <span>Активные</span>,
          component: this.renderTable(programs, true)
      },
      {
          title: <span>Неактивные</span>,
          component: this.renderTable(programs, false)
      }
    ]}else tabs = []
    //let { programs } = this.props

    return (
      <div>
        {programs && tabs.length ?
          <div>
            <Tabs tabs={tabs}/>
            <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterial}>
              {this.state.loaderLast ? 'материалов больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
            </button>
          </div> :
          <div className="pd-25">Ни одного материала не создано <span className="href" onClick={()=>history.push('/create-materials')} >создать</span></div>}
    </div>
    )
  }
}
