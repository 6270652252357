import React, { Component } from 'react'
import Select from 'react-select';
import { header } from './../../../../utilities/page';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default class ViewAvailability extends Component {
    render() {

        let { 
            state,
            onChangeCompany,
            onChangeMaterial,
            handleMaterial,
            handleCompany,
            resultMaterial,
            selectInputCompany,
            changeSelectCompany,
            resultCompany,
            changeSelectMaterial,
            selectInputMaterial,
            sendAvailability
        } = this.props

        let nameComapny
        let nameMaterial

        if(state.selectCompany === 'company') nameComapny = "компании"
        if(state.selectCompany === 'object') nameComapny = "отдела"
        if(state.selectCompany === 'department') nameComapny = "объекта"

        if(state.selectMaterial === 'program') nameMaterial = "программы"
        if(state.selectMaterial === 'course') nameMaterial = "курса"
        if(state.selectMaterial === 'lecture') nameMaterial = "лекции"

        return (
            <div>
            <div className="user-right-content w-50 task-page"> <br/><br/>
                <span className="href" onClick={() => header.historyBack()}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                <h3>Типы материалов</h3>
                <ToggleButtonGroup className="top-task-material-btns" onChange={onChangeMaterial} value={state.selectMaterial} exclusive>
                    <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="program">Программа</ToggleButton>
                    <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="course">Курс</ToggleButton>
                    <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="lecture">Лекция</ToggleButton>
                </ToggleButtonGroup><br/><br/>
                <Select
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])} 
                    value = {selectInputMaterial}
                    options = {resultMaterial}
                    onChange = {changeSelectMaterial}
                    noOptionsMessage = {() => '...'}
                    onInputChange={handleMaterial}
                    placeholder={`Введите название ${nameMaterial}`} 
                    /><br/><br/>

                <h3>Назначить для</h3>
                <ToggleButtonGroup className="top-task-material-btns" onChange={onChangeCompany} value={state.selectCompany} exclusive>
                    <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="company">Компания</ToggleButton>
                    <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="object">Отдел</ToggleButton>
                    <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="department">Объект</ToggleButton>
                </ToggleButtonGroup><br/><br/>
                <Select
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])}
                    options={resultCompany}
                    value={selectInputCompany}
                    noOptionsMessage = {() => '...'}
                    onChange={changeSelectCompany}
                    onInputChange={handleCompany}
                    placeholder={`Введите название ${nameComapny}`}
                    /> <br/><br/>
                <button 
                className="btnSaveProfile fl-left"
                onClick={sendAvailability} 
                disabled={(Object.keys(selectInputCompany).length && Object.keys(selectInputMaterial).length) ? false : true}
                >Сохранить</button> <br/><br/>
            </div>
            </div>
        )
    }
}
