import React from 'react'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core'
import helpers from '../../../../../../utilities/helpers'
import xlsx from "json-as-xlsx";
import moment from "moment";

const styles = () => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  table: {
    minWidth: '100%',
  },
  notFound: {
    width: '100%',
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 600
  }
})

function FilterTable({ classes, data, notFound }) {
  if (notFound) {
    return <h3 className={classes.notFound}>Не найдены записи удовлетворяющие фильтру.</h3>
  }
  let tResult = []
  Boolean(data.length) && data.map(e =>{
    return tResult = [...tResult, {
      user: `${e.users.surname} ${e.users.name}`,
      departments: get(e, 'departments.title', 'Отдел не указан'),
      filial: get(e, 'filials.title', 'Объект не указан'),
      programs: get(e, 'programs.title', 'Программа не указана'),
      courses: get(e, 'courses.title', 'Курс не указан'),
      lectures: get(e, 'lectures.title', 'Лекция не указана'),
      min_score_points: get(e, 'tests.min_score_points', 0),
      max_score_points: `${get(e, 'points', 0)}/${get(e, 'tests.max_score_points', 0)}`,
      success: e.success === true ? 'Успешно сдан' : 'Не сдан',
      points: get(e, 'points', 0),
      id: e.id,
      date: moment(get(e, 'finished_at', '-')).format('DD-MM-YYYY'),
    }]
  })

  function getMaxScorePoints(row) {
    return Number(
      row.max_score_points
      || get(row, 'tests.max_score_points')
      || get(row, 'question_amount')
      || 0
    )
  }

  function getMinScorePoints(row) {
    return isNumber(row.min_score_points)
      ? row.min_score_points
      : get(row, 'tests.min_score_points', 0)
  }

  function getResults(row) {
    const maxScorePoints = getMaxScorePoints(row)
    let points = get(row, 'points', 0)
    points = points < maxScorePoints ? points : maxScorePoints
    return `${points}/${maxScorePoints}`
  }


  function getPerformancePercentage(row) {
    const maxScorePoints = getMaxScorePoints(row)
    let points = get(row, 'points', 0)
    points = points < maxScorePoints ? points : maxScorePoints
    const result = points / maxScorePoints * 100
    return `${helpers.isFloat(result) ? result.toFixed(1) : result}%`
  }


  function download() {
    let headers = {
      id: 'ID',
      user: 'Пользователь'.replace(/,/g, ''),
      departments: "Отдел",
      filial: "Объект",
      programs: "Программа",
      courses: "Курс",
      lectures: "Лекция",
      min_score_points: "Порог сдачи",
      max_score_points: "Результат",
      percentPerformance: 'Процент',
      success: "Статус теста",
      date: 'Дата'
    }

    let itemsNotFormatted = tResult

    let itemsFormatted = []
    const getPercentForXlsx = (points, maxScorePoints) => {
      let maxScore = Number(maxScorePoints)
      points = points < maxScore ? points : maxScore
      const result = points / maxScore * 100
      return `${helpers.isFloat(result) ? result.toFixed(1) : result}%`
    }

    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        user: item.user.replace(/,/g, ''),
        departments: item.departments,
        filial: item.filial,
        programs: item.programs,
        courses: item.courses,
        lectures: item.lectures,
        min_score_points: item.min_score_points,
        max_score_points: item.max_score_points,
        success: item.success,
        percentPerformance: getPercentForXlsx(item.points, item.min_score_points),
        id: item.id,
        date: new Date(moment(item.date, 'DD-MM-yyyy').format('YYYY.MM.DD')),
      })
    })

    let fileTitle = `task_${Date.now()}`
    // Convert and prepare data for XLSX library function
    function convertedForXlsx(headers, title) {
      let res = {
        sheet: title,
        columns: [],
        content: []
      }
      for (let key in headers) {
        if(headers[key] === 'Дата') {
          res.columns.push({label: headers[key], value: key, format: 'dd.mm.yyyy'})
        }
        res.columns.push({label: headers[key], value: key})
      }
      for (let key in itemsFormatted) {
        res.content.push(itemsFormatted[key])
      }
      return [res]
    }

    // library's function for download prepared data into xlsx
    xlsx(convertedForXlsx(headers, fileTitle), {
      fileName: fileTitle,
      extraLength: 3,
      writeMode: "writeFile",
    })
  }


  return Boolean(data.length) && (
    <Paper className={classes.root+" table-report"}>
      <br/>
      <span onClick={() => download()} className="href pd-15 mg-tb-5"><i className="fal fa-file-export"></i> Экспортировать в *xlsx</span>
      <br/><br/>
      <Table className={classes.table + ' report-test-export'}>
        <TableHead className="table-report-head">
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Пользователь</TableCell>
            <TableCell align="right">Отдел</TableCell>
            <TableCell align="right">Объект</TableCell>
            <TableCell align="right">Программа</TableCell>
            <TableCell align="right">Курс</TableCell>
            <TableCell align="right">Лекция</TableCell>
            <TableCell align="right">Порог сдачи</TableCell>
            <TableCell align="right">Результат</TableCell>
            <TableCell align="right">Процент</TableCell>
            <TableCell align="right">Статус теста</TableCell>
            <TableCell align="right">Дата</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">{row.id}</TableCell>
              <TableCell align="right">{`${row.users.surname} ${row.users.name}`}</TableCell>
              <TableCell align="right">{get(row, 'departments.title', 'Отдел не указан')}</TableCell>
              <TableCell align="right">{get(row, 'filials.title', 'Объект не указан')}</TableCell>
              <TableCell align="right">{get(row, 'programs.title', 'Программа не указана')}</TableCell>
              <TableCell align="right">{get(row, 'courses.title', 'Курс не указан')}</TableCell>
              <TableCell align="right">{get(row, 'lectures.title', 'Лекция не указана')}</TableCell>
              <TableCell align="right">{getMinScorePoints(row)}</TableCell>
              <TableCell align="right">{getResults(row)}</TableCell>
              <TableCell align="right">{getPerformancePercentage(row)}</TableCell>
              <TableCell align="right">{row.success === true ? 'Успешно сдан' : 'Не сдан'}</TableCell>
              <TableCell align="right">{row.finished_at ? moment(get(row, 'finished_at', '-')).format('DD.MM.YYYY')  : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

FilterTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FilterTable)
