import React, { Component } from 'react'
import webApi from '../../../../utilities/web-api'
import video_poster from '../../../../../img/video_poster.jpg'
import { withStyles } from '@material-ui/core/styles';
import { header } from './../../../../utilities/page';
import PdfViewer from './../pdf.jsx';
import FileViewer from './../fileReader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Touch from './touch'
import history from '../history';
// import iconPng from '../../../../../img/png.svg'
// import iconMpg from '../../../../../img/mpg.svg'
// import iconPdf from '../../../../../img/pdf.svg'
// import iconMp3 from '../../../../../img/mp3.svg'


const styles = props => ({
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    barColorPrimary: {
      backgroundColor: '#000',
    },
    root: {
        color: '#d1ab66',
      }
  });


class MobileLecture extends Component {
    state = {
        materials: null,
        page: 'home',
        url: null,
        progress: true
    }

    videoLesson = content => {
        return `<h3>${content.title}</h3><video src=${content.video_url} poster=${video_poster} controls controlsList="nodownload"></video></br></br>${content.description}`
    }

    renderMaterial = (url, src) => {
        let type = url.split('&')[url.split('&').length - 1]
        switch (type) {
                case 'image': return header.getModalPage({title: '', content: <FileViewer file={<Touch url={src} />} />})
                case 'audio': return header.getModalPage({title: '', content: <FileViewer file={<audio src={src} controls controlsList="nodownload"></audio>} />})
                case 'pdf': return header.getModalPage({title: '', content: <PdfViewer file={src} />})
                case 'video': return header.getModalPage({title: '', content: <FileViewer file={<video src={src} poster={video_poster} controls controlsList="nodownload"></video>} />})
                default:
                    break;
            }
    }

    splitUrl = url =>{
        let newUrl = url.split('#')[1].split('&')[0]
        return newUrl
    }

    //31
    
    componentDidMount(){
        const params = new URLSearchParams(this.props.location.search)
        const token = params.get('token')
        const _id = this.props.match.params.id
        
        webApi.getLessonMobile(_id, token)
        .then(data => {
            let _html = document.querySelector('.mobile-html-content')
            
            if(data.status === 200){
                this.setState({materials:data.response, progress: false},
                    () => {
                        setTimeout(()=> {
                            let posTop = params.get('top')
                            if(posTop && document.querySelector('.mobile-html-content')) document.querySelector('.mobile').scrollTop += posTop

                            let hrefElem = document.querySelectorAll('.mention')
                            let imgView = document.querySelectorAll('.mobile-html-content img')
                            let srcHref = document.querySelectorAll('.mobile-html-content a')
                            
                            if(srcHref){
                                srcHref.forEach(e => {
                                    e.addEventListener('click', (e) => {
                                        //console.log(e.getAttribute("href").indexOf("#"))
                                        if(e.target.getAttribute("href") && e.target.getAttribute("href").indexOf("#") < 0){
                                            e.preventDefault()
                                            let newUrl = e.target.getAttribute('href')
                                            let offsetTop = document.querySelector('.mobile').scrollTop
                                            
                                            history.push(`/mobile/lecture/${_id}?token=${token}&top=${offsetTop}`)
                                            document.location.href = `/mobile/lecture/${_id}?token=${token}&top=${offsetTop}`
                                            document.location.href = newUrl
                                        }
                                    })
                                })
                            }

                            if(imgView){
                                imgView.forEach(e => {
                                    e.addEventListener('click', (e) => {
                                        let src = e.target.getAttribute('src')
                                        return header.getModalPage({title: '', content: <FileViewer file={<Touch url={src} />} />})
                                        //console.log(e.target.getAttribute('src'))
                                    })
                                })
                            }
                            //console.log(window.location)
                            if(hrefElem){
                                hrefElem.forEach(e => {
                                    e.setAttribute("href", `#${e.attributes.href.nodeValue.split('#')[1]}`)
                                    e.addEventListener('click', () => {
                                        console.log(e.dataset.test)
                                        window.location.href = `#${e.attributes.href.nodeValue.split('#')[1]}`
                                    })
                                })
                            }
                            
                
                            let oldTriggers = document.querySelectorAll('.mobile-html-content a')
                
                            if(oldTriggers){
                                oldTriggers.forEach(e => {
                                    let lnh = e.getAttribute('href').split("#")
                                    
                                    if(lnh.length > 1) e.setAttribute("href", `#${lnh[1]}`)
                                })
                            }
                
                            // document.querySelectorAll( 'oembed[url]' ).forEach( element => {
                            //     // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
                            //     // to discover the media.
                            //     const anchor = document.createElement( 'a' );
                        
                            //     anchor.setAttribute( 'href', element.getAttribute( 'url' ) );
                            //     anchor.className = 'embedly-card';
                        
                            //     element.appendChild( anchor );
                            // } );
                        }, 1000)

                        let material =  this.state.materials
                        let type = material.type
                        let html_content = null

                        switch (type) {
                            case "mixed": {
                                html_content = material.html_content
                                break
                            }
                            case "video": {
                                html_content = this.videoLesson(material)                 
                                break
                            }
                            default:
                                break;
                        }

                        if(_html) _html.innerHTML = html_content
                    }
                )
            }else{
                if(_html) _html.innerHTML += <span>Токой новости не существует</span>
            }
        })
    }


    handlePage = (page, url) => {
        this.setState({page, url})
    }



    additionalMaterial = () => {
        let image, video, pdf, audio
        let {additional_materials} = this.state.materials

        if(additional_materials){
           
            image = additional_materials.filter(e => e.type === 'image')

            video = additional_materials.filter(e => e.type === 'video')

            pdf = additional_materials.filter(e => e.type === 'pdf')

            audio = additional_materials.filter(e => e.type === 'audio')
        }
        // image, video, pdf, audio
        header.getModalPage({
            title: <span><i className="far fa-file-alt color-white"></i> Дополнительные материалы</span>,
            content: <FileViewer classStyle="mobile-addition-materials-modal"
                        image={image}
                        video={video}
                        pdf={pdf}
                        audio={audio}
                        page={this.state.page}
                        url={this.state.url}
                     />
        })
    }

    componentDidUpdate(prevProps, prevState){
        // function ready(e) {
        //     e.currentTarget.documentElement.scrollTop = 200
        // }

        // document.addEventListener("DOMContentLoaded", ready);
        window.scrollTo(0,100)
    }

    render() {
        const { classes } = this.props;
        let additional_materials
        let test_mobile 
        if(this.state.materials) {
            additional_materials = this.state.materials.additional_materials
            test_mobile = JSON.stringify(this.state.materials.test)
        }
        test_mobile && console.log(`test:#${test_mobile}`)
        this.props.location.hash && this.renderMaterial(this.props.location.hash, this.splitUrl(this.props.location.hash))

        return (
            <div className="mobile">
                    <div className="block-content mobile-block-content">
                        {this.state.progress && <div className="mobile-progress"><CircularProgress {...this.props} /></div>}
                        <div className="mobile-html-content"></div>
                        {
                            this.state.materials && this.state.materials.test && this.state.materials.test.published &&
                            <div>
                                <div className="hr"></div>                               
                                <span>Вам доступен тест по этой лекции</span><br/><br/>
                                <a href={`test:#${test_mobile}`}><div className="btnSaveProfile fl-left" >Пройти тест</div></a>
                                <br/><br/><br/><br/><br/><br/>
                            </div>
                        }
                        <div className="mobile-addition-materials" onClick={this.additionalMaterial} >
                            <i className="far fa-file-alt"></i> Дополнительные материалы
                            <span className="mobile-addition-materials-num">
                                {additional_materials && additional_materials.length}
                            </span>
                        </div>
                        {/*this.state.materials && JSON.stringify(additional_materials)*/}
                    </div>
            </div>
        )
    }
}

export default  withStyles(styles)(MobileLecture);
