import React, { Component } from 'react'
//import ViewAdminContent from './view';
import ObjectTab from './object'
import DepartamentTab from './departament'
import AwardsTab from './awards'
import UsersTab from './users'
import CategoryTab from './category'
import CompanyTab from './company'
import HelperTab from './helper'
import PostTab from './post';
import RatingTab from './ratings';

export default class AdminContent extends Component {

  handleTab = (tab) =>{
    switch (tab){
      case ('object'): return <ObjectTab/>
      case ('departament'): return <DepartamentTab/>
      case ('awards'): return <AwardsTab/>
      case ('users'): return <UsersTab/>
      case ('category'): return <CategoryTab/>
      case ('company'): return <CompanyTab/>
      case ('helper'): return <HelperTab/>
      case ('post'): return <PostTab/>
      case ('rating'): return <RatingTab/>
      default: return <CompanyTab/>
    }
  }
  
  render() {
    let { tab } = this.props
    return (
      <div className="admin-content">
        {this.handleTab(tab)}
      </div>
    )
  }
}
