import React, { Component } from 'react'
import webApi from '../../../utilities/web-api';
import { header } from './../../../utilities/page';
import Tooltip from '@material-ui/core/Tooltip';

class SetAward extends Component{
  state = {
    title: null,
    badge: null,
    description: null
  }

  changeSetAward = e => {
    this.setState({[e.target.name]:e.target.value})
  }

  setAward = () => {
    webApi.setAward(this.state)
    .then(data => {
      if(data.status === 200){
        header.getSnackbar(`Достижение "${this.state.title}" успешно создано`)
        return this.props.updateState(data.result)
      }else header.getSnackbar(`Произошла ошибка, достижение не создано`)
    })
  }

  render() {
    return(
    <div>
      <input type="text" onChange={this.changeSetAward} name="title" placeholder="Название достижения"/> <br/><br/>
      <input type="text" onChange={this.changeSetAward} name="description" placeholder="Описание достижения"/> <br/><br/>
      <input type="text" onChange={this.changeSetAward} name="badge" placeholder="URL на изображение достижения"/> <br/><br/>
      <div className="btnSaveProfile center w-50 fl-left" onClick={this.setAward}>Создать и добавить</div>  <span onClick={this.props.back} className="href fl-right">Назад</span>
    </div>
    )
  }
}

class NoAward extends Component{
  state = {
    set_award: false
  }

  setAward = () => { this.setState({set_award: true}) }
  setAwardClose = () => { this.setState({set_award: false}) }

  render() {
    return !this.state.set_award ? <div>Ни одного достижения не создано</div> : <SetAward updateState={this.props.updateState} back={this.setAwardClose}/>
  }
}

class RenderAward extends Component{
  state = {
    id: null,
    title: null
  }

  handleClick = (e) =>{
    this.setState({
      id: e.currentTarget.dataset.id,
      title: e.currentTarget.dataset.title
    }, async() => {
      await this.props.updateState(this.state)
      //this.props.handleClose()
      await header.closeDialog()
    })
  }

  render() {
    let { awards } = this.props
    return(
      awards && awards.map((el,i) =>{
        return(<div onClick={this.handleClick} data-id={el.id} data-title={el.title} key={i} className="container-award">
            <Tooltip title={el.description} aria-label={el.description}><div className="badge-award"><img src={el.images.original} alt={el.title}/></div></Tooltip><br/>
            <div className="title-award">{el.title}</div>
        </div>)
      })
    )
  }
}

export default class GetAwards extends Component {
    state = {
        awards: null
    }

    updateState = state => {
      this.setState({award: state}, () => this.state.award && this.props.changeAward(this.state.award))
    }

    componentWillMount(){
        webApi.getAward()
        .then(data => data.status === 200 && this.setState({awards: data.response.filter(e => e.active === true)}))
        .catch(console.error)
    }

    render() {
      return (
        <div>
          {!this.state.awards ? <NoAward/> : <RenderAward handleClose ={this.props.handleClose} updateState={this.updateState} awards = {this.state.awards}/>}
        </div>
      )
    }
}
