import React, { Component } from 'react'

export default class Touch extends Component {
    state = {
        rotation: 0,
        scale: 1,
        x: 1,
        y: 1
    }
    componentDidMount(){
        document.querySelector('.imgTouchLoad').onload = function (elemEvent){
        if ( !window.requestAnimationFrame ) {

			window.requestAnimationFrame = ( function() {

				return window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				function(callback, element ) {

					window.setTimeout( callback, 1000 / 60 );

				};

			} )();

        }
        
            let input = {dragStartX:0, dragStartY:0, dragX:0, dragY:0, dragDX:0, dragDY:0, dragging:false, touchStartDistance:0, touchStartAngle:0};
            let plateContainer;
            let prefixedTransform;
			let currentScale = 0.5;
            let currentRotation = 0;
            let posX = 0;
            let posY = 0;
            let velocityX = 0;
            let velocityY = 0;
            let containerWidth = elemEvent.target.width;
            let containerHeight = elemEvent.target.height;
            let plateWidth = 400;
            let plateHeight = 400;
            let maxScale = 3;
            let minScale = 0.5
            
            

			plateContainer = document.getElementById('plateContainer');		

			if('transform' in document.body.style){
				prefixedTransform='transform';
			}else if('webkitTransform' in document.body.style){
				prefixedTransform='webkitTransform';
			}
			// listeners
			if (window.PointerEvent) {
                input.pointers=[];
                plateContainer.addEventListener("pointerdown", pointerDownHandler, false);
            }else{
				plateContainer.addEventListener('touchstart', onTouchStart);
				plateContainer.addEventListener('mousedown', onPlateMouseDown);
            }
            console.log(containerWidth)
            //document.getElementById('plateCanvas') && console.log(document.getElementById('plateCanvas').naturalWidth)
			//posY= document.body.containerHeight - (containerHeight / 2)//containerHeight*0.5;
			//console.log(containerWidth, containerHeight, document.body.clientWidth, document.body.clientWidth)


			if(containerWidth > document.body.clientWidth) elemEvent.target.width = document.body.clientWidth

			posX= (document.body.clientWidth - (elemEvent.target.width / 2))/2;
			posY= elemEvent.target.height / 2

			onAnimationFrame();


		function onAnimationFrame() {

			requestAnimationFrame( onAnimationFrame );

			if(input.dragDX !== 0) velocityX = input.dragDX;
			if(input.dragDY !== 0) velocityY = input.dragDY;
			
			posX+= velocityX;
			posY+= velocityY;

			plateContainer.style[prefixedTransform]= 'translate('+posX+'px,'+posY+'px) scale('+currentScale+') translateZ(0)';

			velocityX= velocityX*0.8;
			velocityY= velocityY*0.8;

			input.dragDX=0;
			input.dragDY=0;

		}

		function onPlateMouseDown(event) {
			event.preventDefault();
			document.addEventListener('mouseup', onDocumentMouseUp);
			document.addEventListener('mousemove', onDocumentMouseMove);
			if(event.shiftKey === true) {
				//assume second touchpoint is in middle of screen
				handleGestureStart(posX, posY, event.clientX, event.clientY);
			} else {
				handleGestureStop();
				handleDragStart(event.clientX, event.clientY);
			}
		}

		function onDocumentMouseMove(event) {
			if(event.shiftKey) {
				handleGesture(posX, posY, event.clientX, event.clientY); 
			} else {
				handleDragging(event.clientX, event.clientY);
			}

		}

		function onDocumentMouseUp(event) {
			document.removeEventListener('mouseup', onDocumentMouseUp);
			document.removeEventListener('mousemove', onDocumentMouseMove);
			
			handleGestureStop();
				
			event.preventDefault();
			handleDragStop();
		}

		function onTouchStart(event) {
			event.preventDefault();
			if( event.touches.length === 1){
				document.addEventListener('touchmove', onTouchMove);
				document.addEventListener('touchend', onTouchEnd);
				document.addEventListener('touchcancel', onTouchEnd);
				handleDragStart(event.touches[0].clientX , event.touches[0].clientY);
			}else if( event.touches.length === 2 ){
				handleGestureStart(event.touches[0].clientX, event.touches[0].clientY, event.touches[1].clientX, event.touches[1].clientY );
			}
		}

		function onTouchMove(event) {
			event.preventDefault();
			if( event.touches.length  === 1){
				handleDragging(event.touches[0].clientX, event.touches[0].clientY);
			}else if( event.touches.length === 2 ){
				handleGesture(event.touches[0].clientX, event.touches[0].clientY, event.touches[1].clientX, event.touches[1].clientY );
			}
		}

		function onTouchEnd(event) {
			event.preventDefault();
			if( event.touches.length  === 0 && input.dragging){
				handleDragStop();
				document.removeEventListener('touchmove', onTouchMove);
                document.removeEventListener('touchend', onTouchEnd);
                document.removeEventListener('touchcancel', onTouchEnd);
			}else if(event.touches.length === 1 ){
				handleGestureStop();
				handleDragStart(event.touches[0].clientX, event.touches[0].clientY);
			}
		}
		function indexOfPointer(pointerId){
      for (var i=0;i<input.pointers.length;i++){
        if(input.pointers[i].pointerId === pointerId) {
           return i;
        }
      }
      return -1;
    }
    function pointerDownHandler(event) {
      var pointerIndex=indexOfPointer(event.pointerId);
      if(pointerIndex<0){
        input.pointers.push(event);
      }else{
        input.pointers[pointerIndex] = event;
      }
      if( input.pointers.length === 1){
				handleDragStart(input.pointers[0].clientX , input.pointers[0].clientY);
        window.addEventListener("pointermove", pointerMoveHandler, false);
        window.addEventListener("pointerup", pointerUpHandler, false);
			}else if( input.pointers.length === 2 ){
				handleGestureStart(input.pointers[0].clientX, input.pointers[0].clientY, input.pointers[1].clientX, input.pointers[1].clientY );
			}
    }

		function pointerMoveHandler(event) {
			
            let pointerIndex=indexOfPointer(event.pointerId);
            if(pointerIndex<0){
                input.pointers.push(event);
            }else{
                input.pointers[pointerIndex] = event;
            }
            
            if( input.pointers.length  === 1){
                handleDragging(input.pointers[0].clientX, input.pointers[0].clientY);
            }else if( input.pointers.length === 2 ){
                console.log(input.pointers[0], input.pointers[1]);
                handleGesture(input.pointers[0].clientX, input.pointers[0].clientY, input.pointers[1].clientX, input.pointers[1].clientY );
            }
		}

		function pointerUpHandler(event) {
			let pointerIndex=indexOfPointer(event.pointerId);
			if(pointerIndex<0){
				
			}else{
				input.pointers.splice(pointerIndex,1);
		}
      
		if( input.pointers.length  === 0 && input.dragging){
			handleDragStop();
        	window.removeEventListener("pointermove", pointerMoveHandler, false);
      		window.removeEventListener("pointerup", pointerUpHandler, false);
		}else if(input.pointers.length === 1 ){
			handleGestureStop();
			handleDragStart(input.pointers[0].clientX, input.pointers[0].clientY);
		}
      
		}
		function handleDragStart(x ,y ){
			input.dragging = true;
			input.dragStartX = input.dragX = x;
			input.dragStartY = input.dragY = y;
		}

		function handleDragging(x ,y ){
			if(input.dragging) {
				input.dragDX = x-input.dragX;
				input.dragDY = y-input.dragY;
				input.dragX = x;
				input.dragY = y;
			}
		}

		function handleDragStop(){
			if(input.dragging) {
				input.dragging = false;
				input.dragDX=0;
				input.dragDY=0;
			}
		}
		function handleGestureStart(x1, y1, x2, y2){
			input.isGesture = true;
			let dx = x2 - x1;
			let dy = y2 - y1;
			input.touchStartDistance=Math.sqrt(dx*dx+dy*dy);
			input.touchStartAngle=Math.atan2(dy,dx);
			input.startScale=currentScale;
			input.startAngle=currentRotation;
		}
		function handleGesture(x1, y1, x2, y2){
			console.log('handleGesture')
			if(input.isGesture){
				let dx = x2 - x1;
				let dy = y2 - y1;
				let touchDistance=Math.sqrt(dx*dx+dy*dy);
				let touchAngle=Math.atan2(dy,dx);
				let scalePixelChange = touchDistance - input.touchStartDistance;
				let angleChange = touchAngle - input.touchStartAngle;
				currentScale=input.startScale + scalePixelChange*0.01;
				currentRotation=input.startAngle+(angleChange*180/Math.PI);
				if(currentScale<minScale) currentScale=minScale;
				if(currentScale>maxScale) currentScale=maxScale;
			}
		}
		function handleGestureStop(){
			input.isGesture= false;
		}
    }
}

  render() {
	let {url} = this.props
	
    return (
          <div id="plateContainer" className="plateContainer">
			{/* <img onLoad={e => {
                //containerWidth = e.target.Width;
                //containerHeight = e.target.Height;
            }} src="https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg" id="plateCanvas" className="block-touch" /> */}
            <div id="plateCanvas" className="block-touch">
                <img src={url} className="imgTouchLoad" alt="sdfsdf"/>
            </div>
		</div>
    )
  }
}
