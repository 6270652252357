import React, { Component } from 'react'
import ViewSignUp from './view'
import webApi from '../../../utilities/web-api'
import Preloader from './../../preloader/preloader';
import { showSnackbar } from '../../../store/actions/elements/action'
import { connect } from 'react-redux'

class SignUp extends Component {
    state = {
        login: '',
        typeLogin: '',
        load: false
    }

    handleLogin = e => {
        this.setState({login: e.target.value})
    }

    validType = (temp, collback) => {
        const template = {
            email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
            phone: /^\d{12}$/
        }

        let templ = {
            valid: false,
            type: null
        }

        for (const tmp in template){
            let template_type = template[tmp].test(String(temp).toLowerCase())
            if(template_type) {
                templ.valid = template_type
                templ.type = tmp
            }
        }

        return collback(templ)
    }

    sendLogin = (e) => {
        e.preventDefault()

        let { handleForm } = this.props

        this.validType(this.state.login, data => {
            if(data.valid){
                this.setState({typeLogin: data.type, load:true}, () => {
                    let { login, typeLogin } = this.state
                    webApi.signUp({[typeLogin]: login})
                    .then(data => {
                        this.setState({load: false})
                        if(data.status === 201){
                            let type
                            this.state.typeLogin === 'phone' ? type = 'мобильный телефон' : type = 'e-mail'
                            this.props.showSnackbar(`На ваш ${type} отправлен пароль`)
                            handleForm('SIGN_IN')
                        }
                        else if(data.status === 510) this.props.showSnackbar('Такой пользователь уже существует')
                        else this.props.showSnackbar('Неизвестная ошибка')
                    })
                    .catch(error => console.log(error))
                })
            } else this.props.showSnackbar('Неверный формат телефона/E-mail')
        })
    }

    render() {
        let { load } = this.state
        return (
            load ? <Preloader/> : <ViewSignUp handleLogin = { this.handleLogin } sendLogin = { this.sendLogin } />
        )
    }
}

const mapDispatchToProps = { showSnackbar }

export default connect(null, mapDispatchToProps)(SignUp)
