import React, { Component } from 'react'
import ViewCourses from './view'
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';
import PdfViewer from './../../pdfDesc';
import FileViewer from './../../fileReader';


export default class Lesson extends Component {
    state = {
        materials: null,
        scroll: 0
    }

    componentWillMount(){
        header.pageName('Урок')
        webApi
            .getLesson(this.props.match.params.id)
            .then(data => {
                if(data.status === 200){
                    this.setState({materials:data.response})
                }else if(data.status === 605){
                    this.setState({materials:{
                        html_content: "Лекция вам недостпна",
                        additional_materials:[]
                    }})
                }
            })
            .catch(console.error)
    }

    // renderMaterial = (url, src) => {
    //     let type = url.split('&')[url.split('&').length - 1]
    //     if(type === 'pdf'){
    //         // header.closeDialog()
    //         // window.open(src, '_blank')
    //         return header.isMobile() ? <PdfViewer file={src} /> : null
    //     }else{
    //         switch (type) {
    //             case 'image': return <img src={src} alt="img"/>
    //             case 'audio': return <audio src={src} controls></audio>
    //             //case 'pdf': return header.isMobile() ? <PdfViewer file={src} /> : null//return window.open(src, '_blank')
    //             case 'video': return  <video src={src} controls></video>
    //             default:
    //                 break;
    //         }
    //     }
    // }

    renderMaterial = (url, src) => {
        let type = url.split('&')[url.split('&').length - 1]
        switch (type) {
                case 'image': return header.getModalPage({title: '', content: <FileViewer file={<img src={src} alt="img"/>} />})
                case 'audio': return header.getModalPage({title: '', content: <FileViewer file={<audio src={src} controls></audio>} />})
                case 'pdf': return header.getModalPage({title: '', content: <PdfViewer file={src} />})
                case 'video': return header.getModalPage({title: '', content: <FileViewer file={<video src={src} controls></video>} />})
                default:
                    break;
            }
    }

    splitUrl = url =>{
        let newUrl = url.split('#')[1].split('&')[0]
        return newUrl
    }

    readLesson = () => {
        webApi.setReadLecture(this.state.materials.id)
        .then(data => data.status === 200 && header.getSnackbar('Лекция прочитана'))
    }

    componentDidMount(){
        window.onscroll = () => {
            let scrolled = window.pageYOffset || document.documentElement.scrollTop
            this.setState({scroll: parseInt(scrolled)}, () => {
                let scroll = this.state.scroll + window.innerHeight
                let clientHeight = document.body.clientHeight
                if(scroll === clientHeight) this.state.materials && !this.state.materials.read && this.readLesson()
            })
        }
        
        

        setTimeout(()=> {
            if(window.innerHeight === document.body.offsetHeight) this.state.materials && !this.state.materials.read && this.readLesson()
            let hrefElem = document.querySelectorAll('.mention')
            let href = document.querySelectorAll('a')
            let imgView = document.querySelectorAll('.lesson-page-content img')

            if(href){
                href.forEach(e => {
                    if(e.getAttribute("href").indexOf("#") < 0) e.setAttribute("target", "_blank")
                })
            }

            if(imgView){
                imgView.forEach(e => {
                    e.addEventListener('click', (e) => {
                        let src = e.target.getAttribute('src')
                        return header.getModalPage({title: '', content: <FileViewer type="desc" file={<img src={src} alt="_img" className="fix-image-web"/>} />})
                    })
                })
            }
            if(hrefElem){
                hrefElem.forEach(e => {
                    e.setAttribute("href", `${window.location.origin}/lesson/${this.props.match.params.id}#${e.attributes.href.nodeValue.split('#')[1]}`)
                    e.addEventListener('click', () => {
                        //e.preventDefault()
                        window.location.href = e.getAttribute('href')
                        //window.location.replace(`/lesson/${this.props.match.params.id}`);
                    })
                })
            }

            let oldTriggers = document.querySelectorAll('.lesson-page-content a')

            if(oldTriggers){
                oldTriggers.forEach(e => {
                    let lnh = e.getAttribute('href').split("#")
                    if(lnh.length > 1) e.setAttribute("href", `#${lnh[1]}`)
                })
            }

            document.querySelectorAll( 'oembed[url]' ).forEach( element => {
                // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
                // to discover the media.
                const anchor = document.createElement( 'a' );
        
                anchor.setAttribute( 'href', element.getAttribute( 'url' ) );
                anchor.className = 'embedly-card';
        
                element.appendChild( anchor );
            } );
        }, 1000)
    }

    render() {

        // this.props.location.hash && header.getDialog({
        //     title: '',
        //     content: <div className="view-add-material">{this.renderMaterial(this.props.location.hash, this.splitUrl(this.props.location.hash))}</div>
        // })

        this.props.location.hash && this.renderMaterial(this.props.location.hash, this.splitUrl(this.props.location.hash))

        return (
            <ViewCourses blockEducation = { this.state.materials && this.state.materials }/>
        )
    }
}
