import React, { Component } from 'react'

export default class ViewSignIn extends Component {
    render() {
        let { handleFields, sendForm } = this.props
        return (
            <form onSubmit = { sendForm } className="sign-up-form">
                <div className="form-header">Вход в систему</div>
                {/* { validate && <div className="title-success">Авторизация прошла успешно</div> }
                { this.props.title && !error && !validate && <div className="title-phone">На указанный номер отправлен смс с паролем</div> }
                { error && <div className="title-auth-error">{ error }</div> } */}

                <div className="form-input-line">
                    <input onChange = { handleFields } name="login" type="text" placeholder="Введите номер телефона или e-mail"/>
                {/* { (send && !phone) && <div className="warning-alert">Поле не должно быть пустым</div> } */}
                </div>
                <div className="form-input-line">
                    <input onChange = { handleFields } name="password" type="password" placeholder="Введите пароль"/>
                {/* { (send && !password) && <div className="warning-alert">Поле не должно быть пустым</div> } */}
                </div>
            <button onClick = { sendForm }>Войти</button>
            </form>
        )
    }
}
