import React, { Component } from 'react'

export default class MobileHome extends Component {
    render() {
        return (
            <div className="mobile">
                <div className="mobile-start-page">
                    <div className="block-content">
                        <div className="mobile-logo-ziko"></div>
                        <div className="mobile-home-title">
                            Для использования мобильной версии, пожалуйста, скачайте приложение под вашу операционную систему
                        </div>
                        <div className="mobile-home-app-container mobile-coming-soon">
                            <i className="fab fa-app-store"></i> <br/><br/>
                            App Store <br/>( <span className="font-italic">Скоро...</span> )
                        </div>
                        <div className="mobile-home-app-container">
                            <i className="fab fa-google-play"></i> <br/><br/>
                            Google Play
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}
