import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { header } from './../../../utilities/page';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class DialogHandle extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    //let {open, close, content, title} = this.props  
    return (
      <div>
        {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Slide in alert dialog
        </Button> */}
        <Dialog
          open={this.props.state.active}
          TransitionComponent={Transition}
          keepMounted
          className={(this.props.state.body && this.props.state.body.className) ? this.props.state.body.className : '' }
          onClose={header.closeDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.props.state.body && this.props.state.body.title} <i onClick={header.closeDialog} className="fl-right fas fa-times icon-silver"></i>
          </DialogTitle>
          <DialogContent>           
            {this.props.state.body && this.props.state.body.content}
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={header.closeDialog} color="primary">
              Закрыть
            </Button> 
          </DialogActions> */}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        state: state.modals.dialog
    }
}
export default connect(mapStateToProps)(DialogHandle)