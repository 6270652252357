import React, { Component } from 'react'
import history from '../../history';
import { header } from './../../../../../utilities/page';
import getDiskSpace from './../../../../../utilities/disk-space';
import webApi from '../../../../../utilities/web-api';
import Select from 'react-select';

export default class ViewCreateCourse extends Component {
    state = {
        title : '',
        estimation: '',
        description: '',
        after_test_id: []
    }

    handleInput = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    availabilityLesson = val => {
        if(val.length){
            webApi.getTestLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    console.log(res)
                    this.setState({after_test_input: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({after_test_input: []})
                }              
            })
        }
    }

    changeSelectAvailability = e =>{
        this.setState({after_test_id: e}, () => console.log(this.state.after_test_id))
    }

    render() {
        return (
            <div className="user-right-content">

                <div className="block-content">
                <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                    <div className="w-50 fl-left">
                        Наименование программы обучения <br/>
                        <input type="text" className="inputTraining"  value={this.props.programName} disabled/> <br/><br/>
                        Наименование курса <br/>
                        <input type="text" onChange = { this.handleInput } name="title" className="inputTraining" placeholder="Введите наименование курса"/> <br/><br/>
                        Краткое описание курса <br/>
                        <textarea className="inputTrainingHeight" onChange = { this.handleInput } name="description" placeholder="Опишите курс"></textarea> <br/><br/>
                        Рекомендуемое время на прохождение (мин)<br/>
                        <input onChange = { this.handleInput } name="estimation" className="inputTraining" placeholder = "Введите рекомендуемое время на прохождение" /> <br/><br/>
                        Доступность материала<br/>
                        <Select
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['title'])}
                            value = {this.state.after_test_id}
                            options = {this.state.after_test_input}
                            onInputChange={this.availabilityLesson}
                            onChange={this.changeSelectAvailability}
                            placeholder="Выберите тест после которого курс будет доступен"
                        /> <br/>
                    </div>
                    <div className="w-50 fl-left center">
                        Обложка (jpg/png) <br/><br/>
                        {this.state.images ? <img className="no-img-training img_cover_material" src={this.state.images.original} alt="img"/> : <div className="no-img-training">320x240</div>}
                        <input type="file" className="hide" onChange={e => header.uploader(e.target.files, result => this.setState({images: result}, () => {
                            this.state.images && delete this.state.images.status
                            this.props.uploadCover(this.state.images)
                            }))} id="_program-cover"/> <br/>
                        <button className="btnSaveProfile" onClick={() => getDiskSpace(result => this.setState({images: result}),{type:false, fullUrl: true})}>Выбрать файл</button>
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button onClick={() => this.props.setCourse(this.state)} className="btnSaveProfile fl-left">Сохранить</button>
                    {/* <button onClick = {() => history.push('/create-lecture')} className="btnSaveProfile fl-right">Добавить лекцию в данный курс <i className="fas fa-arrow-right btnSaveProfileFix"></i></button> */}
                </div>
            </div>
        )
    }
}
