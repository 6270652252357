import get from 'lodash/get'
import React, { Component } from 'react'
import ViewHeader from './view'
import { connect } from 'react-redux';
import socket from '../chat/chat-connector';
import { header } from './../../../../utilities/page';
import {withRouter} from 'react-router-dom'

class Header extends Component {
  state = {
    connected: false
  }

  onConnect = () => {
    this.setState({ connected: true })
    socket.getUnreadMessagesCount()
  }

  onMessage = ({ userId = NaN }) => {
    if (!this.isChatRoute && userId !== get(this.props, 'state.user_data.id')) {
      header.updateUnreadMessagesCount(this.props.header.unreadMessagesCount + 1)
    }
  }

  onDisconnect = () => this.setState({ connected: false })

  onUnreadMessagesCount = ({ unreadCount } = {}) => header.updateUnreadMessagesCount(unreadCount || 0)

  componentDidMount() {
    socket
      .on('connect', this.onConnect)
      .on('message', this.onMessage)
      .on('disconnect', this.onDisconnect)
      .on('unreadMessagesCount', this.onUnreadMessagesCount)
      .connect(this.props.state.token)
  }

  get isChatRoute() {
    return this.props.location.pathname === '/chat'
  }

  render() {
    const {user_data} = this.props.state
    const {page_name} = this.props.header
    return (
      user_data
        ? <ViewHeader page_name = { page_name } user = { user_data } connected = {this.state.connected}/>
        : <div></div>
    )
  }
}

const RouterHeader = withRouter(props => <Header {...props}/>);

export default connect(
  (state) => {
    return {
      state: state.user,
      header: state.page
    }
  }
)(RouterHeader)
