import React, { Component } from 'react'
import '../style.css'
import { Link } from 'react-router-dom'
import history from '../right-panel/history'
import { connect } from 'react-redux';
import { newBrdc } from '../../../store/actions/breadcrumbs/action'

class ViewLeftPanel extends Component {
    componentDidMount(){
        //this.subscribe
        document.querySelectorAll('.menu-active') && document.querySelectorAll('.menu-active')[0].classList.remove("menu-active")

        !localStorage.getItem('_h') && localStorage.setItem('_h', JSON.stringify({route: '/'}))
        window.location.pathname === '/' && localStorage.setItem('_h', JSON.stringify({route: '/'}))
        let pathname = JSON.parse(localStorage.getItem('_h')).route


        // let pathname = history.location.pathname

        // let splitPath = pathname.split('/')[1]

        // if(
        //     splitPath === 'lecture' ||
        //     splitPath === 'course' ||
        //     splitPath === 'lecture' ||
        //     splitPath === 'create-lecture' ||
        //     splitPath === 'create-course' ||
        //     splitPath === 'create-materials')
        //patchname ===

        let hrefPath = document.querySelector(`.left-content a[data-route="${pathname}"]`)

        if(hrefPath === null) hrefPath = document.querySelector(`.left-content a[data-route="/"]`)

        hrefPath.children[0].classList.add('menu-active')
    }

    handleHistory = (e) =>{
        const objectHistory = {}
        objectHistory.route = e.currentTarget.pathname
        localStorage.setItem('_h', JSON.stringify(objectHistory))
        history.push(objectHistory.route)
        this.props.newBrdc([{
            title: e.currentTarget.dataset.title,
            route: e.currentTarget.dataset.route
        }])
        //parentElement
        document.querySelectorAll('.menu-active')[0].classList.remove("menu-active")
        if(e.target.localName === 'i' || e.target.localName === 'span') {
            e.target.parentElement.classList.add('menu-active')
        }else{
            e.target.classList.add('menu-active')
        }

    }

    render() {
        let {state} = this.props
        return (
            <div className="left-content menu-min flex-left-menu">
                <div className="logo"></div> <br/>
                    <Link onClick = { this.handleHistory } data-route="/" data-title="Профиль" to="/"><div className="menu menu-active"><i className="fal fa-user-alt"></i><span>Профиль</span></div></Link>
                    <Link onClick = { this.handleHistory } data-route="/material" data-title="Материалы" to="/material"><div className="menu"><i className="fal fa-graduation-cap"></i><span>Обучение</span></div></Link>
                    <Link onClick = { this.handleHistory } data-route="/issues" data-title="Обращения" to="/issues"><div className="menu"><i className="fal fa-comments"/><span>Обращения</span></div></Link>
                    {(state === 'admin' || state === 'teacher') && <Link onClick = { this.handleHistory } data-route="/report" data-title="Отчеты" to="/report"><div className="menu"><i className="fas fa-file-alt"></i><span>Отчеты</span></div></Link>}
                    {(state === 'admin' || state === 'teacher') && <Link onClick = { this.handleHistory } data-route="/tasks" data-title="Задачи" to="/tasks"><div className="menu"><i className="fal fa-tasks"></i><span>Задачи</span></div></Link>}
                    {(state === 'admin' || state === 'teacher') && <Link onClick = { this.handleHistory } data-route="/availability" data-title="Задачи" to="/availability"><div className="menu"><i className="fal fa-thumbtack"></i><span>Доступность</span></div></Link>}
                    {(state === 'admin' || state === 'teacher') && <Link onClick = { this.handleHistory } data-route="/materials" data-title="Создание материала" to="/materials"><div className="menu"><i className="fal fa-layer-plus"></i><span>Создание материала</span></div></Link>}
                    {state === 'admin' && <Link onClick = { this.handleHistory } data-route="/settings" data-title="Настройки" to="/settings"><div className="menu"><i className="far fa-cog"></i><span>Настройки</span></div></Link>}
                    {this.props.children}
            </div>
        )
    }
}
const mapDispatchToProps = {newBrdc}
const mapStateToProps = (state) => {
  return {
    state: state.user.user_data.role,
    unreadMessagesCount: state.page.unreadMessagesCount
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewLeftPanel)
