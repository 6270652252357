import React, { Component } from 'react'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class PdfViewer extends Component {
    state = {
        numPages: 1,
        pageNumber: 1,
        width: window.innerWidth - 25,
        scale: 1
    }

    componentWillMount(){
        // document.getElementById('application').style.backgroundColor = "rgb(78, 78, 78)"
        // document.getElementById('application').style.height = "100vh"
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    pdfNext = () => this.setState({pageNumber: this.state.pageNumber + 1})
    pdfPrev = () => this.setState({pageNumber: this.state.pageNumber - 1})

    componentDidMount(){
        window.onresize = () => this.setState({width: window.innerWidth - 25})
    }

    scaleUp = () => {
        if(this.state.scale < 2) this.setState({scale: this.state.scale += 0.25})
    }

    scaleDown = () => {
        if(this.state.scale > 1) this.setState({scale: this.state.scale -= 0.25})
    }
    

    render() {
        let { pageNumber, numPages, width, scale} = this.state
        return (
        <div className="mobile-pdf">
            <div className="mobile-pdf-nav">
                <button disabled={pageNumber === 1} onClick={this.pdfPrev} className="fl-left"><i className="fas fa-chevron-circle-left"></i></button>
                <button disabled={pageNumber === numPages} onClick={this.pdfNext} className="fl-left"><i className="fas fa-chevron-circle-right"></i></button>
                {pageNumber} / {numPages}
                <button disabled={pageNumber === numPages} onClick={this.scaleUp} className="fl-r"><i className="far fa-search-plus"></i></button>
                <button disabled={pageNumber === numPages} onClick={this.scaleDown} className="fl-r"><i className="far fa-search-minus"></i></button>
                
            </div>
            
            {/* <p>{pageNumber} / {numPages}</p> */}
            
            <Document
                // file={"http://test2dls.aisdrive.by/dls_uploads/users/1/sample.pdf"}
                file={this.props.file}
                onLoadSuccess={this.onDocumentLoadSuccess}
                >
                <Page pageNumber={pageNumber} scale={scale} width={width} />
            </Document>
        </div>    
        )
    }
}
