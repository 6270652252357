import React, { Component } from 'react'
import ViewCreateProgram from './view';
import { header } from './../../../../../utilities/page';
import webApi from '../../../../../utilities/web-api';
import { showSnackbar} from './../../../../../store/actions/elements/action';
import { connect } from 'react-redux'
import history from '../../history';
import ViewEditProgram from './edit';


class CreateProgram extends Component {
    state = {
        categoryVisible: false,
        educationPop: false,
        achievementPop: false,
        teacherPop: false,
        category: '',
        teacher: '',
        selectCategory: '',
        selectTeacher: '',
        title: '',
        description: '',
        images: null,
        estimation: '',
        program:null,
        programTeacher: null,
        insert:{}
    }

    handleInput = e => {
        if(e.target.name === 'estimation'){
            this.setState({insert:{
                ...this.state.insert,
                estimation: e.target.value.replace(/\D/, '')
                }
            })
        }else{
            this.setState({insert:{
                ...this.state.insert,
                [e.target.name]: e.target.value
                }
            })
        }
    }

    // handleNum = e => {
    //     e.target.value.replace(/\D/, '')
    // }


    uploadCover = e => {
        this.setState({insert:{
            ...this.state.insert,
            images: e
        }})
    }

    setProgrm = after =>{

        let obj = this.state.insert
        if(after) obj.after_test_id = after.id

        if(obj.estimation) obj.estimation = obj.estimation * 60

        if(obj.estimation > 8100000) return header.getSnackbar('Рекомендуемое время не может приувеличивать 135000 мин. (3 месяца)')


        if(!this.state.insert.title) return this.props.showSnackbar(`Ошибка: "Наименование программы обучения" не заполнено`)
        else if(!this.state.insert.estimation) return this.props.showSnackbar(`Ошибка: "Рекомендуемое время на прохождение" не заполнено`)
        else{
            webApi.setProgram(obj)
            .then(data => {
                if(data.status === 200){
                    this.props.showSnackbar(`Новая программа "${data.title}" создана`)
                    return history.push('/materials')
                }
                if(data.status === 400){
                    this.props.showSnackbar(`Произошла ошибка`)
                }
            })
            .catch(error => console.log(error))
        }
    }

    updateProgram = after =>{
        let obj = this.state.insert
        let teacher
        if(after === null) obj.after_test_id = null
        if(after) obj.after_test_id = after.id
        if(obj.estimation) obj.estimation = obj.estimation * 60

        if(obj.estimation > 8100000) return header.getSnackbar('Рекомендуемое время не может приувеличивать 135000 мин. (3 месяца)')
        if(obj.teacher_id){
            teacher = obj.teacher_id
            delete obj.teacher_id
        }

        webApi.updateProgram(obj)
        .then(data => {
            if(data.status === 200){
                this.props.showSnackbar(`Программа "${data.title}" отредактирована`)
                return history.push('/materials')
            }
            if(data.status === 400){
                this.props.showSnackbar(`Произошла ошибка`)
            }
        })
        .catch(error => console.log(error))
    }

    handleActive = () =>{
        let obj = this.state.insert
        webApi.programActive(obj.id).then(data => {
            if(data.status === 200){
                header.getSnackbar('Программа успешно опубликована')
                return history.push('/materials')
            }
        })
    }

    handleInActive = () =>{
        let obj = this.state.insert
        webApi.programInActive(obj.id).then(data => {
            if(data.status === 200){
                header.getSnackbar('Программа успешно убрана с публикации')
                return history.push('/materials')
            }
        })
    }

    categoryVisible = value => {
        this.setState({categoryVisible: value})
    }

    componentWillMount(){
        if(this.props.match.params.id){
            header.pageName('Редактирование программы')
            webApi.getProgram(this.props.match.params.id)
            .then(async data => {
                if(data.status === 200){
                    let obj = await data.response 
                    await data.response[0].after_test_id && webApi.getTest(data.response[0].after_test_id).then(data => obj[0].after_test_title = data.response.title)
                    await this.setState({program:obj})
                    await webApi.getProgrmTeacher(data.response[0].id).then(data => {
                        if(data.status === 200) this.setState({programTeacher: data.response})
                    }).catch(error => console.log(error))

                }
            })
            .catch(error => console.log(error))
            this.setState({insert:{
                ...this.state.insert,
                id: this.props.match.params.id
            }})
        }else{
            webApi.getCategory()
            .then(async data => {
                if(data.status === 200){
                    await this.setState({program:data.response})
                    await webApi.getTeacher().then(data => {
                        if(data.status === 200) this.setState({programTeacher: data.response})
                    }).catch(error => console.log(error))
                }
            })
            .catch(error => console.log(error))
            header.pageName('Новая программа')
        }
    }

    assigneTeacher = e => {
        webApi.putTeacher(this.state.insert.id, this.state.programTeacher[0].id, e)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar('Преподаватель изменен')
                //return history.push('/materials')
            }else{
                header.getSnackbar('Произошла ошибка')
            }
        })
        // this.setState({
        //     insert:{
        //         ...this.state.insert,
        //         //teacher_id: e
        //     }
        // })
    }

    assigneCat = e => {
        this.setState({
            insert:{
                ...this.state.insert,
                program_category_id: e
            }
        })
    }

    render() {
        let { educationPop, teacherPop, category, images, teacher,programTeacher, selectTeacher, program, selectCategory, insert } = this.state
        return (
            !this.props.match.params.id ? <ViewCreateProgram
            selectTeacher = { selectTeacher }
            selectCategory = { selectCategory }
            teacher = { teacher }
            category = { category }
            assigneTeacher = { this.assigneTeacher }
            assigneCat = {this.assigneCat}
            educationPop = { educationPop }
            teacherPop = { teacherPop }
            uploadCover = { this.uploadCover }
            handleInput = { this.handleInput }
            setProgrm = { this.setProgrm }
            images={images}
            getCategory = { this.getCategory }
            program = { program && program }
            insertImg = {this.state.insert.images}
            programTeacher = { programTeacher }
            insert={insert}
            /> : <ViewEditProgram
            insert={insert}
            handleInActive = {this.handleInActive}
            handleActive = {this.handleActive}
            selectTeacher = { selectTeacher }
            selectCategory = { selectCategory }
            teacher = { teacher }
            uploadCover = {this.uploadCover}
            updateProgram = { this.updateProgram }
            category = { category }
            assigneTeacher = { this.assigneTeacher }
            assigneCat = { this.assigneCat }
            educationPop = { educationPop }
            teacherPop = { teacherPop }
            images={images}
            insertImg = {this.state.insert.images}
            handleEducationPop = { this.handleEducationPop }
            handleTeacherPop = { this.handleTeacherPop }
            handleInput = { this.handleInput }
            setProgrm = { this.setProgrm }
            getCategory = { this.getCategory }
            program = { program && program[0] }
            programTeacher = { programTeacher && programTeacher[0] }
            />
        )
    }
}

const mapDispatchToProps = { showSnackbar }

export default connect(null, mapDispatchToProps)(CreateProgram)
