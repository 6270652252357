import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import webApi from '../../../../utilities/web-api'
import AsyncSelect from 'react-select/async'
import inclusiveSearchConstructor from '../../../elements/search-contructor'

const userSearch = inclusiveSearchConstructor(
  webApi.inclusiveSearchUser,
  'Такой пользователь не найден.',
  ({ id, name, surname }) => ({ value: id, label: surname + ' ' + name })
)

class DialoguesControls extends PureComponent {
  state = {
    value: {},
    input: null
  }

  onChange = selected => {
    this.setState({ value: selected })
    this.props.createChannel({
      userId: selected.value,
      fullName: selected.label
    })
  }

  clearSearch = () => this.setState({ value: {} })

  render() {
    return (
      <div className='chat-dialogues-controls'>
        <div className="relative">
          {Object.keys(this.state.value).length ? <i onClick={this.clearSearch} className="far fa-times clear-search-chat"/> : null}
          <AsyncSelect
            loadOptions={userSearch}
            onChange={this.onChange}
            loadingMessage={() => 'Идёт поиск...'}
            noOptionsMessage={() => '...'}
            value={Object.keys(this.state.value).length ? this.state.value : ''}
            placeholder={<span><i className="far fa-search silver-icon"/> Поиск...</span>}
          />
        </div>
      </div>
    )
  }
}

DialoguesControls.propTypes = {
  createChannel: PropTypes.func.isRequired,
}

export default DialoguesControls
