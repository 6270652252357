import React, { Component } from 'react'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';

export default class CreateCategory extends Component {
    state = {}

    handleCreate = () => {
        let {title, description} = this.state
        if(!title) return header.getSnackbar("Название должности не заполнено")

        let obj = {title, description}

        if(!obj.description) delete obj.description

        webApi.setPosition(obj)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Новая должность успешно создана")
                this.props.handlePage('view')
            }
        })
    }

    handleInput = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    render() {
        return (  
            <div>
                <span className="href" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>     
                <div className="create-form">
                    <span><b>Название должности</b></span> <br/>
                    <input name = "title" onChange={this.handleInput} type="text" placeholder="Введите название должности"/> <br/><br/>
                    <span><b>Описание</b></span> <br/>
                    <input name = "description" onChange={this.handleInput} defaultValue={this.state.description && this.state.description} type="text" placeholder="Введите описание должности"/> <br/><br/>
                    <div className="btnSaveProfile fl-left" onClick={this.handleCreate}>Создать</div>
                </div>
            </div>
        )
    }
}
