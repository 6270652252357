import { ADD_BRDC, NEW_BRDC, DEL_BRDC } from '../../actions/breadcrumbs/key'

const brdc = {
    brdc:[]
}

function getUnique(arr, comp) {

    const unique = arr
        
    .map(e => e[comp])      
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
  
     return unique;
  }

export default (state = brdc, action) => {
    switch (action.type) {
        case ADD_BRDC:

            let brdc = state.brdc.concat(action.payload)
            
            let uniqBrdc = getUnique(brdc, 'title')

             return {...state, brdc:uniqBrdc}
        case NEW_BRDC:
            return  {...state, brdc:action.payload}
        case DEL_BRDC:
                let del
                if(state.brdc.length > 1) del = state.brdc.splice(state.brdc.length -1, 1)
                return {...state,  del}
        default:
            return state
    }
}