import io from 'socket.io-client'
import EventEmitter from 'events'

const subscribedEvents = [
  'connect',
  'customError',
  'message',
  'privateDialog',
  'dialogues',
  'clients',
  'updateReadMessages',
  'unreadMessagesCount',
  'lastMessages',
  'usernameChanged'
]

class ChatConnector extends EventEmitter {
  static connection
  static url
  static path

  constructor(url = process.env.REACT_APP_WEB_SOCKET_URL, path = process.env.REACT_APP_WEB_SOCKET_PATH) {
    super()
    this.url = url
    this.path = path
  }

  get connected() {
    return Boolean(this.connection)
  }

  _connect = token => {
    this.connection = io(this.url, {
      query: { token },
      path: `/${this.path}`,
      transports: ['websocket']
    })
  }

  _subscribeEvents = () => subscribedEvents
    .forEach(event => {
      this.connection.on(event, payload => {
        if (payload) this.emit(event, JSON.parse(payload))
        else this.emit(event)
      })
    })

  _send = (action, payload) => payload
    ? this.connection.emit(action, JSON.stringify(payload))
    : this.connection.emit(action)

  connect = jwtToken => {
    if (this.connection) {
      this.connection.disconnect()
      this.connection = null
    }
    this._connect(jwtToken)
    this._subscribeEvents()
  }

  sendMessage = (message, dialogId) => this._send('newMessage',{
    dialogId,
    id: Date.now().toString(),
    type: 'message',
    data: {
      message
    }
  })

  sendFile = ({ url, fileName, type, dialogId, preview }) => this._send('newMessage',{
    dialogId,
    id: Date.now().toString(),
    type: 'file',
    data: { url, type, fileName, preview }
  })

  getLastMessages = dialogId => this._send('getLastMessages', {dialogId})

  createPrivateDialog = payload => this._send('createPrivateDialog', payload)

  getDialogues = () => this._send('getDialogues')

  setReadMessages = dialogId => this._send('setReadMessages', {dialogId})

  getUnreadMessagesCount = () => this._send('getUnreadMessagesCount')
}

export default new ChatConnector()
