import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from './image/image';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Mention from './mention/mention';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import InsertImage from './pluguns';
import ImageToolbar from './image/imagetoolbar';
import ImageCaption from './image/imagecaption';
import ImageStyle from './image/imagestyle';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import List from './list/list';
//import Emoji from '@wwalc/ckeditor5-emoji/src/emoji';
import LineHeight from './lineHeight/lineheight';
import SimpleBox from './boxTemplate';
import SimpleBox2 from './boxTemplate_2';
import SimpleBox4 from './boxTemplate_4';



const editorConfiguration = {
  plugins: [
    //MediaEmbed,
    LineHeight,
    PasteFromOffice,
    Table,
    SimpleBox4,
    SimpleBox2,
    SimpleBox,
    Image,
    //Emoji,
    ImageToolbar,
    ImageStyle,
    InsertImage,
    ImageCaption,
    BlockQuote,
    Strikethrough,
    Subscript,
    Superscript,
    Underline,
    Code,
    Link,
    List,
    TableToolbar,
    Mention,
    Font,
    Heading,
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Alignment
  ],
  toolbar: [
    'heading',
    '|',
    'blockquote',
    'link',
    'insertImage',
    // 'mediaEmbed',
    'insertTable',
    //'emoji',
    '|',
    'numberedList',
    'bulletedList',
    'fontSize',
    'fontFamily',
    'fontColor',
    'fontBackgroundColor',
    'lineHeight',
    'bold',
    'italic',
    'alignment',
    'underline',
    'strikethrough',
    'code',
    'subscript',
    'superscript',
    '|',
    'simpleBox_2',
    'simpleBox',
    'simpleBox_4',
    '|',
    'undo',
    'redo'
  ],
  // mediaEmbed: {
  //   removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'spotify', 'youtube', 'vimeo' ]
  // },
  image: {
    toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ],
    styles: ['full','alignLeft','alignRight']
  },
  table: {
    contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
  },
  emoji: [
    { name: 'smile', text: '😀' },
    { name: 'wink', text: '😉' },
    { name: 'cool', text: '😎' },
    { name: 'surprise', text: '😮' },
    { name: 'confusion', text: '😕' },
    { name: 'crying', text: '😢' },
    { name: 'heart', text: '😍' },
    { name: 'angel', text: '😇' },
    { name: 'pained', text: '😧' },
    { name: 'ok', text: '👌' },
    { name: 'goodbye', text: '👋' },
    { name: 'middle', text: '👆' },
    { name: 'pointing', text: '👉' },
    { name: 'like', text: '👍' },
    { name: 'writing', text: '✍️' },
    { name: 'brain', text: '🧠' },
    { name: 'girl', text: '👧' },
    { name: 'gender', text: '🧑' },
    { name: 'student', text: '👨‍🎓' },
    { name: 'teacher', text: '👨‍🏫' },
    { name: 'fire', text: '🔥' },
    { name: 'ring', text: '💍' },
    { name: 'case', text: '💼' },
    { name: 'college', text: '🎓' },
    { name: 'tie', text: '👔' }
  ],
    fontSize: {
      options: [8,9,10,11,12,13,14,17,18,19,21,24,30,36,42]
  },
    fontFamily: {
      options: [
          'default',
          'sans-serif',
          'Arial',
          'Courier New, Courier, monospace',
          'monospace',
          'Georgia, serif',
          'Economica',
          'Geneva',
          'Tahoma',
          'Times New Roman, Times, serif',
          'Trebuchet MS, Helvetica, sans-serif',
          'Lucida Sans Unicode, Lucida Grande, sans-serif',
          'Ubuntu, Arial, sans-serif',
          'Ubuntu Mono, Courier New, Courier, monospace'
      ]
  },
  mention: {
    feeds: [
        {
            marker: '#',
            feed: getFeedItems,
            minimumCharacters: 0,
            //itemRenderer: customItemRenderer
        }
    ]
  }
}

let items = [];

function getFeedItems( queryText ) {
  // As an example of an asynchronous action, return a promise
  // that resolves after a 100ms timeout.
  // This can be a server request or any sort of delayed action.
  return new Promise( resolve => {
      setTimeout( () => {
          const itemsToDisplay = items
              // Filter out the full list of all items to only those matching the query text.
              .filter( isItemMatching )
              // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
              //.slice( 0, 10 );

          resolve( itemsToDisplay );
      }, 100 );
  } );

  // Filtering function - it uses the `name` and `username` properties of an item to find a match.
  function isItemMatching( item ) {
      // Make the search case-insensitive.
      const searchString = queryText.toLowerCase();

      // Include an item in the search results if the name or username includes the current user input.
      return (
          item.name.toLowerCase().includes( searchString ) ||
          item.id.toLowerCase().includes( searchString )
      );
  }
}


// function customItemRenderer( item ) {
//   const itemElement = document.createElement( 'a' );
//   itemElement.src = item.link
//   itemElement.classList.add( 'custom-item' );
//   itemElement.id = `mention-list-item-id-${ item.name }`;
//   itemElement.textContent = `${ item.name } `;

//   const usernameElement = document.createElement( 'a' );

//   usernameElement.classList.add( 'custom-item-username' );
//   usernameElement.textContent = item.id;
//   usernameElement.src = item.link

//   itemElement.appendChild( usernameElement );

//   return itemElement;
// }

class App extends Component {

  render() {
    let { html, trigger, handler } = this.props
    //let { feed } = editorConfiguration.mention.feeds[0]
    //editorConfiguration.mention.feeds[0].feed = []
    // editorConfiguration.mention = {
    //   feeds: [
    //       {
    //           marker: '#',
    //           feed: trigger,
    //           minimumCharacters: 0,
    //       },
    //     ]
    //   }
    // if(trigger.length){
    //   editorConfiguration.mention.feeds[0].feed.push({id: "#lol", link:"#sdf"})
    //   console.log(editorConfiguration.mention.feeds[0].feed)
    // }
    //trigger[0] && console.log(trigger)//feed.filter(el => el.id === trigger[0].id).map(e => console.log(e, trigger))
    items = trigger

    return (
        <CKEditor
          editor={ ClassicEditor }
          config={ editorConfiguration }
          data={ html ? html : ""}

          onChange={ ( event, editor ) => {
            const data = editor.getData();
            handler && handler(data) //{ event, editor, data }
          } }
        />
    );
  }
}

export default App;
