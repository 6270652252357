import React, { Component } from 'react'
import ViewDepartamentTab from './view'
import Create from './create'
import webApi from '../../../../../../utilities/web-api';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import EditDepartment from './edit'

class DepartamentTab extends Component {
    state = {
        page:'view',
        company: null,
        filial: [],
        id_dep: null
    }

    handleDep = id =>{
        this.setState({
            id_dep: id,
            page: 'edit'
        })
    }

    handlePage = page => {
        this.setState({page})
    }

    componentWillMount(){
        webApi.getDepartment()
        .then(data => {
            if(data.status === 200) {


                data.response.forEach((el) => {
                    let filial_new = []
                    webApi.getCompanyId(el.company_id).then(data => {
                      //let company
                      el.company = data.response[0].title
                      el.active ? el.active = "Активный":  el.active = "Отключен"
                      el.created_at = <Moment format="DD.MM.YYYY/hh:mm">{el.created_at}</Moment>
                      filial_new.push(el)
                      this.setState({filial: filial_new})

                    })

                    //filial_new.push(el)
                    //el.company = company

                })

                //this.setState({filial: filial_new})

                //this.setState({filial: filial_new})
                // data.response[0].company_id ? webApi.getCompanyId(data.response[0].company_id).then(data => data.re)
            }
        })
        .catch(console.error)

        this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => console.log(this.state.company)))
    }

    render() {
        return (
            <div>
                {this.state.page === 'view' && <ViewDepartamentTab handleDep={this.handleDep} filial={this.state.filial} handlePage = { this.handlePage } />}
                {this.state.page === 'create' && <Create company_id={this.props.company} company={this.state.company} handlePage = { this.handlePage } />}
                {this.state.page === 'edit' && <EditDepartment id_object={this.state.id_dep}  handlePage={this.handlePage}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        company: state.user.user_data.company_id
    }
}

export default connect(mapStateToProps)(DepartamentTab)
