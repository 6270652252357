import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import webApi from '../../../../utilities/web-api';
import { header } from './../../../../utilities/page';
import { connect } from 'react-redux';
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru'
import { Input } from '@material-ui/core';
import moment from 'moment';

registerLocale('ru', ru)

//registerLocale('ru', ru)

class Profile extends Component {
    state = {
      user: null,
      name: null,
      surname: null,
      birth_date: null,
      el: null
    }

    handleInput = e => this.setState({[e.target.name]:e.target.value})

    handleDate = date => this.setState({birth_date: date});

    savePersonal = e =>{
        e.preventDefault()

        let {name , surname, birth_date} = this.state

        let obj = {name , surname, birth_date}

        let sendData = {}

       for(let data in obj) sendData = {...sendData, [data]:obj[data]}

       sendData.birth_date && (sendData.birth_date = moment(this.state.birth_date).add(1, 'd').format('YYYY-MM-DD'))

       if(birth_date){
        let from = moment().add(-100, 'year').format('YYYY')
        let due = moment().add(-16, 'year').format('YYYY')
        let from_msg = moment().add(-100, 'year').format('DD.MM.YYYY')
        let due_msg = moment().add(-16, 'year').format('DD.MM.YYYY')

        if(parseInt(birth_date.split('-')[0]) < parseInt(from) || parseInt(birth_date.split('-')[0]) > parseInt(due)) return header.getSnackbar(`Дата рождения должна быть в диапазоне ${from_msg} - ${due_msg} гг`)
        }

       if(!name && !surname){
        header.getSnackbar('Имя и фамилия не заполнены')
        }
       else{
            webApi.updateProfile(sendData)
            .then(data => {
                if(data.status === 200){
                    header.getSnackbar('Данные успешно обновлены')
                    let updateData = this.props.user
                    for(let obj in data) updateData = {...updateData, [obj]:data[obj]}
                    header.closeDialog()
                    return header.updateUser(updateData)
                    }
                }
            )
            .catch(error => console.log(error))
       }
    }

    componentWillMount(){
        webApi.getProfile()
        .then(data => this.setState({user: data, birth_date: data.birth_date}))
        .catch(error => console.log(error))
    }

    get isEmptyUserData() {
      return isEmpty(this.state.name) || isEmpty(this.state.surname) || isEmpty(this.state.birth_date)
    }

    one = (data) =>{

        return data && (
            <form onSubmit={this.savePersonal}>
                <div className="menu">
                    Имя <br/>
                    <input type="text" onChange={this.handleInput} name="name" defaultValue={data.name || ''} placeholder={`Имя`}/>
                </div> <br/>

                <div className="menu">
                    Фамилия <br/>
                    <input type="text" onChange={this.handleInput} name="surname" defaultValue={data.surname || ''} placeholder={`Фамилия`}/>
                </div> <br/>

                <div className="menu ">
                    День рождения <br/>
                    <Input
                    className={'datapicker-ui'}
                    disableUnderline={true}
                    onChange={ this.handleInput }
                    id="date"
                    name="birth_date"
                    onClick={()=> document.getElementById('date').setAttribute('onkeydown', 'return false')}
                    inputProps={ {min: moment().add(-100, 'year').format('YYYY-MM-DD'), max: moment().add(-16, 'year').format('YYYY-MM-DD')} }
                    type="date"
                    defaultValue={moment(this.state.birth_date).add(-1, 'd').format('YYYY-MM-DD')}
                    />
                </div> <br/>

                <div className="menu-unhover center">
                    <button onClick={this.savePersonal}
                            disabled={this.isEmptyUserData}
                            className="btnSaveProfile"
                    >Сохранить</button>
                </div>
            </form>
        )
    }

    render() {
        return (
                <div className="editProfile center">
                    <div className="ava-chat">{/*avatar*/}</div>
                    <div className="clear"></div>
                    {this.one(this.state.user)}
                </div>
            )
        }
}

export default connect(
    (state) => {
      return {
        user: state.user.user_data,
      }
    }
)(Profile)
