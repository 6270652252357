import React, { Component } from 'react'

export default class ViewSettingsAdmin extends Component {
    render() {
        let { handleInput, setCategory } = this.props
        return (
        <div className="user-right-content">
            <div className="block-content">
                <a href="/#"><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</a><br/><br/>
                    <div className="w-50 fl-left">
                        Название категории <br/>
                        <input type="text" onChange = { handleInput } className="inputTraining"  placeholder="Введите название категории"/> <br/><br/>
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button onClick = { setCategory } className="btnSaveProfile fl-left">Создать</button>
                </div>
        </div>
        )
    }
}
