import React from 'react'
import AsyncSelect from 'react-select/async'
import {AsyncPaginate} from "react-select-async-paginate";

export default function({ submit, name, findCallback, placeholder, ...props }) {
  const onChange = selected => {
    if (!Array.isArray(selected)) {
      submit(name, selected)
      return
    }

    if (selected || selected !== null) {
      submit(
        name,
        selected.map(el => el.value)
      )
    }
  }

  return (
      <AsyncPaginate
        className="select-report"
        loadOptions={findCallback}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => "..."}
        loadingMessage={() => "Поиск..."}
        isMulti
        {...props}
      />)
}
