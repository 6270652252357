import React, { Component } from 'react'
import history from '../history';
import { header } from './../../../../utilities/page';
import Step1 from './components/TestOptions'
import Step2 from './components/TestAssigne'
import webApi from '../../../../utilities/web-api';
import Tabs from '../../../elements/tabs/view'


export default class CreateTest extends Component {
    state = {
      test_id: null,
      test: null,
    }

    handleTestId = id => this.setState({test_id:id})

    refreshTest = () => {
      webApi.getTest(this.props.match.params.test_id)
      .then(data => data.status === 200 && this.setState({test: data.response}))
    }

    componentWillMount(){
      this.props.match.params.test_id !== "new" && webApi.getTest(this.props.match.params.test_id)
      .then(data => data.status === 200 && this.setState({test: data.response}))

        webApi.getProgram(this.props.match.params.material_id)
        .then(data => {
          if(!data.response.test){
            header.newBreadcrumps([{title:'Создание тестового задания', route:'/create-test'}])
          }else{
            header.newBreadcrumps([{title:data.response.test.title, route:'/create-test'}])
          }
        })
        .catch(err => console.log(err))
        //history.push('/create-test')
        header.pageName('Тестовые задания')
    }

    getSteps = () => {
        return ['Условия', 'Вопросы'];
      }

    minScore = score => {
      this.setState({test:score})
    }

    getStepContent = step => {
        switch (step) {
          case 0:
            return <Step1 options={this.props.match.params} handleTestId = {this.handleTestId}/>;
          case 1:
            return <Step2 options={this.props.match.params} testId = {this.state.test_id}/>;
          default:
            return 'Unknown step';
        }
      }

    handleBack = () =>{
      let material_type = this.props.match.params.material_type
      let material_id = this.props.match.params.material_id

      //console.log(material_type, material_id)

      switch (material_type) {
        case "program": return history.push('/materials')
        case "course": return history.push(`/course/${material_id}`)
        case "lecture": return history.push(`/lecture/${material_id}`)

        default: history.push('/materials')
      }
    }

    renderTestOption = e =>{
      this.setState({test: e})
    }

    render() {
      let tabs = [
        {
            title: 'Условия',
            component: <Step1 test={this.state.test} refreshTest={this.refreshTest} renderTestOption={this.renderTestOption} minScore={this.minScore} options={this.props.match.params} handleTestId = {this.handleTestId}/>
        },
        {
            title: 'Вопросы',
            component: <Step2 test={this.state.test} options={this.props.match.params} testId = {this.state.test_id}/>
        }
    ]
    //console.log(this.props.match.params.material_id)
        return (
        // <ViewCreateTest FinishStep = { <FinishStep /> } getSteps = { this.getSteps } getStepContent = { this.getStepContent } />
        <div className="user-right-content">
          <div className="block-content">
          <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
            <Tabs tabs={tabs}/>
          </div>
        </div>
        )
    }
}
