import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@material-ui/core'
import moment from 'moment'
import xlsx from "json-as-xlsx";

const styles = () => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  notFound: {
    width: '100%',
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 600
  }
})


function FilterTable({classes, data, notFound, statusValues}) {
  if (notFound) {
    return <h3 className={classes.notFound}>Не найдены записи удовлетворяющие фильтру.</h3>
  }

  const findLabelForStatus = label => statusValues.find(el => el.value === label).label

  const findStatusValuesLabel = (label, status) => {
    label = findLabelForStatus(label)
    if (!status) return label
    return `${status}, ${label}`
  }

  const getStatus = ({done, failed, due_done, expired, in_progress}) => {
    let status = ''
    if (done) status = findStatusValuesLabel('done', status)
    if (due_done) status = findStatusValuesLabel('due_done', status)
    if (failed) status = findStatusValuesLabel('failed', status)
    if (in_progress) status = findStatusValuesLabel('in_progress', status)
    if (expired) status = findStatusValuesLabel('expired', status)
    return status || '-'
  }

  const getFullName = ({users}) => users ? `${users.name} ${users.surname}` : '-'

  let tResult = []

  Boolean(data.length) && data.map(row => {
    return tResult = [...tResult, {
      user: getFullName(row),
      departments: get(row, 'users.company_department.title', '-'),
      filial: get(row, 'users.filial.title', '-'),
      company: get(row, 'users.company.title', '-'),
      programs: get(row, 'programs.title', '-'),
      courses: get(row, 'courses.title', '-'),
      date: moment(get(row, 'created_at', '-')).format('DD-MM-YYYY'),
      availability:
        row.done && 'Выполнена в срок' ||
        row.due_done && 'Выполнена не в срок' ||
        row.failed && 'Завершена неуспешно' ||
        row.expired && 'Просрочена' ||
        row.in_progress && 'В процессе' ||
        '-',
      id: get(row, 'id')
    }]
  })

  function convertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ','
        line += array[i][index];
      }
      str += line + '\r\n';
    }

    return str;
  }

  function download() {
    let headers = {
      id: "ID",
      user: 'Пользователь'.replace(/,/g, ''),
      departments: "Отдел",
      filial: "Объект",
      company: "Компания",
      programs: "Программа",
      courses: "Курс",
      date: "Дата",
      availability: "Статус",
    };

    let itemsNotFormatted = tResult

    let itemsFormatted = [];

    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        user: item.user.replace(/,/g, ''),
        departments: item.departments,
        filial: item.filial,
        company: item.company,
        programs: item.programs,
        courses: item.courses,
        availability: item.availability,
        date: new Date(moment(item.date, 'DD-MM-yyyy').format('YYYY.MM.DD')),
        id: item.id
      });
    });

    let fileTitle = `task_${Date.now()}`;

    // Convert and prepare data for XLSX library function
    function convertedForXlsx(headers, title) {
      let res = {
        sheet: title,
        columns: [],
        content: []
      }
      for (let key in headers) {
        if(headers[key] === 'Дата') {
          res.columns.push({label: headers[key], value: key, format: 'dd.mm.yyyy'})
        }
        res.columns.push({label: headers[key], value: key})
      }
      for (let key in itemsFormatted) {
        res.content.push(itemsFormatted[key])
      }
      return [res]
    }

    // library's function for download prepared data into xlsx
    xlsx(convertedForXlsx(headers, fileTitle), {
      fileName: fileTitle,
      extraLength: 3,
      writeMode: "writeFile",
    })

  }

  return Boolean(data.length) && (
    <Paper className={classes.root + " table-report"}>
      <br/>
      <span onClick={() => download()} className="href pd-15 mg-tb-5">
        <i className="fal fa-file-export"/> Экспортировать в *xlsx
      </span>
      <br/><br/>
      <Table className={classes.table}>
        <TableHead className="table-report-head">
          <TableRow>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">ФИО</TableCell>
            <TableCell align="right">Отдел</TableCell>
            <TableCell align="right">Объект</TableCell>
            <TableCell align="right">Компания</TableCell>
            <TableCell align="right">Программа</TableCell>
            <TableCell align="right">Курс</TableCell>
            <TableCell align="right">Дата</TableCell>
            <TableCell align="right">Статус</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{getFullName(row)}</TableCell>
              <TableCell align="right">{get(row, 'users.company_department.title', '-')}</TableCell>
              <TableCell align="right">{get(row, 'users.filial.title', '-')}</TableCell>
              <TableCell align="right">{get(row, 'users.company.title', '-')}</TableCell>
              <TableCell align="right">{get(row, 'programs.title', '-')}</TableCell>
              <TableCell align="right">{get(row, 'courses.title', '-')}</TableCell>
              <TableCell align="right">{moment(get(row, 'created_at', '-')).format('DD.MM.YYYY')}</TableCell>
              <TableCell align="right">{getStatus(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

FilterTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FilterTable)
