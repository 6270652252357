import React, { Component } from 'react'
import Tabs from '../../../elements/tabs/view'
import webApi from '../../../../utilities/web-api';
import { header } from './../../../../utilities/page';
import { connect } from 'react-redux';
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru'
import { Input } from '@material-ui/core';
import moment from 'moment';
import getDiskSpace from './../../../../utilities/disk-space';

registerLocale('ru', ru)

class Profile extends Component {
    state = {
        user: null,
        birth_date: null,
        el: null,
    }

    handleEdit = async (e) =>{
        this.setState({el: e.target.dataset.el}, () => {
            this.state.el === "email" && this.updateEmailNew()
            this.state.el === "phone" && this.updatePhone()
            this.state.el === "password" && this.tabPsw()
        })
        //let btn = await document.getElementsByClassName('table-menu')[2]
        // e.target.dataset.el === "email" && this.updateEmail()
        // e.target.dataset.el === "phone" && this.updatePhone()
        //await e.target.dataset.el === 'email' && this.updateEmail
        //await btn.click()
    }

    handleInput = e => this.setState({[e.target.name]:e.target.value})

    handleDate = date => this.setState({birth_date: date});

    savePersonal = (e) =>{
        e.preventDefault()
        let {name , surname, birth_date, images} = this.state
        let obj = {name , surname, birth_date, images}

        images && delete images.status

        let sendData = {}

       for(let data in obj) sendData = {...sendData, [data]:obj[data]}

       sendData.birth_date && (sendData.birth_date = moment(this.state.birth_date).add(1, 'd').format('YYYY-MM-DD'))

        if(birth_date){
            let from = moment().add(-100, 'year').format('YYYY')
            let due = moment().add(-16, 'year').format('YYYY')
            let from_msg = moment().add(-100, 'year').format('DD.MM.YYYY')
            let due_msg = moment().add(-16, 'year').format('DD.MM.YYYY')
            if(parseInt(birth_date.split('-')[0]) < parseInt(from) || parseInt(birth_date.split('-')[0]) > parseInt(due)) return header.getSnackbar(`Дата рождения должна быть в диапазоне ${from_msg} - ${due_msg} гг`)
        }
        webApi.updateProfile(sendData)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar('Данные успешно обновлены')
                let updateData = this.props.user
                for(let obj in data) updateData = {...updateData, [obj]:data[obj]}
                return header.updateUser(updateData)
                }else if(data.status === 400 && !birth_date){
                    header.getSnackbar('Вы не заполнили дату рождения')
                }else{
                    header.getSnackbar('Данные введены некорректно')
                }
            }
        )
        .catch(console.error)
    }

    updateEmailNew = () =>{
        let {email} = this.state

        return webApi.emailUpdate({email})
         .then(async data => {
            if(data.status === 200){
                 await header.getSnackbar('На ваш e-mail отправлен код с подтверждением')
                 let btn = await document.getElementsByClassName('table-menu')[2]
                 await btn.click()
                 // let updateData = this.props.user
                 // for(let obj in data) updateData = {...updateData, [obj]:data[obj]}
                 // return header.updateUser(updateData)
                 }
            else if (data.status === 429) header.getSnackbar('За короткое время вы слишком часто меняли e-mail. Попробуйте позже')
            else header.getSnackbar('E-mail невалидный')
            }
         )
         .catch(console.error)
    }

    updatePhone = () =>{
        let {phone} = this.state

        return webApi.phoneUpdate({phone})
        .then(async data => {
            if(data.status === 200){
                header.getSnackbar('На ваш телефон отправлен код с подтверждением')
                let btn = await document.getElementsByClassName('table-menu')[2]
                await btn.click()
            }
            else if (data.status === 429) header.getSnackbar('За короткое время вы слишком часто меняли номер телефона. Попробуйте позже')
            else header.getSnackbar('Номер телефона невалидный')
            }
        )
        .catch(console.error)
    }

    updatePassword = (e) => {
        e.preventDefault()
        let {old_password, new_password, repassword} = this.state

        if(repassword !== new_password) return header.getSnackbar('Пароли не совпадают, пожалуйста повторите пароль корректно')
        else{
            webApi.updatePassword({old_password, new_password})
            .then(async data => {
                if(data.status === 200){
                    await header.getSnackbar('Ваш пароль удачно изменен')
                    let btn = await document.getElementsByClassName('table-menu')[0]
                    await btn.click()
                }
                else if(data.status === 403){
                    header.getSnackbar('Ваш пароль неверный')
                }
            })
        }

    }

    tabPsw = async() =>{
        let btn = await document.getElementsByClassName('table-menu')[2]
        await btn.click()
    }

    emailConfirm = e =>{
        e.preventDefault()
        let {code} = this.state

        webApi.emailConfirm({code})
        .then(async data => {
            if(data.status === 200){
                await header.getSnackbar('Ваш e-mail удачно изменен')
                let btn = await document.getElementsByClassName('table-menu')[0]
                await btn.click()

                let updateData = await this.props.user
                updateData = {...updateData, email:this.state.email}
                return header.updateUser(updateData)
            }
            if(data.status === 510){
                await header.getSnackbar('Такой e-mail уже зарегистрирован')
                let btn = await document.getElementsByClassName('table-menu')[0]
                await btn.click()
            }
        })
    }

    phoneConfirm = e =>{
        e.preventDefault()
        let {code} = this.state

        webApi.phoneConfirm({code})
        .then(async data => {
            if(data.status === 200){
                await header.getSnackbar('Ваш телефон удачно изменен')
                let btn = await document.getElementsByClassName('table-menu')[0]
                await btn.click()

                let updateData = await this.props.user
                updateData = {...updateData, phone:this.state.phone}
                return header.updateUser(updateData)

            }
            else if(data.status === 510){
                header.getSnackbar('Данный номер телефона уже зарегистрирован')
                let btn = await document.getElementsByClassName('table-menu')[0]
                await btn.click()
            }
        })
    }

    componentWillMount(){
        webApi.getProfile()
        .then(data => this.setState({user: data, birth_date: data.birth_date}))
        .catch(console.error)
    }

    two = (data) =>{
        return data && (
            <div>
                <div className="menu">
                    <input type="text" onChange={this.handleInput} name="phone" className="w-100" defaultValue={data.phone || ''} placeholder={'Номер телефона'} />
                    <span data-el="phone" onClick={this.handleEdit} className="href cur-p fl-left">изменить номер телефона</span>
                </div>

                <br/><br/>

                <div className="menu">
                    <input type="text" onChange={this.handleInput} name="email" className="w-100" defaultValue={data.email || ''} placeholder={'Email'} />
                    <span data-el="email" onClick={this.handleEdit} className="href cur-p fl-left">изменить e-mail</span>
                </div>

                <br/><br/>

                <div className="menu">
                    <input type="text" className="w-100" placeholder='*******' disabled/>
                    <span data-el="password" onClick={this.handleEdit} className="href fl-left">изменить пароль</span>
                </div><br/>
            </div>
        )
    }

    one = (data) =>{

        return data && (
            <form onSubmit={this.savePersonal}>

                <div className="menu">
                    Имя <br/>
                    <input type="text" onChange={this.handleInput} name="name" defaultValue={data.name || ''} placeholder={`Имя`}/>
                </div> <br/>

                <div className="menu">
                    Фамилия <br/>
                    <input type="text" onChange={this.handleInput} name="surname" defaultValue={data.surname || ''} placeholder={`Фамилия`}/>
                </div> <br/>

                <div className="menu ">
                    День рождения <br/>
                    <Input
                    className={'datapicker-ui'}
                    //classes={{underline:{'&:before': {
                    //    color:"red"
                    //}}}}
                    disableUnderline={true}
                    onChange={ this.handleInput }
                    onClick={()=> document.getElementById('date').setAttribute('onkeydown', 'return false')}
                    id="date"
                    name="birth_date"
                    type="date"
                    inputProps={ {min: moment().add(-100, 'year').format('YYYY-MM-DD'), max: moment().add(-16, 'year').format('YYYY-MM-DD')} }
                    defaultValue={moment(this.state.birth_date).add(-1,'d').format('YYYY-MM-DD')}
                    />
                </div> <br/>

                <div className="menu-unhover center">
                    <button onClick={this.savePersonal} className="btnSaveProfile">Сохранить</button>
                </div>
            </form>
        )
    }

    three = () => {
        return (
            <div>
                {this.state.el === 'password' &&
                (<form onSubmit={this.updatePassword}><div className="menu">
                        <input type="password" onChange={this.handleInput} name="old_password" className="w-100" placeholder='Введите старый пароль'/>
                    </div> <br/>

                    <div className="menu">
                        <input type="password" onChange={this.handleInput} name="new_password"  className="w-100" placeholder='Введите новый пароль'/>
                    </div> <br/>

                    <div className="menu">
                        <input type="password" onChange={this.handleInput} name="repassword" className="w-100" placeholder='Подтвердите новый пароль'/>
                    </div> <br/>

                    <div className="menu-unhover center">
                        <button className="btnSaveProfile" onClick={this.updatePassword}>Готово</button>
                    </div>
                </form>
                )}

                {this.state.el === 'email' &&
                (<form onSubmit={this.emailConfirm}><h3>На вашу электронную почту отправлен код с подтверждением</h3> <div className="hr"></div>
                    <input type="text" onChange={this.handleInput} name="code" placeholder={'Введите код'}/> <br/><br/>
                    <div className="menu-unhover center">
                        <button className="btnSaveProfile" onClick={this.emailConfirm}>Готово</button>
                    </div>
                </form>)}

                {this.state.el === 'phone' &&
                (<form onSubmit={this.phoneConfirm}><h3>На ваш телефон отправлен код с подтверждением</h3> <div className="hr"></div>
                    <input type="text" onChange={this.handleInput} onClick={this.phoneConfirm} name="code" placeholder={'Введите смс-код'}/> <br/><br/>
                    <div className="menu-unhover center">
                        <button className="btnSaveProfile" onClick={this.phoneConfirm}>Готово</button>
                    </div>
                </form>)}
            </div>
        )
    }

    handleTestUpload = e => {
        this.setState({ava:e.target.files}, () => {
            header.uploader(this.state.ava, images => this.setState({images}))
        })
    }

    render() {
        let tabs = [
            {
                title: 'Личная информация',
                component: this.one(this.state.user)
            },
            {
                title: 'Контактная информация',
                component: this.two(this.state.user)
            },
            {
                title: 'Изменение данных',
                component: this.three(this.state.user)
            }
        ]
            return (
                <div className="editProfile center">
                    <div onClick={()=> getDiskSpace(res => this.setState({images: res}), {type: false, fullUrl: true})}>
                        {/* { this.props.insertImg && <img className="no-img-training img_cover_material" src={this.props.insertImg.normal}/> }
                        { (program.images && !this.props.insertImg) && <img className="no-img-training img_cover_material" src={program.images.original}/> }
                        { (!program.images && !this.props.insertImg) && <div className="ava-chat ava-chat-fix"></div> } */}

                        {( this.state.user && this.state.user.images && !this.state.images) &&
                            <img src={ this.state.user.images.original } className="ava-profile ava-chat-fix" width="40px" height="40px" alt=""/>}
                        {( this.state.images ) &&
                            <img src={ this.state.images.original } className="ava-profile ava-chat-fix" width="40px" height="40px" alt=""/>}
                        {( this.state.user && !this.state.user.images && !this.state.images) &&
                            <div className="ava-chat ava-chat-fix"></div>}
                    </div>
                    <input type="file" className="hide" id="upload-ava" onChange={this.handleTestUpload}/>
                    {/* <button onClick={this.fileUpload}>upload</button> */}
                    <div className="clear"></div>
                    <Tabs tabs={tabs} />
                </div>
            )
        }
}

export default connect(
    (state) => {
      return {
        user: state.user.user_data,
      }
    }
)(Profile)
