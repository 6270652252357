import React, { Component } from 'react'
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';
import Select from 'react-select';


export default class CreateSelection extends Component {
    state = {
        text: null,
        require: false,
        question:false,
        question_id: null,
        correct: false,
        body: null,
        helper: null,
        type: "selection",
        answer: false,
        answers: [],
        isInput: false,
        colAnswers: 0,
        points: null,
        resultMaterial: [],
        selectInputMaterial:[]
    }

    componentWillMount(){
    }

    handleInput = e => this.setState({[e.target.name]: e.target.value})

    handleSaveQuestion = () => {
        let { helper, require, text, type, points } = this.state

        let obj = {
            helper,
            required: require,
            text,
            type,
            points,
            test_id: this.props.options.test_id,
            lecture_id: this.state.selectInputMaterial.id
        }

        if(points === null) obj.points = 1 

        !obj.lecture_id || !obj.helper && delete obj.lecture_id
        !obj.helper && delete obj.helper

        webApi.setQuestion(obj).then(data => {
            if(data.status === 200){
                header.getSnackbar("Вопрос успешно добавлен к тесту")
                console.log(data)
                this.setState({
                    question: true,
                    question_id: data.response.id
                }, () => this.props.handleDisabledSelect(this.state.question))
            }else{
                header.getSnackbar("Произошла ошибка в добавлении вопроса")
            }
        })
        .catch(error => console.log(error))
        
    }

    handleRequire = () => {
        this.setState({
            require: !this.state.require
        }, () => console.log(this.state))
    }

    handleCorrect = () =>{
        this.setState({correct: !this.state.correct})
    }

    handleNewAnswer = () => {
        webApi.setAnswer({
            question_id: this.state.question_id,
            body: this.state.body,
            correct: this.state.correct
        })
        .then(data => {
            if(data.status === 200 || data.status === 201){
                this.setState({isInput: false})
                header.getSnackbar("Ответ успешно добавлен к вопросу")
                //this.setState({answer: true})
                this.setState({answers: [
                    ...this.state.answers,{
                        id: data.response.id,
                        body: this.state.body,
                        correct: this.state.correct
                }]}, () => this.setState({body: null, correct: false}))
            }else{
                header.getSnackbar("Произошла ошибка к добавлению ответа")
            }
        })
    }

    handleIsInput = () => {
        this.setState({isInput: true})
    }

    handleSaveAnswer = () => {
        if(this.state.answers && this.state.answers.length >=1){
            let numCurrect = this.state.answers.filter(el => el.correct === true)

            if(numCurrect.length){
                this.setState({
                    text: null,
                    require: false,
                    question:false,
                    question_id: null,
                    correct: false,
                    body: null,
                    answer: false,
                    answers: [],
                    colAnswers: 0
                }, () => {
                    this.props.rerender()
                    document.getElementById("text-questiob-selection").value=""
                    this.props.refreshQuestions()
                })
    
                header.getSnackbar("Вопрос успешно добавлен к тесту")
            }else{
                header.getSnackbar("Вопрос должен содержать хотя бы один правильный ответ")
            }
        }else header.getSnackbar("Ошибка. Вы не добавили ни одного ответа на вопрос")
    }

    removeAnswers = e => {
        // let indexQuestion =  this.state.fetchQuestions.map((el) => el.id).indexOf(id)
        // let newFetchQuestion = this.state.fetchQuestions
        // newFetchQuestion.splice(indexQuestion, 1)
// 
        // this.setState({fetchQuestions: newFetchQuestion})

        let indexAnswer = e.target.dataset.idIndex
        let newAnswer = this.state.answers
        newAnswer.splice(e.target.dataset.idIndex, 1)
        console.log(indexAnswer, newAnswer)
        this.setState({answers: newAnswer}, () => console.log(this.state.answers))

        webApi.deleteAnswer(e.target.dataset.idAnswer)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Ответ успешно удалён")
            }else{
                header.getSnackbar("Произошла ошибка при удалении")
            }
        })
    }

    difficultySelect = (e) => this.setState({points: e.points})

    searchLecture = val => {
        if(val.length){
            webApi.getLectureLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    this.setState({resultMaterial: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({resultMaterial: []})
                }              
            })
        }
    }

    changeSelectLecture = e =>{
        this.setState({selectInputMaterial: e})
    }


    render() {
        let {question, answers, isInput, body} = this.state
        let {scoreType, test_difficulty} = this.props

        return (
            <div className="free-type-question">
            Текст вопроса
            <div className="request-block-test">
                <input type="checkbox" disabled={question} onClick={this.handleRequire} name="require" className="input-checkbox fl-left"/> Обязательный вопрос
            </div> <br/>          
            <input type="text" id="text-questiob-selection" name="text" onChange={this.handleInput} placeholder="Напишите текст вопроса" disabled={question}/>
            <br/><br/>
            Подсказка
            <input type="text" name="helper" onChange={this.handleInput} placeholder="Подсказка для вопроса" disabled={question}/>
            <br/><br/>
            {this.state.helper && 
            <div>
            <i className="far fa-external-link"></i> <span>Привязать к подсказке ссылку на лекцию</span>
            <Select
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])} 
                    value = {this.state.selectInputMaterial}
                    options = {this.state.resultMaterial}
                    onChange = {this.changeSelectLecture}
                    noOptionsMessage = {() => '...'}
                    onInputChange={this.searchLecture}
                    placeholder={`Введите название лекции`} 
                    isDisabled={this.state.question}
            /><br/></div>}
            {/* Количество баллов
            <input type="text" name="points" onChange={this.handleInput} placeholder="Количество баллов за ответ на вопрос" disabled={this.state.question}/>
            <br/><br/> */}
            {scoreType && scoreType !== 'correct_answers' && <div>Количество баллов

            {test_difficulty && <Select
                        onChange={this.difficultySelect}
                        getOptionValue={(option) => (option['level'])}
                        getOptionLabel={(option) => (option['points'])}
                        options = {test_difficulty}
                        defaultValue={this.state.defCompany}
                        placeholder="Выберите количество баллов для вопроса"
                        isDisabled={this.state.question}
            />}
            {/* <input type="text" name="points" onChange={this.handleInput} placeholder="Количество баллов за ответ на вопрос" disabled={this.state.question}/> */}
            <br/><br/></div>}

            {question && (
                    <div>
                        Ответы {question && <div className="fl-right href cur-p" onClick={this.handleIsInput}>Добавить ответ</div>}<br/><br/>
                        {answers && answers.map((el, i) => {
                            return (
                                <div key={i + el.id} className="answersSelection">
                                    <input type="text" className="w-50 fl-left" name="body" defaultValue={el.body} disabled/>
                                    <div className="fl-left w-50">
                                        <span className="fl-right font-red mg-l" onClick={this.removeAnswers} data-id-index={i} data-id-answer={el.id}>Удалить</span>
                                        <span className="fl-right">Верный</span><input type="checkbox" checked={el.correct} className="input-checkbox fl-right" disabled/>
                                    </div>
                                    <div className="clear"></div><br/>
                                </div>
                            )
                        })}
                        {!this.state.answer && <div className="answersSelection">
                            {isInput && <div> <input type="text" data-id="0" className="w-50 fl-left" name="body" onChange={this.handleInput} value={this.state.body || ''} placeholder="Напишите текст ответа"/>
                            <div className="fl-left w-50">
                                <span className="fl-right">Верный</span> <input type="checkbox" checked={this.state.correct} onClick={this.handleCorrect} className="input-checkbox fl-right"/>
                            </div></div>}
                            <div className="clear"></div>
                        </div>}
                        <br/><br/>
                    </div>
            )}

            {!this.state.answer && <div className="btnSaveProfile fl-left" onClick={!this.state.question ? this.handleSaveQuestion : this.handleSaveAnswer}>{!question ? 'Сохранить' : 'Сохранить вопрос'}</div>}
            {question && isInput && <button disabled={body ? false : true} className="fl-left href cur-p btn-def ml-10" onClick={this.handleNewAnswer}>Сохранить ответ к вопросу</button>}
            {this.state.answer && <div className="btnAlert fl-left" onClick={() => this.props.rerender()}>Удалить вопрос</div>}
            <div className="clear"></div>
            </div>
        )
    }
}
