import React, { Component } from 'react'
import ViewProgramTable from './view'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';

export default class ProgramTable extends Component {
  state = {
    programs: null,
    loaderMaterial: false,
    loaderLast: false,
    limit: 5,
    offset: 0
  }

  componentWillMount(){
    let { limit, offset} = this.state

    header.pageName('Материалы')

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms(limit, offset)
      .then(data => {
        if(data.status === 200){
          this.setState({
            programs: data.response,
            loaderMaterial: false
          })
        }else {this.setState({loaderMaterial: false})}
      })
    })
  }

  render() {
    return (
        <ViewProgramTable
          programs = {this.state.programs} 
          match = {this.props.match}
          route = { this.props.route }
          handleTableView = { this.props.handleTableView }/>
    )
  }
}
