import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip';

export default class AwardBlock extends Component {

    render() {
        let { award } = this.props
        return (
            <div className="user-profile-container  mgt-50">
                <b>Достижения</b>
                <div className="hr mb-0"></div>
                <div className="user-profile-award">
                    {award && award.length > 0 ? award.map((a,i) => {
                        return(
                            <Tooltip key={i} title={a.title}>
                            <img src={a.images.original} alt={a.images.original}/>
                            </Tooltip>
                        )
                    }) : <div className="user-profile-role center">Достижений не получено</div>}
                    <div className="clear"></div>
                    {/* <div className="img-test"></div> */}
                </div>
            </div>
        )
    }
}