import React from 'react';
import {styled} from '@mui/material/styles';
import usePagination from "@mui/material/usePagination";
import {ReactComponent as Arrow} from "../../../../img/arrow-prime.svg";
import './style.css'
import {Button} from "@mui/material";

const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    gap: '2px',
    '& li:first-child': {
        marginRight: '20px',
    },
    '& li:last-child': {
        marginLeft: '20px',
    },
});

const ColorButton = styled(Button)(() => ({
    color: '#000',
    height: '40px',
    backgroundColor: '#F4F4F4',
    '&:hover': {
        backgroundColor: '#F4F4F4',
    },
}));

const PageNumberButton = styled(Button)(() => ({
    borderRadius: '4px',
    padding: '12px',
    minWidth: '40px',
    height: '40px',
}));

export const Pagination = ({onChange, allCount, page}) => {
    const {items} = usePagination({
        count: allCount,
        page: page,
        onChange: (event, page) => {
            onChange(page)
        },
    });

    return (
        <nav className={'pagination-wrapper'}>
            <List>
                {items && items.map(({page, type, selected, ...item}, index) => {
                    let children = null;

                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = (
                            <PageNumberButton disabled sx={{color: '#929292'}}>
                                {'…'}
                            </PageNumberButton>
                        );
                    } else if (type === 'page') {
                        children = (
                            <PageNumberButton
                                type="button"
                                sx={{
                                    backgroundColor: selected ? '#D2AD66' : 'transparent',
                                    color: selected  ? '#fff' : '#929292',
                                }}
                                {...item}
                            >
                                {page}
                            </PageNumberButton>
                        );
                    } else {
                        children = (
                            <ColorButton {...item} variant="contained" disableElevation sx={{ textTransform: 'none'}} >
                                {type === 'next' &&
                                    <div className={'button-control-pagination'}>
                                        <span>Следующая</span>
                                        <div className={`button-control-arrow-pagination`}
                                             style={{transform: 'rotate(180deg)'}}
                                        >
                                            <Arrow className={item.disabled ? 'arrow-pagination-disabled' : ''}/>
                                        </div>
                                    </div>}
                                {type === 'previous' &&
                                    <div className={'button-control-pagination'}>
                                        <div className={'button-control-arrow-pagination'}>
                                            <Arrow className={item.disabled ? 'arrow-pagination-disabled' : ''}/>
                                        </div>
                                        <span>Предыдущая</span>
                                    </div>}
                            </ColorButton>
                        );
                    }

                    return <li key={index}>{children}</li>;
                })}
            </List>
        </nav>
    );
};