import React, { Component } from 'react'
import webApi from '../../utilities/web-api';
import { header } from './../../utilities/page';

export default class Recovery extends Component {
    state = {
        input: null,
        type: null,
        page: null,
        confirm:{}
    }

    sendRecovery = e =>{
        e.preventDefault()

        this.validType(this.state.input, res => {
            if(res.type){
                webApi.recoveryPassword({
                    [res.type]: this.state.input
                })
                .then(data =>{
                    if(data.status === 200) {
                        header.getSnackbar(`На Ваш ${res.type === "phone" ? 'номер телефона' : 'e-mail'} отправлен код для подтверждения`)
                        this.setState({
                            page: 'confirm',
                            confirm:{
                                ...this.state.confirm,
                                [res.type]: this.state.input
                            }
                        })
                    }
                })
            }else header.getSnackbar("Данные введены некорректно")
        })
    }

    confirm = e =>{
        e.preventDefault()

        let {confirm} = this.state
        if(confirm.code && confirm.password){
            webApi.confirmPassword(confirm)
            .then(data =>{
                console.log(data)
                if(data.status === 200){
                    header.getSnackbar("Пароль успешно изменен")
                    header.closeDialog()
                }else header.getSnackbar("Данные введены некорректно")
            })
        }else header.getSnackbar("Пожалуйста, заполните все поля")
    }

    validType = (temp, collback) => {
        const template = {
            email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
            phone: /^\d{12}$/
        }

        let templ = {
            valid: false,
            type: null
        }

        for (const tmp in template){
            let template_type = template[tmp].test(String(temp).toLowerCase())
            if(template_type) {
                templ.valid = template_type
                templ.type = tmp
            }
        }

        return collback(templ)
    }

    handleInput = e => {
        this.setState({input: e.target.value})
    }

    handleConfirmInput = e => {
        this.setState({confirm:{
            ...this.state.confirm,
            [e.target.name]: e.target.value
        }})
    }

    render() {
        let { confirm } = this.state
        return (
        <div className="recover-password">
            {this.state.page !== 'confirm' ?
            <form onSubmit={ this.sendRecovery }>
                <div className="form-input-line">
                    <input name="recovery" onChange={ this.handleInput } type="text" placeholder="Введите номер телефона или e-mail"/>
                </div>
                <button onClick={ this.sendRecovery } className="btnSaveProfile w-50 center fix-recovery-btn">Отправить</button>
            </form>:
            <form onSubmit={ this.confirm }>
                <div className="form-input-line">
                    <input name="code" onChange={ this.handleConfirmInput } value={confirm && confirm.code || ''} type="text" placeholder="Введите код подтверждения"/>
                </div>
                <div className="form-input-line">
                    <input name="password" onChange={ this.handleConfirmInput } value={confirm && confirm.password || ''} type="text" placeholder="Введите новый пароль"/>
                </div>
                <button onClick={ this.confirm } className="btnSaveProfile w-50 center fix-recovery-btn">Восстановить</button>
            </form>
        }
        </div>
        )
    }
}
