import React, { Component } from 'react'
import ViewMaterial from './view'
import TableProgram from './table/program/index'
import TableCourse from './table/course/index'
import TableLecture from './table/lecture/index'
import { header } from './../../../../utilities/page';
//import history from '../history';

export default class Material extends Component {
    state = {
        _id: null
    }

    componentWillMount(){
        header.pageName('Материалы')
        !localStorage.getItem('_brdc') && header.newBreadcrumps([{
            title: "Материалы",
            route: '/materials'
        }])
        let route_url = this.props.match.url
        this.setState({_id: route_url})
        header.newBreadcrumps([{title:'Материалы', route: '/materials'}])
    }

    handleRoute = () => {
        let route_url = this.props.match.url
        let arr_route_url = route_url.split('/')
        this.setState({_id: arr_route_url})
    }

    updateUrl = id => {
        this.setState({_id: id})
    }

    render() {
        let tabs = [
            {
                component: <TableProgram/>,
                title: 'Программы'
            },
            {
                component: <TableCourse/>,
                title: 'Курсы'
            },
            {
                component: <TableLecture/>,
                title: 'Лекции'
            }
        ]

        let routeSplit = this.props.match.url.split('/')
        let filterRoute = routeSplit.filter(i => i !== '')
        //header.newBreadcrumps([{title:'Материалы', route:'/materials'}])
        filterRoute.length === 0 && header.newBreadcrumps([{title:'Материалы', route:'/materials'}])
        return (
        <ViewMaterial history={this.props.history} match = {this.props.match}  route_url = {this.state._id} tabs = { tabs }/>
        )
    }
}
