import React, { Component } from 'react'
import MenuBtn from '../../../elements/menu-btn/index'
import history from '../history';
import ProgramTable from './table/program/index'
import CourseTable from './table/course/index'
import LectureTable from './table/lecture/index'
//import Tab from '../../../elements/tabs/view'
//import TableProgram from './table/program/index'
import { header } from './../../../../utilities/page';

export default class ViewMaterial extends Component {

    state = {
        tableView: 'materials'
    }

    createMaterial = () =>{
        console.log(this.props.match.url.split("/")[1])
        history.push('/create-'+this.props.match.url.split("/")[1] + '/' + (this.props.match.params.id || ''))
    }
    backUrl = () => {
        // let url = this.props.match.url.split("/")[1]
        return console.log(this.props)
    }

    handleTableView = name => {
        // if(name != 'program' || name != 'course' || name != 'lecture') console.error(name + ' not avaible')
        this.setState({tableView: name}, () => console.log(this.state.tableView))
    }

    componentWillMount(){
        let routeSplit = this.props.match.url.split('/')
        let filterRoute = routeSplit.filter(i => i !== '')
        this.setState({tableView: filterRoute[0] || 'materials'}, () => console.log(this.state.tableView))
        //console.log(this.props.match)
    }

    renderTable = () => {
        let routeSplit = this.props.match.url.split('/')
        let filterRoute = routeSplit.filter(i => i !== '')
        //header.newBreadcrumps([{title:'Материалы', route:'/materials'}])
        filterRoute.length === 0 && header.newBreadcrumps([{title:'Материалы', route:'/materials'}])


        return (
            <div className="table-materials">
                {filterRoute[0] === 'materials' && <ProgramTable match = {this.props.match} handleTableView = { this.handleTableView } route = {this.props.route_url}/>}
                {filterRoute[0] === 'course' && <CourseTable match = {this.props.match} handleTableView = { this.handleTableView } route = {this.props.route_url}/>}
                {filterRoute[0] === 'lecture' && <LectureTable match = {this.props.match} handleTableView = { this.handleTableView } route = {this.props.route_url}/>}
            </div>)
    }

    render() {
        return (
        <div>
            <div className="user-right-content">
                <div className="block-content"><br/><br/>
                <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                <div onClick={this.createMaterial}><MenuBtn title="Добавить материал" className="btn-material-block-right"/></div><br/><br/><br/><br/>
                {this.renderTable()}
                <div className="btn-material-block btn-material-block-right fl-right"></div>
                </div>
            </div>
        </div>
        )
    }
}
