import React, { Component } from 'react'
import ViewAwardTab from './view'
import webApi from '../../../../../../utilities/web-api';
import CreateAward from './create'
import EditAward from './edit'


export default class AwardTab extends Component {
    state = {
        award: null,
        page:'view',
        id_award: null,
        loaderMaterial: false,
        loaderLast: false,
        limit: 5,
        offset: 0
    }

    handleAward = id =>{
        this.setState({
            id_award: id,
            page: 'edit'
        })
    }

    componentWillMount(){
        let { limit, offset} = this.state

        this.setState({loaderMaterial: true}, () => {
            webApi.getAward(null,limit, offset)
            .then(data => {
                if(data.status === 200)
                {
                    this.setState({
                        award: data.response,
                        loaderMaterial: false,
                        //offset: this.state.offset + limit,
                    })
                }else {this.setState({loaderMaterial: false})}
            })
            .catch(err => console.log(err))
        })
    }

    loadMaterial = () =>{
        let { limit, offset, award } = this.state
    
        this.setState({loaderMaterial: true}, () => {
            webApi.getAward(null,limit, offset + limit)
          .then(data => {
            if(data.status === 200){
              this.setState({
                award: [...award, ...data.response],
                offset: this.state.offset + limit,
                loaderMaterial: false
              }, () => console.log(this.state.award))
            }else this.setState({loaderLast: true, loaderMaterial: false})
          })
        })
      }

    updateAward = () =>{
        webApi.getAward()
        .then(data => {
            if(data.status === 200)
            {
                this.setState({award: data.response})
            }
        })
        .catch(err => console.log(err))
    }

    handlePage = page => {
        this.setState({page})
    }

    render() {
        return (
            <div>
                {this.state.page === 'view' && <ViewAwardTab 
                                                handleAward={ this.handleAward }
                                                handlePage = { this.handlePage }
                                                award={this.state.award}
                                                loadMaterial = {this.loadMaterial}
                                                loaderLast = {this.state.loaderLast}
                                                loaderMaterial = {this.state.loaderMaterial}/>}
                {this.state.page === 'create' && <CreateAward updateAward={this.updateAward} handlePage = { this.handlePage }/>}
                {this.state.page === 'edit' && <EditAward updateAward={this.updateAward} id_award={this.state.id_award}  handlePage={this.handlePage}/>}
            </div>
        )
    }
}
