export default {
  getExcludeType(excludeKey, excludeVal) {
    if (!excludeKey || !excludeVal) return ''
    switch (excludeKey) {
      case 'filial':
      case 'course':
      case 'program': return `&exclude_key=${excludeKey}_id&exclude_val=${excludeVal}`
      default: throw new Error(`getExcludeType wrong type: ${excludeKey}`)
    }
  },

  getMaterialType(type, id) {
    switch (type) {
      case 'departments': return `&department_id=${id}`
      case 'filials': return `&filial_id=${id}`
      case 'companies': return `&company_id=${id}`
      default: throw new Error('getBriefUsersByStructure wrong type passed.')
    }
  },

  statusTranslate(status) {
    switch (status) {
      case 'new' :
        return 'Новое'
      case 'progress' :
        return 'В работе'
      case 'closed' :
        return 'Закрытое'
      default:
        return 'Новое'
    }
  },

  isFloat(n){
    return Number(n) === n && n % 1 !== 0
  },

  categoryTranslate(str) {
    switch (str) {
      case 'content' :
        return 'Ошибка контента'
      case 'test' :
        return 'Тест'
      case 'closed' :
        return 'review'
      default:
        return 'Отзыв'
    }
  },

  statusColor(status) {
    switch (status) {
      case 'new' :
        return 'new'
      case 'progress' :
        return 'В in-progress'
      case 'closed' :
        return 'closed'
      default:
        return 'Новое'
    }
  },

}
