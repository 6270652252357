import React, { Component } from 'react'
import history from '../../history';
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';
import getDiskSpace from '../../../../../utilities/disk-space';
import Select from 'react-select';


export default class ViewCreateCourse extends Component {
    state = {
        input: {},
        course: {},
        after_test_input: []
    }

    componentWillMount(){
        header.pageName('Редактирование курса')
        webApi.getCourse(this.props.match.params.id)
        .then(async data => {
            if(data.status === 200){
                let obj = await data.response 
                await data.response.after_test_id && webApi.getTest(data.response.after_test_id).then(data => obj.after_test_title = data.response.title)
                console.log(obj)
                await this.setState({course: obj}, () => {
                    console.log(this.state.course)
                    webApi.getProgram(this.state.course.program_id)
                    .then(data => this.setState({course:{
                        ...this.state.course,
                        program_title: data.response[0].title
                    }, input:{
                        id: this.props.match.params.id
                    }}))
                    .catch(error => console.log(error))
                })
            }
        })
        .catch(error => console.log(error))
    }

    updateCourse = () => {
        let update = this.state.input
        if(this.state.after_test_id === null) update.after_test_id = null
        if(this.state.after_test_id) update.after_test_id = this.state.after_test_id.id
        update.estimation && (update.estimation = update.estimation * 60)
        if(update.estimation && update.estimation > 8100000) return header.getSnackbar('Рекомендуемое время не может приувеличивать 135000 мин. (3 месяца)')

        webApi.updateCourse(update)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar('Курс успешно изменен')
                return history.push('/course/' + this.state.course.program_id)
            }
        })
        .catch(err => console.log(err))
    }

    uploadImg = e => {
        this.setState({input:{
            ...this.state.input,
            images: e
        }})
    }

    handleInput = e => {
        this.setState({input:{
            ...this.state.input,
            [e.target.name]: e.target.value}
        })
    }

    handleActive = () =>{
        let obj = this.state.course
        webApi.courseActive(obj.id).then(data => {
            if(data.status === 200){
                header.getSnackbar('Курс успешно опубликован')
                return history.push('/course/' + this.state.course.program_id)
            }
        })
    }

    handleInActive = () =>{
        let obj = this.state.course
        webApi.courseInActive(obj.id).then(data => {
            if(data.status === 200){
                header.getSnackbar('Курс успешно убран с публикации')
                return history.push('/course/' + this.state.course.program_id)
            }
        })        
    }

    availabilityLesson = val => {
        if(val.length){
            webApi.getTestLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    //console.log(res)
                    this.setState({after_test_input: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({after_test_input: []})
                }              
            })
        }
    }
    /*input:{
            ...this.state.input,
            [e.target.name]: e.target.value
        }

    */

    changeSelectAvailability = e =>{
        this.setState({
            after_test_id: e
        })
    }

    removeAfter = () => {
        this.setState({after_test_id: null}, () => {
            let inp = document.querySelector('.fix-selector-width input')
            inp.value = ''
        })
    }


    render() {
        let {course, input} = this.state
        //course.estimation && console.log(course.estimation/60)
        let estimation
        if(course.estimation) estimation = course.estimation/60

        return course && (
            <div className="user-right-content">
                <div className="block-content">
                <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                    <div className="w-50 fl-left">
                        Наименование программы обучения <br/>
                        <input type="text" className="inputTraining"  defaultValue={course.program_title} disabled/> <br/><br/>
                        Наименование курса <br/>
                        <input type="text" defaultValue={course.title} onChange = { this.handleInput } name="title" className="inputTraining" placeholder="Введите наименование лекции"/> <br/><br/>
                        Краткое описание курса <br/>
                        <textarea defaultValue={course.description} className="inputTrainingHeight" onChange = { this.handleInput } name="description">
                            <span>{course.description}</span>
                        </textarea> <br/><br/>
                        Рекомендуемое время на прохождение (мин)<br/>
                        <input defaultValue={estimation} onChange = { this.handleInput } name="estimation" className="inputTraining" placeholder = "Введите рекомендуемое время на прохождение" /> <br/><br/>
                        Доступность материала<br/>
                        <i className="fas fa-times fix-del-after-icon" onClick={this.removeAfter}></i>
                        {course.after_test_title && <Select
                            getOptionValue = {(option) => (option['id'])}
                            getOptionLabel = {(option) => (option['title'])}
                            value = {this.state.after_test_id}
                            className="fix-selector-width"
                            //defaultValue = {[{id: course.after_test_id, title: course.after_test_title}]}
                            defaultInputValue = {course.after_test_title.title}
                            options = {this.state.after_test_input}
                            onInputChange = {this.availabilityLesson}
                            onChange = {this.changeSelectAvailability}
                            placeholder = "Выберите тест после которого курс будет доступен"
                        />}
                        {!course.after_test_title && <Select
                            getOptionValue = {(option) => (option['id'])}
                            getOptionLabel = {(option) => (option['title'])}
                            value = {this.state.after_test_id}
                            className="fix-selector-width"
                            options = {this.state.after_test_input}
                            onInputChange = {this.availabilityLesson}
                            onChange = {this.changeSelectAvailability}
                            placeholder = "Выберите тест после которого курс будет доступен"
                        />}
                        <br/>
                    </div>
                    <div className="w-50 fl-left center">
                        Обложка (jpg/png) <br/><br/>
                        {input.images && <div><img className="no-img-training img_cover_material" src={input.images.normal} alt=""/></div>}
                        {course.images && !input.images && <div><img className="no-img-training img_cover_material" src={course.images.normal} alt=""/></div>}
                        {!course.images && !input.images && <div className="no-img-training">320x240</div>}
                        <br/>
                        <input type="file" className="hide" onChange={this.uploadImg} id="_program-cover"/>
                        <button className="btnSaveProfile" onClick={() => getDiskSpace(result => this.uploadImg(result),{type:false, fullUrl: true})}>Выбрать файл</button>
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button onClick={this.updateCourse} className="btnSaveProfile fl-left">Сохранить</button>
                    {course.active ? <button onClick = { this.handleInActive } className="btnSaveProfile fl-left mg-l">Деактивировать</button>:
                    <button onClick = { this.handleActive } className="btnSaveProfile fl-left mg-l">Активировать</button>}
                    {/* <button onClick = {() => history.push('/create-lecture')} className="btnSaveProfile fl-right">Добавить лекцию в данный курс <i className="fas fa-arrow-right btnSaveProfileFix"></i></button> */}
                </div>
            </div>
        )
    }
}
