import React, { Component } from 'react'
import ViewCreateAward from './view';
import webApi from '../../../../utilities/web-api'
import { connect } from 'react-redux';
import { header } from '../../../../utilities/page'
//import Page from './../../../../store/actions/header/action';

class CreateAward extends Component {
    state={
        title: "",
        images: "",
        description: ""
    }

    componentWillMount(){
        header.pageName("Новое достижение")
    }

    handleInput = e =>{
        this.setState({[e.target.name]: e.target.value})
    }

    handleImg = e =>{
        this.setState({images: e})
    }

    createAward = () =>{
        webApi.setAward(this.state, this.props.state)
        .then(data => {
            if(data.status === 200 || data.status === 201){
                header.getSnackbar("Достижение успешно создано")
            }else{
                header.getSnackbar("Произошла ошибка")
            }
        })
        .catch(error => console.log(error))
    }

    render() {
        return (
        <ViewCreateAward handleImg = {this.handleImg} inputValue = {this.state} handleInput = { this.handleInput } createAward = { this.createAward } />
        )
    }
}


export default connect(state => {
    return{state: state.user.token}
})(CreateAward)