import React, { Component } from 'react'
import { header } from './../../../../utilities/page';
import UpdateProfile from './update-profile-pop';
import Disk from './../disk-space/index';

const Confirm = () => {
    return (
        <div className="pd-25">
        <span>Вы уверены, что хотите выйти ?</span> <div className="clear"></div> <br/> <br/>
            <div className="btnSaveProfile fl-left" onClick = {() => {
                header.clearSession()
                return header.closeDialog()
                }}>Да</div>
            <div className="btnSaveProfile fl-right" onClick={() => header.closeDialog()}>Отмена</div> <br/><br/>
        </div>
    )
}

class HeaderUserPop extends Component {
    handleAwards = () =>{
        header.getDialog({
            title: 'Ваши достижения',
            content: 'На данный момент вы не имеете ни одного достижения'
        })
    }

    handleDiskSpace = () => {
        header.getModalPage({
            title: <div><i className="fal fa-copy i-white"></i>Мои файлы</div>,
            content: <Disk/>
        })
    }

    handleUserUpdate = () =>{
        header.getDialog({
            title: 'Редактирование профиля',
            content: <UpdateProfile/>
        })
    }

    handleConfirm = () =>{
        header.getDialog({
            title: null,
            content: <Confirm/>
        })
    }



    render() {
        return (
        <div className="user-header-pop" >
            {/* <div className="pop-header">
                <div className="left-block">Текущий рейтинг<br/><b>16/448</b></div>
                <div className="hr-vertical"></div>
                <div className="right-block">Баллы<br/><b>846</b></div>
                <div className="clear"></div>
            </div> */}
                <div>
                    <br/>
                    <div className="menu text-left" onClick = { this.handleUserUpdate }>
                        <i className="far fa-cog fl-left"></i> Редактировать
                    </div> <div className="clear"></div>

                    <div className="menu text-left" onClick={ this.handleDiskSpace }>
                        <i className="far fa-file-user"></i> Мои файлы
                    </div> <div className="clear"></div>

                    <div className="menu text-left">
                        <i className="far fa-question-circle fl-left"></i> Помощь
                    </div> <div className="clear"></div>

                    <div className="menu text-left" onClick = {/*() => header.clearSession()*/ this.handleConfirm}>
                        <i className="far fa-sign-out-alt fl-left"></i> Выйти
                    </div> 
                </div>
            </div>
        )
    }
}

export default HeaderUserPop 