import {header} from '../../../utilities/page'

const inclusiveSearchReducer = ({id, title}) => ({ value: id, label: title })

export default (
  searchCallback,
  errorMessage = 'Данный элемент не найден',
  reducer = inclusiveSearchReducer,
  programId,
  isUser
) => (inputValue, _) => {
  if (isUser) {
    return inputValue && searchCallback(programId, inputValue, _)
      .then(res => {
        if (programId) {
          return {
            options: res.response ? res.response.items.map(reducer) : res.items.map(reducer),
            hasMore: res.response ? (res.response.items.map(reducer)).length + _.length < res.response.count : (res.items.map(reducer)).length + _.length < res.count
          }
        }
        return {
          options: res.items.map(reducer),
          hasMore: (res.items.map(reducer)).length + _.length < res.count
        }
      })
      .catch(err => {
        if (err.code === 404) {
          header.getSnackbar(errorMessage)
          return {options: [], hasMore: false}
        } else if(err.code === 400) {
          header.getSnackbar('Вы ввели некоректные данные. Поиск осуществляется по буквам и цифрам.')
        } else if(err.code === 403) {
          header.getSnackbar('У вас недостаточно прав для получения данной информации.')
        } else {
          header.getSnackbar('Произошла ошибка на сервере.')
          console.error(err)
        }
      })
  } else {
    return searchCallback(programId, inputValue, _)
      .then(res => {
        if (programId) {
          return {
            options: res.response ? res.response.items.map(reducer) : res.items.map(reducer),
            hasMore: res.response ? (res.response.items.map(reducer)).length + _.length < res.response.count : (res.items.map(reducer)).length + _.length < res.count
          }
        }
        return {
          options: res.response ? res.response.items.map(reducer) : res.items.map(reducer),
          hasMore: (res.items.map(reducer)).length + _.length < res.count
        }
      })
      .catch(err => {
        if (err.code === 404) {
          header.getSnackbar(errorMessage)
          return {options: [], hasMore: false}
        } else if(err.code === 400) {
          header.getSnackbar('Вы ввели некоректные данные. Поиск осуществляется по буквам и цифрам.')
        } else if(err.code === 403) {
          header.getSnackbar('У вас недостаточно прав для получения данной информации.')
        } else {
          header.getSnackbar('Произошла ошибка на сервере.')
          console.error(err)
        }
      })
  }
}
