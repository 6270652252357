import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import AsyncSelect from 'react-select/async'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import webApi from '../../../../../utilities/web-api'
import {header} from '../../../../../utilities/page'
import {AsyncPaginate} from "react-select-async-paginate";

const userReducer = ({ id, name, surname }) => ({ value: id, label: `${name} ${surname}` })

class SelectType extends PureComponent {
  state = {
    type: 'departments'
  }

  generateSearchCallback(type) {
    switch (type) {
      case 'departments':
        return this.props.search(webApi.inclusiveSearchDepartments, 'Такого отдела не найдено.')
      case 'filials':
        return this.props.search(webApi.inclusiveSearchFilials, 'Такого объекта не найдено.')
      case 'companies':
        return this.props.search(webApi.inclusiveSearchCompanies, 'Такой компании не найдено.')
      case 'users':
        return this.props.search(webApi.inclusiveSearchUser, 'Такого пользователя не найдено.', userReducer)
      default:
        throw new Error('Wrong type passed.')
    }
  }

  get isUsersType() {
    return this.state.type === 'users'
  }

  get isMaterialType() {
    const { type } = this.state
    return type === 'companies' || type === 'filials' || type === 'departments'
  }

  getUsersByStructure = id => webApi
    .getBriefUsersByStructure({
      id,
      type: this.state.type,
      excludeKey: this.props.materialType,
      excludeVal: this.props.materialId,
      unlimit: true
    })
    .then(
      users => users.map(
        ({ id, name, surname }) => ({ id, name: `${name} ${surname}` })
      )
    )
    .catch(err => {
      if (err.code === 404) {
        header.getSnackbar('В данной структуре не найдены пользователи, у которых нет текущей задачи.')
      } else if(err.code === 403) {
        header.getSnackbar('У вас недостаточно прав для получения данной информации.')
      } else if(err.code === 400) {
        header.getSnackbar('Вы ввели некоректные данные. Поиск осуществляется по буквам и цифрам.')
      } else {
        header.getSnackbar('Произошла ошибка на сервере.')
        console.error(err)
      }
    })

  onChange = async ({ value, label }) => {
    if (this.isUsersType) {
      this.props.update([{ id: value, name: label }])
    } else if (this.isMaterialType) {
      const users = await this.getUsersByStructure(value)
      if (!isEmpty(users)) this.props.update(users)
    } else {
      throw new Error('onChange unknown type passed.')
    }
  }

  updateType = (_, type) => {
    if (type) this.setState({ type })
  }

  render() {
    return (
      <div className="block-content">
        <h3>Назначить для</h3>
        <ToggleButtonGroup className="top-task-material-btns top-task-material-btnsGroup" value={this.state.type} exclusive onChange={this.updateType}>
          <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="departments">Отдела</ToggleButton>
          <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="filials">Объекта</ToggleButton>
          <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="companies">Компании</ToggleButton>
          <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="users">Пользователя</ToggleButton>
        </ToggleButtonGroup> <br/><br/>

        <AsyncPaginate
          loadOptions={this.generateSearchCallback(this.state.type)}
          onChange={this.onChange}
          value={this.state.value || ''}
          noOptionsMessage = {() => '...'}
          placeholder="Начните ввод для поиска..."
        />
      </div>
    )
  }
}

SelectType.propTypes = {
  update: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  materialType: PropTypes.string.isRequired,
  materialId: PropTypes.string.isRequired
}

export default SelectType
