import React, { Component } from 'react'
import moment from 'moment';

export default class ViewError extends Component {

    state = {
        time: ''
    }

    timeNext = time => {
        
        let fAt = moment(time).valueOf()
        let fNow = moment().valueOf()
        let result = moment(fAt).diff(fNow)
        let resultTime = moment(result).utc().format('HH:mm:ss')//setInterval(() => {moment(result).utc().format('HH:mm:ss')}, 1000)
        
        return resultTime
    }

    componentWillMount(){
        if(this.props.data_error){
                this.setState({time: this.timeNext(this.props.data_error.next_time)})
        }
    }

    componentDidMount(){
        if(this.props.data_error){
            let intervalTime = setInterval(() => {
                this.setState({time: this.timeNext(this.props.data_error.next_time)}, () => {
                    this.state.time === '00:00:00' && clearInterval(intervalTime)
                })
            }, 1000)
        }
    }

    render() {
        let {error, data_error} = this.props
        return error && (
            <div className="user-right-content">
                <div className="block-content">
                    {!data_error && <div className="error_msg_test">{error}</div>}
                    {(data_error && this.state.time !== '00:00:00') && <div className="error_msg_test">{error}, он будет доступен через {this.state.time}</div>}
                    {(data_error && this.state.time === '00:00:00') && <button className="btn_back_test" onClick={() => window.location.reload()}>Попробовать пройти тест ещё раз</button>}               
                </div>
            </div>
        )
    }
}
