import get from 'lodash/get'
import React, { Component } from 'react'
import undraw_questions_75e0 from '../../../../../img/undraw_questions_75e0.svg'
import webApi from '../../../../utilities/web-api';
import history from "../history";
import {header} from "../../../../utilities/page";

export default class BlockTest extends Component {
    state = {
        finished: null,
        attempts: null,
        max_attempts: null,
        id: null
    }

    componentWillMount(){
        this.props.test_id && webApi.getHistoryTest(this.props.test_id).then(data => {
            data.status === 200 && this.setState({finished: data.response.success})
            data.status === 200 && this.setState({id: data.response.id})
        })

        webApi.getHistoryAttempts(this.props.test_id)
        .then(data => data.status === 200 && this.setState({attempts: data.response.attempts}))

        webApi.getTest(this.props.test_id)
        .then(data => {
            // console.log(data, 'data')
            data.status === 200 && this.setState({max_attempts: data.response.max_attempts})
        })
    }

    testStatus = () => {
        let {finished, attempts, max_attempts} = this.state

        if(finished !== null && attempts !== null && max_attempts !== null){
            if((max_attempts - attempts) <= 0 && !finished) return <span className="education-content-stat-avaible font-red">Тест не пройден, посмотреть результат</span>
            if((max_attempts - attempts) > 0 && !finished) return <span className="education-content-stat-avaible href">Пройти тест</span>
            if(finished) return <span className="education-content-stat-avaible href font-green">Тест успешно пройден, посмотреть результат</span>
        }else return <span className="education-content-stat-avaible href">Пройти тест</span>

    }

    handlePushTest = (index, isComplete) => {
        if(typeof index === 'object' && !index.after_test) {
            webApi.getTest(index.after_test_id)
              .then(data => {
                  data.status === 200 && header.getSnackbar(`Чтобы перейти к тесту, пройдите тест "${data.response.title}"`)
              })
        } else {
            isComplete ? history.push('/history/' + this.state.id) : history.push(`/test/${typeof index === 'string' ? index : index.id}`)
        }
    }

    render() {
        let {title, time_to_pass, questions_amount, min_score_points, body, test, test_id} = this.props
        const hasTraining = (body && body.has_training) || (body.test && body.test.has_training)
        return (
        <>
            <div onClick={() => this.handlePushTest(test || test_id, this.state.finished)} className={`block-education test-education cur-p ${((test && test.after_test === false) || (body && body.test && body.test.after_test === false)) && 'disabled-block-education'}`}>
                <div className="education-cover pd-15">
                    <img src={undraw_questions_75e0} alt="undraw_questions"/>
                </div>
                <div className="education-content">
                    <div className="education-content-children education-content-title">
                        <span>{title && title}</span>
                    </div>
                    <div className="education-content-children education-content-description">
                    </div>
                    <div className="education-content-children education-content-footer">
                        <span><i className="fas fa-star-half-alt"/> {questions_amount && questions_amount} вопросов</span>
                        <span ><i className="fal fa-clock"/> {time_to_pass && (time_to_pass/60).toFixed(2)} минут</span>
                        <span><i className="fas fa-star-half-alt"/> Проходной балл {min_score_points && min_score_points}</span>
                        {this.testStatus()}
                    </div>
                </div>
            </div>
            {hasTraining && this.state.finished && <div onClick={() => this.handlePushTest(test || test_id, false)} className={`block-education test-education cur-p ${((test && test.after_test === false) || (body && body.test && body.test.after_test === false)) && 'disabled-block-education'}`}>
                <div className="education-cover pd-15">
                    <img src={undraw_questions_75e0} alt="undraw_questions"/>
                </div>
                <div className="education-content">
                    <div className="education-content-children education-content-title">
                        <span>Повторное прохождение теста {title && title}</span>
                    </div>
                    <div className="education-content-children education-content-description">
                    </div>
                    <div className="education-content-children education-content-footer">
                        <span><i className="fas fa-star-half-alt"/> {questions_amount && questions_amount} вопросов</span>
                        <span ><i className="fal fa-clock"/> {time_to_pass && (time_to_pass/60).toFixed(2)} минут</span>
                        <span className="education-content-stat-avaible href">Пройти тест</span>
                    </div>
                </div>
            </div>}
        </>
        )
    }
}
