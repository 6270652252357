import React, { Component } from 'react'
import history from '../../../history';
import Tooltip from '@material-ui/core/Tooltip';
import { header } from './../../../../../../utilities/page';
import webApi from '../../../../../../utilities/web-api';
import Tabs from '../../../../../elements/tabs/view'

export default class ViewCourseTable extends Component {

  state = {
    course: null,
    loader: true,
    editorState: false,
    orders: null,
    loaderMaterial: false,
    loaderLast: false,
    limit: 5,
    offset: 0
  }

  componentDidMount(){
    this.props.course && this.setState({course: this.props.course})
  }

  componentWillMount(){
    let { limit, offset} = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getCourses(this.props.match.params.id, limit, offset)
      .then(data => {
        if(data.status === 200){
          this.setState({
            course: data.response,
            loaderMaterial: false,
            offset: this.state.offset + limit,
          },() => {
            let {course} = this.state

            this.setState({orders: course.sort(({ order: ordA }, { order: ordB }) => ordA - ordB).map(e => {
              return {id: e.id, order: e.order}
            })})
          })
        }else {this.setState({loaderMaterial: false})}
      })
      .catch(error => console.log(error))
    })
  }

  handleTable = (e, id) => {
    let {editorState} = this.state

    if(!editorState){
      if(e.target.className === 'far fa-edit'){
        history.push('/edit-course/' + e.target.dataset.id)
      }
      else if(e.target.className === 'fas fa-vial'){
        history.push(`/create-test/course/${e.target.dataset.materialId}/${e.target.dataset.testId}`)
      }else if(e.target.className === 'far fa-arrow-down' || e.target.className === 'far fa-arrow-up'){
        return true
      }
      else {
        //history.push('/edit-course/'+id)
        history.push('/lecture/' + e.target.parentNode.dataset.id)
        this.props.handleTableView('lecture')
        header.addBreadcrumps({title:e.target.parentNode.dataset.title, route:'/lecture/' + e.target.parentNode.dataset.id})
      }
    }
  }

 async swapIndex(current, next) {
  // const { status, response } = await webApi.updateCourseOrder([
  //   { id: current.id, order: next.order },
  //   { id: next.id, order: current.order }
  // ])
  // if (status === 200) {
  //   this.setState({
  //     course: this.state.course.map(program => {
  //       const found = response.find(({ id }) => id === program.id)
  //       if (found) program.order = found.order
  //       return program
  //     })
  //   })
  // }

  let course = this.state.course.filter(e => e !== current && e !== next)
  course = [...course, {...current, order: next.order}, {...next, order: current.order}].sort(({order: a}, {order: b}) => a - b)
  this.setState({course})

  let orders = this.state.orders.filter(e => e.id !== current.id && e.id !== next.id)
  orders = [...orders, { id: current.id, order: next.order }, { id: next.id, order: current.order }].sort(({order: a}, {order: b}) => a - b)
  this.setState({orders})
}

saveOrder = () => {
  webApi.updateCourseOrder(this.state.orders).then(data => data.status === 200 && header.getSnackbar('Порядок успешно сохранен'))
  this.setState({editorState: false})
}

  renderTable = (data, active) => {
    const courses = data
      .filter(e => e.active === active)
      .sort(({ order: ordA }, { order: ordB }) => ordA - ordB)

    return(<div className="table fix-table-courses">
        <div className="table-header">
            <div className="table-column">
              {!this.state.editorState ? <Tooltip onClick={() => this.setState({editorState: true})} title="Отсортировать материал" aria-label="Отсортировать материал">
                <i className="fas fa-sort sort-icon"></i>
              </Tooltip> :
              <Tooltip onClick={() => this.saveOrder()} title="Сохранить" aria-label="Сохранить">
                <i className="fas fa-save sort-icon"></i>
              </Tooltip>
              }
              ID</div>
            <div className="table-column">Наименование материала</div>
            <div className="table-column">Количество лекций</div>
            <div className="table-column">Количество участников</div>
            <div className="table-column">Завершило успешно</div>
            <div className="table-column">Тест</div>
            <div className="table-column">Состояние</div>
        </div>
    {courses.map((program, i) =>{
      return(
      <div data-column={i+1} key={i}>
        <div onClick={e => this.handleTable(e, program.id)} data-id={program.id} data-title={program.title} className="table-line">
          <div className="table-column table-column-course">{program.id}
            {this.state.editorState && program && courses[i-1] &&
                    <span onClick={() => this.swapIndex(program, courses[i-1])} className="editMaterialLine orderUp">
                      <Tooltip title="Поднять позицию" aria-label="Поднять позицию">
                        <i data-id={program.id} className="far fa-chevron-square-up"/>
                      </Tooltip>
                    </span>
                  }
            {this.state.editorState && program && courses[i+1] &&
              <span onClick={() => this.swapIndex(program, courses[i+1])} className="orderDown">
                <Tooltip title="Опустить позицию" aria-label="Опустить позицию">
                  <i data-id={program.id} className="far fa-chevron-square-down"/>
                </Tooltip>
              </span>
            }
          </div>
          <div className="table-column table-column-course">{program.title}</div>
          <div className="table-column table-column-course">-</div>
          <div className="table-column table-column-course">-</div>
          <div className="table-column table-column-course">-</div>
          <div className="table-column">{program.test ? <i className="far fa-check-circle have-test"></i> : <i className="far fa-times-circle dont-test"></i>}</div>
          <div className="table-column table-column-course">{active ? 'Активный' : 'Неактивный'}
            {!this.state.editorState &&
              <div data-id={program.id} data-title={program.title}>
                <span className="editMaterialLine"><Tooltip title="Редактировать" aria-label="Редактировать"><i data-id={program.id} className="far fa-edit"></i></Tooltip></span>
                <span className="editMaterialLine editMaterialTest"><Tooltip title="Тест" aria-label="Тест"><i data-material-id={program.id} data-test-id={program.test ? program.test.id : 'new'} className="fas fa-vial"></i></Tooltip></span>
              </div>
            }
            </div>
        </div>
      </div>)
    })}
    </div>)
  }

  loadMaterial = () =>{
    let { limit, offset, course } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getCourses(this.props.match.params.id, limit, offset)
      .then(data => {
        if(data.status === 200){
          this.setState({
            course: [...course, ...data.response],
            offset: this.state.offset + limit,
            loaderMaterial: false
          })
        }else this.setState({loaderLast: true, loaderMaterial: false})
      })
    })
  }

  render() {
    let { course } = this.state

    let tabs = []
    if(course) {
      tabs = [
      {
          title: 'Активные',
          component: this.renderTable(course, true)
      },
      {
          title: 'Неактивные',
          component: this.renderTable(course, false)
      }
    ]}else tabs = []

    return (
    <div>
        { course ?
          <div>
            <Tabs tabs={tabs}/>
            <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterial}>
              {this.state.loaderLast ? 'материалов больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
            </button>
          </div> :
          <div className="pd-25">Ни одного материала не создано <span className="href" onClick={()=>history.push('/create-course/'+this.props.match.params.id)}>создать</span></div>}
    </div>
    )
  }
}
