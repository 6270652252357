import React, { Component } from 'react'
// import TogglePanel from '../../../../elements/TogglePanel/view'
// import BlockTest from './../../contents/block-test';
// import history from '../../history';
import { header } from './../../../../../utilities/page';

export default class ViewProfile extends Component {
    // renderMaterial = () => {
    //     let { arrMaterial} = this.props

    //     const filterMaterial = arrMaterial.map(i =>
    //         {
    //             return(<div key ={i.id}>{i.title}</div>)
    //         }
    //         )


    //     return filterMaterial
    // }

    // componentDidMount(){
    //     setTimeout(() => {window.scrollTo(0, 200)}, 500);
    // }

    render() {
        let { blockEducation } = this.props
    
        return (
            <div>

            <div className="user-right-content" >
                <div className="block-content">
                    <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                    <h3>Доступные программы для обучения</h3>
                    {blockEducation}
                    {
                        !this.props.loaderMaterialAll &&
                        <div className="loadMaterialDual">
                            <button className="loadMaterialBtn" onClick={!this.props.loaderLast && this.props.loadMaterial}>
                                {this.props.loaderLast ? 'материалов больше нет' : this.props.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
                            </button>
                            <button className="loadMaterialBtn" onClick={!this.props.loaderLast && this.props.loadMaterialAll}>
                                {this.props.loaderLast ? 'материалов больше нет' : this.props.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить все'}
                            </button>
                        </div>
                    }
                </div>
            </div>
{/*
            <div className="user-right-content">

                <div className="block-content">
                    {tests && tests.map((el,i) => {
                        return (
                            <div key={el.id} onClick={() => history.pushState('/test/'+el.test.id)} >{
                                el.test && <BlockTest key={i} title={el.test.title} time_to_pass={el.test.time_to_pass} questions_amount={el.test.questions_amount} min_score_points={el.test.min_score_points} />}</div>
                            )
                    })}
                </div>

            </div> */}
        </div>
        )
    }
}
