import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { header } from './../../../../utilities/page';

class Messages extends Component {
  constructor(props) {
    super(props)
    this.chatMessages = React.createRef()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  getAvatar = id => this.props.avatars[id] && this.props.avatars[id].normal
    ? <img className="chat-messages-avatar" src={this.props.avatars[id].normal} alt="user-avatar"/>
    : <div className="ava-chat chat-messages-avatar"/>

  scrollToBottom = () => {
    this.chatMessages.current.scrollTop = this.chatMessages.current.scrollHeight
  }

  getMineMessageClass = userId => this.props.currentUserId === userId ? 'mine' : 'opponent'

  getFileIcon = type => {
    switch (type) {
      case 'image': return <i className="fal fa-image message-file-icon"/>
      case 'pdf': return <i className="fal fa-file-pdf message-file-icon"/>
      case 'audio': return <i className="fal fa-file-audio message-file-icon"/>
      case 'video': return <i className="fal fa-file-video message-file-icon"/>
      default: return <i className="fal fa-file-word message-file-icon"/>
    }
  }

  fullImgPop = data => {
    header.getDialog({
      content: <img src={data.url} alt="image preview" className='message-file-link-preview-pop'/>
    })
  }

  messageContent = (type, data) => {
    switch (type) {
      case 'message':
        return <span className="message-text">{data.message}</span>
      case 'file':
        return <span className="message-file">
          {data.preview && <div className="w-100"><img onClick={ () => this.fullImgPop(data)} src={data.preview} alt="image preview" className='message-file-link-preview'/><div className="hr"></div></div>}
          <a href={data.url} download={data.url} target="_blank" className='message-file-link'>
            {this.getFileIcon(data.type)}
            {data.fileName || 'Нажмите чтобы скачать'}
            <i className="fal fa-download message-file-download-icon"/>
          </a>
        </span>
      default:
        return <span className="message-text">Неизвестный тип сообщения.</span>
    }
  }

  render() {
    const shouldShowUsername = this.props.dialog.type !== 'private'
    return (
      <div ref={this.chatMessages} className="chat-messages">
        {this.props.messages.map(({ data, userId, type }, idx) => (
          <div className={`message ${type} ${this.getMineMessageClass(userId)}`} key={idx}>
            {this.getAvatar(userId)}
            {shouldShowUsername && <span className="message-name">{data.name}</span>}
            {this.messageContent(type, data)}
          </div>
        ))}
      </div>
    )
  }
}

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
  avatars: PropTypes.object.isRequired,
  dialog: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired
}

export default Messages
