import React, { Component } from 'react'
import ViewTest from './view'
import Error from './error'
import webApi from '../../../../utilities/web-api';
import { header } from './../../../../utilities/page';
import history from '../history';

export default class Test extends Component {
    state = {
        id: null,
        data: null,
        questions: null,
        test_avaible_msg: null,
        history_id: null
    }

    componentWillMount(){

        // window.addEventListener("beforeunload", function (e) {
        //     let confirmationMessage = "\o/";

        //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        //     return confirmationMessage;                            //Webkit, Safari, Chrome
        //   });

        this.props.match.params.id && this.setState({id : this.props.match.params.id})
        webApi.getHistoryTest(this.props.match.params.id).then(data => {
            this.setState({history_id: data.response.id})
        })
        header.pageName('Прохождение теста')

        webApi.getTest(this.props.match.params.id)
        .then(data => data.status === 200 && this.setState({data: data.response}, () => {
            header.newBreadcrumps([{
                title: this.state.data.title,
                route: this.props.match.params.id
            }])

            webApi.getQuestion(this.state.data.id)
            .then(data => {
                if(data.response.error && data.response.error.code === 600) this.setState({test_avaible_msg: 'Время на прохождение теста вышло', data_error: data.response.error.payload})
                if(data.response.error && data.response.error.code === 601) this.state.history_id && history.push(`/history/${this.state.history_id}`)//this.setState({test_avaible_msg: 'Тест не доступен, достигнуто максимальное количество попыток'})
                if(data.response.error && data.response.error.code === 602) this.state.history_id && history.push(`/history/${this.state.history_id}`)//this.setState({test_avaible_msg: 'Тест был успешно сдан'})
                if(data.response.error && data.response.error.code === 603) this.setState({test_avaible_msg: 'На данный момент вы не можете пройти тест', data_error: data.response.error.payload})
                data.status === 200 && this.setState({questions: data.response})
            })
            .catch(error => console.log(error))
        }))
        .catch(error => console.log(error))
    }

    render() {
        let { id, questions, test_avaible_msg, data_error } = this.state
        //console.log(this.state, 1111111111111111111111111)
        return (
            <div>
            {test_avaible_msg && <Error error = {test_avaible_msg} data_error = {data_error} id_test={this.props.match.params.id}/>}
            {(id, questions) && <ViewTest  time_to_pass = {this.state.data.time_to_pass} id_test = {id} questions = {questions}/>}
            </div>
        )
    }
}
