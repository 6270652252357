import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import history from '../../../history';
//import history from '../../../history';
import Tabs from '../../../../../elements/tabs/view'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';

export default class ViewLectureTable extends Component {
  state = {
    lecture: null,
    editorState: false,
    orders: null,
    loaderMaterial: false,
    loaderLast: false,
    limit: 5,
    offset: 0
  }

  componentWillMount(){
    let { limit, offset} = this.state
    this.setState({loaderMaterial: true}, () => {
      webApi.getLecture(this.props.match.params.id, limit, offset)
      .then(data => {
        if(data.status === 200){
          this.setState({
            lecture: data.response,
            loaderMaterial: false,
            offset: this.state.offset + limit,
          },() => {
          let {lecture} = this.state
          this.setState({orders: lecture.sort(({ order: ordA }, { order: ordB }) => ordA - ordB).map(e => {
            return {id: e.id, order: e.order}
          })})
        })}else {this.setState({loaderMaterial: false})}
      })
      .catch(console.error)
    })
  }

  loadMaterial = () =>{
    let { limit, offset, lecture } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getLecture(this.props.match.params.id, limit, offset)
      .then(data => {
        if(data.status === 200){
          this.setState({
            lecture: [...lecture, ...data.response],
            offset: this.state.offset + limit,
            loaderMaterial: false
          })
        }else this.setState({loaderLast: true, loaderMaterial: false})
      })
    })
  }

  handleTable = (e,id) => {
    let {editorState} = this.state
    if(!editorState){
      if(e.target.className === 'fas fa-vial'){
        history.push(`/create-test/lecture/${e.target.dataset.materialId}/${e.target.dataset.testId}`)
      }else if(e.target.className === 'far fa-arrow-down' || e.target.className === 'far fa-arrow-up'){
        return true
      } else history.push('/edit-lecture/' + id)
      //header.addBreadcrumps({title:e.target.parentNode.dataset.title, route:'/lecture'+'/' + e.target.parentNode.dataset.id})
    }
  }

  async swapIndex(current, next) {
    // const { status, response } = await webApi.updateLectureOrder([
    //   { id: current.id, order: next.order },
    //   { id: next.id, order: current.order }
    // ])
    // if (status === 200) {
    //   this.setState({
    //     lecture: this.state.lecture
    //     .map(program => {
    //       const found = response.find(({ id }) => id === program.id)
    //       if (found) program.order = found.order
    //       return program
    //     })
    //   })
    // }

    let lecture = this.state.lecture.filter(e => e !== current && e !== next)
    lecture = [...lecture, {...current, order: next.order}, {...next, order: current.order}].sort(({order: a}, {order: b}) => a - b)
    this.setState({lecture})

    let orders = this.state.orders.filter(e => e.id !== current.id && e.id !== next.id)
    orders = [...orders, { id: current.id, order: next.order }, { id: next.id, order: current.order }].sort(({order: a}, {order: b}) => a - b)
    this.setState({orders})
  }

  saveOrder = () => {
    webApi.updateLectureOrder(this.state.orders).then(data => data.status === 200 && header.getSnackbar('Порядок успешно сохранен'))
    this.setState({editorState: false})
  }

  renderTable = (data, active) => {
    const lectures = data
      .filter(e => e.active === active)
      .sort(({ order: ordA }, { order: ordB }) => ordA - ordB)

      return (<div className="table fix-table-lectures">
        <div className="table-header">
            <div className="table-column">
              {!this.state.editorState ? <Tooltip onClick={() => this.setState({editorState: true})} title="Отсортировать материал" aria-label="Отсортировать материал">
                <i className="fas fa-sort sort-icon"></i>
              </Tooltip> :
              <Tooltip onClick={() => this.saveOrder()} title="Сохранить" aria-label="Сохранить">
                <i className="fas fa-save sort-icon"></i>
              </Tooltip>
              }
              ID</div>
            <div className="table-column">Наименование материала</div>
            <div className="table-column">Количество участников</div>
            <div className="table-column">Завершило успешно</div>
            <div className="table-column">Тест</div>
            <div className="table-column">Состояние</div>
      </div>
      {lectures.map((program, i) =>{
      return(
      <div key={i}>
        <div onClick={(e) => this.handleTable(e,program.id)} /*data-id={program.id}*/ data-id={program.title} className="table-line">
          <div className="table-column table-column-course">{program.id}
          {this.state.editorState && program && lectures[i-1] &&
            <span onClick={() => this.swapIndex(program, lectures[i-1])} className="editMaterialLine orderUp">
              <Tooltip title="Поднять позицию" aria-label="Поднять позицию">
                <i data-id={program.id} className="far fa-chevron-square-up"/>
              </Tooltip>
            </span>
          }
          {this.state.editorState && program && lectures[i+1] &&
            <span onClick={() => this.swapIndex(program, lectures[i+1])} className="orderDown">
              <Tooltip title="Опустить позицию" aria-label="Опустить позицию">
                <i data-id={program.id} className="far fa-chevron-square-down"/>
              </Tooltip>
            </span>
          }
          </div>
          <div className="table-column table-column-course">{program.title}</div>
          <div className="table-column table-column-course">-</div>
          <div className="table-column table-column-course">-</div>
          <div className="table-column">{program.test ? <i className="far fa-check-circle have-test"></i> : <i className="far fa-times-circle dont-test"></i>}</div>
          <div className="table-column table-column-course">{active ? 'Активный' : 'Неактивный'}
          {!this.state.editorState &&
            <div data-id={program.id} data-title={program.title}>
              <span className="editMaterialLine"><Tooltip title="Редактировать" aria-label="Редактировать"><i className="far fa-edit"></i></Tooltip></span>
              <span className="editMaterialLine editMaterialTest"><Tooltip title="Тест" aria-label="Тест"><i data-material-id={program.id} data-test-id={program.test ? program.test.id : 'new'} className="fas fa-vial"></i></Tooltip></span>
            </div>
          }
          </div>
        </div>
      </div>)
    })}</div>)
  }

  render() {
    let { lecture } = this.state
    let tabs

    if(lecture) {
      tabs = [
      {
          title: 'Активные',
          component: this.renderTable(lecture, true)
      },
      {
          title: 'Неактивные',
          component: this.renderTable(lecture, false)
      }
    ]}else tabs = []

    return (
    <div>
        { lecture ?
        <div>
          <Tabs tabs={tabs}/>
          <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterial}>
            {this.state.loaderLast ? 'материалов больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
          </button>
        </div> :
        <div className="pd-25">Ни одного материала не создано <span className="href" onClick={()=>history.push('/create-lecture/'+this.props.match.params.id)}>создать</span></div>}
    </div>
    )
  }
}
