import React, { Component } from 'react'
import ViewObjectTab from './view'
import CreateObject from './create'
import { connect } from 'react-redux';
import webApi from '../../../../../../utilities/web-api';
import Moment from 'react-moment';
import EditObject from './edit'

class ObjectTab extends Component {
    state = {
        page:'view',
        company: null,
        depart: [],
        id_object: null
    }

    handleObject = id =>{
        this.setState({
            id_object: id,
            page: 'edit'
        })
    }

    handlePage = page => {
        this.setState({page})
    }

    componentWillMount(){
        webApi.getDepartment()
        .then(data => {
            if(data.status === 200) {


                data.response.forEach((el) => {
                    let filial_new = []
                    webApi.getCompanyId(el.company_id).then(data => {
                      //let company
                      el.company = data.response[0].title
                      el.active ? el.active = "Активный":  el.active = "Отключен"
                      el.created_at = <Moment format="DD.MM.YYYY/hh:mm">{el.created_at}</Moment>
                      filial_new.push(el)
                      this.setState({depart: filial_new})

                    })
                })
            }
        })
        .catch(error => console.log(error))
        this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => console.log(this.state.company)))
    }

    render() {
        return (
            <div>
                {this.state.page === 'view' && <ViewObjectTab depart ={this.state.depart} handleObject={this.handleObject} handlePage = { this.handlePage }/>}
                {this.state.page === 'create' && <CreateObject company_id={this.props.company} company={this.state.company} handlePage = { this.handlePage }/>}
                {this.state.page === 'edit' && <EditObject id_object={this.state.id_object}  handlePage={this.handlePage}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        company: state.user.user_data.company_id
    }
}

export default connect(mapStateToProps)(ObjectTab)

