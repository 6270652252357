import { header } from "./page";
import Disk from '../components/client/right-panel/disk-space/index'
import React from 'react';

/*
result - (collback)
object - (object) {
    
}
*/

function getDiskSpace(result, object = {type: true, fullUrl: true, fileName: false, size: false}){   
    let obj = {}
   
    let proxied = new Proxy(obj, {
        set:    function(target, prop, value) {
                if(value){
                    let newObject = value
                    
                    !object.size && delete newObject.size

                    if(object && !object.type){
                        delete newObject.type
                        if(object.fullUrl){
                            result(newObject) 
                        }else{
                            newObject.original ? result(newObject.original) : result(newObject.url)
                        }
                    }else if(object && object.fileName){
                        newObject.original ?
                        result({
                                ...newObject,
                                nameFile: newObject.original.split('/')[newObject.original.split('/').length - 1]
                            }):
                        result({...newObject, nameFile: newObject.url.split('/')[newObject.url.split('/').length - 1]})
                    } else {
                        newObject.original ?
                        result({
                                type: newObject.type,
                                url: newObject.original
                            }):
                        result(newObject)
                    }
                }

                return Reflect.set(target, prop, value);
        }
    })

    header.getModalPage({
        title: 'Мои файлы',
        content: <Disk propFile={proxied} />
    })
    
    return result
}

export default getDiskSpace
