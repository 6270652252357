import isEmpty from 'lodash/isEmpty'
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import getDiskSpace from '../../../../utilities/disk-space';

class ChatControls extends PureComponent {
  state = {
    message: ''
  }

  handleChangeMessage = e => this.setState({ message: e.target.value })

  sendMessage = e => {
    e.preventDefault()
    if (!isEmpty(this.state.message)) {
      this.props.sendMessage(this.state.message, this.props.currentDialogId)
      this.setState({ message: '' })
    }
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.sendMessage(e)
    }
  }

  handleDiskSpace = () => {
    getDiskSpace(({ url, type, nameFile, original, preview }) => {
      this.props.sendFile({
        dialogId: this.props.currentDialogId,
        fileName: nameFile,
        url: url || original,
        type,
        preview
      })
      this.setState({ message: '' })
    },{
      type: true,
      fileName: true,
      fullUrl: true
    })
  }

  render() {
    return (
      <form onSubmit={this.sendMessage} className='chat-controls-container'>
        <div className="hr"/>
        <div className="relative">
          <textarea
            className='chat-controls-message'
            onChange={this.handleChangeMessage}
            value={this.state.message}
            placeholder="Введите Сообщение"
            onKeyPress={this.handleKeyPress}
          />
          <i onClick={this.handleDiskSpace} className="fal fa-paperclip add-file-chat"/>
        </div>
        <div className="chat-controls-submit-wrap">
          <button
            className='chat-controls-submit btnSaveProfile'
            onClick={this.sendMessage}
            disabled={this.state.message.length < 1}
          >Отправить сообщение</button>
        </div>
      </form>
    )
  }
}

ChatControls.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  sendFile: PropTypes.func.isRequired,
  currentDialogId: PropTypes.string.isRequired
}

export default ChatControls
