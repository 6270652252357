import React, { Component } from 'react'

export default class ViewAwardTab extends Component {

    state ={
        select: null
    }

    handleSelect = e => {
    }

    render() {

        let {award} = this.props
        return (
            <div>
                <div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>
                    Добавить <i className="fas fa-plus"></i>
                </div>
                <div className="clear"><br/></div>
                    {award ? award.sort((a, b) => (a.active < b.active) ? 1 : -1).map((el,i) => {
                        return (
                            <div className="award-block cur-p" onClick={() => this.props.handleAward(el.id)} data-id={el.id} key={i}>
                                <div className="award-img">
                                    <img src={el.images && el.images.original} alt={el.title}/>
                                </div>
                                <div className="award-desc-block">
                                    <div className="award-title">{el.title} <span className="fl-r">{el.active ? <span className="font-green">Активное</span> : <span className="font-red">Неактивное</span>}</span></div>
                                    {el.description}
                                </div>
                                <div className="clear"></div>
                            </div>
                )
            }) : <div>Ни одного достижения не создано</div>}
            <button className="loadMaterialBtn" onClick={!this.props.loaderLast && this.props.loadMaterial}>
                {this.props.loaderLast ? 'материалов больше нет' : this.props.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
            </button>
        </div>
        )
    }
}
