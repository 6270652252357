import React, { Component } from 'react'
import ViewCreateCourse from './view';
import { header } from './../../../../../utilities/page';
import webApi from '../../../../../utilities/web-api';
import { showSnackbar } from './../../../../../store/actions/elements/action';
import { connect } from 'react-redux'
import history from '../../history';

class CreateCourse extends Component {
    state = {
        programName: null
    }

    componentWillMount(){
        header.pageName('Новый курс')
        //console.log(this.props.match)
        webApi.getProgram(this.props.match.params.id)
        .then(data => this.setState({programName: data.response[0].title}))
        .catch(console.error)

        webApi.getUpload()
        .then(console.log)
    }

    uploadCover = e => {
        this.setState({images:e})
    }

    setCourse = (body) =>{
        let { title, estimation, description, images, after_test_id } = body
        
        //
        estimation = parseInt(estimation) * 60

        if(estimation && estimation > 8100000) return header.getSnackbar('Рекомендуемое время не может приувеличивать 135000 мин. (3 месяца)')

        if(!description) return header.getSnackbar('Ошибка: "Краткое описание курса" не заполнено')

        if(!title) return this.props.showSnackbar(`Ошибка: "Наименование курса" не заполнено`)
        else if(!estimation) return this.props.showSnackbar(`Ошибка: "Рекомендуемое время на прохождение" не заполнено`)
        else{
            let obj = {
                title,
                description,
                estimation,
                images,
                program_id: this.props.match.params.id,
                after_test_id: after_test_id.id || null
            }
            
            if(!obj.after_test_id) delete obj.after_test_id

            webApi.setCourse(obj)
            .then(data => {
                if(data.status === 200){
                    this.props.showSnackbar(`Новый курс "${data.title}" создан`)
                    return history.push('/course/'+this.props.match.params.id)
                }
                if(data.status === 400){
                    this.props.showSnackbar(`Произошла ошибка`)
                }
            })
            .catch(console.error)
        }
    }

    render() {
        return (
        <ViewCreateCourse uploadCover={this.uploadCover} setCourse = { this.setCourse } programName = { (this.state.programName || '') } match={this.props.match}/>
        )
    }
}

const mapDispatchToProps = { showSnackbar }

export default connect(null, mapDispatchToProps)(CreateCourse)
