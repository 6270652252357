import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import webApi from '../../../../../utilities/web-api'
import { header } from './../../../../../utilities/page';
import {AsyncPaginate} from "react-select-async-paginate";

class FilterTable extends Component {
  state = {
    type: 'program',
    value: {}
  }

  generateSearchCallback(type) {
    switch (type) {
      case 'course':
        return this.props.search(webApi.inclusiveSearchCourses, 'Такой курс не найден.')
      case 'program':
        return this.props.search(webApi.inclusiveSearchPrograms, 'Такой программы не найдено.')
      default:
        throw new Error('Wrong type passed.')
    }
  }

  onChange = selected => {
    this.setState({ value: selected })
    this.props.update(selected.value, this.state.type)
  }

  updateType = (_, type) => {
    if (type) {
      this.setState({ type, value: {} })
      this.props.update(null, type)
    }
  }

  render() {
    console.log(this.state.value.length)
    return (
      <div className="block-content"> <br/><br/>
        <div className="href cur-p">
        <span className="href" onClick={() => header.historyBack()}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
        </div>
        <h3>Тип материала</h3>
        <ToggleButtonGroup className="top-task-material-btns" value={this.state.type} exclusive onChange={this.updateType}>
          <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="program">Программа</ToggleButton>
          <ToggleButton classes={{selected: 'top-task-material-btns-active'}} value="course">Курс</ToggleButton>
        </ToggleButtonGroup> <br/>
        <h3>Наименование материала</h3>
        <AsyncPaginate
          loadOptions={this.generateSearchCallback(this.state.type)}
          onChange={this.onChange}
         // value={this.state.value.length > 0 ? this.state.value : ''}
          noOptionsMessage = {() => '...'}
          placeholder="Начните ввод для поиска материала"
        />
      </div>
    )
  }
}

FilterTable.propTypes = {
  update: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
}

export default FilterTable
