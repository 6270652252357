import React, { Component } from 'react'
import Scheduler from '../scheduler';

export default class UserTasks extends Component {
  render() {
    return (
      <div className="user-profile-container  mgt-50">
        {this.props.user && this.props.user.id && <Scheduler forProfile={true} id = {this.props.user.id}/>}
      </div>
    )
  }
}
