import React, { Component } from 'react'
import ViewHelper from './view'

export default class Helper extends Component {
    render() {
        return (
            <ViewHelper/>
        )
    }
}
