import React, { Component } from 'react'
import ViewCategoryTab from './view'
import Create from './create'
import EditCategory from './edit'

export default class CategoryTab extends Component {
    state = {
        page:'view',
        id_category: null,
        position: {}
    }

    handlePage = page => {
        this.setState({page})
    }

    handleCategory = (id, title, description) =>{
        this.setState({
            position: {id, title, description},
            page: 'edit'
        })
    }

    render() {
        return (
            <div>
                {this.state.page === 'view' && <ViewCategoryTab handlePage = { this.handlePage } handleCategory = {this.handleCategory} />}
                {this.state.page === 'create' && <Create handlePage = { this.handlePage } />}
                {this.state.page === 'edit' && <EditCategory position={this.state.position}  handlePage={this.handlePage}/>}
            </div>
            // this.state.page === 'view' ? <ViewCategoryTab handlePage = { this.handlePage } /> : <Create handlePage = { this.handlePage } />
        )
    }
}
