import React, { Component } from 'react'
import ViewCreateLecture from './view';
import { header } from './../../../../../utilities/page';
import history from '../../history';
import webApi from '../../../../../utilities/web-api';
import { showSnackbar } from './../../../../../store/actions/elements/action';
import { connect } from 'react-redux'

class CreateLecture extends Component {
    state ={course: null}
    componentWillMount(){
        header.pageName('Новая лекция')
        webApi.getCourse(this.props.match.params.id).then(data => this.setState({course: data.response.title}))
    }

    setLecture = (body, html_body) =>{
        let { title, description, images, estimation, additionMaterial, type, video_url, after_test_id } = body

        let obj = {
            images,
            title,
            description,
            additional_materials : additionMaterial,
            estimation,
            type,
            video_url,
            html_content: html_body,
            course_id: this.props.match.params.id,
            after_test_id: after_test_id.id || null
        }

        if(!obj.after_test_id) delete obj.after_test_id

        if(!obj.description) return header.getSnackbar('Ошибка: "Краткое описание лекции" не заполнено')

        if(!obj.estimation) return header.getSnackbar('Ошибка: "Продолжительность лекции" не заполнено')
        if(!obj.html_content && type !== 'video') return header.getSnackbar('Ошибка: Материал лекции не может быть пустым')

        !obj.video_url && delete obj.video_url
        if(obj.estimation) obj.estimation = obj.estimation * 60

        !obj.additionMaterial && delete obj.additionMaterial
        obj.additional_materials && obj.additional_materials.map((el, i) => {
            if(!el.title){
                obj.additional_materials.splice(i, 1)
            }
            //return true
        })
        !obj.images && delete obj.images
        !obj.estimation && delete obj.estimation

        if(obj.estimation && obj.estimation > 8100000) return header.getSnackbar('Рекомендуемое время не может приувеличивать 135000 мин. (3 месяца)')

        if(!title) return this.props.showSnackbar(`Ошибка: "Наименование лекции" не заполнено`)
        //else if(!estimation) return this.props.showSnackbar(`Ошибка: "Рекомендуемое время на прохождение" не заполнено`)
        else{
           webApi.setLecture(obj)
            .then(data => {
                if(data.status === 200){
                    this.props.showSnackbar(`Новая лекция "${data.title}" создана`)
                    history.goBack()

                }
                if(data.status === 400){
                    this.props.showSnackbar(`Произошла ошибка`)
                }
            })
            .catch(console.error)
        }
    }

    render() {
        return (
        <ViewCreateLecture match={this.props.match} course={this.state.course} id_match={this.props.match} setLecture = {this.setLecture}/>
        )
    }
}

const mapDispatchToProps = { showSnackbar }

export default connect(null, mapDispatchToProps)(CreateLecture)
