import React, { Component } from 'react'
import ViewCourses from './view';
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';
import BlockEducation from './../../contents/block-education';
import BlockTest from './../../contents/block-test';
import history from '../../history';
//import get from 'lodash/get'


export default class Course extends Component {
    state = {
      materials: null,
      program: null,
      loaderMaterial: false,
      loaderLast: false,
      limit: 5,
      offset: 0
    }
    componentWillMount(){
      let { limit, offset} = this.state

      header.pageName('Курсы')

      this.setState({loaderMaterial: true}, () => {
        webApi.getCourses(this.props.match.params.id, limit, offset)
        .then(data => {
          if(data.status === 200){
            this.setState({
              materials: data.response,
              loaderMaterial: false,
              //offset: this.state.offset + limit,
            })
          }else {this.setState({loaderMaterial: false})}
        })
        .catch(error => error)
        
        webApi.getProgram(this.props.match.params.id).then(data => {
          if(data.status === 200) (data.response[0].test && data.response[0].test.published) && this.setState({program: data.response[0].test})
        })
      })
    }

    handlePush = (e, index) =>{
      if (index.after_test_id && !index.after_test) {
        webApi.getTest(index.after_test_id)
        .then(data => {
          data.status === 200 && header.getSnackbar(`Чтобы перейти к обучению материала, пройдите тест "${data.response.title}"`)
        })
      } else {
        header.addBreadcrumps({title:e.currentTarget.dataset.title, route: '/courses/'+e.currentTarget.dataset.route})
        history.push('/lectures/'+e.currentTarget.dataset.route)
      }
    }

    handlePushTest = (index) => {
      if(!index.test.after_test) {
        webApi.getTest(index.test.after_test_id)
        .then(data => {
          data.status === 200 && header.getSnackbar(`Чтобы перейти к тесту пройдите тест "${data.response.title}"`)
        })
      } else {
        history.push('/test/'+index.test.id)
      }
    }

    renderMaterial = () => {
        let { materials } = this.state
        if(materials){
          //.filter(e => e.active === true)
          return materials.sort(({ order: ordA }, { order: ordB }) => ordA - ordB).map(index => {
            return(<div className="cur-p" key={ index.id }>
                    <div data-title={index.title} data-route={index.id} onClick={e => this.handlePush(e, index)}><BlockEducation body = {index}/></div>
                    {index.test && index.test.type !== "course_final" && index.test.published && <div onClick={() => this.handlePushTest(index)}><BlockTest title={index.test.title} time_to_pass={index.test.time_to_pass} questions_amount={index.test.questions_amount} min_score_points={index.test.min_score_points} /></div>}
                  </div>)
          })
        }
        else {
          return (<div><br/>На данный момент материалов для обучения нет</div>)
        }   
    }

    loadMaterial = () =>{
      let { limit, offset, materials } = this.state
  
      this.setState({loaderMaterial: true}, () => {
        webApi.getCourses(this.props.match.params.id, limit, offset + limit)
        .then(data => {
          if(data.status === 200){
            this.setState({
              materials: [...materials, ...data.response],
              offset: this.state.offset + limit,
              loaderMaterial: false
            })
          }else this.setState({loaderLast: true, loaderMaterial: false})
        })
      })
    }

    render() {
        return (
            <ViewCourses
            tests = {this.state.program}
            blockEducation = { this.renderMaterial() }
            loadMaterial = {this.loadMaterial}
            loaderLast = {this.state.loaderLast}
            loaderMaterial = {this.state.loaderMaterial}
            materials={this.state.materials}
          />
        )
    }
}
