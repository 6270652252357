import React, { Component } from 'react'
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';
import Select from 'react-select';

export default class CreateFree extends Component {
    state = {
        text: null,
        require: false,
        question:false,
        question_id: null,
        correct: true,
        answer: false,
        helper: null,
        type: "free",
        points: null,
        resultMaterial: [],
        selectInputMaterial:[]
    }

    componentWillMount(){
    }

    handleInput = e => this.setState({[e.target.name]: e.target.value})

    handleSaveQuestion = () => {
        let { helper, require, text, type, points } = this.state

        let obj = {
            helper,
            required: require,
            text,
            type,
            points,
            test_id: this.props.options.test_id,
            lecture_id: this.state.selectInputMaterial.id
        }

        !obj.lecture_id || !obj.helper && delete obj.lecture_id
        !obj.helper && delete obj.helper
        if(obj.points < 1) obj.points = 1
        if(obj.points < 1) return header.getSnackbar('Стоимость вопроса не может быть меньше одного балла')

        webApi.setQuestion(obj).then(data => {
            if(data.status === 200){
                header.getSnackbar("Вопрос успешно добавлен к тесту")
                this.setState({
                    question: true,
                    question_id: data.response.id
                }, () => this.props.handleDisabledSelect(this.state.question))
            }else{
                header.getSnackbar("Произошла ошибка в добавлении вопроса")
            }
        })
        .catch(error => console.log(error))

    }

    handleRequire = () => {
        this.setState({
            require: !this.state.require
        }, () => console.log(this.state))
    }

    handleSaveAnswer = () => {
        console.log(this.state.question_id)
        webApi.setAnswer({
            question_id: this.state.question_id,
            body: this.state.body,
            correct: true
        })
        .then(data => {
            if(data.status === 200 || data.status === 201){
                header.getSnackbar("Ответ успешно добавлен к вопросу")

                this.setState({
                    text: null,
                    require: false,
                    question:false,
                    question_id: null,
                    correct: true,
                    answer: false}, () => {
                        this.props.rerender()
                        document.getElementById("text-questiob-free").value=""
                        this.props.refreshQuestions()
                    })
            }else{
                header.getSnackbar("Произошла ошибка к добавлению ответа")
            }
        })
    }

    difficultySelect = (e) => this.setState({points: e.points})

    searchLecture = val => {
        if(val.length){
            webApi.getLectureLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    this.setState({resultMaterial: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({resultMaterial: []})
                }
            })
        }
    }

    changeSelectLecture = e =>{
        this.setState({selectInputMaterial: e})
    }

    render() {
        let {scoreType, test_difficulty} = this.props

        return (
            <div className="free-type-question">
            Текст вопроса <br/>
            <div className="request-block-test">
                <input type="checkbox" disabled={this.state.question} onClick={this.handleRequire} name="require" className="input-checkbox fl-left"/> Обязательный вопрос
            </div>
            <input type="text" id="text-questiob-free" name="text" onChange={this.handleInput} placeholder="Напишите текст вопроса" disabled={this.state.question}/>
            <br/><br/>
            Подсказка
            <input type="text" name="helper" onChange={this.handleInput} placeholder="Подсказка для вопроса" disabled={this.state.question}/>
            <br/><br/>
            {this.state.helper &&
            <div>
            <i className="far fa-external-link"></i> <span>Привязать к подсказке ссылку на лекцию</span>
            <Select
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])}
                    value = {this.state.selectInputMaterial}
                    options = {this.state.resultMaterial}
                    onChange = {this.changeSelectLecture}
                    noOptionsMessage = {() => '...'}
                    onInputChange={this.searchLecture}
                    placeholder={`Введите название лекции`}
                    isDisabled={this.state.question}
            /><br/></div>}

            {scoreType && scoreType !== 'correct_answers' && <div>Количество баллов
            {/* <input type="text" name="points" onChange={this.handleInput} placeholder="Количество баллов за ответ на вопрос" disabled={this.state.question}/> */}
            {test_difficulty && <Select
                        onChange={this.difficultySelect}
                        getOptionValue={(option) => (option['level'])}
                        getOptionLabel={(option) => (option['points'])}
                        options = {test_difficulty}
                        defaultValue={this.state.defCompany}
                        placeholder="Выберите количество баллов для вопроса"
                        isDisabled={this.state.question}
            />}
            <br/><br/></div>}

            {this.state.question && (
                    <div>
                        Текст oтвета <br/>
                        <input type="text" name="body" onChange={this.handleInput} placeholder="Напишите текст ответа" disabled={this.state.answer}/> <br/><br/>
                    </div>
            )}

            {!this.state.answer && <div className="btnSaveProfile fl-left" onClick={!this.state.question ? this.handleSaveQuestion : this.handleSaveAnswer}>Сохранить</div>}
            {this.state.answer && <div className="btnAlert fl-left">Удалить вопрос</div>}
            <div className="clear"></div>
            </div>
        )
        }
}
