import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
//import { header } from './../../../../utilities/page';
import getDiskSpace from './../../../../utilities/disk-space';

export default class InsertImage extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add( 'insertImage', locale => {
            const view = new ButtonView( locale );

            view.set( {
                label: 'Вставить изображение',
                icon: imageIcon,
                tooltip: true,
            } );

            // Callback executed once the image is clicked.
            view.on( 'execute', () => {
                getDiskSpace(e => {
                    let imageUrl = e.url

                    editor.model.change( writer => {
                        const imageElement = writer.createElement( 'image', {
                            src: imageUrl,
                            class: 'image-flex-container'
                        } );

                        const figure = writer.createElement( 'div', { class: 'image-flex-container' } );
    
                        // Insert the image in the current selection location.
                        editor.model.insertContent( imageElement, editor.model.document.selection );
                    } );
                })
            } );

            return view;
        } );
    }
}