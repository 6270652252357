import React, { Component } from 'react'

export default class ViewSignUp extends Component {
    render() {
        let { handleLogin, sendLogin } = this.props
        return (
            <form onSubmit={ sendLogin } className="authComponent sign-up-form">
                <div className="form-header">Регистрация</div>
                <div className="form-input-line">
                    <input type="text" onChange = { handleLogin } placeholder="Введите номер телефона или e-mail"/>
                    {/* { (send && !phone) && <div className="warning-alert">Поле не должно быть пустым</div> }
                    { (send && error) && <div className="warning-alert">{error}</div> } */}
                </div>
                <button onClick={ sendLogin }>Продолжить</button>
            </form>
        )
    }
}