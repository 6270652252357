import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, AtomicBlockUtils } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import history from '../../history';
import getDiskSpace from '../../../../../utilities/disk-space';
//import audio from '../../../../../../audio/50cent.mp3'
//import AudioPlayer from './../../../../../utilities/audio-player/index';
import Select from 'react-select';
import { header } from '../../../../../utilities/page';
import webApi from '../../../../../utilities/web-api';
import CkEditor from '../../ckEditor/ckedit'


export default class ViewCreateLecture extends Component {
    state = {
        editorState: null,
        nameLecture: null,
        additionMaterialMemory: [],
        additionMaterial: [],
        trigger: [],
        type: 'mixed',
        video_url: null,
        after_test_id: []
    }

    async componentDidMount(){
        let l = await document.querySelector('.pagin')
        await this.setState({nameLecture: l.innerText.split(' / ')[2]})

    }



    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
    };

    uploadImg = e => {
        this.setState({
            images: e
        })
    }

    typeFile = (type, src) =>{
        switch (type) {
            case 'image': return <img src={src} alt="img"/>
            case 'audio': return <audio src={src} width="90%" controls></audio>
            case 'pdf': return <audio src={src} width="90%" controls></audio>
            case 'video': return <video src={src} width="90%" controls></video>
            default:
                break;
        }
    }

    handleInput = e => {
        if(e.target.name === 'estimation'){
            this.setState({
                estimation: e.target.value.replace(/\D/, '')
            })
        }else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    handleSelect = e =>{
        this.setState({
            type: e.value
        })
    }

    inputMemory = (e, index) => {
        let obj = this.state.additionMaterial
        obj[index].title = e.target.value

        let objTrigger = this.state.trigger
        objTrigger[index].id = `#${e.target.value}`
        objTrigger[index].name = `${e.target.value}`

        this.setState({
            additionMaterial: obj,
            trigger: objTrigger
        })


    }

    delMemory = index =>{
        let obj = this.state.additionMaterial
        obj.splice(index, 1)

        let objTrigger = this.state.trigger
        objTrigger.splice(index, 1)
        this.setState({additionMaterial: obj, trigger: objTrigger})
    }

    addMemory = () => {
        getDiskSpace(result => {
            result.title = result.url.split('-')[1] || '' //result.url.split('/')[result.url.split('/').length - 1]
            this.setState({
                additionMaterial: [
                    ...this.state.additionMaterial,
                    result
                ]
            }, () => {
                //${window.location.origin}/lesson/${this.props.match.params.id}
                let objTrigger = {}
                objTrigger.id = `#${result.url.split('-')[1]}` || ''
                objTrigger.name = result.url.split('-')[1] || ''
                objTrigger.link = `#${result.url}&${result.type}`

                this.setState({
                    trigger: [
                        ...this.state.trigger,
                        objTrigger
                    ]
                }, () => console.log(this.state.trigger))
            })
        })
    }

    renderVideoType = () =>{
        return (
            <div>
                {this.state.video_url &&
                    <video src={this.state.video_url} width="500px" controls></video>
                }
                <br/><br/>
                <div onClick={() => getDiskSpace(result => this.setState({video_url: result.url}, () => console.log(this.state)))} className="btnSaveProfile fl-left">Выбрать файл</div>
            </div>
        )
    }

    handlerHtml = html =>{
        this.setState({editorState: html})
    }

    // changeSelectLecture = e =>{
    //     this.setState({selectInputMaterial: e})
    // }

    availabilityLesson = val => {
        if(val.length){
            webApi.getTestLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    console.log(res)
                    this.setState({after_test_input: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({after_test_input: []})
                }              
            })
        }
    }

    changeSelectAvailability = e =>{
        this.setState({after_test_id: e}, () => console.log(this.state.after_test_id))
    }

    render() {
        let options = [
            {value: 'mixed', label: 'Смешанный'},
            {value: 'video', label: 'Видео-лекция'}
        ]
        //console.log(this.state.trigger)
        return (
        <div className="user-right-content">
            <div className="block-content">
            <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                <div className="w-50 fl-left">
                    Наименование курса <br/>
                    <input type="text" className="inputTraining" value={(this.props.course && this.props.course) || ''} disabled/> <br/><br/>
                    Наименование лекции <br/>
                    <input type="text" onChange={this.handleInput} name="title" className="inputTraining" placeholder="Введите название лекции"/> <br/><br/>
                    Краткое описание лекции <br/>
                    <textarea className="inputTrainingHeight" onChange={this.handleInput} name="description" placeholder="Опишите лекцию"></textarea> <br/><br/>
                    Продолжительность лекции (мин)
                    <input type="text" onChange={this.handleInput} name="estimation" value={this.state.estimation || ''} className="inputTraining" placeholder="Введите продолжительность лекции"/> <br/><br/>
                    Тип лекции<br/>
                    <Select onChange={this.handleSelect} placeholder="Выберите тип лекции" defaultValue={options[0]} options={options} /> <br/>
                    Доступность материала<br/>
                    <Select
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        value = {this.state.after_test_id}
                        options = {this.state.after_test_input}
                        onInputChange={this.availabilityLesson}
                        onChange={this.changeSelectAvailability}
                        placeholder="Выберите тест после которого лекция будет доступна"
                    /> <br/>
                </div>
                <div className="w-50 fl-left center">
                    Обложка (jpg/png) <br/><br/>
                    {this.state.images ? <div><img className="no-img-training img_cover_material" src={this.state.images.normal} alt=""/></div> : <div className="no-img-training">320x240</div>} <br/>
                    <input type="file" className="hide" onChange={this.uploadImg} id="_program-cover"/>
                    <button className="btnSaveProfile" onClick={() => getDiskSpace(result => this.uploadImg(result),{type:false, fullUrl: true})}>Выбрать файл</button>
                </div>
                <div className="clear"></div>
                {/* <br/>
                <AudioPlayer file = { audio }/> */}
                <br/>
                <br/>

                <b>Дополнительные материалы к лекции</b>

                <div className="addition-material-block">
                    <div className="addition-material-header"><i onClick={this.addMemory} className="fal fa-file-plus"></i></div>
                    <div className="addition-material-edit">
                        {this.state.additionMaterial && this.state.additionMaterial.map((el, i) =>{
                            console.log(el)
                            return (
                                <div key={i + "-key"} className="addition-material-add" data-id={i}>
                                    {!el.title && <div className="test-alarm">Для публикации дополнительного материала введите его название</div>}
                                    <div className="addition-material-icon">
                                        <i className={`fal fa-file-${el.type}`}></i>
                                        {el.title && <i className="fal fa-check"></i>}
                                    </div>
                                    <div className="addition-material-file">

                                        <input onChange={e => this.inputMemory(e, i)} defaultValue={el.url.split('-')[1]}  type="text" placeholder="Название материала"/><br/><br/>
                                        {/*this.typeFile(el.type, el.url)*/}
                                        <b>{el.url && el.url.split('/')[el.url.split('/').length - 1]}</b><br/><br/>
                                        <i className="font-red" onClick={() => this.delMemory(i)}>Удалить</i>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                { this.state.type === 'mixed' ? <div>
                <b>Материал лекции</b> <br/><br/>
                {/* <Editor
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbarCustomButtons={[<CustomOption />]}
                    mention={{
                        separator: ' ',
                        trigger: '#',
                        suggestions: this.state.trigger,
                      }}
                /> */}

                <CkEditor handler = { this.handlerHtml } trigger={this.state.trigger} />
                <br/>
                <div className="help-wysiwyg">
                      # - привязка ссылки к материалу
                </div>
                </div> : this.renderVideoType()
                }
                {/* <textarea
                    disabled
                    value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}
                /> */}

                <br/>
                <div className="clear"></div>
                <button onClick = { () => this.props.setLecture(this.state, this.state.editorState)} className="btnSaveProfile fl-right">Сохранить</button>
                <br/>
                <br/>
            </div>
        </div>
        )
    }
}
