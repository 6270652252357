import React, { Component } from 'react'
import ViewUsersTab from './view'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';
import CreateUser from './create'
import EditUser from './edit'
import { connect } from 'react-redux';

class UsersTab extends Component {
    state = {
        profiles: [],
        page:'view',
        company: null,
        id_user:null
    }

    handlePage = page => {
        this.setState({page})
    }

    componentWillMount(){
        webApi.getProfiles()
        .then(data=> data.status === 403 ? header.getSnackbar('У вас нет прав для чтения каталога') : this.setState({profiles: data.response}, () => {
            this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => console.log(this.state.company)))
        }))
        .catch(error => console.log(error))
    }

    handleUserId = id =>{
        this.setState({
            id_user: id,
            page: 'edit'
        }, () => console.log(this.state))
    }

    render() {
        let { profiles, id_user } = this.state

        return (
            <div>
                {this.state.page === 'view' && <ViewUsersTab company ={this.props.company} handleUserId={this.handleUserId} handlePage={this.handlePage} profiles = { profiles }/>}
                {this.state.page === 'create' && <CreateUser company={this.state.company} handlePage={this.handlePage}/>}
                {this.state.page === 'edit' && <EditUser id_user={id_user} company={this.state.company} handlePage={this.handlePage}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        company: state.user.user_data.company_id
    }
}
export default connect(mapStateToProps)(UsersTab)
