import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import webApi from '../../../../../../utilities/web-api';
import Moment from 'react-moment';

export default class ViewObjectTab extends Component {
    state={
        filial: null,
        department: null,
        limit: 10,
        offset: 0,
        loaderMaterial: false,
        loaderLast: false,
    }

    componentWillMount(){
      let {limit, offset} = this.state

      this.setState({loaderMaterial: true}, () => {
        webApi.getFilial(null, limit,offset)
        .then(data => {
            if(data.status === 200) {
              this.setState({
                loaderMaterial: false,
                offset: this.state.offset + limit,
                loaderMaterial: false
              },() =>{
                let filial_new = []

                data.response.forEach(async (el) => {
                    await webApi.getCompanyId(el.company_id).then(data => el.company = data.response[0].title)
                    await el.active ? el.active = "Активный":  el.active = "Отключен"
                    el.created_at = await <Moment format="DD.MM.YYYY/hh:mm">{el.created_at}</Moment>
                    await filial_new.push(el)
                    await this.setState({filial: filial_new})
                })
                })
            }
        })
        .catch(error => console.log(error))
      })
    }

    loadMaterial = () =>{
      let { limit, offset } = this.state
  
      this.setState({loaderMaterial: true}, () => {
        webApi.getFilial(null, limit,offset)
        .then(data => {
            if(data.status === 200) {
              this.setState({
                loaderMaterial: false,
                offset: this.state.offset + limit,
                loaderMaterial: false
              },() =>{
                let filial_new = this.state.filial

                data.response.forEach(async el => {
                    await webApi.getCompanyId(el.company_id).then(data => el.company = data.response[0].title)
                    await el.active ? el.active = "Активный":  el.active = "Отключен"
                    el.created_at = await <Moment format="DD.MM.YYYY/hh:mm">{el.created_at}</Moment>
                    await filial_new.push(el)
                    await this.setState({filial: filial_new})
                })
            })
          }else this.setState({loaderLast: true, loaderMaterial: false})
        })
        .catch(error => console.log(error))
      })
    }
    
    render() {
          return (
          <div>
          <div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>Добавить <i className="fas fa-plus"></i></div> <div className="clear"><br/></div>
          {this.state.filial ?
          <div className="table fix-table-programms">
            <div className="table-header">
                <div className="table-column">ID</div>
                <div className="table-column">Компания</div>
                <div className="table-column">Объект</div>
                <div className="table-column">Дата создания</div>
                <div className="table-column">Состояние</div>
            </div>
            {this.state.filial && this.state.filial.map((program, i) =>{
            return(
            <div data-column={i+1} key={i}>
              <div className="table-line" onClick={() => this.props.handleObject(program.id)}>
                <div className="table-column">{program.id}</div>
                <div className="table-column">{program.company}</div>
                <div className="table-column">{program.title}</div>
                <div className="table-column">{program.created_at}</div>
                <div className="table-column">{program.active}</div>
              </div>
            </div>)
          })}</div> : <span>Ни одного объекта не создано</span>}

          <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterial}>
            {this.state.loaderLast ? 'материалов больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
          </button>
          </div>)

    }
}
