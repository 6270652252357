import React, { Component } from 'react'
import moment from 'moment';

export default class TestStat extends Component {

    state = {
        timeStamp: 168,
        timeStampRun: 0
    }

    componentWillMount(){
        if(this.props.questions){
                this.setState({time: this.timeNext(this.props.questions.created_at)})
        }
    }

    componentDidMount(){
        if(this.props.questions){
            let intervalTime = setInterval(() => {
                this.setState({time: this.timeNext(this.props.questions.created_at)}, () => {
                    // if(this.state.time === '00:00:00'){
                        // clearInterval(intervalTime)
                    // }

                    //let date = moment(this.props.questions.created_at).valueOf()
                    //let now = moment().valueOf()
                    //let result = moment(now).diff(date)
                    //let resultTime = moment(result).utc().format('HH:mm:ss')
                    //let timeStamp
                    //this.props.time_to_pass && (timeStamp = moment().startOf('day').seconds(this.props.time_to_pass).format('HH:mm:ss'))

                    //console.log(this.state.time+10, timeStamp)
                    //let newTime = 
                    if (this.state.time >= this.props.time_to_pass) {
                        //console.log('FINISH-TIME')
                        if(this.props.id_test){
                            clearInterval(intervalTime)
                            this.props.finishTest()
                        }
                    }
                })
            }, 1000)
        }

    }

    timeNext = time => {
        
        let fAt = new Date(time).getTime(); ///moment(time).valueOf()
        let fNow = Date.now() //moment().valueOf()
        //let fFinish = moment().valueOf()
        let result = moment(fNow).diff(fAt)
        let resultTime = moment(result).utc().format('HH:mm:ss')//setInterval(() => {moment(result).utc().format('HH:mm:ss')}, 1000)
        
        return Math.round(result/1000) ;
    }

    
    
    render() {
        // if(this.props.questions){
            
        // }
        //this.props.questions && console.log(this.timeNext(this.props.questions.created_at))
        let timeStamp
        this.props.time_to_pass && (timeStamp = moment().startOf('day').seconds(this.props.time_to_pass).format('HH:mm:ss'))
        //let timeStampRun = moment().startOf('day').seconds(this.state.timeStampRun).format('HH:mm:ss')
        const { type, q_length, q_num, points, min_points, result } = this.props
        
        return (
        <div className="test-stat">
            <div className="test-timer">
                <i className="fas fa-stopwatch"></i> <span id="test-timer">{!result ? moment().startOf('day').seconds(this.state.time).format('HH:mm:ss') +'/' + timeStamp : 'dfgdf'}</span>
            </div>
            <div className="test-quest">
                <i className="fas fa-tasks"></i> { type !== 'finish' ? 'вопрос '+q_num+'/'+q_length : `${ points && points}/${min_points && min_points.min_score_points} - тест ${result ? 'сдан' : 'не сдан'}`}
                </div>
        </div>
        )
    }
}

