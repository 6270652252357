import React, { Component } from 'react'
import BlockTest from './../../contents/block-test';
import history from '../../history';
import { header } from './../../../../../utilities/page';
import webApi from '../../../../../utilities/web-api';

export default class ViewLectures extends Component {

    render() {
        let {blockEducation, tests, materials} = this.props

        return (
            <div>
            <div className="user-right-content">
                <div className="block-content">
                    <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                    <h3>Доступные лекции</h3>
                    {blockEducation}
                    {materials &&
                    <button className="loadMaterialBtn" onClick={!this.props.loaderLast && this.props.loadMaterial}>
                        {this.props.loaderLast ? 'материалов больше нет' : this.props.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
                    </button>}
                </div>
            </div>

            <div className="user-right-content">
                <div className="block-content">
                    {tests && tests.published && (
                        <div>
                            <h3>Итоговый тест</h3>
                            <div ><BlockTest test={tests} body={tests} test_id={tests.id} title={tests.title} time_to_pass={tests.time_to_pass} questions_amount={tests.questions_amount} min_score_points={tests.min_score_points} /></div>
                        </div>
                    )
                    }
                </div>
            </div>
            </div>
        )
    }
}
