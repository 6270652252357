import React from 'react'
import { AsyncPaginate } from "react-select-async-paginate";
import AsyncSelect from "react-select/async";

export default function({ submit, name, findCallback, placeholder, ...other }) {
  const onChange = selected => {
    if (selected || selected !== null) {
      submit(
        name,
        selected.map(el => el.value)
      )
    }
  }

  return (
    <AsyncPaginate
      className="select-report"
      loadOptions={findCallback}
      onChange={onChange}
      placeholder={placeholder}
      noOptionsMessage={() => "..."}
      loadingMessage={() => "Поиск..."}
      isMulti
      {...other}
    />)
}
