import React, { Component } from 'react'
import ViewMenuBtn from './view'
import history from '../../../components/client/right-panel/history'

export default class MenuBtn extends Component {
    state = {
        anchorEl: null,
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget })
    }
    
    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        let { title, icon } = this.props

        let menuList = [{
                title: 'Программу',
                title_event(){
                    history.push('/create-program')
                }
            },
            {
                title: 'Курс',
                title_event(){
                    history.push('/create-course')
                }
            },
            {
                title: 'Лекцию',
                title_event(){
                    history.push('/create-lecture')
                }
            }]

        return (
        <ViewMenuBtn menu_list = { menuList } title = { title } icon = {icon} anchorEl = { this.state.anchorEl } open = { this.handleClick } close = { this.handleClose } />
        )
    }
}
