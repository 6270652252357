import { PAGE_NAME, UNREAD_MESSAGE } from '../../actions/header/key'

const pageName = {
    page_name: '',
    unreadMessagesCount: 0
}

export default (state = pageName, action) => {
    switch (action.type) {
        case PAGE_NAME:
            return {...state, page_name: action.payload}
        case UNREAD_MESSAGE:
            return {...state, unreadMessagesCount: action.payload}
        default:
            return state
    }
}
