//SHOW_DIALOG, HIDE_DIALOG, SHOW_SNACKBAR, HIDE_SNACKBAR
export const SHOW_MODAL_PAGE = 'SHOW_MODAL_PAGE'
export const HIDE_MODAL_PAGE = 'HIDE_MODAL_PAGE'
export const SHOW_DIALOG = 'SHOW_DIALOG'
export const HIDE_DIALOG = 'HIDE_DIALOG'
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'
export const IS_ACTIVE_MODAL = 'IS_ACTIVE_MODAL'
export const CHANGE_USER = 'CHANGE_USER'
export const IS_ACTIVE_SUCCESS_MODAL = 'IS_ACTIVE_SUCCESS_MODAL'
