import React from 'react'
import without from 'lodash/without'
import Dialog from '@material-ui/core/Dialog'
import webApi from '../../../../../utilities/web-api'
import {header} from '../../../../../utilities/page'

export default ({ close, autoGenerationData, testId }) => {
  const [pickedQuestions, setPickedQuestions] = React.useState([])

  const addQuestion = question => {
    setPickedQuestions([ ...pickedQuestions, question ])
  }

  const removeQuestion = question => {
    setPickedQuestions(without(pickedQuestions, question))
  }

  const addLectureQuestions = lecture => {
    if (!lecture.test.question.length) return
    setPickedQuestions([ ...pickedQuestions, ...lecture.test.question ])
  }

  const removeLectureQuestions = lecture => {
    if (!lecture.test.question.length) return
    setPickedQuestions(without(pickedQuestions, ...lecture.test.question))
  }

  const isPickedQuestion = question => pickedQuestions.includes(question)

  const isPickedLectureQuestion = lecture => lecture.test.question.every(
    question => pickedQuestions.includes(question)
  )

  const save = async () => {
    try {
      const body = pickedQuestions.map(question => {
        question.test_id = testId
        return question
      })
      await webApi.insertQuestions(body)
      header.getSnackbar("Вопросы успешно добавлены к тесту")
      close()
    } catch (e) {
      header.getSnackbar("Добавить вопросы неудалось.")
    }
  }

  const questionsSubElement = questions => (
    <ul>
      {questions.map((question, idx) => (
        <li key={`question-${idx}`}>
          <i
            onClick={e => {
              e.preventDefault()
              isPickedQuestion(question) ? removeQuestion(question) : addQuestion(question)
            }}
            className={`far ${isPickedQuestion(question) ? 'fa-minus' : 'fa-plus'}`}
          />
          <span>Вопрос - {question.text}</span>
        </li>
      ))}
    </ul>
  )

  return (
    <Dialog open={true} onClose={close}>
      <div className="dialog_autogen_questions">
        Выберите вопросы для теста
        <div className="hr"></div>
      <ul key='pick-dialog'>
        {autoGenerationData.map((lecture, lectIdx) => {
          if (!lecture.test || !lecture.test.question.length) return
          return (
            <div key={`lecture-${lectIdx}`}>
              <li>
                <i
                  onClick={e => {
                    e.preventDefault()
                    isPickedLectureQuestion(lecture) ? removeLectureQuestions(lecture) : addLectureQuestions(lecture)
                  }}
                  className={`far ${isPickedLectureQuestion(lecture) ? 'fa-minus' : 'fa-plus'}`}
                />
                <span>Лекция - {lecture.title}</span>
              </li>
              {questionsSubElement(lecture.test.question)}
            </div>
          )
        })}
      </ul>
      <button
        className="btnSaveProfile"
        onClick={save}
        disabled={pickedQuestions.length < 1}
      >Создать вопросы</button>
      </div>
    </Dialog>
  )
}
