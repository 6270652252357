import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    width: '100%',
    marginTop: 10,
  }
})

function FilterTable({ classes, clearUsers, submitTasks, isValid, canClear }) {
  return (
    <div className={classes.root + " block-content"}> <br/><br/>
      <button disabled={!canClear} className="btn-def fl-left" onClick={clearUsers}>Отмена</button>
      <button disabled={!isValid} className="btnSaveProfile fl-left" onClick={submitTasks}>Сохранить</button>
      <div className="clear"/>
    </div>
  )
}

FilterTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clearUsers: PropTypes.func.isRequired,
  submitTasks: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  canClear: PropTypes.bool.isRequired,
}

export default withStyles(styles)(FilterTable)
