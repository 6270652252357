import React, { Component } from 'react'
import TestResultTable from './test-result/table'
import AvailabilityResultTable from './availability-result/table'
import TestResultForms from './test-result/filter'
import AvailabilityResultForms from './availability-result/filter'
import webApi from '../../../../utilities/web-api'
import { header } from './../../../../utilities/page'
import Tabs from '../../../elements/tabs/view'


const statusValues = [
  { value: "", label: "Любой" },
  { value: "done", label: "Выполнена в срок" },
  { value: "due_done", label: "Выполнена не в срок" },
  { value: "in_progress", label: "В процессе" },
  { value: "expired", label: "Просрочена" },
  { value: "failed", label: "Завершена неуспешно" }
]

export default class Report extends Component {
  state = {
    testResultTableData: [],
    availabilityTableData: [],
    notFound: false,
  }

  componentWillMount() {
    header.pageName('Отчеты')
    header.newBreadcrumps([{ title: 'Отчеты', route: '/report' }])
  }

  handleReportError = err => {
    switch (err.code) {
      case 404:
        this.setState({notFound: true})
        break
      case 403:
        header.getSnackbar('У вас нет прав для формирования отчета.')
        break
      default:
        header.getSnackbar('Непредвиденная ошибка на сервере.')
    }
  }

  submitResultForm = filters => webApi.getHistoryReport(filters)
    .then(testResultTableData => {
      this.setState({
        testResultTableData,
        notFound: false
      })
    })
    .catch(this.handleReportError)

  submitAvailabilityForm = filters => webApi.getAvailabilityReport(filters)
    .then(availabilityTableData => {
      this.setState({
        availabilityTableData,
        notFound: false
      })
    })
    .catch(this.handleReportError)

  render() {
    const tabs = [
      {
        title: 'Результаты тестирования',
        component: <>
          <TestResultForms submit={this.submitResultForm} />
          <div className="block-content">
            <TestResultTable data={this.state.testResultTableData} notFound={this.state.notFound} />
          </div>
        </>
      },
      {
        title: 'Отчеты по задачам',
        component: <>
          <AvailabilityResultForms
            submit={this.submitAvailabilityForm}
            statusValues={statusValues}
          />
          <div className="block-content">
            <AvailabilityResultTable
              data={this.state.availabilityTableData}
              notFound={this.state.notFound}
              statusValues={statusValues}
            />
          </div>
        </>
      }
    ]

    return (
      <div className='user-right-content'>
        <Tabs tabs={tabs} />
      </div>
    )
  }
}
