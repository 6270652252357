import React, { Component } from 'react'
import iconPng from '../../../../img/png.svg'
import iconMpg from '../../../../img/mpg.svg'
import iconPdf from '../../../../img/pdf.svg'
import iconMp3 from '../../../../img/mp3.svg'
import video_poster from '../../../../img/video_poster.jpg'
import PdfViewer from './pdf.jsx';
import MultiTouch from './mobile/touch'
//import iconSvg from '../../../../img/png.svg'
//import { header } from './../../../utilities/page';

export default class FileViewer extends Component {
    state = {
        page: 'home',
        url: null,
        scale: 1,
        imgWidth: null
    }

    scaleUp = () => {
        let img = document.querySelector('.fix-image-web')
        !this.state.imgWidth && img && this.setState({imgWidth: img.clientWidth}, () => console.log(this.state.imgWidth))
        if(img && img.clientWidth < window.innerWidth){
            if(img.clientWidth >= window.innerWidth || parseInt(img.style.width) >= window.innerWidth){
                img.style.width = window.innerWidth + 'px'
            }
            else {
                img.style.width = img.clientWidth * 2 + 'px'
                console.log(img.clientWidth, window.innerWidth, img, parseInt(img.style.width))
            }
        }
    }

    scaleDown = () => {
        let img = document.querySelector('.fix-image-web')
        if(img){
            img.style.width = img.clientWidth / 2 + 'px'
        }
    }

    handlePage = (page, url) => this.setState({page, url})


    render() {
        let {image, video, pdf, audio, type} = this.props
        let {page, url} = this.state

        return (
        <div className={`mobile-pdf ${this.props.classStyle && this.props.classStyle}`}>
            {type === 'desc' ? 
                <div className="file-viewer-desc">
                    <div className="mobile-pdf-nav">
                        <button onClick={this.scaleUp} className="fl-r"><i className="far fa-search-plus"></i></button>
                        <button onClick={this.scaleDown} className="fl-r"><i className="far fa-search-minus"></i></button>                       
                    </div> 
                    {this.props.file}
                </div> : this.props.file}
            
            {page === 'home' && image && image.length > 0 && <div className="align-center mt-20">
                          <div className="text-left"><i className="far fa-file-image"></i> Изображения</div>
                          <div className="hr"></div>
                            {image.map((e,i) => (
                                <div key={i} onClick={() => this.handlePage('image', e.url)} data-url={e.url} className="mobile-addition-materials-card">
                                    <img src={iconPng} alt="..."/> <span>{e.title}</span>
                                </div>
                            // <img key={i} src={e.url} alt={e.title}/>
                            ))}
                      </div>}

            {page === 'home' && video && video.length > 0 && <div className="align-center mt-20">
                          <div className="text-left"><i className="far fa-file-video"></i> Видео</div>
                          <div className="hr"></div>
                          {video.map((e,i) => (
                            <div key={i} onClick={() => this.handlePage('video', e.url)} data-url={e.url} className="mobile-addition-materials-card">
                                <img src={iconMpg} alt="..."/> <span>{e.title}</span>
                            </div>
                            // <video key={i} src={e.url} poster={video_poster} controls></video>
                        ))}
                      </div>}

            {page === 'home' && pdf && pdf.length > 0 && <div className="align-center mt-20">
                        <div className="text-left"><i className="far fa-file-pdf"></i> PDF</div>
                        <div className="hr"></div>
                        {pdf.map((e,i) => (
                            <div key={i} data-url={e.url} className="mobile-addition-materials-card" onClick={() => this.handlePage('pdf', e.url)} >
                                <img src={iconPdf} alt="..."/> <span>{e.title}</span>
                            </div>
                        ))}
                    </div>}

            {page === 'home' && audio && audio.length > 0 && <div className="align-center mt-20">
                          <div className="text-left"><i className="far fa-file-audio"></i> Аудио</div>
                          <div className="hr"></div>
                          {audio.map((e,i) =>(
                                <div key={i} data-url={e.url} className="mobile-addition-materials-card" onClick={() => this.handlePage('audio', e.url)}>
                                    <img src={iconMp3} alt="..."/> <span>{e.title}</span>
                                </div>)
                            )}
                      </div>}

            {page === 'image' && url && 
                <div className="mobile-current-file">
                    {/* <div className="mobile-pdf-nav">
                        <button onClick={this.scaleUp} className="fl-r"><i className="far fa-search-plus"></i></button>
                        <button onClick={this.scaleDown} className="fl-r"><i className="far fa-search-minus"></i></button>
                        
                    </div> */}
                    {console.log(document.querySelector('.image-fix-scale'))}
                    <button onClick={()=> this.setState({page: 'home'})} className="btnSaveProfile fl-r"><i className="fas fa-chevron-left"></i></button><div className="clear"></div>
                    {/* <img className = "image-fix-scale" style={{transform: `scale(${this.state.scale}) translate(0, ${this.state.scale * 20 + '%'}`}} src={url}/> */}
                    <MultiTouch url = {url} />
                </div>
            }
            {page === 'video' && url &&
                <div className="mobile-current-file">
                    <button onClick={()=> this.setState({page: 'home'})} className="btnSaveProfile fl-r"><i className="fas fa-chevron-left"></i></button><div className="clear"></div>
                    <video src={url} poster={video_poster} className="video-mobile-test" controls controlsList="nodownload"></video>
                </div>
            }
            {page === 'pdf' && url &&
                <div className="mobile-current-file">
                    <button onClick={()=> this.setState({page: 'home'})} className="btnSaveProfile fl-r"><i className="fas fa-chevron-left"></i></button><div className="clear"></div>
                    <PdfViewer file={url} />
                </div>
            }
            {page === 'audio' && url &&
                <div className="mobile-current-file">
                    <button onClick={()=> this.setState({page: 'home'})} className="btnSaveProfile fl-r"><i className="fas fa-chevron-left"></i></button><div className="clear"></div>
                    <audio src={url} controls controlsList="nodownload"></audio>
                </div>
            }
        </div>    
        )
    }
}