import React, { Component } from 'react'
import history from '../history'
import { header } from './../../../../utilities/page';

export default class ViewCreateAward extends Component {
    handleHistory = () => {
        history.push('/settings')
    }

    uploadImg = e => {
        header.uploader(e.target.files, result => {
            delete result.status
            this.props.handleImg(result)
        })
    }

    render() {
        let { handleInput, createAward, inputValue } = this.props
        return (
            <div className="user-right-content">
                <div className="block-content">
                <div className="href cur-p" onClick={this.handleHistory}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад<br/><br/></div>
                    <div className="w-50 fl-left">
                        Наиминование достижения <br/>
                        <input type="text" onChange = { handleInput } name="title" className="inputTraining"  placeholder="Введите название достижения"/> <br/><br/>
                        Описание достижения <br/>
                        <input type="text" className="inputTraining" onChange = { handleInput } name="description" placeholder="Введите описания достижения"/> <br/><br/>
                        <br/>
                        <div className="btnSaveProfile fl-left" onClick={() => document.getElementById('_program-cover').click()} >Загрузить изображение</div>
                        <input type="file" className="hide" onChange={this.uploadImg} id="_program-cover"/>
                    </div>
                    <div className="w-50 fl-left center">
                        <div className="frame-award">
                            <b>{inputValue.title ? inputValue.title : 'Наиминование достижения'}</b> <br/><br/>
                            {(inputValue.images && inputValue.images.original) ? <img src = { inputValue.images.original } alt = { inputValue.title } width="100" height="100"/> : <div className="no-img-award"><i className="fas fa-award"></i></div>}<br/><br/>
                            {inputValue.description ? inputValue.description : 'Описание достижения'}
                        </div>
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button className="btnSaveProfile fl-left" onClick={ createAward }>Создать достижение</button>
                </div>
            </div>
        )
    }
}
