import React, { Component } from "react";
import PreloaderComponent from "./application/utilities/preloader";
import Auth from "./application/components/auth/index";
import Client from "./application/components/client/index";
import webApi from "./application/utilities/web-api";
import {
  getToken,
  getUuid,
  setUser,
  setUuid,
  getRefreshToken,
  setAppealPermission, setPermissions
} from "./application/store/actions/client/action";
import { connect } from "react-redux";
import Snackbar from "./application/components/elements/modals/snackbar";
import Dialog from "./application/components/elements/modals/dialog";
import ModalPage from "./application/components/elements/modals/modalPage";
import {v4 as uuidv4} from 'uuid';
import history from "./application/components/client/right-panel/history";
import Helper from "./application/components/client/right-panel/helper/index";
import PdfViewer from "./application/components/client/right-panel/pdf";
import Mobile from "./application/components/client/right-panel/mobile";
import store from "./application/store";

class App extends Component {
  componentWillMount = async () => {
    if (localStorage.getItem('uuid')) {
      await this.props.setUuid(localStorage.getItem("uuid"));
      await store.dispatch(setUuid(localStorage.getItem("uuid")))
    } else {
      await localStorage.setItem('uuid', uuidv4())
      await store.dispatch(setUuid(localStorage.getItem("uuid")))
    }


    const token = await store.dispatch(getToken(localStorage.getItem('token')));
    (await token) &&
      await webApi
        .getProfile(token)
        .then((data) => {
          this.props.setUser(data)
          webApi.getPermissionUser(data.id)
            .then(res => store.dispatch(setPermissions(res.response)))
        })
        .catch(console.error);

    window.addEventListener("onhashchange", () => {
      console.log("hash");
    });
  };

  availabilityAgent = () => {
    const ua = navigator.userAgent;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    )
      return true;
    else return false;
  };

  //this.availabilityAgent() ? <Mobile /> :

  render() {
    const { token, user_data } = this.props.state;
    return (
      <div>
        {
          /*this.availabilityAgent() ? <Mobile />
          : */

          token && user_data && user_data.name ? (
            <PreloaderComponent component={<Client user={user_data} />} />
          ) : (
            <PreloaderComponent component={<Auth />} />
          )
        }
        <Snackbar />
        <Dialog />
        <ModalPage />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.user,
  };
};

const mapDispatchToProps = { getToken, setUser, setUuid, getUuid, getRefreshToken };

export default connect(mapStateToProps, mapDispatchToProps)(App);
