import React, { Component } from 'react'
import { header } from './../../../../../utilities/page';

export default class ViewAdminMenu extends Component {

  handleActive = async e => {
    this.props.handleTab(e)
    document.querySelectorAll('.admin-menu-category-active')[0].classList.remove("admin-menu-category-active")
    e.target.classList.add('admin-menu-category-active')
    header.pageName('Настройки')
    //await e.target.classList.add('admin-menu-category-active')
    // oldActive[0] && await oldActive[0].classList.remove('admin-menu-category-active')
    //e.target.classList.add('admin-menu-category-active')
  }
//<i class="fal fa-question-circle"></i>
  render() {
    return (
      <div className="admin-menu">
        <div onClick = { this.handleActive } data-tab="company" className="admin-menu-category admin-menu-category-active">
          <i className="far fa-clipboard-list"></i> Справочник "Компании"
        </div>
        <div onClick = { this.handleActive } data-tab="object" className="admin-menu-category">
          <i className="far fa-clipboard-list"></i> Справочник "Объекты"
        </div>
        <div onClick = { this.handleActive } data-tab="departament" className="admin-menu-category">
        <i className="far fa-clipboard-list"></i> Справочник "Отделы"
        </div>
        {/* <div onClick = { this.handleActive } data-tab="post" className="admin-menu-category">
        <i className="far fa-address-card"></i> Справочник "Должности"
        </div>
        <div onClick = { this.handleActive } data-tab="rating" className="admin-menu-category">
        <i className="far fa-address-card"></i> Справочник "Рейтинг"
        </div> */}
        <div onClick = { this.handleActive } data-tab="awards" className="admin-menu-category">
        <i className="far fa-award"></i> Достижения
        </div>
        <div onClick = { this.handleActive } data-tab="users" className="admin-menu-category">
          <i className="far fa-users"></i> Пользователи
        </div>
        <div onClick = { this.handleActive } data-tab="category" className="admin-menu-category">
        <i className="far fa-books"></i> Категории
        </div>
        <div onClick = { this.handleActive } data-tab="helper" className="admin-menu-category">
        <i className="far fa-question-circle"></i> Помощь
        </div>
      </div>
    )
  }
}
