import React, { Component } from 'react'
import Dialog from '../../../../elements/modals/dialog'
import GetAwards from './../../../../elements/other/get-awards';
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';
import Select from 'react-select';
import history from '../../history';
import AutoGenerationModal from './AutoGenerationModal';

export default class TestOptions extends Component {
    state = {
        dialog: false,
        openSelectorType: false,
        openSelectorAssessment: false,
        award:{title:null,id:null},
        type: {
            value: null,
            title: null
        },
        assessment: {
            value: 'Оценка по количеству баллов',
            title: 'score_points'
        },
        title:null,
        questions_amount:null,
        time_to_pass: null,
        min_score_points: null,
        max_score_points:null,
        max_attempts: 1,
        assessment_type: 'score_points',
        backupTest: null,
        options: null,
        test: null,
        newTest: false,
        award_condition: 'fulfill',
        difficulty: [],
        update_test_difficulty: [],
        autoGenerationData: [],
        isOpenAutoGenerationModal: false,
        has_training: null
    }

    componentWillMount(){
        this.setState({options: this.props.options, newTest: this.props.options.test_id === 'new'}, () => {

            if(this.state.options.test_id !== 'new'){
                webApi.getTest(this.state.options.test_id)
                .then(async data => {
                    if(data.status === 200){
                        let obj = await data.response
                        data.response.after_test_id && await webApi.getTest(data.response.after_test_id).then(data => obj.after_test_title = data.response.title)
                        await this.setState({test: obj}, () => {
                        data.response.award_id && webApi.getAward(data.response.award_id).then(data => data.status === 200 && this.setState({award:{...this.state.award, title:data.response[0].title}}))
                        this.setState({has_training: obj.has_training})
                })}
            })
            }
            if(this.state.options && this.state.options.material_type === "program") this.setState({ type: 'program_final'})
            else if(this.state.options && this.state.options.material_type === "course") this.setState({ type: 'course_final'})
            else if(this.state.options && this.state.options.material_type === "lecture") this.setState({ type: 'course_intermediate'})
        })
    }

    refreshTest = () => {
        this.setState({options: this.props.options}, () => {
            if(this.state.options.test_id !== 'new'){
                webApi.getTest(this.state.options.test_id)
                .then(data => data.status === 200 && this.setState({test: data.response}, () => {
                    data.response.award_id && webApi.getAward(data.response.award_id).then(data => data.status === 200 && this.setState({award:{...this.state.award, title:data.response[0].title}}))
                }))
            }
            if(this.state.options && this.state.options.material_type === "program") this.setState({ type: 'program_final'})
            else if(this.state.options && this.state.options.material_type === "course") this.setState({ type: 'course_final'})
            else if(this.state.options && this.state.options.material_type === "lecture") this.setState({ type: 'course_intermediate'})
        })
    }

    openSelectorType = () =>{
        this.setState({openSelectorType: !this.state.openSelectorType})
    }
    openSelectorAssessment = () => {
        this.setState({openSelectorAssessment: !this.state.openSelectorAssessment})
    }

    selectTypeClick = e => {
        this.setState({
            type: {title:e.currentTarget.dataset.title, value:e.currentTarget.dataset.value}
        })
    }

    activeTest = () => {
        this.props.options && webApi.getTestQuestions(this.props.options.test_id)
        .then(res => {
            if(res.status === 200) {
                let points = 0

                res.response.forEach(el => {
                    points += el.points
                })

                let pointSearch

                if(res && this.props.test.difficulty){
                    let valid = []
                    this.props.test.difficulty.map(e => {
                        valid = [
                            ...valid,
                            {
                                level: e.level,
                                length: res.response.filter(p => e.points === p.points).length,
                                limit: e.limit
                            }
                        ]
                    })

                    pointSearch = valid.map(e => {
                        if(e.limit > e.length) {
                            return `Уровень: ${e.level}, количество вопросов меньше заданному ${e.limit}/${e.length}\n`
                        }
                    }).filter(e => e )
                }

                if(this.state.test.assessment_type === "correct_answers" && res.response.length < this.state.test.questions_amount) return header.getSnackbar(`Вопросов в тесте меньше чем ${this.state.test.questions_amount}`)

                if(pointSearch.length > 0) return header.getSnackbar(pointSearch[0])
                    webApi.updateTest({
                        id: this.props.options.test_id,
                        published: true
                    })
                    .then(data => {
                        if(data.status === 200){
                            header.getSnackbar(`Тест опубликован`)
                            this.setState({test:{
                                ...this.state.test,
                                published: true
                            }})
                        }
                    })

            }else{
                header.getSnackbar(`Для публикации, создайте вопросы для теста`)
            }
        })
    }

    deactiveTest = () => {
        webApi.updateTest({
            id: this.props.options.test_id,
            published: false
        })
        .then(data => {
            if(data.status === 200){
                header.getSnackbar(`Тест снят с публикации`)
                this.setState({test:{
                    ...this.state.test,
                    published: false
                }})
            }else if(data.status === 606) return header.getSnackbar('Этот тест нельзя снять с публикации т.к. на него ссылается обучающий материал')
        })
    }

    handlePostTest = () => {
            let {title,
                questions_amount,
                award_condition,
                type,
                max_score_points,
                max_attempts,
                time_to_pass,
                assessment_type,
                min_score_points,
                next_attempt_time,
                award,
                difficulty,
              has_training,
            } = this.state

            next_attempt_time = parseInt(next_attempt_time) * 60
            time_to_pass = time_to_pass * 60
            award ? award = award.id : award = null

            let obj = {
                award_id: award,
                title,
                time_to_pass,
                questions_amount,
                min_score_points,
                max_score_points,
                type: type,
                next_attempt_time,
                assessment_type,
                max_attempts,
                award_condition,
                difficulty,
                after_test_id: this.state.after_test_id ? this.state.after_test_id.id : null,
                has_training: this.state.has_training !== null ? this.state.has_training : true,
            }

            let error = false
            let difficultyQuestions = 0
            let difficultyPoints = 0

            obj.difficulty.forEach(e => {
                    difficultyQuestions += parseInt(e.limit)
                    difficultyPoints += parseInt(e.points) * parseInt(e.limit)
                }
            )

            obj.difficulty.map(e => {
                if(parseInt(e.limit) < 1 || parseInt(e.points) < 1) {
                    error = true
                }
            })

            if(!obj.after_test_id) delete obj.after_test_id

            if(error) return header.getSnackbar('Значение в уровнях сложности должны быть больше нуля')
            if(obj.assessment_type === 'score_points' && isNaN(difficultyPoints)) return header.getSnackbar('Значение в уровнях сложности не должно быть пустым')
            if(obj.assessment_type === 'score_points' && difficultyPoints !== parseInt(obj.max_score_points)) return header.getSnackbar(`Максимальное количество баллов не соответствует суммарному количеству баллов уровней сложности ${max_score_points}/${difficultyPoints}`)
            if(obj.assessment_type === 'score_points') obj.questions_amount = difficultyQuestions

            if(!obj.assessment_type) obj.assessment_type = 'score_points'
            if(!obj.difficulty.length) delete obj.difficulty
            if(obj.assessment_type === 'score_points'){
                if(obj.difficulty.length < 2) return header.getSnackbar('Количество уровней сложности должно быть не менее двух')
            }

            if(obj.assessment_type === 'correct_answers' && parseInt(min_score_points) > parseInt(questions_amount)){
                return header.getSnackbar('Минимальное количество баллов\nдолжно быть не меньше количеству вопросов')
            }

            if(!obj.max_score_points) delete obj.max_score_points
            if(this.state.award_condition_point) obj.award_condition_point = this.state.award_condition_point

            if(obj.award_id === null) delete obj.award_id

            if(obj.assessment_type === 'score_points' && parseInt(obj.max_score_points) < parseInt(obj.questions_amount)) return header.getSnackbar('Максимальное количество баллов\nдолжно быть не меньше количество вопросов')
            if(obj.assessment_type === 'score_points' && parseInt(obj.min_score_points) < parseInt(obj.questions_amount) || parseInt(obj.min_score_points) > parseInt(obj.max_score_points)) return header.getSnackbar('Минимальное количество баллов\nдолжно быть не меньше максимальному количеству вопросов')
            if(this.state.award_condition_point && parseInt(this.state.award_condition_point) > parseInt(obj.min_score_points)) return header.getSnackbar('Количество баллов для получения достижения должно быть не меньше минимальному количеству баллов для прохождения теста')

            if(obj.assessment_type === 'correct_answers') obj.min_answers = obj.min_score_points

            webApi.setTest(obj)
            .then(data => {
                if(data.status === 200){
                    header.getSnackbar(`Тест ${this.state.title} создан`)
                    this.setState({test: data.response})
                    this.props.minScore(data.response)
                    history.push(`/create-test/${this.state.options.material_type}/${this.state.options.material_id}/${data.response.id}`)
                    this.props.handleTestId(data.response.id)
                    this.props.options.material_type === "program" && webApi.updateProgram({id: this.state.options.material_id,test_id: data.response.id})
                    this.isCoursePage && webApi.updateCourse({id: this.state.options.material_id,test_id: data.response.id})
                    this.props.options.material_type === "lecture" && webApi.updateLecture({id: this.state.options.material_id,test_id: data.response.id})
                 }else if(data.status === 510) header.getSnackbar(`Такое название теста уже существует`)
                else header.getSnackbar(`Произошла ошибка, некорректно заполнены данные теста`)
            })
            .catch(console.error)
    }

    changeInput = e => {

        if(this.props.options.test_id !== "new"){
            this.setState({update:{...this.state.update,[e.target.name]:e.target.value},
            },
            () => this.state.update.time_to_pass && this.setState({update:{...this.state.update,time_to_pass:this.state.update.time_to_pass * 60}}))
         }else this.setState({[e.target.name]:e.target.value})
    }

    changeHasTrainingSelect = e => {
        if(this.props.options.test_id !== "new"){
            this.setState({update:{...this.state.update,has_training: e.value},
              },
              () => this.setState({update:{...this.state.update, has_training: this.state.update.has_training}}))
        }else this.setState({has_training: e.value})
    }

    handleClose = () => {
        this.setState({dialog: false})
    }

    handleOpen = () => {
        header.getDialog({
            title: 'Достижения',
            content: this.renderAwards()
        })
    }

    changeAward = e => {
        this.setState({award: e})
    }

    handleSelect = e => {
        this.setState({
            type: e
        })
    }

    handleSelectAsssment = e =>{
        this.setState({assessment_type: e.value})
    }

    optionsType = () => {
        let options
        if(this.state.options && this.state.options.material_type === "program") options = [{ value: 'program_final', label: 'Итоговое тестирование по программе' }]
        else if(this.state.options && this.state.options.material_type === "course") options = [{ value: 'course_final', label: 'Итоговое тестирование по курсу' }]
        else if(this.state.options && this.state.options.material_type === "lecture") options = [{ value: 'course_intermediate', label: 'Промежуточное тестирование внутри курса' }]
        return options
    }


    renderAwards = () => (
      <div className="dialog_big">
          <GetAwards handleClose = {this.handleClose} changeAward={this.changeAward}/>
      </div>
    )

    updateTest = () => {
        let award
        this.state.award ? award = this.state.award.id : award = null

        this.setState({update:{
            ...this.state.update,
            id: this.props.options.test_id,
            award_id: award,
            after_test_id: this.state.after_test_id ? this.state.after_test_id.id : this.state.test.after_test_id ? this.state.test.after_test_id.id : null
        }}, () => {

            let obj = this.state.update



            !obj.award_id && delete obj.award_id

            if(obj.next_attempt_time) obj.next_attempt_time = obj.next_attempt_time * 60

            if((this.state.update.max_score_points && this.state.update.questions_amount)
              && this.state.update.max_score_points < this.state.update.questions_amount) {
                return header.getSnackbar('Максимальное количество баллов\nдолжно быть не меньше количество вопросов')
            }

            if(this.state.test.assessment_type === 'correct_answers') {
                if(this.state.update.min_score_points && this.state.update.questions_amount){
                    if(parseInt(this.state.update.min_score_points) > parseInt(this.state.update.questions_amount))
                    return header.getSnackbar('Минимальное количество баллов\nдолжно быть не меньше количеству вопросов')
                }
                else if(!this.state.update.min_score_points && this.state.test.min_score_points && this.state.update.questions_amount){
                    if(parseInt(this.state.test.min_score_points) > parseInt(this.state.update.questions_amount))
                    return header.getSnackbar('Минимальное количество баллов\nдолжно быть не меньше количеству вопросов')
                }

                else if(!this.state.update.questions_amount && this.state.update.min_score_points && this.state.test.questions_amount){
                    if(parseInt(this.state.update.min_score_points) > parseInt(this.state.test.questions_amount))
                    return header.getSnackbar('Минимальное количество баллов\nдолжно быть не меньше количеству вопросов')
                }
            }


            webApi.updateTest(obj).then(data => {
                if(data.status === 200) {
                    this.props.renderTestOption(data.response)
                    header.getSnackbar(`Данные теста обновлены`)
                    this.refreshTest()
                }else if(data.status === 510) header.getSnackbar(`Такое название теста уже существует`)
                else header.getSnackbar(`Произошла ошибка, некорректно заполнены данные теста`)
            })
        })
    }

    handleAwardCondition = e => {
        if(this.props.options.test_id !== "new"){
            this.setState({update:{...this.state.update,award_condition:e.value}})
         } else this.setState({award_condition: e.value})
    }

    handleSearchIndex = (arr, attr, value) => {
        for(let i = 0; i < arr.length; i += 1) {
            if(arr[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    assessmentType = () => {
        let {test} = this.state
        const assasmentOptions = [
            { value: 'score_points', label: 'Оценка по количеству баллов' },
            { value: 'correct_answers', label: 'Оценка по количеству вопросов' }
        ]

        let options
        if(test && test.assessment_type){
            if(test.assessment_type === 'score_points') options = assasmentOptions[0]
            else options = assasmentOptions[1]
        }

        return options
    }

    addDifficulty = () =>{
        if(this.state.difficulty.length === 5) return header.getSnackbar('Достигнут максимальный лимит уровней сложности вопросов')

        this.setState({
            difficulty:[
                ...this.state.difficulty,
                {
                    level: this.state.difficulty.length += 1,
                    limit: 1,
                    points: 1
                }
            ]
        })
    }

    deleteDifficulty = (i) =>{
        let difficulty = this.state.difficulty
        difficulty.splice(i, 1)

       difficulty.map((el, index) => {
           el.level = ++index
           return([...difficulty, el]
           )
        })

        this.setState({difficulty})
    }

    changeDifficulty = (e, index) => {
        let difficulty = this.state.difficulty

        difficulty[index] = {
            ...difficulty[index],
            [e.target.name]: e.target.value
        }

        this.setState({difficulty})
    }

    renderDifficulty = (arrayDif) =>{
        let newTest = this.props.options.test_id !== "new"
        return arrayDif.map((el, i) => (
            <div key={i+'ssss'}>
                <span className="difficultySpanLevel fl-left mglr-10">{el.level}</span>
                <input onChange={e => this.changeDifficulty(e,i)} name="points" className="inputDifficulty fl-left mglr-10" type="text" placeholder="Количество баллов" value={el.points} disabled={newTest}/>
                <input onChange={e => this.changeDifficulty(e,i)} name="limit" className="inputDifficulty fl-left mglr-10" type="text" placeholder="Вопросов в тесте" value={el.limit} disabled={newTest}/>
                {!newTest && <i className="fas fa-trash-alt difficultySpanLevel icon-red icon-large" onClick={() => this.deleteDifficulty(i)}/>}
                <div className="clear"/>
                <br/>
            </div>
        ))
    }

    availabilityLesson = val => {
        if(val.length){
            webApi.getTestLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                this.setState({after_test_input: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({after_test_input: []})
                }
            })
        }
    }

    changeSelectAvailability = e =>{
        this.setState({after_test_id: e})
    }

    removeAfter = () => {
        this.setState({after_test_id: null}, () => {
            let inp = document.querySelector('.fix-selector-width input')
            inp.value = ''
        })
    }

    openAutoGenerationModal = async () => {
        const autoGenerationData = await webApi.fetchLectureAutogenerateQuestion(this.props.options.material_id)
        this.setState({
            autoGenerationData,
            isOpenAutoGenerationModal: true
        })
    }

    onCloseAutoGenerationModal = async () => {
        this.setState({
            autoGenerationData: [],
            isOpenAutoGenerationModal: false
        })
    }

    get isCoursePage() {
        return this.props.options && this.props.options.material_type === 'course'
    }

    render() {
        let {test, assessment_type, newTest, award_condition, update} = this.state
        let title_dialog = "Достижения"

        const assasmentOptions = [
            { value: 'score_points', label: 'Оценка по количеству баллов' },
            { value: 'correct_answers', label: 'Оценка по количеству вопросов' }
        ]

        const awardCondition = [
            {value: 'fulfill', label: 'Минимальный проходной балл'},
            {value: 'fulfill_with_awards_condition_point', label: 'Количество баллов для получения достижения'}
        ]

        const trainingCondition = [
            {value: true, label: 'Да'},
            {value: false, label: 'Нет'}
        ]

        let options = this.optionsType()

        let selectAssessment = this.assessmentType()

        return (
            <div>
                <div className="w-50 fl-left adaptive-fix-test">
                    {this.isCoursePage &&
                        <div>
                            <button className="btnSaveProfile" onClick={this.openAutoGenerationModal}>Открыть окно автогенерации ворпосов</button>
                            <br/><br/>
                        </div>
                    }
                    Наименование тестового задания <br/>
                    <input type="text" name="title" defaultValue={(!newTest && test) ? test.title : ''} onChange={this.changeInput} className="inputTraining"  placeholder="Введите название тестового задания"/> <br/><br/>
                    Доступность теста
                    {
                        test && !test.after_test_id &&
                        <div>
                            <i className="fas fa-times fix-del-after-icon" onClick={this.removeAfter}/>
                            <Select
                                className="fix-selector-width"
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['title'])}
                                value = {this.state.after_test_id}
                                options = {this.state.after_test_input}
                                onInputChange={this.availabilityLesson}
                                onChange={this.changeSelectAvailability}
                                placeholder="Выберите тест после которого этот тест будет доступен"
                            />
                            <div className="clear"/>
                        </div>
                    }
                    {
                        test && test.after_test_id &&
                        <div>
                            <i className="fas fa-times fix-del-after-icon" onClick={this.removeAfter}/>
                            <Select
                                className="fix-selector-width"
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['title'])}
                                value = {this.state.after_test_id}
                                options = {this.state.after_test_input}
                                onInputChange={this.availabilityLesson}
                                defaultValue={[{id: test.after_test_id, title: test.after_test_title}]}
                                onChange={this.changeSelectAvailability}
                                placeholder="Выберите тест после которого этот тест будет доступен"
                            />
                            <div className="clear"/>
                        </div>
                    }
                    {
                        !test &&
                        <div>
                            <i className="fas fa-times fix-del-after-icon" onClick={this.removeAfter}/>
                            <Select
                                className="fix-selector-width"
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['title'])}
                                value = {this.state.after_test_id}
                                options = {this.state.after_test_input}
                                onInputChange={this.availabilityLesson}
                                onChange={this.changeSelectAvailability}
                                placeholder="Выберите тест после которого этот тест будет доступен"
                            />
                            <div className="clear"/>
                        </div>
                    }
                    <br/>
                    Тип тестового задания
                    { options && <Select noOptionsMessage = {() => '...'} placeholder="Выберите тип курса" onChange={this.handleSelect} defaultValue={options[0]} name="select" options={options} isDisabled/>} <br/>
                    Время для прохождения тестирования (мин) <br/>
                    <input name='time_to_pass' defaultValue={(!newTest && test) ? test.time_to_pass/60 : ''} onChange={this.changeInput} type="text" className="inputTraining" placeholder="Введите время на прохождение теста"/> <br/><br/>
                    Выберите тип оценки тестирования
                    {
                        selectAssessment && <Select noOptionsMessage = {() => '...'} placeholder="Выберите тип оценки тестирования" defaultValue={selectAssessment} onChange={this.handleSelectAsssment} name="select" options={assasmentOptions} isDisabled={!(this.props.options.test_id === 'new')}/>
                    }
                    {
                        !selectAssessment && <Select noOptionsMessage = {() => '...'} placeholder="Выберите тип оценки тестирования" defaultValue={assasmentOptions[0]} onChange={this.handleSelectAsssment} name="select" options={assasmentOptions} isDisabled={!(this.props.options.test_id === 'new')}/>
                    }<br/>
                    Максимальное количество баллов <br/>
                    <input name="max_score_points" defaultValue={(!newTest && test) ? (test && test.assessment_type === 'correct_answers') ? '' : test.max_score_points : ''} onChange={this.changeInput} type="text" className="inputTraining"  placeholder="Введите максимальное количество баллов" disabled={(assessment_type && assessment_type === 'correct_answers') || (test && test.assessment_type === 'correct_answers')}/> <br/><br/>
                    Количество вопросов <br/>
                    <input name="questions_amount" defaultValue={(!newTest && test) ? test.questions_amount : ''} onChange={this.changeInput} type="text" className="inputTraining" disabled={newTest === false ? (test && test.assessment_type === 'score_points') : (assessment_type && assessment_type === 'score_points')} placeholder="Введите максимальное количество вопросов"/> <br/><br/>
                    Минимальное количество баллов для прохождения <br/>
                    <input type="text" name="min_score_points" defaultValue={(!newTest && test) ? test.min_score_points : ''} className="inputTraining" onChange={this.changeInput} placeholder="Введите минимальное количество баллов для прохождения"/> <br/><br/>
                    Количество попыток <br/>
                    <input type="text" name="max_attempts" defaultValue={(!newTest && test) ? test.max_attempts : ''} className="inputTraining" onChange={this.changeInput} placeholder="Введите максимальное количество попыток"/> <br/><br/>
                    Время до следующей попытки (мин) <br/>
                    <input type="text" name="next_attempt_time" defaultValue={(!newTest && test) ? test.next_attempt_time/60 : ''} className="inputTraining" onChange={this.changeInput} placeholder="Введите время до следующей пересдачи теста"/> <br/><br/>
                    {
                        this.props.options.test_id === "new" && this.state.assessment_type === 'score_points' &&
                        <div>
                            <span className="fl-left">Уровни сложности вопросов</span>
                            <span className="fl-right href" onClick={this.addDifficulty} >Добавить</span>
                            <br/><br/>
                            {this.state.difficulty.length > 0 &&
                            <div className="difficultyHeader">
                                <div className="difficultyBlock">Уровень</div>
                                <div className="difficultyBlock">Баллы</div>
                                <div className="difficultyBlock">Кол-во вопросов</div>
                                <div className="clear"/>
                                <br/>
                            </div>
                            }
                        </div>
                    }

                    {this.props.options.test_id !== "new" && this.state.assessment_type === 'score_points' && test && test.difficulty.length > 0 &&
                        <div className="difficultyHeader">
                            <div className="difficultyBlock">Уровень</div>
                            <div className="difficultyBlock">Баллы</div>
                            <div className="difficultyBlock">Кол-во вопросов</div>
                            <div className="clear"/>
                            <br/>
                        </div>
                    }

                    {this.props.options.test_id === "new" &&
                        this.state.assessment_type === 'score_points' && this.renderDifficulty(this.state.difficulty)
                    }
                    {this.props.options.test_id !== "new" &&
                        this.state.assessment_type === 'score_points' && test && this.renderDifficulty(test.difficulty)
                    }

                    Достижение <br/>
                    <input type="text" onClick={this.handleOpen} className="inputTraining cur-p" defaultValue={this.state.award.title || '' }  placeholder="Выберите достижение за прохождение теста"/> <br/><br/>
                    Условие получение достижения <br/>
                    {(test &&
                        <Select
                        placeholder="Выберите условие"
                        defaultValue={awardCondition[this.handleSearchIndex(awardCondition, 'value', test.award_condition)]}
                        onChange={this.handleAwardCondition}
                        name="select"
                        noOptionsMessage = {() => '...'}
                        options={awardCondition}
                        />)
                    }
                    {
                        (!test &&
                        <Select
                        placeholder="Выберите условие"
                        defaultValue={awardCondition[0]}
                        noOptionsMessage = {() => '...'}
                        onChange={this.handleAwardCondition}
                        name="select"
                        options={awardCondition}
                        />)
                    }<br/>
                    {
                        (((!update || !update.award_condition) && test && test.award_condition === 'fulfill_with_awards_condition_point') || (update && update.award_condition === 'fulfill_with_awards_condition_point')) &&
                        <div>Количество баллов для получения достижения
                            <input name='award_condition_point' onChange={this.changeInput} defaultValue={test.award_condition_point} type="text" className="inputTraining" placeholder="Введите количество баллов"/><br/><br/>
                        </div>
                    }

                    {(!test && award_condition && award_condition === 'fulfill_with_awards_condition_point') &&
                    <div>Количество баллов для получения достижения
                        <input name='award_condition_point' onChange={this.changeInput} type="text" className="inputTraining" placeholder="Введите количество баллов"/><br/><br/>
                    </div>}
                    Прохождение повторного теста
                    <br/>
                    {trainingCondition[this.handleSearchIndex(trainingCondition, 'value', this.state.has_training)] && !this.state.newTest && <Select
                      onChange={this.changeHasTrainingSelect}
                      name={'hasTraining'}
                      options={trainingCondition}
                      defaultValue={trainingCondition[this.handleSearchIndex(trainingCondition, 'value', this.state.has_training)]}
                      isSearchable={false}
                    />}

                    {this.state.newTest && <Select
                      onChange={this.changeHasTrainingSelect}
                      name={'hasTraining'}
                      options={trainingCondition}
                      defaultValue={trainingCondition[0]}
                      isSearchable={false}
                    />}

                    <br/><br/>

                    <div className="btnSaveProfile fl-left" onClick={this.props.options.test_id === 'new' ? this.handlePostTest : this.updateTest}>Сохранить <i className="far fa-check"/></div>
                    {(test && test.published === false) && <div className="btnSaveProfile fl-left mg-l" onClick={this.activeTest}>Опубликовать <i className="far fa-link"/></div>}
                    {(!newTest && test && test.published === true) && <div className="btnSaveProfile fl-left mg-l" onClick={this.deactiveTest}>Деактивировать <i className="far fa-link"/></div>}
                    <a href={`${window.location.origin}/create-test/${this.state.options.material_type}/${this.props.options.material_id}/new`}><div className="btnAlert fl-right mg-l">Создать новый</div></a>
                </div>
                <Dialog content={this.renderAwards()} title={title_dialog} open ={this.state.dialog} close={this.handleClose}/>
                <input type="text" className="hidden" name="next_attempt_time"/>
                <div className="clear"/>

                {this.isCoursePage && this.state.isOpenAutoGenerationModal &&
                    <AutoGenerationModal
                      close={this.onCloseAutoGenerationModal}
                      autoGenerationData={this.state.autoGenerationData}
                      testId={this.props.options.test_id}
                    />
                }
            </div>
        )
    }
}
