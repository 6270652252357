import React from 'react'
// import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'

const styles = () => ({
  root: {
    width: '100%',
    margin: '10px 5px',
  },
  chip: {
    margin: '5px 7px 5px 0'
  }
})

function FilterTable({ classes, users, deleteUser }) {
  return (
    <div className={classes.root + " block-content"}>
      {(users && users.length) ? <h3>Пользователи которым будет назначена задача</h3> : ''}
      {users.map(({ id, name }) => (
        <Chip
          label={name}
          onDelete={() => deleteUser(id)}
          className={classes.chip}
          key={id}
        />
      ))}
    </div>
  )
}

// FilterTable.propTypes = {
//   classes: PropTypes.object.isRequired,
//   users: PropTypes.array.isRequired,
//   deleteUser: PropTypes.func.isRequired,
// }

export default withStyles(styles)(FilterTable)
