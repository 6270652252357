import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

function TabContainer(props) {
  const { children, dir } = props;

  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

// const styles = theme => ({
//   root: {
//     backgroundColor: "#fff",
//     width: '100%',
//     position: 'relative',
//     minHeight: 200,
//   },
//   tabsIndicator: {
//     backgroundColor: '#00B0FF',
//   },
//   tabRoot: {
//     backgroundColor: '#fff',
//   }
// });

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    shadows: "0 0 15px red",

  },
  tabsRoot: {
    //borderBottom: '1px solid #e8e8e8',
    shadows: "0 0 15px red",
    backgroundColor: '#fff'
  },
  tabsIndicator: {
    backgroundColor: '#fdd385',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    //padding: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3
  },
});

class FloatingActionButtonZoom extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, theme } = this.props
    const { tabs } = this.props

    return (
      <div className={classes.root}>
        <AppBar position="static" className="tab-parent" color="default">
          <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
            >
                {tabs.map((i,index) => <Tab key={ index } label={i.title} className="table-menu"/>)}
          </Tabs>
        </AppBar>
        <SwipeableViews
          className="fix-tabs-overflow"
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
            {tabs.map((i,index) =><TabContainer className="fix-tabs-overflow" key={ index } dir={theme.direction}>{i.component}</TabContainer>)}
        </SwipeableViews>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FloatingActionButtonZoom);
