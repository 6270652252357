import React from 'react'

const EmptyDialogues = () => {

  return (
    <div className="chat-empty-dialog">
      <h4>Для того чтобы начать диалог с пользователями, Вам нужно найти их в поиске в левой части экрана</h4>
    </div>
  )
}

export default EmptyDialogues
