import React, { Component } from 'react'
import moment from 'moment';

export default class CompleteTask extends Component {
    state = {
        complete: []
    }

    componentDidMount(){
        let {timeline} = this.props
        if(timeline && timeline.length > 0){
            this.setState({complete: timeline.filter(e => e.completed)})
        }
    }

    timeDiff = (at, now) => {
        let fAt = moment(at).valueOf()
        let fNow = moment(now).valueOf()
        let result = moment(fNow).diff(fAt)
        return moment(result).utc().format('HH:mm:ss')
    }

    render() {
        let {complete} = this.state
        return (
            <div className="user-profile-container  mgt-50">
                <b>Последние выполненые задачи</b>
                <div className="hr"></div>
                {
                    complete.length > 0 ?
                    // this.parseTasks(complete) :
                    complete.map((e, i) => {
                        return i <= 3 && (
                            <div className="user-profile-task-block" key={i}>
                                <i className="far fa-calendar-alt mgr-10"></i>
                                {moment(e.from_date).format('DD-MM-YYYY')} - {moment(e.due_date).format('DD-MM-YYYY')}
                                <div className="hr mg-tb-5"></div>
                                <br/>
                                {e.program && 
                                    <div>
                                        <span>Программа: </span>{e.program.title}
                                        {
                                            e.program_history &&
                                            <div className="user-profile-task-details">
                                                <span>Баллы: </span>{e.program_history.points}<br/>
                                                <span>Время: </span>{this.timeDiff(e.program_history.created_at, e.program_history.finish_time)}<br/>
                                                <span>Ответы: </span>{e.program_history.question_results.filter(q => q.result).length}/{e.program_history.test.questions_amount}<br/>
                                                <span>Правильность: </span>{`${((e.program_history.question_results.filter(q => q.result).length / e.program_history.test.questions_amount) * 100)}%`}<br/>
                                                <span>Достижение: </span>{e.program_history.award_id ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>}
                                            </div>
                                        }
                                        <br/>
                                    </div>
                                }
                                {e.course && 
                                    <div>
                                        <span>Курс: </span>{e.course.title}
                                        {
                                            e.course_history &&
                                            <div className="user-profile-task-details">
                                                <span>Баллы: </span>{e.course_history.points}<br/>
                                                <span>Время: </span>{this.timeDiff(e.course_history.created_at, e.course_history.finished_at)}<br/>
                                                <span>Ответы: </span>{e.course_history.question_results.filter(q => q.result).length}/{e.course_history.test.questions_amount}<br/>
                                                <span>Правильность: </span>{`${((e.course_history.question_results.filter(q => q.result).length / e.course_history.test.questions_amount) * 100)}%`}<br/>
                                                <span>Достижение: </span>{e.course_history.award_id ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>}
                                            </div>
                                        }
                                        <br/>
                                    </div>
                                }
                                <div className="hr mg-tb-5"></div>
                                <i className="far fa-check-circle mgr-10 user-profile-task-complete"></i> {moment(e.updated_at).format('DD-MM-YYYY')}
                            </div>
                        )
                    }) :
                    <div className="user-profile-role center">Ни одной задачи не выполнено</div>
                }
                {
                   /* complete.length >= 3 && <div className="href center">Показать все</div>*/
                }
            </div>
        )
    }
}
