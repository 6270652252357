import React, { Component } from 'react'
import { header } from './../../utilities/page';
import Recovery from './recovery';

export default class ViewAuth extends Component {
    
    routeForm = () => {
        let { component } = this.props.state

        return component === 'SIGN_IN' ?  this.props.handleForm('SIGN_UP') : this.props.handleForm('SIGN_IN')
    }

    render() {
        let { component, toggleString } = this.props
        return (
            <div>
                <div className="logo_sdo"></div>
                <div className="title">Система дистанционного обучения</div>
                <div className="form">
                    <div className="authComponent">
                        {component}
                    </div>
                </div>
                <div className="form-menu">
                    <div onClick={() => header.getDialog({title: "Восстановление пароля", content: <Recovery/>})}>Восстановить пароль</div>
                </div>
                {/*<div className="form-menu">
                <div>Забыли пароль?</div>
                <div onClick = { this.routeForm }>{toggleString}</div>
                {/* <div onClick={ this.signUp }>{signIn ? 'Регистрация' : 'Назад'}</div> <br/> */}
                {/* <button className="btnDel" onClick={this.delBlock}>Удалить аккаунт</button>
                { delBlock && <div className="del-block">
                    {result && <div className="title-success">Аккаунт удалён</div> }
                    {error && <div className="title-auth-error">Номер телефона не найден</div> }
                    <input type="text" onChange={ this.handleInput } placeholder="Номер телефона"/><button onClick={ this.delPhone }>Удалить</button>
                </div> }</div> */}
                
            </div>
        )
    }
}
