import React, { Component } from 'react'
import { preload_true, preload_false } from '../store/actions/preloader/action'
import { Grid } from 'react-preloading-component';
import { connect } from 'react-redux'
import '../../style.css'

class PreloaderComponent extends Component {
    componentWillMount(){
        this.props.preload_false()
    }

    componentDidMount(){
        setTimeout(() =>{this.props.preload_true()}, 1000)
    }

    render () {
        return(
            <div>
                {this.props.preloader && this.props.component}
                {!this.props.preloader && <div className="preload-page-start"><Grid color="#045072" /></div>}
            </div>
            // this.props.preloader ? this.props.component : <div className="preload-page-start"><Grid color="#045072" /></div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        preloader: state.preloader.load
    }
}

const mapDispatchToProps = { preload_true, preload_false }

export default connect(mapStateToProps, mapDispatchToProps)(PreloaderComponent)
