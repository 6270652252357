import React, { Component } from 'react'
import history from '../history';
import ViewHelper from './../helper/view';
import ViewLecture from './lecture.jsx'
import Home from './index.jsx'
import Touch from './touch'
import { Switch, Route,Router } from 'react-router-dom'

export default class Mobile extends Component {
    render(){
        return(
            <Router history = { history }>
                <Switch>
                    <Route path="/" exact component={ Home } />
                    <Route path="/touch" exact component={ Touch } />
                    <Route path="/mobile/helper" exact component={ ViewHelper } />
                    <Route path="/mobile/lecture/:id" exact component={ ViewLecture } />
                    <Route path="*" component={ Home } />
                </Switch>
            </Router>
        )
    }
}
