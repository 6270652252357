import React, { Component } from 'react'
import ReactTable from 'react-table'
import Moment from 'react-moment';
import webApi from '../../../../../../utilities/web-api';
import moment from 'moment';
import { header } from './../../../../../../utilities/page';
//import Tabs from '../../../../../elements/tabs/view'


export default class ViewUsersTab extends Component {
  state = {
    search:{
      name: null,
      surname: null,
      phone: null
    },
    users: null,
    loaderMaterial: false,
    loaderLast: false,
    loaderMaterialAll: false,
    allProfiles: null,
    status: "active",
    limit: 50,
    offset: 0
  }

  componentWillMount(){
    let { limit, offset, status } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProfiles(limit, offset)
      .then(data=> {
        if(data.status === 403){
          header.getSnackbar('У вас нет прав для чтения каталога')
        }
        else if(data.status === 200){
           this.setState({
             loaderMaterial: false,
             profiles: data.response,
             offset: this.state.offset + limit,
            }, () => {
              this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => {
                let { profiles } = this.state
                let obj = profiles
                let newObj = []

                obj && obj.forEach(el => {
                  if(el.id) el.id = parseInt(el.id)
                  el.created_at = moment(el.created_at).format("DD.MM.YYYY/hh:mm").toString()
                  if(el.filial) el.filial_new = el.filial.title
                  if(el.company_department) {
                    el.company_department_new = el.company_department.title
                  }
                  newObj.push(el)
                  obj = {el}
                })

                this.setState({users: newObj}, () => console.log(this.state))
              }))
          })}else {this.setState({loaderMaterial: false})}
    })
    .catch(error => console.log(error))
    })
  }

  searchUsers = (e) =>{
    this.setState({
      loaderMaterial: true,
      search:{
        ...this.state.search,
        [e.target.id]: e.target.value
      }
    }, () => {
      webApi.getProfilesAllLike(status, this.state.search)
      .then(data=> {
        if(data.status === 403){
          header.getSnackbar('У вас нет прав для чтения каталога')
        }
        else if(data.status === 200){
           this.setState({
             loaderMaterial: false,
             profiles: data.response,
            }, () => {
              this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => {
                let { profiles } = this.state
                let obj = profiles
                let newObj = []

                obj && obj.forEach(el => {
                  if(el.id) el.id = parseInt(el.id)
                  el.created_at = moment(el.created_at).format("DD.MM.YYYY/hh:mm").toString()
                  if(el.filial) el.filial_new = el.filial.title
                  if(el.company_department) {
                    el.company_department_new = el.company_department.title
                  }
                  newObj.push(el)
                  obj = {el}
                })

                this.setState({users: newObj})
              }))
          })}
          else if(data.status === 404){
            this.setState({users: null, loaderMaterial: false})
          }
          else {this.setState({loaderMaterial: false})}
      })
      .catch(error => console.log(error))
    })
  }

  refreshStatus = (status,e) => {
    let { limit, offset, users, profiles, loaderMaterialAll } = this.state
    document.querySelector("span[data-active='true']") && document.querySelector("span[data-active='true']").setAttribute("data-active", "false")
    e.target.setAttribute("data-active", "true")

    if(loaderMaterialAll){
      let userStatus

      switch (status) {
        case 'active':
          this.setState({
            users: profiles.filter(e => e.active === true)
          })
          break
        case 'banned':
          this.setState({
            users: profiles.filter(e => e.active === false)
          })
            break;
        case 'all':
          this.setState({
            users: profiles
          })
            break;
        default:
            userStatus = null
          break;
      }
      profiles.filter(e => e.active === userStatus)

    }else{
      this.setState({loaderMaterial: true}, () => {
        webApi.getProfiles(limit, 0, status)
        .then(data=> {
          if(data.status === 403){
            header.getSnackbar('У вас нет прав для чтения каталога')
          }
          else if(data.status === 200){
             this.setState({
              loaderMaterial: false,
              profiles: data.response,
              status,
              offset: this.state.offset + limit,
              }, () => {
                this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => {
                  let { profiles } = this.state
                  let obj = profiles
                  let newObj = []

                  obj && obj.forEach(el => {
                    if(el.id) el.id = parseInt(el.id)
                    el.created_at = moment(el.created_at).format("DD.MM.YYYY/hh:mm").toString()
                    if(el.filial) el.filial_new = el.filial.title
                    if(el.company_department) {
                      el.company_department_new = el.company_department.title
                    }
                    newObj.push(el)
                    obj = {el}
                  })

                  this.setState({users: [...newObj]})
                }))
            })}else this.setState({loaderLast: true, loaderMaterial: false})
      })
      .catch(error => console.log(error))
      })
    }
  }

  loadMaterial = () =>{
    let { limit, offset, users, status, profiles } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProfiles(limit, offset, status )
      .then(data=> {
        if(data.status === 403){
          header.getSnackbar('У вас нет прав для чтения каталога')
        }
        else if(data.status === 200){
           this.setState({
            loaderMaterial: false,
            profiles: data.response,
            offset: this.state.offset + limit,
            }, () => {
              this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => {
                let { profiles } = this.state
                let obj = profiles
                let newObj = []

                obj && obj.forEach(el => {
                  el.created_at = moment(el.created_at).format("DD.MM.YYYY/hh:mm").toString()
                  if(el.id) el.id = parseInt(el.id)
                  if(el.filial) el.filial_new = el.filial.title
                  if(el.company_department) {
                    el.company_department_new = el.company_department.title
                  }
                  newObj.push(el)
                  obj = {el}
                })

                this.setState({users: [...users, ...newObj]})
              }))
          })}else this.setState({loaderLast: true, loaderMaterial: false})
    })
    .catch(error => console.log(error))
    })
  }

  loadMaterialAll = () =>{
    let { users, status, allMaterial } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProfilesAll()
      .then(data=> {
        if(data.status === 403){
          header.getSnackbar('У вас нет прав для чтения каталога')
        }
        else if(data.status === 200){
           this.setState({
            loaderMaterial: false,
            profiles: data.response,
            loaderMaterialAll: true
            }, () => {
              this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => {
                let { profiles } = this.state
                let obj = profiles
                let newObj = []

                obj && obj.forEach(el => {
                  el.created_at = moment(el.created_at).format("DD.MM.YYYY/hh:mm").toString()
                  el.id = parseInt(el.id)
                  if(el.filial) el.filial_new = el.filial.title
                  if(el.company_department) {
                    el.company_department_new = el.company_department.title
                  }
                  newObj.push(el)
                  obj = {el}
                })

                this.setState({users: newObj})
              }))
          })}else this.setState({loaderLast: true, loaderMaterial: false})
    })
    .catch(error => console.log(error))
    })
  }

  render() {
    const columns = [{
      Header: "ID",
      accessor: 'id', // String-based value accessors!
      minWidth: 100,
      Cell: props => parseInt(props.value)
    }, {
      Header: 'Фамилия',
      accessor: 'surname',
      minWidth: 100
    }, {
      Header: 'Имя',
      accessor: 'name',
      minWidth: 100
    },{
      Header: 'Компания',
      accessor: 'company.title',
      minWidth: 100
    },{
      Header: 'Объект',
      accessor: 'filial_new',
      minWidth: 100
    },{
      Header: 'Отдел',
      accessor: 'company_department_new',
      minWidth: 100
    },{
      Header: 'Роль',
      accessor: 'role',
      minWidth: 100
    },{
      Header: 'Дата регистрации',
      accessor: 'created_at',
      minWidth: 100,
    }]

    console.log(this.state.users)

    // let prfSize = 5
//
    // if(profiles && profiles.length > 5) prfSize = profiles.length

    return (
      <div>
        <span className="href" data-active="true" onClick={e => this.refreshStatus("all",e)}>Все</span>
        <span className="href mg-l" data-active="false" onClick={e => this.refreshStatus("active",e)}>Активные</span>
        <span data-active="false" className="href mg-l" onClick={e => this.refreshStatus("banned",e)}>Заблокированные</span>
        <br/> <br/>
          <input type="text" id="name" className="search-user-input" onChange = {e => this.searchUsers(e)} placeholder="Имя"/>
          <input type="text" id="surname" className="search-user-input mg-l" onChange = {e => this.searchUsers(e)} placeholder="Фамилия"/>
          <input type="number" id="phone" className="search-user-input mg-l" onChange = {e => this.searchUsers(e)} placeholder="Номер телефона"/>
        <div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>Добавить <i className="fas fa-plus"></i></div> <div className="clear"></div>
        {/* {profiles ? <TableSort handleUserId={handleUserId} profiles = { profiles } rows = { rows } /> : <div>Пользователей нет</div>} */}
        <div className="fix-table-font"> <br/>
        {(this.state.users && this.state.users.length) &&
          <ReactTable
              data={this.state.users}
              resizable={false}
              pageSize = {this.state.users.length}
              columns={columns}
              defaultSorted={[
                {
                  id: "id",
                  desc: false
                }
              ]}
              getTrProps={(state, rowInfo) => {
                return {
                  onClick: () => {this.props.handleUserId(rowInfo.original.id)}
                }
              }}
          />}
        </div>
{/*
        <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterial}>
            {this.state.loaderLast ? 'материалов больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
        </button> */}

        {
          !this.state.loaderMaterialAll ?
          <div className="loadMaterialDual">
              <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterial}>
                {this.state.loaderLast ? 'пользователей больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
              </button>
              <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterialAll}>
                  {this.props.loaderLast ? 'пользователей больше нет' : this.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить все'}
              </button>
          </div> : 
          <div className="loadMaterialBtn">Пользователей больше нету</div>
        }
      </div>
    )
  }
}
