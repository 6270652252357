import React, { Component } from 'react'
import ViewCourseTable from './view'
import { header } from './../../../../../../utilities/page';
import webApi from '../../../../../../utilities/web-api';

export default class CourseTable extends Component {
  state = {
    course: null,
    programName: null
  }
  
  componentWillMount(){
    header.pageName('Курсы')
    webApi.getCourses(this.props.match.params.id)
    .then(data => data.status === 200 && this.setState({course: data.response}))
    .catch(error => console.log(error))

    webApi.getProgram(this.props.match.params.id)
    .then(data => this.setState({programName: data.response[0].title}))
    .catch(error => console.log(error))
  }

  render() {
    return (
      <ViewCourseTable programName={this.state.programName} course = {this.state.course} match = { this.props.match } route = { this.props.route }  handleTableView = { this.props.handleTableView }/>
    )
  }
}
