import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default class componentName extends Component {
    render() {
        let { anchorEl, open, title, icon, menu_list } = this.props
        return (
        <div className="btn-material-block-right">
            <Button color="primary" className="btn-material-primary fl-right btn-material-block-right" aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={ open }>{ title && title } {icon && icon}</Button>
            <Menu id="simple-menu" anchorEl = { anchorEl } open={Boolean(anchorEl)} onClose={this.handleClose}>
                {menu_list.map((e,index) => <MenuItem key={index} onClick={e.title_event}>{e.title}</MenuItem>)}
            </Menu>
        </div>
        )
    }
}
