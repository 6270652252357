import React, { Component } from 'react'
import ViewPrograms from './view'
import BlockEducation from '../../contents/block-education'
import { connect } from 'react-redux';
import { header } from './../../../../../utilities/page';
import webApi from '../../../../../utilities/web-api';
import history from '../../history';

class Programs extends Component {
  state ={
    numMaterial: 7,
    materials: null,
    arrMaterial: '',
    loaderMaterial: false,
    loaderLast: false,
    limit: 5,
    offset: 0,
    allMaterial: false
  }

  componentWillMount(){
    let { limit, offset} = this.state
    header.pageName('Материалы')

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms(limit, offset)
      .then(data => {
        if(data.status === 200){
          this.setState({
            materials: data.response,
            loaderMaterial: false,
            offset: this.state.offset + limit,
          })
        }else {this.setState({loaderMaterial: false})}
      })
      .catch(console.error)
    })
  }

  handlePush = (e, index) =>{
    if (index.after_test_id && !index.after_test) {
      webApi.getTest(index.after_test_id)
        .then(data => {
          data.status === 200 && header.getSnackbar(`Чтобы перейти к обучению материала, пройдите тест "${data.response.title}"`)
        })
    } else {
      header.newBreadcrumps([{title:'Материалы', route: '/training'}, {title:e.currentTarget.dataset.title, route: '/courses/'+e.currentTarget.dataset.route}])
      history.push('/courses/'+e.currentTarget.dataset.route)
    }
  }

  loadMaterial = () =>{
    let { limit, offset, materials } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms(limit, offset + limit)
      .then(data => {
        if(data.status === 200){
          this.setState({
            materials: [...materials, ...data.response],
            offset: this.state.offset + limit,
            loaderMaterial: false
          })
        }else this.setState({loaderLast: true, loaderMaterial: false})
      })
    })
  }

  loadMaterialAll = () =>{
    let { materials, allMaterial } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms()
      .then(data => {
        if(data.status === 200){
          this.setState({
            materials: data.response,
            allMaterial: true,
            loaderMaterial: false
          })
        }else this.setState({loaderLast: true, loaderMaterial: false})
      })
    })
  }


  renderMaterial = () =>{
    let { materials } = this.state
    if (materials) {
      return materials.map(index => (
        <div
          className="cur-p"
          key={ index.id }
          data-title={index.title}
          data-route={index.id}
          onClick={e => this.handlePush(e, index)}><BlockEducation body = {index}/>
        </div>
      ))
    }
    else {
      return (<div><br/>На данный момент материалов для обучения нет</div>)
    }
  }

  numMat = (num) => {
    this.setState({numMaterial: num})
  }

  render() {

    return (
      <ViewPrograms
        tests = {this.state.materials}
        blockEducation = { this.renderMaterial() }
        numMat = { this.numMat }
        loadMaterial = {this.loadMaterial}
        loaderLast = {this.state.loaderLast}
        loaderMaterial = {this.state.loaderMaterial}
        loaderMaterialAll = {this.state.allMaterial}
        loadMaterialAll = {this.loadMaterialAll}
      />

    )
  }
}

export default connect((store) => {
  return {store: store.user.token}
})(Programs)
