import React from 'react'

export default ({ audioUrl }) => {
  return (
    <div className='lesson-material-type audio'>
      <audio className="audio-content" controls controlsList="nodownload">
        <source src={audioUrl} type="audio/mpeg"/>
        <span className="error-content">Аудио не доступно.</span>
      </audio>
    </div>
  )
}
