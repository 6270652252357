import React from 'react'
import without from 'lodash/without'
import PropTypes from 'prop-types'

const Dialogues = ({ dialogues, avatars, getFriedIdFromDialog, currentDialogId, changeCurrentDialog, clients, currentUserId }) => {
  const isCurrent = id => currentDialogId === id ? 'active' : ''

  const isCurrentUserOnline = users => {
    if (users.length === 2 && users.every(id => id === users[0])) return true
    users = without(users, currentUserId)
    return clients.some(el => users.includes(el))
  }

  const getUserAvatar = users => {
    const id = getFriedIdFromDialog(users, currentUserId)
    return avatars[id]
      ? <img className="chat-dialogues-avatar" src={avatars[id].preview} alt="user avatar"/>
      : <div className="ava-chat default-header-avatar"/>
  }

  const onlineBlock = users => {
    const isOnline = isCurrentUserOnline(users)
    return (
      <div className={`chat-dialogues-online-block ${isOnline ? '': 'offline'}`}>
        <span className="chat-dialogues-online-icon"/>
        <span className="chat-dialogues-online-status">{isOnline ? 'Онлайн' : 'Не в сети'}</span>
      </div>
    )
  }

  return (
    <div className="chat-dialogues-container">
      <h3 className="chat-dialogues-contact-list">Контакт лист</h3>
      {dialogues.map(({ title, _id, unreadMessages, users }) => (
        
        <div
          className={`dialog ${isCurrent(_id)}`}
          key={_id}
          onClick={() => changeCurrentDialog(_id)}
        >

          {getUserAvatar(users)}

          <div className='chat-dialogues-contact-right'>
            <span className='chat-dialogues-dialog-name'>{title}</span>
            {unreadMessages && unreadMessages.count > 0 &&
            <div className="chat-dialogues-new-message">{unreadMessages.count}</div>
            }
            {onlineBlock(users)}
          </div>
        </div>
      ))}
    </div>
  )
}

Dialogues.propTypes = {
  dialogues: PropTypes.array.isRequired,
  changeCurrentDialog: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  currentUserId: PropTypes.string.isRequired,
  avatars: PropTypes.object.isRequired,
  currentDialogId: PropTypes.string.isRequired
}

export default Dialogues
