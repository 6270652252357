import React, { Component } from 'react'
import history from '../../history';
import Select from 'react-select';
import getDiskSpace from './../../../../../utilities/disk-space';
import { header } from './../../../../../utilities/page';
import webApi from '../../../../../utilities/web-api';

export default class ViewEditProgram extends Component {
    state = {
        title : '',
        description: '',
        after_test_id: []
    }

    programsOptions = () => {
        let programs = []

        this.props.program && this.props.program.map(el =>{
            return programs = [...programs, {value: el.id, label: el.title}]
        })

        return programs
    }

    teachersOptions = () => {
        let teachers = []

        this.props.programTeacher && this.props.programTeacher.map(el =>{
            return teachers = [...teachers, {value: el.id, label: el.name + '' + el.surname}]
        })

        return teachers
    }

    availabilityLesson = val => {
        if(val.length){
            webApi.getTestLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    console.log(res)
                    this.setState({after_test_input: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({after_test_input: []})
                }              
            })
        }
    }

    changeSelectAvailability = e =>{
        this.setState({after_test_id: e}, () => console.log(this.state.after_test_id))
    }


    render() {
        let {
            handleInput,
            setProgrm,
            assigneTeacher,
            assigneCat,
            insert
        } = this.props

        console.log(insert)


        return  (
            <div className="user-right-content">

                <div className="block-content">
                <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                    <div className="w-50 fl-left">
                        Наименование программы обучения <br/>
                        <input onChange = { handleInput }  type="text" name="title" className="inputTraining"  placeholder="Введите название программы"/> <br/><br/>
                        Категория программы обучения <br/>
                        <Select noOptionsMessage = {() => '...'} placeholder="Выберите категорию программы" onChange={e => assigneCat(e.value)} options={this.programsOptions()} /> <br/>
                        Краткое описание программы <br/>
                        <textarea onChange = { handleInput } name="description" className="inputTrainingHeight" placeholder="Опишите программу"></textarea> <br/><br/>
                        Преподаватель <br/>
                        <Select noOptionsMessage = {() => '...'} placeholder="Выберите преподавателя" onChange={e => assigneTeacher(e.value)} options={this.teachersOptions()} /> <br/>
                        Рекомендуемое время на прохождение (мин)<br/>
                        <input onChange = { handleInput } name="estimation" value={insert.estimation || ''} className="inputTraining" placeholder = "Введите рекомендуемое время на прохождение" /> <br/><br/>
                        Доступность материала<br/>
                        <Select
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['title'])}
                            value = {this.state.after_test_id}
                            options = {this.state.after_test_input}
                            onInputChange={this.availabilityLesson}
                            onChange={this.changeSelectAvailability}
                            placeholder="Выберите тест после которого программа будет доступна"
                        /> <br/>
                    </div>
                    <div className="w-50 fl-left center">
                        Обложка (jpg/png) <br/><br/>
                        {(insert && insert.images) ? <img className="no-img-training img_cover_material" src={insert.images.original} alt="img"/> : <div className="no-img-training">320x240</div>}
                        <br/>
                        <input type="file" className="hide" onChange={e => this.props.uploadCover(e.target.files)} id="_program-cover"/>
                        <button className="btnSaveProfile" onClick={() => getDiskSpace(result => this.props.uploadCover(result),{type:false, fullUrl: true})} >Выбрать файл</button>
                         
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button onClick = {() => setProgrm(this.state.after_test_id) } className="btnSaveProfile fl-left">Сохранить</button>
                </div>
            </div>
        )
    }
}
