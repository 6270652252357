import React, { Component } from 'react'
import Select from 'react-select';
import webApi from '../../../../../utilities/web-api';
import { header } from './../../../../../utilities/page';

export default class FetchQuestions extends Component {
    state = {}

    handleSaveAnswer = (id) => {
        webApi.setAnswer({
            question_id: id,
            body: this.state[id+'-value-id'],
            correct: true
        }).then(data => {
            if(data.status === 200) {
                header.getSnackbar('Ответ успешно добавлен к вопросу')
                document.querySelectorAll(`[name="${id}-value-id"]`)[0].setAttribute("disabled", "disabled")
                document.querySelectorAll(`[name="${id}-btn-id"]`)[0].remove()
                document.querySelectorAll(`[name="${id}-alert-id"]`)[0].remove()
            }
        })
    }

    correctToggle = (e) => {
        this.setState({[e.target.name]: !this.state[e.target.name]})
    }

    removeAnswers = (e, id) => {
        let indexAnswer = e.target.dataset.idIndex
        let newAnswer = this.state[id+'-answers']

        newAnswer.splice(e.target.dataset.idIndex, 1)
        this.setState({[id+'-answers']: newAnswer})

        webApi.deleteAnswer(e.target.dataset.idAnswer)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Ответ успешно удалён")
            }else{
                header.getSnackbar("Произошла ошибка при удалении")
            }
        })
    }

    handleCreateSelection = id =>{

        this.setState({[id+'-created']: true})
        header.getSnackbar('Ответ успешно добавлен к вопросу')
        document.querySelectorAll(`[name="${id}-value-id"]`)[0].setAttribute("disabled", "disabled")
        document.querySelectorAll(`[name="${id}-btn-id"]`)[0].remove()
        document.querySelectorAll(`[name="${id}-alert-id"]`)[0].remove()
    }

    handleNewAnswerSelection = (id) => {
        webApi.setAnswer({
            question_id: id,
            body: this.state[id+'-value-id'],
            correct: this.state[id+'-correct'] || false
        })
        .then(data => {
            if(data.status === 200 || data.status === 201){
                document.querySelectorAll(`[name="${id}-value-id"]`)[0].value=''
                header.getSnackbar("Ответ успешно добавлен к вопросу")
                let _stateAnswer = this.state[id+'-answers'] || []
                _stateAnswer.push({
                    id: data.response.id,
                    body: data.response.body,
                    correct: data.response.correct
                })

                this.setState({[id+'-answers']: _stateAnswer, [id+'-isInput']: false, [id+'-value-id']: ''})
            }else{
                header.getSnackbar("Произошла ошибка к добавлению ответа")
            }
        })
    }

    handleUpdateInput = (t,e) =>{
        if(this.state[e.id + '-edit']){
            this.setState({
                [t.target.name]: t.target.value,
                [e.id + '-editAnswer']:{
                    ...this.state[e.id + '-editAnswer'],
                    body: t.target.value
                }
            })
        }else{
            this.setState({
                [t.target.name]: t.target.value
            })
        }
    }

    editAnsw = (t, e, el) => {
        if(t.target.classList.contains('fa-edit')){
            t.target.classList.remove('fa-edit')
            t.target.classList.add('fa-check')

            e.type && e.type === 'free' ? this.setState({[e.id + '-editAnswer']: e.answers[0]}) : this.setState({[e.id + '-editAnswer']: e})

            let elems = document.querySelectorAll(`input[data-id="${e.id}"]`)

            if(elems){
                elems.forEach(e => {
                    e.removeAttribute('disabled')//setAttribute('disabled', 'false')
                })
            }
        }else{
            if(!this.state[e.id + '-editAnswer'].body) return header.getSnackbar('Поле ответа не должно быть пустым')
            t.target.classList.remove('fa-editfa-check')
            t.target.classList.add('fa-edit')

            let elems = document.querySelectorAll(`input[data-id="${e.id}"]`)// || document.querySelectorAll(`input[name="${e.id}-value-id"]`)

            webApi.updateAnswerBody(this.state[e.id + '-editAnswer'])
            .then(data => {
                if(data.status === 200){
                    header.getSnackbar("Ответ отредактирован")
                }
            })

            if(elems){
                elems.forEach(e => {
                    e.setAttribute('disabled', true)//setAttribute('disabled', 'false')
                })
            }

        }
    }

    editAnswOption = (t, e) => {
        if(t.target.classList.contains('fa-edit')){
            t.target.classList.remove('fa-edit')
            t.target.classList.add('fa-check')
            let obj = {
                id: e.id,
                points: e.points,
                text: e.text,
                helper: e.helper,
                required: e.required,
                lecture_id: e.lecture_id
            }
            !obj.lecture_id && delete obj.lecture_id
            !obj.helper && delete obj.helper

            //obj.answers && delete obj.answers
            this.setState({[e.id + '-editAnswerOption']: obj, [e.id + '-editAnswerSelection']: true})

            let elems = document.querySelectorAll(`input[data-id-question="${e.id}"]`)

            if(elems){
                elems.forEach(e => {
                    e.removeAttribute('disabled')//setAttribute('disabled', 'false')
                })
            }
        }else{
            //if(!this.state[e.id + '-editAnswer'].body) return header.getSnackbar('Поле ответа не должно быть пустым')

            t.target.classList.remove('fa-editfa-check')
            t.target.classList.add('fa-edit')

            let elems = document.querySelectorAll(`input[data-id-question="${e.id}"]`)// || document.querySelectorAll(`input[name="${e.id}-value-id"]`)

            webApi.updateAnswerOption(this.state[e.id + '-editAnswerOption'])
            .then(data => {
                if(data.status === 200){
                    this.setState({[e.id + '-editAnswerSelection']: false})
                    header.getSnackbar("Вопрос отредактирован")
                    this.props.refreshQuestions()
                }
            })

            if(elems){
                elems.forEach(e => {
                    e.setAttribute('disabled', true)//setAttribute('disabled', 'false')
                })
            }

        }
    }

    editStateInput = (t,e) => {
        //if(!t.target.value) return header.getSnackbar('Поле ответа не должно быть пустым')

        this.setState({[e.id + '-editAnswer']: {
            ...this.state[e.id + '-editAnswer'],
            [t.target.name]: t.target.value
        }})
    }

    editStateCorrect = (t, e, el) => {
        let lenthCorrect = el.answers.filter(e => e.correct === true).length
        this.setState({[e.id + '-editAnswer']: {
            ...this.state[e.id + '-editAnswer'],
            correct: !this.state[e.id + '-editAnswer'].correct
        }})
    }

    editStateRequired = (t, e) => {
        this.setState({[e.id + '-editAnswerOption']: {
            ...this.state[e.id + '-editAnswerOption'],
            required: !this.state[e.id + '-editAnswerOption'].required
        }})
    }

    delAnsw = (t, e) => {
        let del = t.target.parentElement.parentElement//.innerHTML = ''
        webApi.deleteAnswer(e.id).then(data => {
            if(data.status === 200){
                del.innerHTML = ''
            }
        })
    }

    stateEdit = id => {
        let answ = 0
        document.querySelectorAll(`.input-checkbox[data-answer="${id}"]`).forEach(e => e.checked && (answ += 1))
        if(document.querySelectorAll(`.input-checkbox[data-answer="${id}"]`).length > 0 && this.state[id+'-edit'] && !answ) return header.getSnackbar('В вопросе должен быть хотя бы один правильный ответ')
        this.setState({[id+'-edit']: !this.state[id+'-edit'], [id + '-editAnswerSelection']: false}, () => {
            if(!this.state[id+'-edit']){
                let elems = document.querySelectorAll(`input[data-answer="${id}"], input[data-id-question="${id}"]`)

                if(elems){
                    elems.forEach(e => {
                        e.setAttribute('disabled', 'true')
                    })
                }
            }
        })
        this.setState({[id+'-isInput']: false})
    }

    stateEditSave = id => {
        this.handleNewAnswerSelection(id)
    }

    handleIsInput = id => {
        this.setState({[id+'-isInput']: !this.state[id+'-isInput']})
    }

    editQuestion = (t, e) => {
        this.setState({
            [e.id + '-editAnswerOption']:{
                ...this.state[e.id + '-editAnswerOption'],
                [t.target.name]: t.target.value
            }
        })
    }

    validPoints = () => {
        let {fetchQuestions, test_difficulty} = this.props

        if(fetchQuestions && test_difficulty){
            let valid = []
            test_difficulty.map(e => {
                valid = [
                    ...valid,
                    {
                        level: e.level,
                        length: fetchQuestions.length > 0 ? fetchQuestions.filter(p => e.points === p.points).length : null,
                        limit: e.limit
                    }
                ]
            })

            return valid
        }
    }

    selectionLesson = (val) => {
        if(val.length){
            webApi.getLectureLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    this.setState({resultMaterial: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({resultMaterial: []})
                }
            })
        }
    }
    changeSelectionLesson = (e, el) =>{
        this.setState({
            [el.id + '-editAnswerOption']:{
                ...this.state[el.id + '-editAnswerOption'],
                lecture_id: e.id
            }
        })
    }


    render() {
        let {fetchQuestions, scoreType, test_difficulty} = this.props
        const typeTest = [
            { value: 'selection', label: 'Выбор из вариантов ответа' },
            { value: 'free', label: 'Текстовый ответ' }
        ]

        return (fetchQuestions && fetchQuestions.length) ? fetchQuestions.map((el, i) => {
            return (
                <div className="blockTableNewTest" key={i+el.id}>
                    Тип вопроса <br/>
                    <Select placeholder="Выберите тип вопроса" defaultValue={el.type === 'selection' ? typeTest[0] : typeTest[1]} name="select" options={typeTest} isDisabled={true}/>
                    <div className="free-type-question">
                        Текст вопроса <br/>
                        <div className="request-block-test">
                        <input type="checkbox"
                            data-id-question={el.id}
                            disabled={!this.state[el.id + '-editAnswerOption']}
                            onClick={(e) => this.editStateRequired(e,el)}
                            checked={this.state[el.id + '-editAnswerOption'] ? this.state[el.id + '-editAnswerOption'].required : el.required}
                            name="require"
                            className="input-checkbox fl-left"/> Обязательный вопрос
                        </div>
                        <input type="text" name="text" onChange={(t) => this.editQuestion(t, el)} data-id-question={el.id} defaultValue={el.text} disabled={true}/>
                    </div> <br/>
                    Подсказка
                    <div>
                        {this.state[el.id+'-edit'] && <i className="far fa-edit edit-test fl-right" onClick={(e)=> this.editAnswOption(e,el)}></i>}
                        <input type="text" name="helper" onChange={(t) => this.editQuestion(t, el)} data-id-question={el.id} defaultValue={el.helper ? el.helper : 'Подсказка не задана'} placeholder="Подсказка для вопроса" disabled={true}/>
                        <br/><br/>
                    </div>
                    {
                        el.lecture_title && <div>
                        <span>Привязать к подсказке ссылку на лекцию</span>
                        <Select
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['title'])}
                                value = {this.state.selectInputMaterial}
                                options = {this.state.resultMaterial}
                                defaultValue={[{id: el.lecture_id,title: el.lecture_title}]}
                                noOptionsMessage = {() => '...'}
                                onInputChange = {this.selectionLesson}
                                onChange = {e => this.changeSelectionLesson(e, el)}
                                placeholder={`Введите название лекции`}
                                isDisabled={!this.state[el.id + '-editAnswerSelection']}
                        /><br/></div>
                    }
                    {
                        !el.lecture_title && <div>
                        <span>Привязать к подсказке ссылку на лекцию</span>
                        <Select
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['title'])}
                                value = {this.state.selectInputMaterial}
                                options = {this.state.resultMaterial}
                                //defaultValue={[{id: el.lecture_id,title: el.lecture_title}]}
                                noOptionsMessage = {() => '...'}
                                onInputChange = {this.selectionLesson}
                                onChange = {e => this.changeSelectionLesson(e, el)}
                                placeholder={`Введите название лекции`}
                                isDisabled={!this.state[el.id + '-editAnswerSelection']}
                        /><br/></div>
                    }
                    {scoreType && scoreType !== 'correct_answers' && <div>Количество баллов
                     <input type="text" onChange={(t) => this.editQuestion(t, el)} name="points" defaultValue={el.points || 1} placeholder="Количество баллов за ответ на вопрос" disabled={true}/>
                    {/* {test_difficulty && <Select
                    data-id-question={el.id}
                        onChange={this.difficultySelect}
                        getOptionValue={(option) => (option['level'])}
                        getOptionLabel={(option) => (option['points'])}
                        options = {test_difficulty}
                        defaultValue={this.state.defCompany}
                        placeholder="Выберите количество баллов для вопроса"
                        isDisabled={this.state.question}
                    />} */}
                    <br/><br/></div>}

                    {el.type === 'free' && (
                        <div>
                        {(el.answers[0] === undefined && !this.state[el.id+'-value-id']) && <div className="test-alarm"><i className="far fa-exclamation-circle"></i>Пожалуйста добавьте ответ на вопрос для его публикации</div>}
                        {(el.answers[0] === undefined && this.state[el.id+'-value-id']) && <div name={el.id + '-alert-id'} className="test-success test-success-transition"><i className="far fa-check-circle"></i>Теперь вы можете сохранить вопрос к тесту</div>}
                        <span>Ответ</span> <br/>
                            {this.state[el.id+'-edit'] && <i className="far fa-edit edit-test fl-right" onClick={(e)=> this.editAnsw(e,el)}></i>}
                            <input type="text" onChange={(e) => this.handleUpdateInput(e, el)} data-answer={el.id} data-id={el.id} name={el.id + '-value-id'} placeholder="Напишите текст ответа" defaultValue={el.answers[0] && el.answers[0].body} disabled={(el.answers[0] && el.answers[0].body) ? true : false}/>
                        </div>
                    )}


                    {el.type === 'selection' && <span>Ответы</span>}

                    {(el.type === 'selection' && el.answers[0] === undefined) && <div className="answersSelection">

                        {(el.answers[0] === undefined && (!this.state[el.id+'-answers'] || this.state[el.id+'-answers'].length < 1)) && <div className="test-alarm"><i className="far fa-exclamation-circle"></i>Пожалуйста добавьте ответ на вопрос для его публикации</div>}
                        {(el.answers[0] === undefined && (this.state[el.id+'-answers'] && this.state[el.id+'-answers'].length >= 1)) && <div name={el.id + '-alert-id'} className="test-success test-success-transition"><i className="far fa-check-circle"></i>Теперь вы можете сохранить вопрос к тесту</div>}

                        {this.state[el.id+'-answers'] && this.state[el.id+'-answers'].map((q, i) => {
                            return (
                                <div key={i + q.id} className="answersSelection">
                                    <input type="text" className="w-50 fl-left" name="body" defaultValue={q.body} disabled/>
                                    <div className="fl-left w-50">
                                        {!this.state[el.id+'-created'] && <span className="fl-right font-red mg-l" onClick={(e) => this.removeAnswers(e, el.id)} data-id-index={i} data-id-answer={q.id}>Удалить</span>}
                                        <span className="fl-right">Правильный ответ</span><input type="checkbox" checked={q.correct || false} className="input-checkbox fl-right" disabled/>
                                    </div>
                                    <div className="clear"></div><br/>
                                </div>
                            )
                        })}
                        {!this.state[el.id+'-created'] &&
                        <div>
                        <div className="fl-right href cur-p" onClick={() => this.handleNewAnswerSelection(el.id)}>Добавить ответ</div><br/><br/>

                        <input type="text" data-id="0" className="w-50 fl-left" name={el.id + '-value-id'} onChange={(e) => this.handleUpdateInput(e, el)} placeholder="Напишите текст ответа"/>
                        <div className="fl-left w-50">
                            <span className="fl-right">Правильный ответ</span> <input type="checkbox" name={el.id + '-correct'} checked={this.state[el.id+'-correct']} onClick={this.correctToggle} className="input-checkbox fl-right"/>
                        </div>
                        <div className="clear"></div></div>}

                    </div>}

                    {el.type === 'selection' && this.state[el.id+'-edit'] && !this.state[el.id+'-isInput'] && <div><div className="fl-right href cur-p" onClick={() => this.handleIsInput(el.id)}>Добавить ответ</div><br/><br/></div>}

                    {el.type === 'selection' && this.state[el.id+'-isInput'] &&
                    <div className="answersSelection">
                        <input type="text" data-id="0" className="w-50 fl-left" name={el.id + '-value-id'} onChange={(e) => this.handleUpdateInput(e, el)} placeholder="Напишите текст ответа"/>
                        <div className="fl-left w-50">
                            <span className="fl-right">Правильный ответ</span> <input type="checkbox" name={el.id + '-correct'} onClick={this.correctToggle} className="input-checkbox fl-right"/>
                        </div>
                        <div className="clear"></div><br/>
                    </div>}

                    {this.state[el.id+'-answers'] && this.state[el.id+'-answers'].map((q, i) => {
                            return (
                                <div key={i + q.id} className="answersSelection">
                                    <input type="text" data-answer={el.id} data-id={q.id} className="w-50 fl-left" name="body" onChange={(e) => this.editStateInput(e,q)} defaultValue={q.body} disabled/>
                                    <div className="fl-left w-50">
                                        {this.state[el.id+'-edit'] && <i className="fas fa-times edit-test fl-right" onClick={(e)=> this.delAnsw(e,q)}></i>}
                                        {this.state[el.id+'-edit'] && <i className="far fa-edit edit-test fl-right" onClick={(e)=> this.editAnsw(e,q, el)}></i>}
                                        <span className="fl-right">Правильный ответ</span>
                                        <input type="checkbox"
                                        data-answer={el.id}
                                        data-id={q.id}
                                        onClick={(e) => this.editStateCorrect(e,q, el)}
                                        checked={this.state[q.id + '-editAnswer'] ? this.state[q.id + '-editAnswer'].correct : q.correct}
                                        className="input-checkbox fl-right"
                                        disabled={!this.state[q.id + '-editAnswer']}
                                        />
                                    </div>
                                    <div className="clear"></div><br/>
                                </div>
                            )
                        })}

                    {el.type === 'selection' && el.answers && el.answers.map((an, key) => {
                        return(
                            <div key={key} className="answersSelection">
                                <input type="text" data-id={an.id} data-answer={el.id} className="w-50 fl-left" onChange={(e) => this.editStateInput(e,an)} name="body" defaultValue={an.body} disabled/>

                                <div className="fl-left w-50">
                                    {this.state[el.id+'-edit'] && <i className="fas fa-times edit-test fl-right" onClick={(e)=> this.delAnsw(e,an)}></i>}
                                    {this.state[el.id+'-edit'] && <i className="far fa-edit edit-test fl-right" onClick={(e)=> this.editAnsw(e,an, el)}></i>}
                                    <span className="fl-right">Правильный ответ</span>
                                    <input type="checkbox"
                                    data-id={an.id}
                                    data-answer={el.id}
                                    onClick={(e) => this.editStateCorrect(e,an, el)}
                                    checked={this.state[an.id + '-editAnswer'] ? this.state[an.id + '-editAnswer'].correct : an.correct}
                                    className="input-checkbox fl-right"
                                    disabled={!this.state[an.id + '-editAnswer']}
                                    />
                                </div>
                                <div className="clear"></div><br/>
                            </div>
                        )
                    })}
                    <br/><br/>
                    {(el.answers[0] === undefined && (this.state[el.id+'-answers'] && this.state[el.id+'-answers'].length >= 1) && el.type !== 'free') && <div name={el.id + '-btn-id'} className="btnSaveProfile fl-left test-success-transition" onClick={() => this.handleCreateSelection(el.id)}>Cохранить</div> }
                    {(el.answers[0] === undefined && this.state[el.id+'-value-id'] && el.type === 'free') && <div name={el.id + '-btn-id'} className="btnSaveProfile fl-left test-success-transition" onClick={() => this.handleSaveAnswer(el.id)}>Добавить ответ и сохранить</div>}
                    <div className="btnAlert fl-right" onClick={() => this.props.deleteQuestion(el.id)}>Удалить вопрос</div>
                    {this.state[el.id+'-edit'] && this.state[el.id+'-isInput'] && <button disabled={!this.state[el.id+'-value-id']} className="fl-left href cur-p btn-def ml-10" onClick={() => this.stateEditSave(el.id)}>Сохранить ответ к вопросу</button>}
                    {!this.state[el.id+'-edit'] ? <div className="default-btn fl-right mglr-10" onClick={() => this.stateEdit(el.id)}>Редактировать </div> : <div className="default-btn fl-right mglr-10" onClick={() => this.stateEdit(el.id)}>Готово </div>}
                    <div className="clear"></div>
                </div>
            )
        }) : null
    }
}
