import React, { Component } from 'react'
import webApi from '../../../../utilities/web-api'
import moment from 'moment'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { header } from './../../../../utilities/page';
import history from '../history';
import Tooltip from '@material-ui/core/Tooltip';


export default class TableAvailability extends Component {
    state = {
        tableRow: null,
        tableValue: [],
        limit: 10,
        offset: 0,
        loaderMaterial: false,
        loaderLast: false,
    }

    availability = () =>{
      let { limit, offset } = this.state

      webApi.getAllAvailability(limit,offset)
        .then(data => {
            if(data.status === 200){
              this.setState({
                loaderMaterial: false,
                offset: this.state.offset + limit,
                loaderMaterial: false
              },() =>{
                let result = data.response
                let newObject = this.state.tableValue

                result.map(el =>{
                    let elementObj

                    for(let obj in el){
                        elementObj = {...elementObj, [obj]:el[obj] || '-'}

                        if(el.company) elementObj.company = el.company.title
                        if(el.department) elementObj.department = el.department.title
                        if(el.filial) elementObj.filial = el.filial.title
                        if(el.program) elementObj.program = el.program.title
                        if(el.course) elementObj.course = el.course.title
                        if(el.lecture) elementObj.lecture = el.lecture.title
                        if(el.created_at) elementObj.created_at = moment(el.created_at).format('DD.MM.YYYY/HH:mm')
                    }

                    newObject = [...newObject, elementObj]

                })

                this.setState({tableValue: newObject})
              })
            }
            
        })
    }

    deleteAvailability = id =>{
      webApi.deleteAvailability(id)
      .then(data => {
        if(data.status === 200){
          header.getSnackbar('Доступность успешно удалена')
          let {tableValue} = this.state
          let idDelete = tableValue.map(e => e.id).indexOf(id)
          tableValue.splice(idDelete, 1)
          this.setState({tableValue})
        }else{
          header.getSnackbar('Произошла ошибка')
        }
      })
    }

    componentWillMount(){
        header.pageName('Доступность материала')
        this.setState({loaderMaterial: true}, () => this.availability())    
    }

    loadMaterial = () =>{
      let { limit, offset } = this.state
  
      this.setState({loaderMaterial: true}, () => {
        webApi.getAllAvailability(limit,offset)
        .then(data => {
            if(data.status === 200) {
              this.setState({
                loaderMaterial: false,
                offset: this.state.offset + limit,
                loaderMaterial: false
              },() =>{
                let result = data.response
                let newObject = this.state.tableValue

                result.map(el =>{
                    let elementObj

                    for(let obj in el){
                        elementObj = {...elementObj, [obj]:el[obj] || '-'}

                        if(el.company) elementObj.company = el.company.title
                        if(el.department) elementObj.department = el.department.title
                        if(el.filial) elementObj.filial = el.filial.title
                        if(el.program) elementObj.program = el.program.title
                        if(el.course) elementObj.course = el.course.title
                        if(el.lecture) elementObj.lecture = el.lecture.title
                        if(el.created_at) elementObj.created_at = moment(el.created_at).format('DD.MM.YYYY/HH:mm')
                    }

                    newObject = [...newObject, elementObj]

                })

                this.setState({tableValue: newObject})
            })
          }else this.setState({loaderLast: true, loaderMaterial: false})
        })
        .catch(error => console.log(error))
      })
    }

    render() {
        const columns = [{
            Header: "ID",
            accessor: 'id',
            minWidth: 100,
            Cell: props => <span className='number'>{props.value} <Tooltip title="Удалить задачу" aria-label="Удалить задачу"><i onClick={() => this.deleteAvailability(props.value)} className="far fa-trash-alt"></i></Tooltip></span>
          }, {
            Header: 'Компания',
            accessor: 'company',
            Cell: props => <span className='number'>{props.value}</span>,
            minWidth: 100
          }, {
            Header: 'Отдел',
            accessor: 'department',
            Cell: props => <span className='number'>{props.value}</span>,
            minWidth: 100
          }, {
            Header: 'Объект',
            accessor: 'filial',
            Cell: props => <span className='number'>{props.value}</span>,
            minWidth: 100
          }, {
            Header: 'Программа',
            accessor: 'program',
            Cell: props => <span className='number'>{props.value}</span>,
            minWidth: 100
          }, {
            Header: 'Курс',
            accessor: 'course',
            Cell: props => <span className='number'>{props.value}</span>,
            minWidth: 100
          }, {
            Header: 'Лекция',
            accessor: 'lecture',
            Cell: props => <span className='number'>{props.value}</span>,
            minWidth: 100
          }, {
            Header: 'Дата',
            accessor: 'created_at',
            Cell: props => <span className='number'>{props.value}</span>,
            minWidth: 100
          }]

        return (
            <div className="user-right-content">
                <div className="block-content">
                    <span className="href" onClick={() => header.historyBack()}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>

                    <div onClick={()=>history.push('/availability/create')} className="btnSaveProfile fl-right">Создать</div><br/>

                    <div className="fl-left w-50">{this.state.tableValue && `Всего: ${this.state.tableValue.length}`}</div> <br/><br/>

                    {this.state.tableValue && this.state.tableValue.length ? <ReactTable
                        data={this.state.tableValue}
                        resizable={false}
                        pageSize = {this.state.tableValue.length}
                        columns={columns}
                      />: <span>Ни одной доступности нет</span>}
                  </div>
                  <button className="loadMaterialBtn" onClick={!this.state.loaderLast && this.loadMaterial}>
                    {this.state.loaderLast ? 'материалов больше нет' : this.state.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
                  </button>
            </div>
        )
    }
}
