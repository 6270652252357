import React, { Component } from 'react'
import imgFail from '../../../../../img/fail.png'
import imgSuccess from '../../../../../img/success.svg'
import FinishStat from './finish'
import history from '../history';
import moment from 'moment';
import { header } from './../../../../utilities/page';
import Tooltip from '@material-ui/core/Tooltip';
import Helper from './helper'

export default class TestFinish extends Component {
    state = {
        achievement: null
    }

  render() {
      let {question_results, test, success, points} = this.props.data

    return (
        <div className="right-content">

                <div className="user-right-content">

                    {/* <div className="block-content min-pd-cont">Название страницы / Подстраницы / и тд.тп.</div>
                    <br/> */}


                        <div className="min-block-left">
                            <span className="href" onClick={() => history.push('/material')}><i className="fas fa-arrow-left color-blue"></i> Вернуться к разделу<br/><br/></span>
                            <div className="block-title"><b>Результат тестирования</b></div> <br/>
                            <span className="color-shadow font-min"><b>Тестирование по теме "{test && test.title}"</b></span>

                            <FinishStat data = {this.props.data} result={success} points={points} min_points={test} type="finish"/>

                            <div className="result-test-block">
                                {question_results && question_results.map((el,i) => {
                                    return(
                                        <Tooltip key={el.id + i + 2} title="Нажмите для подробного описания" aria-label="Тест">
                                            <div
                                            key={el.id + i}
                                            onClick={() => header.getDialog({
                                                title: "Описание вопроса",
                                                content: <Helper data={el}/>
                                            })}
                                            className={`result-test-tasck ${el.result && "result-test-tasck-success"}`}
                                            >
                                                {i + 1}
                                            </div>
                                        </Tooltip>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="min-block-right text-center">
                            {/* <div className="min-block-right-title"><b>Поздравляем!</b></div> */}
                            {success ? <div className="min-block-right-title"><b>Поздравляем!</b></div> : <div className="min-block-right-title-finish"><b>Тест не пройден!</b></div>}
                            <br/>
                            {/* <img src={imgSuccess} alt="Success - Recontext Solutions"></img> */}
                            {success ? <img src={imgSuccess} alt="Success - Recontext Solutions"></img> : <img src={imgFail} alt="fail"></img>}
                            <br/><br/>
                            <div className="min-block-right-inf color-shadow text-center">
                            {success ?
                            <div>Вы успешно завершили тестирование.{this.props.achievement && <span>Вами получено достижение <b>{this.props.achievement.title}</b> за достигнутый результат.</span>} Продолжайте в том же духе и повышайте свои профессиональные качества!</div>:
                            <div>К сожалению набранных вами баллов недостаточно для успешного завершения данного теста. Изучите материал и возвращайтесь для следующей попытки. Следующая попытка возможна через <b>{this.props.nextTime && moment().startOf('day').seconds(this.props.nextTime).format('HH:mm:ss')}</b></div>}
                                <br/><br/>
                                <button className="btn-lighting" onClick={header.getModalPage}>Мои достижения</button>
                            </div>
                        </div>
                        <div className="clear"></div>

                </div>
            </div>
    )
  }
}
