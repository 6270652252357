import React, { Component } from 'react'
import ViewHistory from './view'
import webApi from '../../../../../utilities/web-api';
import { header } from '../../../../../utilities/page';
import { connect } from 'react-redux';

class History extends Component {
    state = {
        next_attempt_time: null,
        achievement: null,
        dataHistory: null,
        dataTest: null,
        dataQuestions: null,
        dataAwards: null,
        dataAnswers: null,
        maxAttempts: null,
        usedAttempts: null
    }

    componentWillMount(){
        header.pageName('Результат тестирования')
        header.newBreadcrumps([{title: 'Результат тестирования', route: '/'}])

        this.props.match && webApi.getHistory(this.props.match.params.id)
        .then(data => {
            if(data.status === 200){

                this.setState({
                    dataHistory: data.response
                })

                webApi.getHistoryAttempts(data.response.test.id)
                .then(data => data.status === 200 && this.setState({usedAttempts: data.response.attempts}))

                webApi.getTest(data.response.test.id)
                .then(data => {
                    if(data.status === 200){
                        this.setState({
                            next_attempt_time: data.response.next_attempt_time,
                            dataTest: data.response,
                            maxAttempts: data.response.max_attempts
                        })
                    }
                })

                this.props.user_id && webApi.getAchievements().then(award => {
                    if(award.status === 200){
                        
                        let filterAward = award.response.filter(el => {
                            return el.test_id === data.response.test.id
                        })
                        console.log(filterAward)
                        filterAward && this.setState({achievement: filterAward})
                    }
                })
            }
        })
    }

    render() {
        let {dataHistory, next_attempt_time, maxAttempts, usedAttempts} = this.state
        return (
            dataHistory && <ViewHistory
            maxAttempts = {maxAttempts}
            nextTime = {next_attempt_time}
            achievement = {this.state.achievement}
            data = {dataHistory}
            usedAttempts = {usedAttempts}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user_id: state.user.user_data.id
    }
}

export default connect(mapStateToProps)(History)