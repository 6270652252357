import React, { Component } from 'react'
import ViewAdminMenu from './view';

export default class AdminMenu extends Component {
  render() {
    let { handleTab } = this.props
    return (
      <ViewAdminMenu handleTab = { handleTab }/>
    )
  }
}
