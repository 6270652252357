import React, { Component } from 'react'
import { Grid } from 'react-preloading-component';

export default class Preloader extends Component {
    render() {
        return (
            <div className="pr-form">           
                <Grid color="#045072" />
            </div>
        )
    }
}
