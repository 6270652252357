import React, { Component } from 'react'
// import TogglePanel from '../../../elements/TogglePanel/view'
import Tooltip from '@material-ui/core/Tooltip';
import { header } from './../../../../utilities/page';
import Scheduler from '../scheduler/index'

const AwardComponent = props => {
    let awards = props.awards
    let awardsAll = props.awardsAll
    let filtred

    (awards && awardsAll) && (filtred = awardsAll.filter(el=> awards.every(el2=> el2.id!==el.id)))

    return (
        <div className="achievement-block b-0">
            <div className="block-content-full">
            {
                awards && awards.map((el,i) => {
                    return(
                        <div key={i} className="achievement-block-full-children">
                            <div className="achievement-block-full-img achievement-block-children-complete">
                                <img  src={el.images.original} alt={el.images.original}/>
                            </div>
                            <div className="achievement-block-desc">
                                <b>{el.title}</b><br/> <br/>
                                {el.description}
                            </div>
                        </div>
                    )
                })
            }
            {
                (awards && awardsAll) ? filtred.map((el,i) => {
                    return(
                        <div key={i} className="achievement-block-full-children achievement-black">
                            <div className="achievement-block-full-img">
                                <img src={el.images.original} alt={el.images.original}/>
                            </div>
                            <div className="achievement-block-desc">
                                <b>{el.title}</b><br/>
                                {el.description}
                            </div>
                        </div>
                    )
                }) : awardsAll && awardsAll.map((el,i) => {
                    return(
                        <div key={i} className="achievement-block-children achievement-black">
                            <div className="achievement-block-full-img">
                                <img src={el.images.original} alt={el.images.original}/>
                            </div>
                            <div className="achievement-block-desc">
                                <b>{el.title}</b><br/>
                                {el.description}
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}

export default class ViewProfile extends Component {
    render() {
        let { blockEducation } = this.props
        let limitAward = '11'
        let awards = this.props.awards
        let awardsAll = this.props.awardsAll
        let filtred

        (awards && awardsAll) && (filtred = awardsAll.filter(el=> awards.every(el2=> el2.id!==el.id)))

        const modalAwards = {
            title: 'Достижения',
            content: <AwardComponent awards = {this.props.awards} awardsAll = {this.props.awardsAll}/>
        }

        return (
            <div>

            <div className="achievement-block">
                <div className="user-right-content">
                    <div className="block-content">
                        <h3 className="fl-left">Достижения</h3> <span onClick={() => header.getModalPage(modalAwards)} className="href fl-right fix-all-awards">Показать все достижения</span><div className="clear"></div>
                        <br/>
                        {
                            awards && awards.map((el,i) => {
                                limitAward -= 1

                                if(limitAward <= 0) return false

                                return(
                                    <div key={i} className="achievement-block-children achievement-block-children-complete">
                                        <Tooltip title={
                                            <React.Fragment>
                                                <span><b>{el.title}</b></span>
                                                <div className="hr mg-tb-5"></div>
                                                <span>{el.description}</span>
                                            </React.Fragment>
                                        } aria-label={el.description}>
                                            <img src={el.images.original} alt={el.images.original}/>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                        {
                            (awards && awardsAll) ? filtred.map((el,i) => {
                                limitAward -= 1
                                if(limitAward <= 0) return false
                                return(
                                    <div key={i} className="achievement-block-children achievement-black">
                                        <Tooltip title={
                                            <React.Fragment>
                                                <span><b>{el.title}</b></span>
                                                <div className="hr mg-tb-5"></div>
                                                <span>{el.description}</span>
                                            </React.Fragment>
                                        } aria-label={<div><span>{el.title}</span><br/><span>{el.description}</span></div>}>
                                            <img src={el.images.original} alt={el.images.original}/>
                                        </Tooltip>
                                    </div>
                                )
                            }) : awardsAll && awardsAll.map((el,i) => {
                                limitAward -= 1
                                if(limitAward <= 0) return false
                                return(
                                    <div key={i} className="achievement-block-children achievement-black">
                                        <Tooltip title={
                                            <React.Fragment>
                                                <span><b>{el.title}</b></span>
                                                <div className="hr mg-tb-5"></div>
                                                <span>{el.description}</span>
                                            </React.Fragment>
                                        } aria-label={el.description}>
                                            <img src={el.images.original} alt={el.images.original}/>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                        <div className="clear"></div>
                    </div>
                </div>
            </div>

             <div className="user-right-content">
                 <div className="block-content">
                    <h3>Доступные программы для обучения</h3>
                    {blockEducation}
                    <button className="loadMaterialBtn" onClick={!this.props.loaderLast && this.props.loadMaterial}>
                        {this.props.loaderLast ? 'материалов больше нет' : this.props.loaderMaterial ? <div className="loadMaterialProgress"></div> : 'загрузить ещё'}
                    </button>
                 </div>
             </div>

            {/* <div className="user-right-content">

                <div className="block-content">
                    <br/>
                    <TogglePanel title="Доступные тестовые задания" body={'На данный момент тестовых заданий нет'} defaultExpanded={true}/>
                </div>

            </div> */}

            <div className="user-right-content">
                <Scheduler />
            </div>
            {/* <div className="user-right-content">

                <div className="block-content">
                    <div className="block-title">Календарь событий</div>

                    <div className="calendar-block">
                        <div className="calendar-head">Ноябрь</div>
                        <div className="calendar-body">
                            <div className="clear"></div>
                            <div className="calendar-day">
                                29</div>
                            <div className="calendar-day">
                                30</div>
                            <div className="calendar-day calendar-success">
                                <div className="calendar-alert">текст описание</div>
                                31 <br/><span>Название события</span></div>
                            <div className="calendar-day">
                                1</div>
                            <div className="calendar-day">
                                2</div>
                            <div className="calendar-day">
                                3</div>
                            <div className="calendar-day">
                                4</div>
                            <div className="calendar-day calendar-success">
                                <div className="calendar-alert">текст описание</div>
                                5 <br/><span>Название события</span></div>
                            <div className="calendar-day">
                                6</div>
                            <div className="calendar-day">
                                7</div>
                            <div className="calendar-day">
                                8</div>
                            <div className="calendar-day">
                                9</div>
                            <div className="calendar-day">
                                10</div>
                            <div className="calendar-day calendar-alarm">
                                <div className="calendar-alert">текст описание</div>
                                11 <br/><span>Название события</span></div>
                            <div className="calendar-day">
                                12</div>
                            <div className="calendar-day">
                                13</div>
                            <div className="calendar-day">
                                14</div>
                            <div className="calendar-day">
                                15</div>
                            <div className="calendar-day calendar-primary">
                                <div className="calendar-alert">текст описание</div>
                                16 <br/><span>Название события</span></div>
                            <div className="calendar-day">
                                17</div>
                            <div className="calendar-day">
                                18</div>
                            <div className="calendar-day">
                                19</div>
                            <div className="calendar-day calendar-success">
                                <div className="calendar-alert">текст описание</div>
                                20 <br/><span>Название события</span></div>
                            <div className="calendar-day">
                                21</div>
                            <div className="calendar-day">
                                22</div>
                            <div className="calendar-day">
                                <div className="calendar-alert">текст описание</div>
                                23 <br/><span>Название события</span></div>
                            <div className="calendar-day">
                                24</div>
                            <div className="calendar-day">
                                25</div>
                            <div className="calendar-day">
                                26</div>
                            <div className="calendar-day">
                                27</div>
                            <div className="calendar-day calendar-alarm">
                                <div className="calendar-alert">текст описание</div>
                                28 <br/><span>Название события</span></div>
                            <div className="calendar-day">
                                29</div>
                            <div className="calendar-day">
                                30</div>
                            <div className="calendar-day">
                                1</div>
                            <div className="calendar-day">
                                2</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>

            </div> */}
        </div>
        )
    }
}
