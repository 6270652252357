import React, { Component } from 'react'
import ViewUpdate from './view'
import webApi from '../../../utilities/web-api'
import { getToken, setUser } from '../../../store/actions/client/action'
import { connect } from 'react-redux';
class Update extends Component {
    state = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        password: '',
        rePassword: ''
    }

    inputTarget = e =>{
        this.setState({[e.target.name]: e.target.value})
    }

    sendUpdateUser = async () =>{
        let { password, rePassword } = this.state
        let { getToken, setUser } = this.props
        let updateObj = {}
        let error = false

        if(password !== rePassword){
            error = true
        }

        if(!error){
            for (const filter in this.state ){
                if(this.state[filter]){
                    updateObj[filter] = this.state[filter]
                }
            }

            delete updateObj.rePassword
            delete updateObj.password

            webApi.updateProfile(updateObj, this.props.state.user_data.token)
            .then(async data => {
                if(data.status === 200){
                    await localStorage.setItem('token', this.props.state.user_data.token)
                    await getToken(this.props.state.user_data.token)
                    await webApi.getProfile().then( data => setUser(data))
                }
            })
            .catch(error => console.log(error))
        }
        

    }

    render() {
        let { user_data } = this.props.state
        return <ViewUpdate user = { user_data } inputTarget = { this.inputTarget } sendUpdateUser = { this.sendUpdateUser }/>
    }
}

const mapDispatchToProps = { getToken, setUser }

export default connect((state) => {
    return {state: state.user}
}, mapDispatchToProps)(Update)
