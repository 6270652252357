import React from 'react'

export default ({ videoUrl, description}) => {
  return (
    <div className='lesson-material-type lesson-video-container'>
      <video className="video-content" controls controlsList="nodownload">
        <source src={videoUrl} type="video/mp4"/>
        <span className="error-content">Видео недоступно.</span>
      </video>
      <div className="hr"></div>
      {description}
    </div>
  )
}
