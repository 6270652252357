import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { showModalPage, hideModalPage } from '../../../store/actions/elements/action'
import { header } from './../../../utilities/page';

const styles = {
  appBar: {
    position: 'relative',
    backgroundColor: '#2f2f2f'
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    console.log(11)
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Open full-screen dialog
        </Button> */}
        <Dialog
          fullScreen
          open={this.props.state.active}
          onClose={header.closeModalPage}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {this.props.state.body && this.props.state.body.title}
              </Typography>
              <IconButton color="inherit" onClick={header.closeModalPage} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {this.props.state.body && this.props.state.body.content}
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = { showModalPage, hideModalPage }
const mapStateToProps = (state) => {
    return {
        state: state.modals.modalPage
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FullScreenDialog))