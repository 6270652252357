import React, { Component } from 'react'
import ViewSettingsAdmin from './view'
import { header } from './../../../../../utilities/page';
import webApi from '../../../../../utilities/web-api';

export default class SettingsAdmin extends Component {
    state = {
        title: ""
    }

    componentWillMount(){
        header.pageName('Новая категория')
    }

    handleInput = e => {
        this.setState({title: e.target.value})
    }

    setCategory = () =>{
        webApi.setCategory(this.state)
        .then(data => console.log(data))
        .catch(error => console.log(error))
    }

    render() {
        return (
        <ViewSettingsAdmin setCategory = { this.setCategory } handleInput = { this.handleInput } />
        )
    }
}
