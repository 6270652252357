import React, { Component } from 'react'
import webApi from '../../../../utilities/web-api';
import history from '../history';
import { header } from './../../../../utilities/page';


export default class Helper extends Component {
  state = {
    lecture: null
  }

  componentWillMount(){
    let { data } = this.props

    data.lecture_id && webApi.getLectureId(data.lecture_id).then(data => {
      if(data.status === 200){
        this.setState({lecture: data.response.title})
      }
    })
    //console.log(window.location)
    //data.lecture_id && console.log(`${protocol}//${window.location.host}/lesson/${data.lecture_id}`)
    //onClick={() => history.push(`${window.location.protocol}//${window.location.host}/lesson/${data.lecture_id}`)}
  }

  getLecture = id =>{
    header.closeDialog()
    history.push(`/lesson/${id}`)
  }
 
  render() {
      let { data } = this.props
      //console.log(data)
      return data && (
        <div className="helper-pop"><br/>
          <b>Текст вопроса</b><br/><br/>
          { data.text }<br/><br/>

          <b>Ваш ответ</b>
          {data.answer.map((el, i) => {
            return(
                <span key={i} className="helper-user">{el}</span>
            )
          })}

        {data.helper && (
          <div> <br/><br/>
            <b>Подсказка к вопросу</b> <br/><br/>
            <span className="helper-span">{data.helper}</span>
          </div>
        )}

        {data.lecture_id && (
          <div> <div className="hr"></div>
            <i className="far fa-engine-warning helper-span"></i> Вопрос относится к лекции "<b>{this.state.lecture}</b>" <br/><br/>
            <span className="helper-span href" onClick={() => this.getLecture(data.lecture_id)} >Посмотреть лекцию</span>
          </div>
        )}
        </div>
      )
  }
}
