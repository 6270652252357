import React, { Component } from 'react'
import noAvatar from '../../../../../img/ava.jpg';

export default class UserBlock extends Component {
    parseRole = role =>{
        switch (role) {
            case "admin": return "Администратор"
            case "teacher": return "Учитель"
            case "student": return "Студент"
            default:
                break;
        }
    }

    parseRating = (rating, res) =>{
        let user = rating.map(e => e.id).indexOf(this.props.user.id)
        return res([user + 1, rating.length])
    }

    render() {
        let { user } = this.props

        return (
            <div className="user-profile-container center">
                {`${user.surname} ${user.name}`}
                <div className="hr"></div>
                {user.images ?
                <img
                    className="user-profile-avatar"
                    src={user.images.original}
                    alt={`${user.surname} ${user.name}`}
                /> :
                <img
                    className="user-profile-avatar"
                    src={noAvatar}
                    alt={`${user.surname} ${user.name}`}
                />
                }
                <div className="user-profile-l-r">
                    <div>
                        <i className="fal fa-user-tag"></i>
                        Роль
                    </div>
                    <div>
                       {this.parseRole(user.role)}
                    </div>
                    <div className="clear"></div>
                </div>

                <br/>

                <div className="user-profile-l-r">
                    <div>
                        <i className="fal fa-star-half-alt"></i>
                        Рейтинг
                    </div>
                    <div>
                        {user.ratings && this.parseRating(user.ratings, res => `${res[0]}/${res[1]}`)}
                    </div>
                    <div className="clear"></div>
                </div>

                <div className="user-profile-btn">
                        Написать пользователю
                </div>
            </div>
        )
    }
}
