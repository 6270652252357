import React, { Component } from 'react'
import ViewLeftPanel from './view'

export default class LeftPanel extends Component {
  render() {
    return (
      <div>        
        <ViewLeftPanel/>
      </div>
    )
  }
}
