import React, { Component } from 'react'
import ViewDepartamentTab from './view'
import EditComapny from './edit';
import Create from './create'
import webApi from '../../../../../../utilities/web-api';
import { connect } from 'react-redux';

class DepartamentTab extends Component {
    state = {
        page:'view',
        company: null,
        id_company: null,
    }

    handlePage = page => {
        this.setState({page})
    }

    componentWillMount(){
        this.props.company && webApi.getCompanyId(this.props.company).then(data => this.setState({company: data.response[0].title}, () => console.log(this.state.company)))
    }

    handleCompany = id =>{
        this.setState({
            id_company: id,
            page: 'edit'
        })
    }

    render() {
        return (
            <div>
                {this.state.page === 'view' && <ViewDepartamentTab handleCompany={this.handleCompany} handlePage = { this.handlePage } />}
                {this.state.page === 'create' && <Create company_id={this.props.company} company={this.state.company} handlePage = { this.handlePage } />}
                {this.state.page === 'edit' && <EditComapny id_company={this.state.id_company}  handlePage={this.handlePage}/>}
            </div>
        )//{this.state.page === 'edit' && <EditUser id_user={id_user} company={this.state.company} handlePage={this.handlePage}/>}
    }
}

const mapStateToProps = (state) => {
    return {
        company: state.user.user_data.company_id
    }
}

export default connect(mapStateToProps)(DepartamentTab)