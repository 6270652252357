import isEqual from 'lodash/isEqual'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import without from 'lodash/without'
import first from 'lodash/first'
import set from 'lodash/set'
import get from 'lodash/get'
import React, { Component } from 'react'
import { header } from '../../../../utilities/page'
import webApi from '../../../../utilities/web-api'
import socket from './chat-connector'
import Messages from './messages'
import MessagesHeader from './messages-header'
import Dialogues from './dialogues'
import EmptyDialogues from './empty-dialogs'
import DialoguesControls from './dialogues-controls'
import Controls from './controls'
import {connect} from 'react-redux'

const sleepReadMessagesTimeout = 1000

class Chat extends Component {
  state = {
    messages: [],
    avatars: {},
    onlineClients: [],
    dialogues: [],
    currentDialogId: null,
    readMessagesTimer: null
  }

  clearReadMessagesTimer = () => this.readMessagesTimer && clearTimeout(this.readMessagesTimer)

  componentDidUpdate(prevProps, prevState, snapshot) {
    header.updateUnreadMessagesCount(this.countDialoguesUnreadMessages(this.state.dialogues) || 0)
  }

  setReadMessages = () => {
    this.clearReadMessagesTimer()
    this.readMessagesTimer = setTimeout(() => {
      socket.setReadMessages(this.state.currentDialogId)
      this.setState({ messages: this.state.messages.map(message => {
        message.read = true
        return message
        })
      })
    }, sleepReadMessagesTimeout)
  }

  incrementUnreadDialogMessage = dialogId => {
    const dialogues = this.state.dialogues.map(dialog => {
      if (dialog._id === dialogId) {
        if (has(dialog, 'unreadMessages.count')) {
          dialog.unreadMessages.count++
        } else {
          set(dialog, 'unreadMessages.count', 1)
        }
      }
      return dialog
    })
    header.updateUnreadMessagesCount(this.countDialoguesUnreadMessages(dialogues))
    this.setState({ dialogues })
  }

  get isCurrentDialogHasUnreadMessages() {
    return this.state.dialogues.some(
        ({ _id, unreadMessages }) => _id === this.state.currentDialogId && unreadMessages && unreadMessages.count
      )
  }

  getUniqDialogUsersIds = dialogues => dialogues
    .reduce((memo, { users }) => {
      users.forEach(userId => {
        if (!memo.includes(userId)) memo.push(userId)
      })
      return memo
    }, [])

  getAvatarsAsHasMap = avatars => avatars
    .reduce((memo, { id, images }) => {
      memo[id] = images
      return memo
    }, {})

  countDialoguesUnreadMessages = dialogues => dialogues.reduce((memo, dialog) => {
    if (has(dialog, 'unreadMessages.count')) memo += dialog.unreadMessages.count
    return memo
  }, 0)

  onCustomError = err => header.getSnackbar(err.error.message)

  onDialogues = dialogues => {
    const currentDialogId = get(dialogues, '[0]._id')
    if (currentDialogId) {
      socket.getLastMessages(currentDialogId)
      webApi.fetchUsersAvatars({ ids: this.getUniqDialogUsersIds(dialogues) })
        .then(avatars => {
          this.setState({ dialogues, currentDialogId, avatars: this.getAvatarsAsHasMap(avatars) })
          this.isCurrentDialogHasUnreadMessages && this.setReadMessages()
          const unreadMessagesCount = this.countDialoguesUnreadMessages(dialogues)
          if (unreadMessagesCount > 0) {
            header.updateUnreadMessagesCount(unreadMessagesCount)
          }
        })
        .catch(() => {
          this.setState({ dialogues, currentDialogId })
        })
    }
  }

  onMessage = ({ message, dialogId, userId, read }) => {
    if (this.state.currentDialogId === dialogId) {
      this.setState({ messages: [...this.state.messages, { ...message, userId, read }] })
    } else {
      this.incrementUnreadDialogMessage(dialogId)
    }
  }

  onPrivateDialog = dialog => {
    const currentDialogId = dialog._id
    if (!this.state.dialogues.some(({ _id }) => _id === currentDialogId)) {
      const dialogues = [...this.state.dialogues, dialog]
      socket.getLastMessages(currentDialogId)
      webApi
        .fetchUsersAvatars({ ids: this.getUniqDialogUsersIds(dialogues) })
        .then(avatars => this.setState({
          dialogues,
          currentDialogId,
          avatars: this.getAvatarsAsHasMap(avatars)
        }))
        .catch(() => this.setState({ dialogues, currentDialogId }))
    } else {
      this.setState({ currentDialogId })
    }
  }

  onLastMessages = lastMessages => {
    const messages = lastMessages.map(({ message, read, userId }) => ({...message, read, userId}))
    this.setState({ messages })
    this.isCurrentDialogHasUnreadMessages && this.setReadMessages()
  }

  onUpdateReadMessages = ({ dialogId }) => {
    const dialogues = this.state.dialogues.map(dialog => {
      if (dialog._id === dialogId && dialog.unreadMessages && dialog.unreadMessages.count) {
        dialog.unreadMessages.count = 0
      }
      return dialog
    })

    this.setState({ dialogues })
  }

  onClients = onlineClients => {
    if (!isEqual(this.state.onlineClients, onlineClients)) {
      this.setState({ onlineClients })
    }
  }

  onUsernameChanged = ({ title, dialogId }) => {
    this.setState({
      dialogues: this.state.dialogues.map(dialog => {
        if (dialog._id === dialogId) {
          dialog.title = title
        }
        return dialog
        })
    })
  }

  componentWillMount() {
    header.pageName('Общение')
    if (socket.connected) socket.getDialogues()
    else socket.on('connect', () => socket.getDialogues())
    socket
      .on('customError', this.onCustomError)
      .on('dialogues', this.onDialogues)
      .on('message', this.onMessage)
      .on('privateDialog', this.onPrivateDialog)
      .on('lastMessages', this.onLastMessages)
      .on('updateReadMessages', this.onUpdateReadMessages)
      .on('clients', this.onClients)
      .on('usernameChanged', this.onUsernameChanged)
  }

  componentWillUnmount() {
    this.clearReadMessagesTimer()
    socket
      .off('customError', this.onCustomError)
      .off('dialogues', this.onDialogues)
      .off('message', this.onMessage)
      .off('privateDialog', this.onPrivateDialog)
      .off('lastMessages', this.onLastMessages)
      .off('updateReadMessages', this.onUpdateReadMessages)
      .off('clients', this.onClients)
  }

  changeCurrentDialog = currentDialogId => {
    if (currentDialogId !== this.state.currentDialogId) {
      this.setState({ currentDialogId, messages: [] })
      socket.getLastMessages(currentDialogId)
    }
  }

  createChannel = ({ userId, fullName }) => {
    const [surname = '', name = ''] = fullName.split(' ')
    socket.createPrivateDialog({ userId, name, surname })
  }

  get currentDialog() {
    return this.state.dialogues.find(({ _id }) => this.state.currentDialogId === _id)
  }

  get currentFriendId () {
    if (this.currentDialog) {
      return this.getFriedIdFromDialog(this.currentDialog.users, this.props.user.id)
    }
    return null
  }

  getFriedIdFromDialog = (users, currentUserId) =>  first(without(users, currentUserId))

  render() {
    return (
      <div className='chat-container'>
        <div className="chat-top-wrapper">
          <div className="chat-dialogues">
            <DialoguesControls createChannel={this.createChannel} />
            {!isEmpty(this.state.dialogues) && <Dialogues
              dialogues={this.state.dialogues}
              clients={this.state.onlineClients}
              avatars={this.state.avatars}
              currentUserId={this.props.user.id}
              getFriedIdFromDialog={this.getFriedIdFromDialog}
              currentDialogId={this.state.currentDialogId}
              changeCurrentDialog={this.changeCurrentDialog}
            />}
          </div>
          <div className="chat-messages-container">
            {isEmpty(this.state.dialogues)
              ? <EmptyDialogues/>
              : <>
                <MessagesHeader
                  images={get(this.state.avatars, this.currentFriendId, {})}
                  dialog={this.currentDialog}
                  currentUserId={this.props.user.id}
                  clients={this.state.onlineClients}
                />
                <Messages
                  messages={this.state.messages}
                  currentUserId={this.props.user.id}
                  avatars={this.state.avatars}
                  dialog={this.currentDialog}
                />
                {this.state.currentDialogId && <Controls
                  sendMessage={socket.sendMessage}
                  sendFile={socket.sendFile}
                  currentDialogId={this.state.currentDialogId}
                />}
              </>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  user: store.user.user_data
}))(Chat)
