import { LOAD_FALSE, LOAD_TRUE } from '../../actions/preloader/key'
const loaderComponent = {
    load: false
}

export default (state = loaderComponent, action) => {
    switch (action.type) {
        case LOAD_TRUE:
            return {...state, load: true}
        case LOAD_FALSE:
            return {...state, load: false}
        default:
            return state
    }
}