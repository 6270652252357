import React, { Component } from 'react'
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import JqxScheduler, { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxscheduler';
import webApi from '../../../../utilities/web-api';

export default class Scheduler extends Component {
    state = {
        timeLines: null,
        newTimeLine: []
    }

    searchInnerText = (tag, text, replace) => {
        let aTags = document.getElementsByTagName(tag);
        let searchText = text//"www.jqwidgets.com";
        let found;

        for (var i = 0; i < aTags.length; i++) {
          if (aTags[i].textContent === searchText) {
            found = aTags[i];
            break;
          }
        }
        if(found) found.textContent = replace
    }

    componentWillMount(){
        console.log(this.props.id)
        webApi.getTimeLine(this.props.id)
        .then(data => {
            if(data.status === 200){
                    let timeLines = data.response
                    let newObjTimeLines = []
                    timeLines.forEach(e => {
                        newObjTimeLines = [...newObjTimeLines, {
                            id: e.id,
                            start: e.from_date,
                            end: e.due_date,
                            calendar: e.course_id ? 'Доступные курсы' : 'Доступные программы',
                            subject: e.course_id ? `Доступен новый курс "${e.course.title}"` : `Доступна новая программа "${e.program.title}"`,
                            readOnly: true,
                            draggable: false,
                            resizable: false,
                            location: '',
                        }]
                    })

                    newObjTimeLines && this.setState({newTimeLine: newObjTimeLines})

            }
        })
    }

    componentDidMount(){
        //let btns = document.getElementsByClassName("jqx-group-button-normal")
        let headerScheduler = document.querySelector('.jqx-scheduler-toolbar')
        headerScheduler && headerScheduler.classList.add('jqx-scheduler-toolbar-custom')
        this.searchInnerText('span', 'www.jqwidgets.com', '')
        this.searchInnerText('a', 'Today', 'Сегодня')
        this.searchInnerText('a', 'Clear', 'Сбросить')

        // if(btns){
        //     //btns[0].textContent = "День"
        //     btns[0].textContent = "Неделя"
        //     btns[1].textContent = "Месяц"
        // }
    }

    handleEvent = e => {
        console.log(e)
    }

    render() {
        let myScheduler = React.createRef()
        let appointments = []
        if(this.state.newTimeLine) appointments = this.state.newTimeLine

        let source = {
            dataFields: [
                { name: 'id', type: 'string' },
                { name: 'description', type: 'string' },
                //{ name: 'location', type: 'string' },
                { name: 'subject', type: 'string' },
                { name: 'calendar', type: 'string' },
                { name: 'start', type: 'date' },
                { name: 'end', type: 'date' },
                { name: 'readOnly', type: 'boolean' },
                { name: 'draggable', type: 'boolean' },
                { name: 'resizable', type: 'boolean' },
            ],
            dataType: 'array',
            id: 'id',
            localData: appointments
        };
        const dataAdapter = new jqx.dataAdapter(source);

        this.options = {
            appointmentDataFields: {
                description: 'description',
                from: 'start',
                id: 'id',
                //location: 'location',
                resourceId: 'calendar',
                subject: 'subject',
                to: 'end',
                readOnly: 'readOnly',
                draggable: 'draggable',
                resizable: 'resizable'
            },
            date: new jqx.date(new Date()),
            height: 600,
            ready: () => {
                setTimeout(() => {
                    //myScheduler.current.ensureAppointmentVisible('id1');
                });
            },
            resources: {
                colorScheme: 'scheme05',
                dataField: 'calendar',
                source: new jqx.dataAdapter(source)
            },
            source: dataAdapter,
            views: [
                //'weekView',
                'monthView',
                //'agendaView'
            ]
        };
        //console.log(this.state)
        return (
            <div>

                    {!this.props.forProfile ?
                    <h3>Календарь задач</h3> :
                    <div>
                        <b>Задачи пользователя</b>
                        <div className="hr"></div>
                    </div>
                    }
                    <JqxScheduler ref={myScheduler}
                        // @ts-ignore

                        width="100%"
                        height={this.options.height}
                        date={this.options.date}
                        source={this.options.source}
                        showLegend={true}
                        view={"monthView"}
                        appointmentDataFields={this.options.appointmentDataFields}
                        resources={this.options.resources}
                        contextMenuItemClick={e => console.log(e)}
                        views={this.options.views}
                        ready={this.options.ready}
                        onCellClick={this.handleEvent}

                        theme={'material'}
                        editDialog={false}
                        localization={{
                            "/": ".",
                            firstDay: 1,
                            days: {
                                names: ["воскресенье","понедельник","вторник","среда","четверг","пятница","суббота"],
                                namesAbbr: ["Вс","Пн","Вт","Ср","Чт","Пт","Сб"],
                                namesShort: ["Вс","Пн","Вт","Ср","Чт","Пт","Сб"]
                            },
                            months: {
                                names: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь",""],
                                namesAbbr: ["янв","фев","мар","апр","май","июн","июл","авг","сен","окт","ноя","дек",""]
                            },
                            monthsGenitive: {
                                names: ["января","февраля","марта","апреля","мая","июня","июля","августа","сентября","октября","ноября","декабря",""],
                                namesAbbr: ["янв","фев","мар","апр","май","июн","июл","авг","сен","окт","ноя","дек",""]
                            },
                            AM: null,
                            PM: null,
                            patterns: {
                                d: "dd.MM.yyyy",
                                D: "d MMMM yyyy 'г.'",
                                t: "H:mm",
                                T: "H:mm:ss",
                                f: "d MMMM yyyy 'г.' H:mm",
                                F: "d MMMM yyyy 'г.' H:mm:ss",
                                Y: "MMMM yyyy"
                            },
                            clearString: 'Сбросить',
                            todayString: 'Сегодня',
	                        weekViewString: "Неделя",
	                        monthViewString: "Месяц",
                        }
                    }
                        //dayNameFormat={'full'}
                        toolBarRangeFormat={'dd.MM.yyyy'}
                    />
            </div>
        )
    }
}
