import React, { Component } from 'react'
import history from '../../../history';
import { header } from './../../../../../../utilities/page';
import webApi from '../../../../../../utilities/web-api';
//import getDiskSpace from '../../../../../../utilities/disk-space';


export default class ViewCreateAward extends Component {
    state={
      question: null,
      answer: null
    }

    componentWillMount(){
      header.pageName("Помощь")
    }

    handleHistory = () => {
        history.push('/settings')
    }

    handleInput = e =>{
      this.setState({[e.target.name]: e.target.value}, () => console.log(this.state))
    }

    createHelper = () =>{
        let {question, answer} = this.state
        if(!question || !answer) return header.getSnackbar('Все поля обязательны для заполнения')
        
        webApi.setHelper(this.state)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar('Подсказка успешно создана')
                this.props.handlePage('view')
            }
        }) //&& header.getSnackbar('Подсказка успешно создана'))
    }

    

    render() {
        //let { inputValue } = this.state
        //inputValue.badge && console.log(inputValue.badge.split('.')[3])
        return (
            <div className="user-right-content">
                <div className="block-content">
                <div className="href cur-p" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад<br/><br/></div>
                    <div className="w-50 fl-left">
                        Наименование <br/>
                        <input type="text" onChange = { this.handleInput } name="question" className="inputTraining"  placeholder="Введите название раздела помощи"/> <br/><br/>
                        Описание<br/>
                        <textarea className="inputTrainingHeight" onChange = { this.handleInput } type="text" name="answer" placeholder="Введите описания раздела помощи"></textarea><br/><br/>
                        {/* <input type="text" className="inputTraining" onChange = { this.handleInput } name="description" placeholder="Введите описания достижения"/> <br/><br/> */}
                        
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button className="btnSaveProfile fl-left" onClick={ this.createHelper }>Сохранить</button>
                </div>
            </div>
        )
    }
}
