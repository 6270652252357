import {
    SET_USER,
    TOKEN,
    CLEAR_TOKEN,
    SET_UUID,
    GET_UUID,
    SET_TOKEN,
    REFRESH_TOKEN, SET_APPEAL_PERMISSION
} from '../../actions/client/key'

const client = {
    auth: false,
    token: undefined,
    user_data: undefined,
    uuid: null,
    refreshToken: null,
    user_permission: []
}

export default (state = client, action) => {
    switch (action.type) {
        case SET_USER:
            return {...state, user_data: action.payload}
        case TOKEN:
            return {...state, token: action.payload}
        case REFRESH_TOKEN:
            return {...state, refreshToken: action.payload}
        case SET_TOKEN:
            return {...state, token: action.payload}
        case CLEAR_TOKEN:
            return {...state, token: null}
        case SET_UUID:
            return {...state, uuid: action.payload}
        case GET_UUID:
            return {...state, uuid: action.payload}
        case SET_APPEAL_PERMISSION:
            return {...state, user_permission: action.payload}
        default:
            return state
    }
}
