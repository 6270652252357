import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addBrdc, newBrdc, delBrdc } from '../../../../store/actions/breadcrumbs/action'
import history from '../history';

class Breadcrumbs extends Component {
    componentWillMount = () => {
        !localStorage.getItem('_brdc') && this.props.newBrdc([{title:'Профиль', route:'/'}])
    }

    render() {
        let { state } = this.props

        let col = state.brdc.length -1

        localStorage.setItem('_brdc', JSON.stringify(state.brdc))

        return <div>{state.brdc && state.brdc.map((el,i) => {
            return (
                <span key={i}>
                  {i === col ? <span>{el.title} </span> : <span onClick = { () => {
                      history.push(el.route)
                      this.props.delBrdc(i+1)
                      } }><span className="href">{el.title}</span> / </span>}
              </span>
            )
        })}</div>
    }
}

const mapDispatchToProps = { addBrdc, newBrdc, delBrdc }

const mapStateToProps = (state) => {
    return {
        state: state.brdc
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs)