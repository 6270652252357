import React, { Component } from 'react'
import ViewLectureTable from './view'
import { header } from './../../../../../../utilities/page';
import webApi from '../../../../../../utilities/web-api';

export default class LectureTable extends Component {
  state = {
    lecture: null,
    courseName: null
  }

  componentWillMount(){
    header.pageName('Лекции')
    webApi.getLecture(this.props.match.params.id)
    .then(data => data.status === 200 && this.setState({lecture: data.response}))
    .catch(error => console.log(error))
  }

  render() {
    return (
        <ViewLectureTable lecture={this.state.lecture} match = {this.props.match} handleTableView = { this.props.handleTableView }/>
    )
  }
}