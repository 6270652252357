import React, { Component } from 'react'
//import TableSort from './../../../../../elements/table-sort/index';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import webApi from '../../../../../../utilities/web-api';

export default class ViewCategoryTab extends Component {
    state={
        category: null
    }

    componentWillMount(){
      webApi.getCategory()
      .then(data => {if(data.status === 200){
          this.setState({category:data.response})
      }})
      .catch(err => console.log(err))
  }
    
    render() {
        
          const columns = [{
            Header: "ID",
            accessor: 'id', // String-based value accessors!
            minWidth: 100,
            Cell: props => <span className='number'>{props.value}</span> 
          }, {
            Header: 'Наименование',
            accessor: 'title',
            Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
            minWidth: 100
          }]

          //console.log(columns)
        
          return  (
            <div><div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>Добавить <i className="fas fa-plus"></i></div> <div className="clear"><br/></div>
            {this.state.category ? <ReactTable
            data={this.state.category}
            resizable={false}
            defaultPageSize = {this.state.category.length}
            getTrProps={(state, rowInfo) => {
              return {
                onClick: () => {this.props.handleCategory(rowInfo.original.id)}
              }
            }}
            columns={columns}
            /> : <div>Ни одной категории не создано</div>}</div>)
    }
}

