import React, { Component } from 'react'
import ViewAwardTab from './view'
//import webApi from '../../../../../../utilities/web-api';
import CreateAward from './create'
import EditAwards from './edit'


export default class AwardTab extends Component {
    state = {
        award: null,
        page:'view',
        id_award: null
    }
    componentWillMount(){
        return true
    }

    updateAward = () =>{
        return true
    }

    handlePage = page => {
        this.setState({page})
    }

    handleAward = id =>{
        console.log(id)
        this.setState({
            id_award: id,
            page: 'edit'
        })
    }

    render() { 
        return (
            <div>
                {this.state.page === 'view' && <ViewAwardTab handleAward={this.handleAward} handlePage = { this.handlePage } award={this.state.award}/>}
                {this.state.page === 'create' && <CreateAward updateAward={this.updateAward} handlePage = { this.handlePage }/>}
                {this.state.page === 'edit' && <EditAwards id_award={this.state.id_award} handlePage={this.handlePage}/>}
            </div>
        )
    }
}
