import React, { Component } from 'react'
import UserBlock from './user-block'
import AwardBlock from './award-block'
import UserData from './user-data'
import UserTasks from './user-tasks';
import CompleteTask from './complete-task';

export default class ViewProfileUser extends Component {
    render() {
        let { user } = this.props

        return user && (
            <div>
                <div className="user-profile-left">
                    <UserBlock user={user}/>
                    <AwardBlock award={user.achievements}/>
                    {user.timeline && <CompleteTask timeline={user.timeline}/>}
                </div>
                <div className="user-profile-right">
                    <UserData user={this.props.user}/>
                    <UserTasks user={this.props.user}/>
                </div>
                <div className="clear"></div>
            </div>

        )
    }
}
