import React, { Component } from 'react'
import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import { header } from './../../../../utilities/page'
import MaterialType from './material-type'
import SelectDate from './select-date'
import SearchType from './search-type'
import SelectedList from './selected-list'
import SubmitButtons from './submit-buttons'
import webApi from '../../../../utilities/web-api'
import inclusiveSearchConstructor from './../../../elements/search-contructor'

export default class Tasks extends Component {
  state = {
    users: [],
    from_date: moment().toISOString(),
    due_date: moment().add(1, 'month').toISOString(),
    materialType: 'program',
    materialId: null,
  }

  updateMaterial = (materialId, materialType) => this.setState({ materialId, materialType, users: [] })

  updateFromDate = from_date => this.setState({ from_date })

  updateDueDate = due_date => this.setState({ due_date })

  updateUsers = users => this.setState({
    users: uniqBy([...this.state.users, ...users], ({ id }) => id)
  })

  deleteUser = id => this.setState({
    users: this.state.users.filter(user => user.id !== id)
  })

  clearUsers = () => {
    this.setState({users: []})
    if(document.getElementById('react-select-2-input')) document.getElementById('react-select-2-input').value = ''
    if(document.querySelector('.css-xp4uvy')) document.querySelector('.css-xp4uvy').textContent = ''
  }

  getFieldName = type => {
    switch (type) {
      case 'program': return 'program_id'
      case 'course': return 'course_id'
      default: throw new Error('getFieldName wrong type passed')
    }
  }

  submitTasks = async () => {
    if(document.getElementById('react-select-2-input')) document.getElementById('react-select-2-input').value = ''
    if(document.querySelector('.css-xp4uvy')) document.querySelector('.css-xp4uvy').textContent = ''
    try {
      const {users, from_date, due_date, materialType, materialId} = this.state

      if (!materialId) throw new Error('materialId is required')

      const type = this.getFieldName(materialType)
      const requestData = users.map(user => ({
        user_id: user.id,
        from_date,
        due_date,
        [type]: materialId
      }))
      await webApi.createTimelines(requestData)
      header.getSnackbar('Задачи назначены успешно.')
      this.clearUsers()
    } catch (err) {
      if (err.code === 510) {
        header.getSnackbar('На данных юзерах уже назначены задачи.')
      } else {
        header.getSnackbar('Не удалось создать назначить задачи.')
        console.error(err)
      }
    }
  }

  componentWillMount() {
    header.pageName('Создание задач')
    header.newBreadcrumps([{ title: 'Задачи', route: '/tasks' }])
  }

  get isValidData() {
    const {users, from_date, due_date, materialType, materialId} = this.state
    return Boolean(
      materialType && from_date && due_date && materialId && !isEmpty(users)
    )
  }

  render() {
    return (
      <div className="user-right-content w-50 task-page">
        <MaterialType
          update={this.updateMaterial}
          search={inclusiveSearchConstructor}
        />
        <SelectDate
          updateFromDate={this.updateFromDate}
          updateDueDate={this.updateDueDate}
          from_date={this.state.from_date}
          due_date={this.state.due_date}
        />
        {this.state.materialId && this.state.materialType &&
          <SearchType
            update={this.updateUsers}
            search={inclusiveSearchConstructor}
            materialType={this.state.materialType}
            materialId={this.state.materialId}
          />
        }
        <SelectedList
          users={this.state.users}
          deleteUser={this.deleteUser}
        />
        <SubmitButtons
          clearUsers={this.clearUsers}
          submitTasks={this.submitTasks}
          isValid={this.isValidData}
          canClear={!isEmpty(this.state.users)}
        />
      </div>
    )
  }
}
