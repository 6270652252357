import React, { Component } from 'react'
import moment from 'moment';

export default class TestStat extends Component {

    state = {
        timeStamp: 168,
        timeStampRun: 0
    }

    render() {
        const { type, q_length, q_num, points, min_points, result } = this.props
        let resultTime
        if(this.props.data){
            
            let fAt = moment(this.props.data.created_at).valueOf()
            let fNow = moment(this.props.data.finished_at).valueOf()
            let result = moment(fNow).diff(fAt)
            resultTime = moment(result).utc().format('HH:mm:ss')
        }

        return (
        <div className="test-stat">
            <div className="test-timer">
                <i className="fas fa-stopwatch"></i> <span id="test-timer">{`${resultTime}/${this.props.data && moment().startOf('day').seconds(this.props.data.test.time_to_pass).format('HH:mm:ss')}`}</span>
            </div>
            <div className="test-quest">
                <i className="fas fa-tasks"></i> { type !== 'finish' ? 'вопрос '+q_num+'/'+q_length : `${ points && points}/${min_points && min_points.min_score_points} - тест ${result ? 'сдан' : 'не сдан'}`}
                </div>
        </div>
        )
    }
}
