import React, { Component } from 'react'
import history from '../../../history';
import { header } from './../../../../../../utilities/page';
import webApi from '../../../../../../utilities/web-api';
import getDiskSpace from '../../../../../../utilities/disk-space';


export default class ViewCreateAward extends Component {
    state={
      title: "",
      images: "",
      description: "",
      active: true
    }

    componentWillMount(){
      header.pageName("Новое достижение")
      webApi.getAward(this.props.id_award).then(data => {
          if(data.status === 200){
              let res = data.response[0]
              this.setState({
                title: res.title,
                images: res.images,
                description: res.description,
                active: res.active
              })
          }
      })
    }

    handleHistory = () => {
        history.push('/settings')
    }

    uploadImg = e => {
        header.uploader(e.target.files, result => {
            delete result.status
            this.setState({images: result})
        })
    }

    handleInput = e =>{
      this.setState({[e.target.name]: e.target.value})
    }

    handleImg = e =>{
        this.setState({images: e})
    }

    createAward = () =>{
        if(this.state.images){
            let obj = this.state
            obj.id = this.props.id_award
            delete obj.images.size

            webApi.updateAwards(obj)
            .then(data => {
                if(data.status === 200 || data.status === 201){
                    this.props.updateAward()
                    this.props.handlePage('view')
                    return header.getSnackbar("Достижение успешно обновлено")
                }else{
                    header.getSnackbar("Произошла ошибка")
                }
            })
            .catch(error => console.log(error))
        }else header.getSnackbar("Выберите изображение для достижения")
    }



    render() {
        /*
        title: "",
      images: "",
      description: ""
        */
       let { title, description, active } = this.state
        return (
            <div className="user-right-content">
                <div className="block-content">
                <div className="href cur-p" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад<br/><br/></div>
                    <div className="w-50 fl-left">
                        Наименование достижения <br/>
                        <input type="text" defaultValue={title && title} onChange = { this.handleInput } name="title" className="inputTraining"  placeholder="Введите название достижения"/> <br/><br/>
                        Описание достижения <br/>
                        <input type="text" defaultValue={description && description} className="inputTraining" onChange = { this.handleInput } name="description" placeholder="Введите описания достижения"/> <br/><br/>
                        <br/>
                        <div className="default-btn fl-left" onClick={() => getDiskSpace(result => result && this.setState({images: result}), {type: false, fullUrl: true})} >Загрузить изображение</div>
                        {/* <input type="file" className="hide" onChange={this.uploadImg} id="_program-cover"/> */}
                    </div>
                    <div className="w-50 fl-left center">
                        <div className="frame-award">
                            <b>{this.state.title ? this.state.title : 'Наименование достижения'}</b> <br/><br/>
                            {this.state.images ? <img src = { this.state.images.original } alt = { this.state.images.original } width="100" height="100"/> : <div className="no-img-award"><i className="fas fa-award"></i></div>}<br/><br/>
                            {this.state.description ? this.state.description : 'Описание достижения'}
                        </div>
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button className="btnSaveProfile fl-left" onClick={ this.createAward }>Обновить достижение</button>
                    <button className="btnSaveProfile fl-left mglr-10" onClick={() => this.setState(state => ({active: !state.active})) }>{active ? 'Деактивировать' : 'Активировать'}</button>
                </div>
            </div>
        )
    }
}