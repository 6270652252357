import React, { Component } from 'react'
//import src from '../../../../img/educ3.jpg'
import { header } from '../../../utilities/page';
import webApi from '../../../utilities/web-api';


export default class Ts extends Component {
    state = {
        srcNewImg: null,
        imageFile: null,
        imageSrc: null,
        img: null,
        canvasW: 0,
        canvasH: 0,
        ratio: '1-1',
        posX: 10,
        posY: 10,
        w: 0,
        h: 0,
        scale: 160,
        resize: false
    }

    b64toBlob = (b64Data, contentType, sliceSize) => {
        contentType = contentType || ''
        sliceSize = sliceSize || 512

        let byteCharacters = atob(b64Data)
        let byteArrays = []

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize)

            let byteNumbers = new Array(slice.length)
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

      let blob = new Blob(byteArrays, {type: contentType});
      blob.aspect_ratio = '16-9'

      let file = new File(byteArrays, `${Date.now()}.png`, {type: contentType, lastModified: Date.now()})
      return file;
    }

    fileResult = () => {

        let { posX, posY, img} = this.state

        console.log(img)
        let c=document.getElementById('canvas_t');

        let MAX_WIDTH = 500
        let MAX_HEIGHT = 500
        let width = img.width
        let height = img.height
        let ratio = 1

        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            ratio = Math.max(width / MAX_WIDTH, height / MAX_HEIGHT)
            width = width / ratio
            height = height / ratio
        }

        c.width = width
        c.height = height
        let origW = width
        let origH = height

        console.log(c.width, c.height, " | ", width, height, " | ", img.width, img.height)
        let ctx = c.getContext('2d')
        ctx.clearRect(0, 0, 500, 500)

        ctx.drawImage(img, 0, 0, width, height )
        let dataCanvas = this.state.ratio !== "original" ? ctx.getImageData(posX, posY, this.state.w, this.state.h) : ctx.getImageData(0, 0, origW, origH)

        let convert_img = document.createElement('canvas')

        convert_img.width = this.state.w
        convert_img.height = this.state.h

        convert_img = convert_img.getContext('2d')
        if(this.state.ratio !== "original") convert_img.rect(posX, posY, this.state.w, this.state.h);
        else convert_img.rect(0, 0, origW, origH);
        convert_img.fillStyle = 'white';
        convert_img.fill();
        convert_img.putImageData(dataCanvas, 0, 0);

        let convert = new Image()

        convert.src = convert_img.canvas.toDataURL("image/png")

        let ImageURL =  this.state.ratio !== "original" ? convert.src : img.src

        //console.log(img.src)

        let block = ImageURL.split(";");

        let contentType = block[0].split(":")[1];
        let realData = block[1].split(",")[1];

        let blob = this.b64toBlob(realData, contentType);

        let formDataToUpload = new FormData();

        formDataToUpload.append("file", blob);

        this.props.togglePreloader(true)
        header.closeDialog(blob)
        webApi.setUpload(formDataToUpload, this.state.ratio === "original" ? "4-3" : this.state.ratio)
        .then(async () => {
            await this.props.getFilesRefresh()
            await this.props.togglePreloader(false)
        })
    }

    handleCanvas = () => {
        let { posX, posY, img, ratio} = this.state

        let c=document.getElementById('canvas_t');
            if(c){
                let MAX_WIDTH = 500
                let MAX_HEIGHT = 500
                let width = img.width
                let height = img.height
                let ratioNew = 1

                if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                    ratioNew = Math.max(width / MAX_WIDTH, height / MAX_HEIGHT)
                    width = width / ratioNew
                    height = height / ratioNew
                }

                c.width = width
                c.height = height
                let ctx = c.getContext('2d')
                ctx.clearRect(0, 0, 500, 500)
                ctx.filter = 'grayscale(100) blur(3px)'

                ctx.drawImage(img, 0, 0, width, height )
                ctx.filter = 'grayscale(0) blur(0)'

                if(ratio !== "original"){
                    ctx.strokeRect(posX, posY, this.state.w, this.state.h)
                    //ctx.drawImage(img, posX * ratio, posY * ratio, this.state.w * ratio, this.state.h * ratio, posX, posY, this.state.w, this.state.h);
                    ctx.drawImage(img, posX * ratioNew, posY * ratioNew, this.state.w * ratioNew, this.state.h * ratioNew, posX, posY, this.state.w, this.state.h);
                    ctx.fillStyle = 'rgba(138, 196, 235, 0.7)'
                    ctx.fillRect(posX + this.state.w - 5,posY + this.state.h - 5, 10,10)
                }else{
                    ctx.strokeRect(0, 0, this.state.w, this.state.h)
                    //ctx.drawImage(img, posX * ratio, posY * ratio, this.state.w * ratio, this.state.h * ratio, posX, posY, this.state.w, this.state.h);
                    ctx.drawImage(img, 0, 0, width * ratioNew, height * ratioNew, 0, 0, width, height);
                }
            }
    }


    getDataUri = (url, callback) => {
        let image = new Image()

        image.onload = function () {
            var canvas = document.createElement('canvas')
            canvas.width = this.naturalWidth
            canvas.height = this.naturalHeight

            canvas.getContext('2d').drawImage(this, 0, 0)


            callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''))


            callback(canvas.toDataURL('image/png'))
        };

        image.src = url
    }

    mousePosition = (e) => {
        let conv = document.getElementById('canvas_t')
        let rect = conv.getBoundingClientRect()
        let posX = (e.clientX - rect.left) - (this.state.w / 2)
        let posY = (e.clientY - rect.top) - (this.state.h / 2)
        if(posX <= 0) posX = 1
        if(posX >= conv.width - this.state.w) posX = (conv.width - this.state.w) - 1
        if(posY <= 0) posY = 1
        if(posY >= conv.height - this.state.h) posY = (conv.height - this.state.h) - 1
        this.setState({posX, posY})
    }

    forma_orig = () => {
        let h = this.state.h
        let w = this.state.w
        this.setState({w, h, ratio: 'original'})
    }

    forma_16_9 = () => {
        let h = this.state.h
        let w = h *(16/9)
        this.setState({w, h, ratio: '16-9'})
    }

    forma_4_3 = () => {
        let h = this.state.h
        let w = h *(4/3)
        this.setState({w, h, ratio: '4-3'})
    }

    forma_1_1 = () => {
        let h = this.state.h
        let w = h
        this.setState({w, h, ratio: '1-1'})
    }

    convertImageToCanvas = () => {
        let canvas = document.getElementById('canvas_t')
        canvas = new Image();
	    canvas.src = canvas.toDataURL("image/png");
	    return canvas;
    }

    handleScale = e => {
        this.setState({scale: e.target.value}, () => {
            let ratio = this.state.ratio.split('-')
            this.setState({
                h:this.state.scale,
                w: this.state.h *(ratio[0]/ratio[1])
            })
        })

    }

    componentDidMount(){
        this.props.src && console.log(this.props.src)
        if(this.props.src){

        let file = this.props.src

        let reader = new FileReader()

        reader.onload = r => {
            this.setState({
                imageFile: file,
                imageSrc: r.target.result
            }, async () => {
                let newImg = new Image()
                newImg.src = this.state.imageSrc
                newImg.onload = () => {
                    console.log(newImg.src, newImg.width)

                    this.setState({
                        img: newImg,
                        //canvasW: newImg.width,
                        //canvasH: newImg.height,
                        canvasW: 500,
                        canvasH: 500,
                        ratio: '1-1',
                        posX: 10,
                        posY: 10,
                        // h: newImg.height/2,
                        // w: newImg.height/2
                        h: 100,
                        w: 100
                    })
                }

            })
        }
        reader.readAsDataURL(file);
    }

    }

    resize = e =>{
        let { posX, posY, ratio} = this.state
        let rect = document.getElementById('canvas_t').getBoundingClientRect()
        let X = e.clientX - rect.left
        let Y = e.clientY - rect.top
        let newCordY = Y - posY - this.state.h
        //let newCordX = X - posX - this.state.w

        ratio = ratio.split('-')
        if(X > posX + this.state.w  || Y > posY + this.state.h){
            this.setState({h: this.state.h += newCordY, w: this.state.h * (ratio[0]/ratio[1]) })
        }else{
            this.setState({h: this.state.h -= -newCordY, w: this.state.h * (ratio[0]/ratio[1]) })
        }
    }

    mouseMove = e => {
        let { posX, posY, w, h} = this.state
            let rect = document.getElementById('canvas_t').getBoundingClientRect()
            let X = e.clientX - rect.left
            let Y = e.clientY - rect.top

            let x = document.getElementById('canvas_t')


            function move(){
                x.addEventListener("mousemove", self.mousePosition);
            }

            let self = this


            function removeHandler() {
                x.removeEventListener("mousemove", self.resize);
                x.removeEventListener("mousemove", self.mousePosition);

            }

            function removeResize() {
                x.removeEventListener("mousemove", self.resize);
            }

            function addResize(){

                x.addEventListener("mousemove", self.resize);

            }
            if((X >= posX + w - 5 && X <= posX + w + 5) && (Y >= posY + h - 5 && Y <= posY + h + 5)){
                document.getElementById('canvas_t').style.cursor="nwse-resize"
                this.setState({resize: true}, () => {
                    x.addEventListener('mousedown', removeHandler)
                    x.addEventListener('mousedown', addResize)
                    x.addEventListener('mouseUp', removeResize)
                })
            }

            else{
                document.getElementById('canvas_t').style.cursor="move"
                this.setState({resize: false}, () => {
                    if(!this.state.resize)
                    {
                        x.addEventListener('mousedown', removeHandler)
                        x.addEventListener('mousedown', removeResize)
                        x.addEventListener('mousedown', move)
                        x.addEventListener('mouseup', removeHandler)
                    }
                })
            }
    }

    handleFileImage = e => {
        let file = e.target.files[0]
        let reader = new FileReader()

        reader.onload = r => {
            this.setState({
                imageFile: file,
                imageSrc: r.target.result
            }, async () => {
                let newImg = new Image()
                newImg.src = this.state.imageSrc
                newImg.onload = () => {
                    this.setState({
                        img: newImg,
                        //canvasW: newImg.width,
                        //canvasH: newImg.height,
                        canvasW: 500,
                        canvasH: 500,
                        ratio: '1-1',
                        posX: 10,
                        posY: 10,
                        // h: newImg.height/2,
                        // w: newImg.height/2
                        h: 100,
                        w: 100
                    })
                }

            })
        }
        reader.readAsDataURL(file);

    }

    render() {
        this.state.img && this.handleCanvas(this.state.img)

        return this.props.src && (
            <div>
                {/* <input onChange={this.handleFileImage} type="file" name="file" /> */}
                <canvas onMouseMoveCapture={this.mouseMove} id="canvas_t" height={this.state.canvasH} width={this.state.canvasW}></canvas>
                <div className="hr"></div>
                <button className="btnSaveProfile mglr-10" onClick={this.forma_orig}>Оригинальная</button>
                <button className="btnSaveProfile mglr-10" onClick={this.forma_16_9}>16:9</button>
                <button className="btnSaveProfile mglr-10" onClick={this.forma_4_3}>4:3</button>
                <button className="btnSaveProfile mglr-10" onClick={this.forma_1_1}>1:1</button>
                <div className="hr"></div>
                <button className="btnSaveProfile mglr-10" onClick={this.fileResult} >Сохранить</button>

            </div>
        )
    }
}
