import React, { Component } from 'react'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';
import Select from 'react-select';

export default class CreateDepartament extends Component {
    state = {
        company_id: null,
        company: null
    }

    componentWillMount(){
        webApi.getCompany().then(data => data.status === 200 && this.setState({company: data.response}, () => console.log(this.state)))
    }

    handleCreate = () => {
        let {title, company_id} = this.state

        let obj = {title, company_id}
        if(!obj.title) return header.getSnackbar("Название отдела не заполнено")
        if(!obj.company_id) return header.getSnackbar("Название компании не заполнено")
        

        webApi.setDepartment(obj)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Новый отдел успешно создан")
                this.props.handlePage('view')
            }
        })
    }

    handleInput = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    onChangeSelect = e => {
        this.setState({company_id: e.id})
    }

    render() {
        return (  
            <div>
                <span className="href" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>     
                <div className="create-form">
                    <span><b>Название отдела</b></span> <br/>
                    <input name = "title" onChange={this.handleInput} type="text" placeholder="Введите название отдела"/> <br/><br/>
                    <span><b>Название компании</b></span> <br/>
                    {/* <input type="text" defaultValue={this.props.company && this.props.company} disabled/> <br/><br/> */}
                    <Select 
                        onChange={this.onChangeSelect}
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        options = {this.state.company || [{id: 0, title: "Нет ни одной компании"}]}
                        placeholder="Выберите компанию"
                    /><br/><br/>
                    <div className="btnSaveProfile fl-left" onClick={this.handleCreate}>Создать</div>
                </div>
            </div>
        )
    }
}
