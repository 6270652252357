import React, { Component } from 'react'
import TogglePanel from '../../../elements/TogglePanel/view'
import webApi from '../../../../utilities/web-api';


export default class ViewAwardTab extends Component {

    state ={
        select: null,
        data: null
    }

    componentWillMount(){
        const params = new URLSearchParams(this.props.location.search)
        const token = params.get('token')

        webApi.getHelperMobile(token).then(data => {
            if(data.status === 200){
                this.setState({data: data.response})
            } 
        })
    }

    handleSelect = e => {
        console.log(e)
    }
    
    render() {
        let {data} = this.state
        //let {award} = this.props
        return (
            <div className="block-content">
                {/* <div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>
                    Добавить <i classNam
                    e="fas fa-plus"></i>
                </div> */}
                {
                    data ? data.map((e, i) => (<div key = {i} className="relative"><TogglePanel title = {e.question} body = {e.answer} /><br/></div>)) : <div>Ни одного раздела помощи не создано</div>
                }
                                
        </div>
        )
    }
}
