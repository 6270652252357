import React, { Component } from 'react'
import ViewProfile from './view'
import BlockEducation from '../contents/block-education'
import webApi from '../../../../utilities/web-api';
import { connect } from 'react-redux';
import { header } from './../../../../utilities/page';
import history from '../history';

class Profile extends Component {
  state ={
    numMaterial: 7,
    materials: null,
    arrMaterial: '',
    awards: null,
    awardsAll: null,
    loaderMaterial: false,
    loaderLast: false,
    limit: 5,
    offset: 0
  }

  /*this.setState({materials: data}, () => {
      // let { materials, numMaterial } = this.state
      // let i = 0
      // let mat = []
      // for (const material in materials) {
      //   i++
      //   mat = [...mat, materials[material]]
      //   if(i >= numMaterial) break
      // }
      // this.setState({arrMaterial: mat})
    })*/

  componentWillMount(){
    let { limit, offset} = this.state
    header.pageName('Профиль')
    header.newBreadcrumps([{title:'Профиль', route: '/'}])

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms(limit, offset)
      .then(data => {
        if(data.status === 200){
          this.setState({
            materials: data.response,
            loaderMaterial: false
          })
        }else {this.setState({loaderMaterial: false})}
      })
      .catch(console.error)
    })

    this.props.user && webApi.getAchievement(this.props.user.id)
                        .then(data => {
                            if(data.status === 200){
                              delete data.status
                              this.setState({awards: data.response})
                            }}
                          )

    webApi.getAward()
    .then(data => {
        if(data.status === 200){
          delete data.status
          this.setState({awardsAll: data.response})
        }}
      )
  }

  loadMaterial = () =>{
    let { limit, offset, materials } = this.state

    this.setState({loaderMaterial: true}, () => {
      webApi.getProgramms(limit, offset + limit)
      .then(data => {
        if(data.status === 200){
          this.setState({
            materials: [...materials, ...data.response],
            offset: this.state.offset + limit,
            loaderMaterial: false
          })
        }else this.setState({loaderLast: true, loaderMaterial: false})
      })
    })
  }

  handlePush = (e, index) =>{
    if (index.after_test_id && !index.after_test) {
      webApi.getTest(index.after_test_id)
        .then(data => {
          data.status === 200 && header.getSnackbar(`Чтобы перейти к обучению материала, пройдите тест "${data.response.title}"`)
        })
    } else {
      header.newBreadcrumps([{title:'Материалы', route: '/training'}, {title:e.currentTarget.dataset.title, route: '/courses/'+e.currentTarget.dataset.route}])
      history.push('/courses/'+e.currentTarget.dataset.route)
    }
  }


  renderMaterial = () =>{
    let { materials } = this.state
    if(materials){
      //filter(e => e.active === true)
      return materials.sort(({ order: ordA }, { order: ordB }) => ordA - ordB).map(index => {
        return(<div className="cur-p" key={ index.id } data-title={index.title} data-route={index.id} onClick={e => this.handlePush(e, index)}><BlockEducation body = {index}/></div>)
      })
    }
    else {
      return (<div><br/>На данный момент материалов для обучения нет</div>)
    }
  }

  numMat = (num) => {
    this.setState({numMaterial: num})
  }

  render() {

    return (
      <ViewProfile
        loadMaterial = {this.loadMaterial}
        awardsAll={this.state.awardsAll}
        awards ={this.state.awards}
        user={this.props.user}
        blockEducation = { this.renderMaterial() }
        numMat = { this.numMat }
        loaderLast = {this.state.loaderLast}
        loaderMaterial = {this.state.loaderMaterial}
      />
    )
  }
}

export default connect((store) => {
  return {
    store: store.user.token,
    user: store.user.user_data,
  }
})(Profile)
