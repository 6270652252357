import React from 'react'

export default ({ innerHtml }) => {
  return (
    <div className='lesson-material-type mixed'>
      <div
        className="lesson-mixed"
        dangerouslySetInnerHTML={{ __html: innerHtml }}
      />
    </div>
  )
}
