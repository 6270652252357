import React, { Component } from 'react'
import ViewSignIn from './view'
import webApi from '../../../utilities/web-api'
import {getRefreshToken, getToken, setRefreshToken, setUser} from '../../../store/actions/client/action'
import { showSnackbar, hideSnackbar } from '../../../store/actions/elements/action'
import { connect } from 'react-redux'
import { header } from './../../../utilities/page';
import ViewUpdate from '../../client/right-panel/header/update-new-user'

class SignIn extends Component {
    state = {
        login: '',
        password: ''
    }

    handleFields = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
//17cf34
    handleUserUpdate = () =>{
        header.getDialog({
            title: 'Заполните личную информацию',
            content: <ViewUpdate/>
        })
    }

    sendForm = e => {
        e.preventDefault()
        let { getToken, setUser, getRefreshToken } = this.props
        webApi.signIn(this.state)
        .then(async data => {
            if(data.status === 200){
                if(!data.active) return this.props.showSnackbar('Пользователь заблокирован')
                await setUser(data)


                let { name } = await this.props.state

                if(name !== null){
                    await localStorage.setItem('token', data.token)
                    await getToken(data.token)
                }

                else {
                    /*this.props.handleForm('UPDATE')*/
                    await getToken(data.token)
                    await localStorage.setItem('token', data.token)
                    await this.handleUserUpdate()
                }
            }
            else this.props.showSnackbar('Логин или пароль введены неверно')
        })
        .catch(error => console.log(error))
    }

    render() {
        return (
            <ViewSignIn handleFields = { this.handleFields } sendForm = { this.sendForm } />
        )
    }
}

const mapDispatchToProps = { getToken, setUser, showSnackbar, hideSnackbar, getRefreshToken }

export default connect((state) => {
    return {
        state: state.user.user_data,
        modal: state.modals.snackbar
    }
}, mapDispatchToProps)(SignIn)
