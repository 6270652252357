import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import webApi from '../../../../../../utilities/web-api';
import Moment from 'react-moment';

export default class ViewObjectTab extends Component {
    state={
        filial: [],
        department: null,
    }

    componentWillMount(){
        webApi.getDepartment()
        .then(data => {
            if(data.status === 200) {
              let filial_new = []

                data.response.forEach(async (el) => {
                      await webApi.getCompanyId(el.company_id).then(data => el.company = data.response[0].title)
                      //await webApi.getCompanyId(el.company_id).then(data =>  console.log(data.response[0].title))

                      await el.active ? el.active = "Активный":  el.active = "Отключен"
                      el.created_at = await <Moment format="DD.MM.YYYY/hh:mm">{el.created_at}</Moment>

                      await filial_new.push(el)
                      await this.setState({filial: filial_new})
                })


            }
        })
        .catch(console.error)

    }

    render() {
      let columns = [{
        Header: "ID",
        accessor: 'id', // String-based value accessors!
        minWidth: 100,
        Cell: props => <span className='number'>{props.value}</span>
      }, {
        Header: 'Компания',
        accessor: 'company',
        Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
        minWidth: 100
      }, {
        Header: 'Отдел',
        accessor: 'title',
        Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
        minWidth: 100
      }, {
        Header: 'Дата создания',
        accessor: 'created_at',
        Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
        minWidth: 100
      },{
        Header: 'Состояние',
        accessor: 'active',
        Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
        minWidth: 100
      }]
          return (
            <div>
            <div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>Добавить <i className="fas fa-plus"></i></div> <div className="clear"><br/></div>
            {/* {this.state.filial && this.state.filial.length && <ReactTable data={this.state.filial} resizable={false} defaultPageSize = {this.state.filial.length} columns={columns}/>} */}
            <div className="table fix-table-programms">
            <div className="table-header">
                <div className="table-column">ID</div>
                <div className="table-column">Компания</div>
                <div className="table-column">Отдел</div>
                <div className="table-column">Дата создания</div>
                <div className="table-column">Состояние</div>
            </div>
            {this.state.filial && this.state.filial.map((program, i) =>{
            return(
            <div data-column={i+1} key={i}>
              <div className="table-line" onClick={() => this.props.handleDep(program.id)}>
                <div className="table-column">{program.id}</div>
                <div className="table-column">{program.company}</div>
                <div className="table-column">{program.title}</div>
                <div className="table-column">{program.created_at}</div>
                <div className="table-column">{program.active}</div>
              </div>
            </div>)
          })}</div>
            </div>)
      }
}

