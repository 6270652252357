import React, {useRef, useState} from 'react';
import './style.css'
import Button from "@material-ui/core/Button";
import store from "../../../../../store";
import {changeModal, changeSuccessModal} from "../../../../../store/actions/elements/action";
import Icon from '../../../../../../img/icon_cancel.svg'
import inclusiveSearchConstructor from "../../../../elements/search-contructor";
import webApi from "../../../../../utilities/web-api";
import AsyncSelect from "../../report/availability-result/filter/async-multiselect";
import Select from "react-select";
import {header} from "../../../../../utilities/page";
import IconFile from '../../../../../../img/attached-file-icon.svg';
import {ReactComponent as IconCancelWhite} from "../../../../../../img/icon_cancel_white.svg";
import bytes from 'bytes';
import OpenFolder from '../../../../../../img/folderopen.svg';
import SuccessIssueModal from "../complete-modal/view";

const CreateIssueModal = ({setAppeals, setAppealsAllCount}) => {

    const [isActive, setIsActive] = useState(false)
    const [filesSrc, setFilesSrc] = useState({});
    const [id, setId] = useState(null);
    const ref = useRef(null);

    const removeFile = () => {
        if (ref.current) {
            ref.current.value = '';
        }
    };

    const removeAllFile = () => {
        setFilesSrc({});
    };

    const [selects, setSelects] = useState({
        category: [],
        program: [],
        course: [],
        lecture: [],
        test: [],
        text: '',
    })

    const [require, setRequire] = useState({
        category: true,
        program: true,
        course: true,
        lecture: true,
        test: false,
        text: true,
    })

    const [categoryVal, setCategoryVal] = useState('')

    const acceptFileType = 'image/*,.png,.docx,.doc,.pdf,.jpg,.jpeg';
    const maxFileSize = 5 * 1024 * 1024;

    const [drag, setDrag] = useState(false);

    const dragStartHandler = event => {
        event.preventDefault();
        setDrag(true);
    };

    const dragLeaveHandler = event => {
        event.preventDefault();
        setDrag(false);
    };

    const onDropHandler = event => {
        event.preventDefault();
        const [file] = event.dataTransfer.files;

        if (file.size > maxFileSize) {
            header.getSnackbar('Ваш файл превысил разрешенный размер')
            setDrag(false);
            return;
        }

        dispatch(addPhoto(file));
    };

    const onChangeHandler = event => {
        if (event.target.files) {
            const {files} = event.target;

            for (let i = 0; i < files.length; i++) {
                const file = files.item(i);

                if (file) {
                    if (file.size > maxFileSize) {
                        header.getSnackbar('Ваш файл превысил разрешенный лимит в 5MB')
                        return;
                    }

                    if (!acceptFileType.includes(file.name.split('.').pop())) {
                        header.getSnackbar('Данный тип файла не поддерживается')
                        return;
                    }

                    const reader = new FileReader();

                    reader.onload = (ev) => {
                        setFilesSrc(prevState => ({
                            ...prevState,
                            [file.name]: {
                                data: String(ev.target.result) || '',
                                size: file.size,
                                file
                            },
                        }));
                    };

                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const handleRemoveImage = key => {
        setFilesSrc(prevState => {
            const {[key]: removedValues, ...values} = prevState;
            return values;
        });
        removeFile();
    };

    store.subscribe(() => {
        setIsActive(store.getState().modals.modal)
    })

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '40px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '40px',
            position: 'static'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            transform: 'translateY(0)',
            position: 'static'
        }),
        placeholder: (provided, state) => ({
            ...provided,
        }),
        indicatorSeparator: (provided, state) => ({}),
    }

    const closeHandler = () => {
        store.dispatch(changeModal(false))
        setSelects({
            category: [],
            program: [],
            course: [],
            lecture: [],
            test: [],
            text: '',
        })
        setCategoryVal('')
      setFilesSrc({})
    }

    const onValue = (name, value) => {
        setSelects({...selects, [name]: value})
    }

    const onCategorySelectValue = (e) => {
        setSelects({
            category: e.value,
            program: [],
            course: [],
            lecture: [],
            test: [],
            text: '',
        })
        setCategoryVal(e.value)

        switch (e.value) {
            case 'test' :
                return setRequire({...require, course: false, lecture: false, test: true})
            case 'review' :
                return setRequire({...require, program: false, course: false, lecture: false, text: true, test: false})
            case 'content' :
                return setRequire({
                    category: true,
                    program: true,
                    course: true,
                    lecture: true,
                    test: false,
                    text: true,
                })
            default:
                return setRequire({
                    category: true,
                    program: true,
                    course: true,
                    lecture: true,
                    test: false,
                    text: true,
                })
        }
    }

    const inclusiveSearchReducer = ({id, title}) => ({value: id, label: title})

    const textareaHandler = (e) => {
        setSelects({...selects, text: e.target.value})
    }

    let isDone = false
    const compareObjects = (obj1, obj2) => {
        let res = false
        const keys = Object.keys(obj1);

        for (const key of keys) {
            if (obj1[key] === false) {
                continue; // Skip iteration for keys with false value
            }

            if (
                obj1[key] === true &&
                obj2[key] &&
                (typeof obj2[key] === "object" &&
                    obj2[key].value !== undefined &&
                    obj2[key].value.trim() !== "") || (typeof obj2[key] === "string" && obj2[key])
            ) {
                continue; // Move to the next key
            } else {
                header.getSnackbar('Заполните необходимые поля')
                return;
            }
        }
        isDone = true
    }

    const sendIssue = () => {
        let req = {}
        for (let key in selects) {
            if (selects[key].value || key !== 'category' && key !== 'text') {
                req[key + '_id'] = selects[key].value
            }
        }
        req.category = selects.category
        req.text = selects.text

        compareObjects(require, selects)

        if (!isDone) {
            return
        }

        webApi.createIssue(req)
            .then(res => {
                store.dispatch(changeSuccessModal(true));
                const formDateFile = new FormData();
                setId(res.id)
                Object.values(filesSrc).forEach(({file}) => {
                    formDateFile.append('file', file, file.name);
                })
                webApi.addFile(res.id, formDateFile).then(() => {
                    header.getSnackbar('Ваши файлы успешно загружены!');
                })
            })
            .then(() => {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());

                webApi.getAppeals(params)
                    .then(res => {
                        setAppeals(res.items)
                        setAppealsAllCount(res.count)
                    })
                    .catch(() => {
                        setAppealsAllCount(0)
                        setAppeals([])
                    })
            })
            .catch(error => console.log(error))
            .finally(() => {
                closeHandler()
            })

        return req
    }

    return (
        <>
            <div onClick={closeHandler} className={`wrapper ${isActive ? 'active-wrapper' : ''}`}>
                <div onClick={e => e.stopPropagation()} className={`modal-wrapper ${isActive ? 'active-modal' : ''}`}>
                    <div className="modal-header">
                        <div className="title">Новое обращение</div>
                        <span className={'close'} onClick={closeHandler}><img src={Icon} alt={'icon'}/></span>
                    </div>
                    <div className="modal-body">
                        <div className="modal-select-wrapper">
                            <div className="select-title">Категория проблемы {require.category &&
                              <span className={'require-tag'}>*</span>}</div>
                            <Select
                              onChange={onCategorySelectValue}
                              styles={customStyles}
                              options={[{value: 'content', label: 'Ошибка контента'}, {value: 'test', label: 'Тест'}, {
                                  value: 'review',
                                  label: 'Отзыв'
                              }]}
                              placeholder={'Выберите категорию'}
                              name={'category'}
                              key={store.getState().modals.modal}
                            />
                        </div>
                        <div className="modal-select-wrapper">
                            <div className="select-title">Программа {require.program &&
                              <span className={'require-tag'}>*</span>}</div>
                            <AsyncSelect
                              styles={customStyles}
                              submit={onValue}
                              findCallback={inclusiveSearchConstructor(
                                webApi.inclusiveSearchPrograms,
                                'Такая программа не найдена.'
                              )}
                              placeholder="Выберите программу"
                              name='program'
                              additional={{offset: 0}}
                              isDisabled={selects.category === 'review' || !categoryVal}
                              isMulti={false}
                              key={selects.category || store.getState().modals.modal}
                            />
                        </div>
                        <div className="modal-select-wrapper">
                            <div className="select-title">Курс {require.course &&
                              <span className={'require-tag'}>*</span>}</div>
                            <AsyncSelect
                              styles={customStyles}
                              submit={onValue}
                              findCallback={inclusiveSearchConstructor(
                                webApi.getCoursesSelect, '', inclusiveSearchReducer, selects.program.value
                              )}
                              placeholder={'Выберите курс'}
                              isDisabled={!selects.program.value || selects.category === 'review'}
                              isMulti={false}
                              name={'course'}
                              programmId={selects.program.value && selects.program.value}
                              key={selects.program.value || store.getState().modals.modal}
                            />
                        </div>
                        <div className="modal-select-wrapper">
                            <div className="select-title">Лекция {require.lecture &&
                              <span className={'require-tag'}>*</span>}</div>
                            <AsyncSelect
                              submit={onValue}
                              styles={customStyles}
                              findCallback={inclusiveSearchConstructor(
                                webApi.getLectureIssue, 'У выбранного курса лекций нет', inclusiveSearchReducer, selects.course.value)}
                              placeholder={'Выберите лекцию'}
                              isDisabled={!selects.course.value || selects.category === 'review'}
                              isMulti={false}
                              name={'lecture'}
                              additional={{offset: 0}}
                              key={[selects.program.value, selects.course.value]}
                            />
                        </div>
                        <div className="modal-select-wrapper">
                            <div className="select-title">Тест {require.test &&
                              <span className={'require-tag'}>*</span>}</div>
                            <AsyncSelect
                              submit={onValue}
                              styles={customStyles}
                              findCallback={inclusiveSearchConstructor(
                                webApi.getProgramTests, '', inclusiveSearchReducer, selects.program.value)}
                              placeholder={'Выберите тест'}
                              isDisabled={selects.category === 'review' || !selects.category || selects.category === 'content'}
                              isMulti={false}
                              name={'test'}
                              key={[selects.program.value, selects.category]}
                            />
                        </div>
                        <div className="text-wrapper">
                            <div className="select-title">
                                Текст обращения
                                <span className={'require-tag'}> *</span>
                            </div>
                            <textarea name={'text'} value={selects.text} onChange={textareaHandler} placeholder={'Текст...'}
                                      className={'modal-textarea'}/>
                        </div>
                        <div className="text-wrapper">
                            <div className="select-title">Документы</div>

                            <label className="input-file-label">
                                <input
                                  className="text-wrapper-file-input"
                                  ref={ref}
                                  type="file"
                                  multiple
                                  onChange={onChangeHandler}
                                />
                                <img src={OpenFolder} alt={'open folder'}/>
                                <span className="input-file-description">
                                {'Прикрепить не более 5 файлов общим объёмом до 5МБ. Форматы doc, docx, pdf, jpeg, jpg, png'}
                            </span>
                            </label>

                            {(!!Object.keys(filesSrc).length) && Object.keys(filesSrc).map(key => (
                              <div className="file-wrapper" key={key}>
                                  <img src={IconFile} alt={'file-icon'}/>
                                  <div className="file-info">
                                      <div className="file-name">{key}</div>
                                      <div className="file-size">{
                                          bytes(filesSrc[key] ? filesSrc[key].size : 0, {unit: 'KB'})
                                      }</div>
                                  </div>
                                  <div className="delete-file-icon-wrapper" onClick={() => handleRemoveImage(key)}>
                                      <IconCancelWhite/>
                                  </div>
                              </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-button">
                        <Button
                          onClick={sendIssue}
                          className={'button-create'}
                          color={'inherit'}
                          fullWidth={true}
                        >
                            Отправить обращение
                        </Button>
                    </div>
                </div>
            </div>
            <SuccessIssueModal id={id} />
        </>
    );
};

export default CreateIssueModal;
