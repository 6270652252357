import React, {useEffect, useState} from 'react';
import './style.css'
import Icon from '../../../../../../img/attached-file-icon.svg'
import IconComment from '../../../../../../img/note-remove.svg'
import Button from "@material-ui/core/Button";
import {useParams, useHistory} from "react-router-dom";
import webApi from "../../../../../utilities/web-api";
import moment from "moment";
import Helpers from "../../../../../utilities/helpers";
import store from "../../../../../store";
import {header} from "../../../../../utilities/page";

const IssuePage = () => {
  let history = useHistory();

  const [data, setData] = useState();
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState()
  const [files, setFiles] = useState([])

  const {id} = useParams()

  const [isUser, setIsUser] = useState(false)

  useEffect(() => {
    header.pageName('Обращения')
    header.newBreadcrumps([{title: '', route: `/issues${id}`}])
    setIsUser(!store.getState().user.user_permission.some(permission => permission.type === 'appeals' && permission.write))
    webApi.getAppeal(id).then(res => {
      setComment(res.comment)
      setData(res)
    })
    webApi.getUploadedFiles(id).then(res => setFiles(res))
  }, []);


  const handleSaveChanges = async () => {
    if (data.status === 'closed') return history.push('/issues')

    if (comment && comment !== data.comment) {
      await webApi.setAppealComment({
        id,
        comment
      })

      return history.push('/issues')
    }
    await webApi.setAppealStatus({
      id,
      status
    })

    history.push('/issues')
  }

  const {
    statusColor,
    statusTranslate,
    categoryTranslate
  } = Helpers

  const getFileName = (file) => {
    return file.split('/').pop()
  }

  const AttachedFiles = ({files}) => {
    return files.map(el => {
      return (
        <a className={'file-link'} href={el.url}>
          <div className="file-wrapper" key={el.url}>
            <img src={Icon} alt={'file-icon'}/>
            <div className="file-info">
              <div className="file-name">{getFileName(el.url)}</div>
              <div className="file-size">{el.size} Kb</div>
            </div>
          </div>
        </a>
      )
    })
  }

  return (
    data && <div className={'user-right-content issue'}>
      <div style={{padding: '20px 30px'}}>
        <span className="href" onClick={() => history.push('/issues')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
        <div className="issue-wrapper">
          <div style={{borderBottom: '1px solid #E7EAEE'}}>
            <div className="issue-header">
              <div className="issue-title">
                Обращение #{data.id}
              </div>
              {isUser
                ?
                <span className={`issue-status-span ${statusColor(data.status)}`}>{statusTranslate(data.status)}</span>
                : <select
                  className="issue-status-select"
                  style={{backgroundRepeat: 'no-repeat', backgroundSize: '5px 5px, 5px 5px, 2.5em 2.5em'}}
                  name='issue-status'
                  defaultValue={data.status}
                  onChange={e => setStatus(e.target.value)}
                  disabled={data.status === 'closed'}
                >
                  <option value={'new'}>Новое</option>
                  <option value={'progress'}>В работе</option>
                  <option value={'closed'}>Закрытое</option>
                </select>
              }
            </div>
          </div>
          <div className="issue-body">
            <div className={`${isUser ? 'issue-section user' : 'issue-section'}`}>
              <div className="issue-info relative">
                {isUser && data.comment &&
                  <div className={'is-comment'}><img src={IconComment} alt={'icon'}/> <span>Комментарий</span></div>}
                {!isUser
                  ? <div className="issue-username">{data.user}</div>
                  : (data.comment && <div className='issue-textarea'>
                    {data.comment}
                    <div className={'answer-info'}>
                      {data.comment && `${data.commentator}, ${moment(data.updated_at).format('DD.MM.YYYY, HH:mm')}`}
                    </div>
                  </div>)
                }
                <div className="issue-info-item">
                  <span className={'issue-info-type'}>Тема обращения:</span>
                  <span className={'issue-info-name'}> {categoryTranslate(data.category) || '-'}</span>
                </div>
                <div className="issue-info-item">
                  <span className={'issue-info-type'}>Программа:</span>
                  <span className={'issue-info-name'}> {data.program || '-'}</span>
                </div>
                <div className="issue-info-item">
                  <span className={'issue-info-type'}>Курс:</span>
                  <span className={'issue-info-name'}> {data.course || '-'}</span>
                </div>
                <div className="issue-info-item">
                  <span className={'issue-info-type'}>Лекция:</span>
                  <span className={'issue-info-name'}> {data.lecture || '-'}</span>
                </div>
                <div className="issue-info-item">
                  <span className={'issue-info-type'}>Тест:</span>
                  <span className={'issue-info-name'}> {data.test || '-'}</span>
                </div>
              </div>
              {!isUser && <div className="issue-date">
                {moment(data.created_at).format('DD.MM.YYYY, HH:mm')}
              </div>}
            </div>
            <div className="issue-section text-content">
              <div className="issue-text-title">
                Текст обращения:
              </div>
              <div className="issue-text-content">
                {data.text || '-'}
              </div>
            </div>
            <div className="attached-files">
              <AttachedFiles files={files}/>
            </div>
          </div>
          {!isUser && <div className="issue-footer">
            <textarea
              placeholder={'Комментарий...'}
              className={'issue-textarea'}
              onChange={e => setComment(e.target.value)}
              value={comment}
              disabled={data.status === 'closed'}
            />
            <Button
              onClick={handleSaveChanges}
              size={'large'}
              className={'issue-button'}
            >
              {data.status === 'closed' ? 'Вернуться обратно' : 'Сохранить и закрыть'}
            </Button>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default IssuePage;
