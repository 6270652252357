import React, {useEffect, useRef, useState} from 'react';
import Button from "@material-ui/core/Button";
import './style.css'
import {header} from "../../../../utilities/page";
import store from "../../../../store";
import {changeModal} from "../../../../store/actions/elements/action";
import {Link, useHistory} from "react-router-dom";
import {ReactComponent as CommentIcon} from '../../../../../img/comment-icon.svg'
import ArrowSortIcon from '../../../../../img/sort-arrow-icon.svg'
import AttachIcon from '../../../../../img/attach-icon.svg'
import AsyncSelect from "../../right-panel/report/availability-result/filter/async-multiselect";
import inclusiveSearchConstructor from "../../../elements/search-contructor";
import webApi from "../../../../utilities/web-api";
import Helpers from "../../../../utilities/helpers";
import moment from "moment";
import CreateIssueModal from "./create-issue-modal/view";
import {Pagination} from "../../../elements/pagination/pagination";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {styled} from "@mui/material/styles";
import {omitBy} from "lodash";
import isEmpty from "lodash/isEmpty";

const Issues = () => {
  const {
    statusColor,
    statusTranslate,
    categoryTranslate
  } = Helpers

  const history = useHistory();

  const [isUser, setIsUser] = useState(false)
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('desc');
  const [status, setStatus] = useState('new');
  const [offset, setOffset] = useState('0');
  const [limit, setLimit] = useState('25');
  const [pagePagination, setPagePagination] = useState(1);
  const [appealsAllCount, setAppealsAllCount] = useState(0);
  const [selectedIssues, setSelectedIssues] = useState({})
  const [appeals, setAppeals] = useState([])
  const [filters, setFilters] = useState({
    programId: '',
    courseId: '',
    testId: '',
    lectureId: '',
    userId: '',
  })

  const refScroll = useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({behavior: "smooth", block: "start"})
  };

  useEffect(() => {
    store.subscribe(() => {
      setIsUser(!store.getState().user.user_permission.some(permission => permission.type === 'appeals' && permission.write))
    })
    header.pageName('Обращения')
    header.newBreadcrumps([{title: '', route: '/issues'}])
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const queryParams = new URLSearchParams(omitBy({search, sort, status: isUser ? '' : status, offset, limit, ...filters}, isEmpty));
      history.push({search: `?${queryParams}`});

      webApi.getAppeals({search, sort, status: isUser ? '' : status, offset, limit, ...filters})
        .then(res => {
          setAppeals(res.items)
          setAppealsAllCount(res.count)
        })
        .catch(() => {
          setAppealsAllCount(0)
          setAppeals([])
        })
    }, 500);
    return () => clearTimeout(timer);
  }, [search, sort, status, offset, limit, isUser]);

  const handleSelectIssue = (id, value) => {
    if (!value) {
      setSelectedIssues(prevState => {
        const {[id]: deletedId, ...newPrevState} = prevState;
        return newPrevState
      })

      return
    }
    setSelectedIssues(prevState => ({...prevState, [id]: value}))
  }

  const GenerateRows = ({data}) => {
    return data && data.map(item => {
      return <Link key={item.id} className={'issue-link'} data-route={`issues/${item.id}`} data-title="Обращение"
                   to={`issues/${item.id}`}>
        <tr className={'issues-table body-wrapper body-row'}>
          <td className={'id'}>
            {!isUser && item.status !== 'closed' &&
              <input
                id="checkboxIssueId"
                className={'custom-checkbox'}
                onClick={e => e.stopPropagation()}
                checked={selectedIssues[item.id]}
                onChange={(e) => handleSelectIssue(item.id, e.target.checked)}
                type='checkbox'/>}
            <label htmlFor="checkboxIssueId"></label>
            {item.id}
          </td>
          <td>
            {moment(item.created_at).format('DD.MM.YYYY, HH:mm')}
          </td>
          <td>
            <span className={`body-status ${statusColor(item.status)}`}>
              {statusTranslate(item.status)}
            </span>
          </td>
          <td className={'body-desc'}>
            <div className={'body-desc-wrapper'}>
              <div className={'body-desc-wrapper desc-title'}>{categoryTranslate(item.category)}</div>
              <div className={'body-desc-wrapper desc-content'}>
                <p>{item.text}</p>
              </div>
            </div>
            {item.has_file && <img src={AttachIcon} alt={'icon'} />}
          </td>
          <td className={'body-comment'}>
            <div className={'comment-icon'} data-content={item.comment}>{item.comment &&
              <CommentIcon className={'comment-icon-svg'} alt={'icon'}/>}</div>
          </td>
        </tr>
      </Link>
    })
  }

  const handleChangeStatus = (newStatus) => setStatus(newStatus)

  const handleCloseByIds = async () => {
    await webApi.closeIssuesByIds({ids: Object.keys(selectedIssues)});
    setSelectedIssues({});

    const queryParams = new URLSearchParams(omitBy({search, sort, status: isUser ? '' : status, offset, limit, ...filters}, isEmpty));
    history.push({search: `?${queryParams}`});

    webApi.getAppeals({search, sort, status, offset, limit, ...filters})
      .then(res => {
        setAppealsAllCount(res.count)
        setAppeals(res.items)
      })
  }

  const onValue = (name, value) => {
    const newFilters = {...filters, [name]: value !== null ? value.value : ''}
    setFilters(newFilters)

    const queryParams = new URLSearchParams(omitBy({search, sort, status: isUser ? '' : status, offset, limit, ...newFilters}, isEmpty));
    history.push({search: `?${queryParams}`});

    webApi.getAppeals({search, sort, status, offset, limit, ...newFilters})
      .then(res => {
        setAppeals(res.items)
        setAppealsAllCount(res.count)
      })
      .catch(() => {
        setAppeals([])
        setAppealsAllCount(0)
      })
  }
  // const userReducer = ({ id, name, surname }) => ({ value: id, label: `${name} ${surname}` })

  const userReducer = ({id, name, surname}) => ({value: id, label: `${name} ${surname}`})

  const onChangePaginationHandler = (page) => {
    setOffset(String((page-1) * Number(limit)))
    setPagePagination(page)
    scrollToRef(refScroll)
  }

  const onChangeLimit = (event) => {
    setOffset('0')
    setPagePagination(1)
    setLimit(event.target.value)
    scrollToRef(refScroll)
  }

  const customStyle = {
    container: (provided, state) => ({
      ...provided,
      border: 'none',
      height: 40
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      height: '100%'
    }),
    indicatorSeparator: (provided, state) => ({}),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 14,
      // height: state.isFocused ? 'auto%' : '100%',
      top: state.isActive ? '67%' : '50%'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: 14,
      // height: state.isFocused ? 'auto%' : '100%',
      top: !state.isActive && '50%',
      maxWidth: '170px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      position:  'static'
    }),
  }

  const StyledSelect = styled(Select)(({ theme }) => ({
    borderRadius: '4px',
    background: '#F4F4F4',
    overflow: 'hidden',
    '& > div > div': {
      padding: '0px 16px',
      width: '127px',
      display: 'flex',
      height: '40px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      background: '#F4F4F4 !important',
    },
    '& > div > svg': {
      right: '10px',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  }));

  return (
    <div className={'user-right-content issue'} ref={refScroll}>
      {!isUser && <div className={'status-row'}>
        <div className={'status-wrapper'}>
          <div
            onClick={() => handleChangeStatus('new')}
            className={'item ' + (status === 'new' ? 'active' : '')}
          >
            Новые
          </div>
          <div
            onClick={() => handleChangeStatus('progress')}
            className={'item ' + (status === 'progress' ? 'active' : '')}
          >
            В работе
          </div>
          <div
            onClick={() => handleChangeStatus('closed')}
            className={'item ' + (status === 'closed' ? 'active' : '')}
          >
            Закрытые
          </div>
          <div
            onClick={() => handleChangeStatus('')}
            className={'item ' + (status === '' ? 'active' : '')}
          >
            Все
          </div>
        </div>
        <Button className={'add-issue-button'} onClick={() => store.dispatch(changeModal(true))}
                variant={'outlined'}><span
          style={{fontSize: 18, marginRight: 10}}>+</span><span>Создать обращение</span></Button>
      </div>}
      <div className={`search-wrapper ${isUser && 'user-mode'}`}>
        <div className="actions-wrapper">
          {!isUser && (
            <input
              placeholder={'Поиск по номеру обращения'}
              className={'search-input'}
              type={'text'}
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
          )}
          {!isUser && <div className={'search-user-wrapper'}>
            <AsyncSelect
              styles={customStyle}
              submit={onValue}
              findCallback={inclusiveSearchConstructor(
                webApi.inclusiveSearchUser,
                'Такой пользователь не найден.',
                userReducer, '', true
              )}
              isMulti={false}
              placeholder="Выберите пользователя"
              name='userId'
              additional={{offset: 0}}
              isClearable={true}
              backspaceRemovesValue={true}
              isLoading={false}
            />
          </div>}
        </div>
        {!isUser ?
          <Button
            className={Object.keys(selectedIssues).length > 0 ?  'change-status-button active-button' : 'change-status-button'}
            onClick={handleCloseByIds}
          >
            Отметить как закрытое
          </Button> :
          <Button className={'add-issue-button'} onClick={() => store.dispatch(changeModal(true))}
                  variant={'outlined'}><span
            style={{fontSize: 18}}>+</span><span>Создать обращение</span></Button>}
      </div>
      {!isUser && <div className="filters">
        <div className={'filter-item-wrapper'}>
          <div className='select-title'>Программа</div>
          <AsyncSelect
            className={'filter-select'}
            submit={onValue}
            findCallback={inclusiveSearchConstructor(
              webApi.inclusiveSearchPrograms,
              'Такая программа не найдена.'
            )}
            placeholder="Выберите программу"
            name='programId'
            additional={{offset: 0}}
            isMulti={false}
            isClearable={true}
            backspaceRemovesValue={true}
          />
        </div>
        <div className={'filter-item-wrapper'}>
          <div className='select-title'>Курс</div>
          <AsyncSelect
            className={'filter-select'}
            submit={onValue}
            findCallback={inclusiveSearchConstructor(
              webApi.inclusiveSearchCourses,
              'Такой курс не найден.'
            )}
            placeholder="Выберите курс"
            name='courseId'
            additional={{offset: 0}}
            isMulti={false}
            isClearable={true}
            backspaceRemovesValue={true}
          />
        </div>
        <div className={'filter-item-wrapper'}>
          <div className='select-title'>Лекция</div>
          <AsyncSelect
            className={'filter-select'}
            submit={onValue}
            findCallback={inclusiveSearchConstructor(
              webApi.getAllLectures,
              'Такая лекция не найдена.'
            )}
            placeholder="Выберите лекцию"
            name='lectureId'
            isMulti={false}
            isClearable={true}
            backspaceRemovesValue={true}
          />
        </div>
        <div className={'filter-item-wrapper'}>
          <div className='select-title'>Тест</div>
          <AsyncSelect
            className={'filter-select'}
            submit={onValue}
            findCallback={inclusiveSearchConstructor(
              webApi.getAllTests,
              'Такой тест не найден.'
            )}
            placeholder="Выберите тест"
            name='testId'
            isMulti={false}
            isClearable={true}
            backspaceRemovesValue={true}
          />
        </div>
      </div>}
      <table className={'issues-table'}>
        <thead className={'head-wrapper'}>
        <tr className={'head-row'}>
          <th onClick={() => setSort(prev => prev === 'asc' ? 'desc' : 'asc')}
              className={'id-admin'}>
            Номер обращения
            <img className={sort === 'asc' && 'sort-arrow'} src={ArrowSortIcon} alt={'icon'}/>
          </th>
          <th className={'date'}>Дата</th>
          <th className={'status'}>Статус</th>
          <th className={'desc'}>Описание проблемы</th>
          <th className={'comment'}>Комментарий</th>
        </tr>
        </thead>
        {appeals.length ? (
          <tbody className={'issues-table body-wrapper'}>
          <GenerateRows data={appeals}/>
          </tbody>
        ) : <div className={'nothing-wrapper'}>
          <div className={''}>К сожалению, ничего не найдено.</div>
        </div>}
      </table>
      <div className={'pagination-select-wrap'}>
        <Pagination allCount={Math.ceil(appealsAllCount / Number(limit))}
                    page={pagePagination}
                    onChange={(page) => onChangePaginationHandler(page)}/>
        <StyledSelect
            value={limit}
            onChange={onChangeLimit}
            displayEmpty
        >
          <MenuItem value={'25'}>{'25 записей'}</MenuItem>
          <MenuItem value={'50'}>{'50 записей'}</MenuItem>
          <MenuItem value={'100'}>{'100 записей'}</MenuItem>
        </StyledSelect>
      </div>
      <CreateIssueModal setAppeals={setAppeals} setAppealsAllCount={setAppealsAllCount}/>
    </div>
  );
};

export default Issues
