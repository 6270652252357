import React, { Component } from 'react'
import Select from 'react-select';
import webApi from '../../../../../utilities/web-api';
import getDiskSpace from './../../../../../utilities/disk-space';
import { header } from '../../../../../utilities/page';

export default class EditCreateProgram extends Component {
    state = {
        title : '',
        description: '',
        program: null,
        programTeacher: null,
        after_test_input: []
    }

    // setProgrm = () =>{
    //     webApi.setProgramm(this.state)
    //     .then(data => console.log(data))
    //     .catch(error => console.log(error))
    // }

    // handleInput = e => {
    //     this.setState({[e.target.name]: e.target.value}, () => console.log(this.state))
    // }

    componentWillMount(){
        webApi.getCategory(50,0)
            .then(async data => {
                if(data.status === 200){
                    await this.setState({program:data.response})
                    console.log(data.response)
                    await webApi.getTeacher().then(data => {
                        if(data.status === 200) this.setState({programTeacher: data.response})
                    }).catch(error => console.log(error))

                }
            })
            .catch(error => console.log(error))
    }

    programsOptions = () => {
        let programs = []
 
        this.state.program && this.state.program.map(el =>{
            return programs = [...programs, {value: el.id, label: el.title}]
        })

        return programs
    }

    teachersOptions = () => {
        let teachers = []

        this.state.programTeacher && this.state.programTeacher.map(el =>{
            return teachers = [...teachers, {value: el.id, label: el.name + '' + el.surname}]
        })

        return teachers
    }

    availabilityLesson = val => {
        if(val.length){
            webApi.getTestLike(val)
            .then(e => {
                if(e.status === 200){
                let res = e.response
                    //console.log(res)
                    this.setState({after_test_input: res})
                }else{
                    header.getSnackbar('Ничего не найдено')
                    this.setState({after_test_input: []})
                }              
            })
        }
    }
    /*input:{
            ...this.state.input,
            [e.target.name]: e.target.value
        }

    */

    changeSelectAvailability = e =>{
        this.setState({
            after_test_id: e
        })
    }

    removeAfter = () => {
        this.setState({after_test_id: null}, () => {
            let inp = document.querySelector('.fix-selector-width input')
            inp.value = ''
        })
    }

    render = () => {
        let {
            handleInput,
            updateProgram,
            uploadCover,
            handleActive,
            handleInActive,
            program,
            assigneTeacher,
            assigneCat,
            programTeacher,
            insert
        } = this.props

        if(programTeacher){
            program = {...program, nameTeacher: programTeacher.name, surnameTeacher: programTeacher.surname}
        }

        return program && (
            <div className="user-right-content">
                <div className="block-content">
                <span className="href" onClick={header.historyBack}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                    <div className="w-50 fl-left">
                        Наименование программы обучения <br/>
                        <input onChange = { handleInput } defaultValue={ program.title } type="text" name="title" className="inputTraining"  placeholder="Введите название программы"/> <br/><br/>
                        Категория программы обучения (<span className="min-span light-silver">{program.category && program.category.title ? program.category.title : 'Категория не назначена'}</span>)<br/>
                        <Select noOptionsMessage = {() => '...'} placeholder="Выберите категорию" loadingMessage={"..."}  onChange={e => assigneCat(e.value)} options={this.programsOptions()} /> <br/>
                        Краткое описание программы <br/>
                        <textarea defaultValue={ program.description } onChange = { handleInput } name="description" className="inputTrainingHeight" placeholder="Опишите программу"></textarea> <br/><br/>
                        Преподаватель (<span className="min-span light-silver">{program.surnameTeacher ? `${program.surnameTeacher} ${program.nameTeacher}` : 'Преподаватель не назначен'}</span>)<br/>
                        <Select noOptionsMessage = {() => '...'} placeholder="Выберите преподавателя" loadingMessage={"..."} onChange={e => assigneTeacher(e.value)} options={this.teachersOptions()} /> <br/>
                        Рекомендуемое время на прохождение (мин)<br/>
                        <input defaultValue={ program.estimation/60 } onChange = { handleInput } value={insert.estimation ? insert.estimation || '' : program.estimation/60} name="estimation"  type="num" className="inputTraining" placeholder = "Введите рекомендуемое время на прохождение" /> <br/><br/>
                        Доступность материала<br/>
                        <i className="fas fa-times fix-del-after-icon" onClick={this.removeAfter}></i>
                        {program.after_test_title && <Select
                            getOptionValue = {(option) => (option['id'])}
                            className="fix-selector-width"
                            getOptionLabel = {(option) => (option['title'])}
                            value = {this.state.after_test_id}
                            //defaultValue = {[{id: course.after_test_id, title: course.after_test_title}]}
                            defaultInputValue = {program.after_test_title}
                            options = {this.state.after_test_input}
                            onInputChange = {this.availabilityLesson}
                            onChange = {this.changeSelectAvailability}
                            placeholder = "Выберите тест после которого программа будет доступна"
                        />}
                        {!program.after_test_title && <Select
                            getOptionValue = {(option) => (option['id'])}
                            getOptionLabel = {(option) => (option['title'])}
                            value = {this.state.after_test_id}
                            className="fix-selector-width"
                            options = {this.state.after_test_input}
                            onInputChange = {this.availabilityLesson}
                            onChange = {this.changeSelectAvailability}
                            placeholder = "Выберите тест после которого программа будет доступна"
                        />}
                        <br/>
                    </div>
                    <div className="w-50 fl-left center">
                        Обложка (jpg/png) <br/><br/>
                        { this.props.insertImg && <img className="no-img-training img_cover_material" src={this.props.insertImg.normal} alt="img"/> }
                        { (program.images && !this.props.insertImg) && <img className="no-img-training img_cover_material" src={program.images.original} alt="img"/> }
                        { (!program.images && !this.props.insertImg) && <div className="no-img-training">320x240</div> }
                        <br/>
                        <input type="file" className="hide" onChange={e => uploadCover(e.target.files)} id="_program-cover"/>
                        <button className="btnSaveProfile" onClick={() => getDiskSpace(result => uploadCover(result),{type:false, fullUrl: true})} >Выбрать файл</button>
                         
                    </div>
                    <div className="clear"></div>
                    <br/>
                    <button onClick = {() => updateProgram(this.state.after_test_id) } className="btnSaveProfile fl-left">Сохранить</button>
                    {program.active ? <button onClick = { handleInActive } className="btnSaveProfile fl-left mg-l">Деактивировать</button>:
                    <button onClick = { handleActive } className="btnSaveProfile fl-left mg-l">Активировать</button>}
                    {/* <button onClick = {() => history.push('/create-course')} className="btnSaveProfile fl-right">Добавить курс в данную программу <i className="fas fa-arrow-right btnSaveProfileFix"></i></button> */}
                </div>
            </div>
        )
    }
}
