import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import webApi from '../../../../../../utilities/web-api';
import Moment from 'react-moment';

export default class ViewObjectTab extends Component {
    state={
        company: []
    }

    componentWillMount(){
        webApi.getCompany()
        .then(data => {
            if(data.status === 200) {
                    let filial_new = []

                data.response.forEach((el) => {
                    el.active ? el.active = "Активный":  el.active = "Отключен"
                    el.created_at = <Moment format="DD.MM.YYYY/hh:mm">{el.created_at}</Moment>
                    filial_new.push(el)
                })

                this.setState({company: filial_new})
            }
        })
        .catch(error => console.log(error))

    }

    render() {

        // let col = data => {
        //     let genColumns = []

        //     for(let i = 0; i < data.length; i++){
        //         //genColumns.push({[Object.keys[data]]: data[Object.keys[data][i]]})
        //         genColumns.push({
        //             Header: Object.keys(data[i])[i],
        //             accessor: data[0][Object.keys(data[i])[i]],
        //             Cell: props => <span className='number'>{props.value}</span>
        //         })
        //     }

        //     return genColumns
        // }

        // const genColumns = []


        // for(let i = 0; i < data.length; i++){
        //     //genColumns.push({[Object.keys[data]]: data[Object.keys[data][i]]})
        //     genColumns.push({
        //         Header: Object.keys(data[i])[i],
        //         accessor: data[0][Object.keys(data[i])[i]],
        //         Cell: props => <span className='number'>{props.value}</span>
        //     })
        // }

          const columns = [{
            Header: "ID",
            accessor: 'id', // String-based value accessors!
            minWidth: 100,
            Cell: props => <span className='number'>{props.value}</span>
          }, {
            Header: 'Компания',
            accessor: 'title',
            Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
            minWidth: 100
          }, {
            Header: 'Дата создания',
            accessor: 'created_at',
            Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
            minWidth: 100
          },{
            Header: 'Состояние',
            accessor: 'active',
            Cell: props => <span className='number'>{props.value}</span>, // Custom cell components!
            minWidth: 100
          }]

          //console.log(columns)
          console.log(this.props.handleCompany)
          return (
            <div>
              <div className="btnSaveProfile fl-right" onClick={()=>this.props.handlePage('create')}>Добавить <i className="fas fa-plus"></i></div> <div className="clear"><br/></div>
            {this.state.company.length ? <ReactTable
            data={this.state.company}
            resizable={false}
            defaultPageSize = {this.state.company.length}
            getTrProps={(state, rowInfo) => {
              return {
                onClick: () => {this.props.handleCompany(rowInfo.original.id)}
              }
            }}
            columns={columns}
            />: <div>Ни одной компании не создано</div>}</div>)
    }
}

